import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './UserManagement.html';
import UserManagementService from './UserManagementService';
import { worker } from 'cluster';
import { FilterModel } from './FilterModel';
import { UserManagementModel } from './UserManagementModel';
import { Toaster } from '../../Services/toast';
import ValidationService from '../../Services/ValidationService';
import UserService from '../../shared/userService/UserService';
import UserRoleService from '../../shared/userService/UserRoleService';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        UserManagementService,
    ],
})
export default class UserManagement extends Vue {
    @Inject(UserManagementService) public userManagementService!: UserManagementService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public typeOptions: any[] = [
        {
            id: 1,
            name: 'Admin',
        },
        {
            id: 2,
            name: 'Provider User',
        },
        {
            id: 3,
            name: 'CITCAdmin',
        },
        {
            id: 4,
            name: 'CITCUser',
        },
        {
            id: 5,
            name: 'ProviderAdmin',
        },
    ];
    public headers: any = [
        { text: this.$t('shared.userName'), value: 'username', align: 'center', sortable: false },
        { text: this.$t('shared.userEmail'), value: 'email', align: 'center', sortable: false },
        { text: this.$t('shared.phoneNumber'), value: 'mobileNumber', align: 'center', sortable: false },
        { text: this.$t('shared.type'), value: 'roleName', align: 'center', sortable: false },
        {
            text: this.$t('userManagementPage.providerName'),
            sortable: false, value: 'providerName', align: 'center',
        },
        {
            text: this.$t('userManagementPage.OperatorName'),
            sortable: false, value: 'operatorName', align: 'center',
        },
        {
            text: this.$t('dashboardPage.status'),
            sortable: false, value: 'isLocked', align: 'center',
        },
        { text: this.$t('shared.actions'), sortable: false, value: 'id', align: 'center', width: '75' },
    ];
    public users: UserManagementModel[] = [];
    public usersCount: number = 0;
    public userId: string = '';
    public isLocked: boolean = false;
    public superUserDialog: boolean = false;
    public userName: string = '';
    public lockDialog: boolean = false;
    public resendPasswordDialog: boolean = false;
    public userRole: any; 
    public User: any;
    public usersFiltered: UserManagementModel[] = [];
    public filterModel: FilterModel = {
        username: '',
        mobile: '',
        email: '',
    };

    public created() {
        this.$emit('childinit', this.layoutSup);
    }

    public async mounted() {
        this.User = await this.userService.getUser();
        this.userRole = await this.userRoleService.GetUserRole(this.User);
        this.search();
    }

    @Watch('filterModel', { deep: true })
    public async search() {
        const response = await this.userManagementService.Get
            (this.filterModel.username, this.filterModel.email, this.filterModel.mobile, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.users = response.result;
        this.usersCount = response.totalCount;
    }
    public isEqual(value1: string | null, value2: string) {
        if (value2 === undefined) {
            value2 = '';
        }
        return (value1 === '' || value1 === null || value2.includes(value1));
    }
    public async filter(val: any) {
        const response = await this.userManagementService.Get
            (this.filterModel.username, this.filterModel.email, this.filterModel.mobile, '0', '10');
    }
    public async updatePaginate(val: any) {
        const response = await this.userManagementService.Get
            (this.filterModel.username, this.filterModel.email, this.filterModel.mobile,
                (val.page - 1).toString(), val.rowsPerPage);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.users = response.result;
        this.usersCount = response.totalCount;
    }

    public showSuperUserDialog(evt: any, id: string) {
        evt.preventDefault();
        this.userId = id;
        this.superUserDialog = true;
    }

    public hideSuperUserDialog() {
        this.userId = '';
        this.superUserDialog = false;
    }
    public async saveSuperUser() {
        const response = await this.userManagementService.SwitchAdminToSuper(this.userId);
        if (response.isSuccess) {
            Toaster.success(this.$t('userManagementPage.EditUserSuccessfully').toString());
            this.search();
        } else {
            Toaster.error(response.errorMessage);
        }
        this.hideSuperUserDialog();;
    }

    public showLockDialog(evt: any, id: string, isLocked: boolean) {
        evt.preventDefault();
        this.userId = id;
        this.isLocked = !isLocked;
        this.lockDialog = true;
    }

    public hideLockDialog() {
        this.userId = '';
        this.isLocked = false;
        this.lockDialog = false;
    }

    public showResendPasswordDialog(userName: string) {
        this.userName = userName;
        this.resendPasswordDialog = true;
    }

    public hideResendPasswordDialog() {
        this.userName = '';
        this.resendPasswordDialog = false;
    }

    public async saveShortCode() {
        const response = await this.userManagementService.SwitchLock(this.userId, this.isLocked);
        if (response.isSuccess) {
            this.hideLockDialog();
            Toaster.success(this.$t('userManagementPage.EditUserStatusSuccessfully').toString());
            this.search();
        } else {
            Toaster.error(response.errorMessage);
        }
    }

    public async resendPasswordEmail() {
        const response = await this.userManagementService.ResendPasswordEmail(this.userName);
        if (response.isSuccess) {
            this.resendPasswordDialog = false;
            Toaster.success(this.$t('userManagementPage.resendPasswordEmailSuccessfully').toString());
        } else {
            Toaster.error(response.errorMessage);
        }
    }

    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }

    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
}
