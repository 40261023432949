import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { log } from 'util';
import { Inject, Service } from 'vue-di-container';
import { PayfortModel } from '../MainPaymentComponents/PayfortModel';
import { HyperPayModel } from './HyperPayModel';
import { ProviderInvoiceInfoModel } from './ProviderInvoiceInfoModel';

@Service()
export default class PaymentService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async Get(id: string[]): Promise<any> {
        this.loaderService.ShowLoader();
        const ids = id;
        try {
            const url = window.$config.HostName + 'api/payment-transaction';
            const result = await this.axiosService.axiosInstance.post(url, ids);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            return false;
        }
    }
    public async GetInfoToCreateSadadNumber(id: string[]): Promise<any> {
        this.loaderService.ShowLoader();
        const ids = id;
        try {
            const url = window.$config.HostName + 'api/payment-transaction/get-info-to-create-sadad-number';
            const result = await this.axiosService.axiosInstance.post(url, ids);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            return false;
        }
    }
    public async AddPaymentTransaction(id: string[], checkOutId: string, isMadaValue: boolean
        , sadadNumber: string | null, sadadNumberExpireDate: Date | null): Promise<any> {
        this.loaderService.ShowLoader();
        const ids = id;
        const model = {
            ids: id,
            checkOurId: checkOutId,
            isMada: isMadaValue,
            sadadNumber: sadadNumber,
            sadadNumberExpireDate: sadadNumberExpireDate
        };
        try {
            const url = window.$config.HostName + 'api/payment-transaction/add-payment-transaction';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            return false;
        }
    }
    public async GetHyperPayModel(amount: string, IsMada: boolean): Promise<any> {
        try {
            const url = '/HyperPay/GetHyperPayModel?Amount=' + amount + '&IsMada=' + IsMada;
            const result = await this.axiosService.axiosInstance.get(url);
            const Data = result.data;
            return Data;
        } catch (exception) {
            return new HyperPayModel();
        }
    }
    public async getSadadNumber(providerName: string, phoneNumber: string,
        issueDate: string, expireDate: string, senderNames: string,
        unitPrice: string, billNumber: string, providerEmail: string,
        vat: string,): Promise<any> {
        try {
            const url = '/Efaa/UploadSimpleInvoice?providerName=' + providerName
                + '&phoneNumber=' + phoneNumber
                + '&issueDate=' + issueDate
                + '&expireDate=' + expireDate
                + '&senderNames=' + senderNames
                + '&unitPrice=' + unitPrice
                + '&billNumber=' + billNumber
                + '&providerEmail=' + providerEmail
                + '&vat=' + vat;
            const result = await this.axiosService.axiosInstance.get(url);
            const Data = result.data;
            return Data;
        } catch (exception) {
            return new HyperPayModel();
        }
    }
    public async CancelSadadNumbers(sadadNumbers: string[]): Promise<any> {
        try {
            const url = '/Efaa/CancelInvoice';
            const result = await this.axiosService.axiosInstance.put(url, sadadNumbers);
            const Data = result.data;
            return Data;
        } catch (exception) {
            return false;
        }
    }

    public async getAll(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/citckeywords/getall';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async providerHasVatNumber() {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/providers/userProviderHasVatNumber';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getProviderNewInfo() {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/providers/getProviderInvoiceInfo';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async editProviderNewInfo(providerInvoiceInfo: ProviderInvoiceInfoModel): Promise<any> {
        this.loaderService.ShowLoader();
        const request = {
            vatNumber: providerInvoiceInfo.vatNumber,
            country: providerInvoiceInfo.country,
            city: providerInvoiceInfo.city,
            district: providerInvoiceInfo.district,
            streetName: providerInvoiceInfo.streetName,
            buildingNumber: providerInvoiceInfo.buildingNumber,
            postalCode: providerInvoiceInfo.postalCode,
            additionalNumber: providerInvoiceInfo.additionalNumber,
            otherIdentifier: providerInvoiceInfo.otherIdentifier,
        };
        try {
            const url = window.$config.HostName + 'api/providers/editProviderInvoiceInfo';
            const result = await this.axiosService.axiosInstance.put(url, request);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async GetProviderBalance(id: string): Promise<any> {
        try {
            const url = window.$config.HostName + 'api/providers/getbalanceamount/' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            return result.data;
        } catch (exception) {
            return false;
        }
    }
    public async SetCreditLimitTransactionAsPaid(ids: string[], paymentIdentifier: string ): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const model = {
                paymentTransactionIds: ids,
                paymentIdentifier: paymentIdentifier,
            };
            const url = window.$config.HostName + 'api/payment-transaction/set-credit-limit-transaction-as-paid';
            const result = await this.axiosService.axiosInstance.put(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async payRequestEmail(ids: string[]): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/payment-transaction/pay-request-email-creditlimit';
            const result = await this.axiosService.axiosInstance.put(url, ids);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
