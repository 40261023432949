
<div class="container">
    <div class="row">
        <div class="col-md-6 mx-auto mt-5">
            <div class="payment">
                <div class="payment_header">
                    <div class="check"><i class="fa fa-check" aria-hidden="true"></i></div>
                </div>
                <div class="content">
                    <h1 v-if="!isFaild">{{$t('shared.PaymentSuccess')}}</h1>
                    <h1 v-if="isFaild">{{$t('shared.PaymentFaild')}}</h1>
                    <h5 v-if="isFaild">{{$t('shared.PaymentFaildMsg')}}</h5>
                    <br /> <br />
                    <a v-if="!isPublic" href="javascript:void(0);" @click="GotoDashboard">{{$t('shared.GotoDashboard')}}</a>
                </div>

            </div>
        </div>
    </div>
</div>
