import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { UserManagementModel } from './UserManagementModel';

@Service()
export default class UserManagementService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async Get(
        username: string, email: string, mobile: string, pageIndex: string,
        pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/users?username=' + username
                + '&email=' + email
                + '&mobileNumber=' + mobile
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GeById(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/users/' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async Put(model: UserManagementModel): Promise<any> {
        this.loaderService.ShowLoader();
        const request = {
            id: model.id,
            username: model.username,
            email: model.email,
            mobileNumber: model.mobileNumber,
            isLocked: model.isLocked,
            roleId: model.roleId,
            providerId: model.providerId != null ? model.providerId : '',
            ProviderName: '',
            roleName: '',
            operatorName: '',

        };
        try {
            const url = window.$config.HostName + 'api/users';

            const result = await this.axiosService.axiosInstance.put(url, request);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async Post(model: UserManagementModel): Promise<any> {
        this.loaderService.ShowLoader();
        const request = {
            username: model.username,
            email: model.email,
            mobileNumber: model.mobileNumber,
            roleId: model.roleId,
            providerId: model.providerId != null ? model.providerId : '',
            isLocked: model.isLocked,
            ProviderName: '',
            roleName: '',
            operatorName: '',
        };
        try {
            const url = window.$config.HostName + 'api/users';
            const result = await this.axiosService.axiosInstance.post(url, request);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async ResendPasswordEmail(userName: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/users/resend-password-admin?userName=' + userName;
            const result = await this.axiosService.axiosInstance.post(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async GetLinkCertificate(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/certificate/' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async SwitchLock(id: string, isLocked: boolean): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            let url;
            if (isLocked) {
                url = window.$config.HostName + 'api/users/lock?id=' + id;
            } else {
                url = window.$config.HostName + 'api/users/unlock?id=' + id;
            }
            const result = await this.axiosService.axiosInstance.put(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async SwitchAdminToSuper(id: string,): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/users/switch-admin-to-super?id=' + id;
            const result = await this.axiosService.axiosInstance.put(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
