import { Inject } from 'vue-di-container';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Toaster } from '../../../Services/toast';
import ValidationService from '../../../Services/ValidationService';
import { AttachmentCategoryModel } from '../AttachmentCategoryModel';
import AttachmentCategoryService from '../AttachmentCategoryService';
import WithRender from './AddAttachmentCategory.html';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        AttachmentCategoryService,
    ],
})
export default class AddAttachmentCategory extends Vue {
    @Inject(AttachmentCategoryService) public attachmentCategoryService!: AttachmentCategoryService;
    @Inject(ValidationService) public validationService!: ValidationService;

    @Prop({ type: String, default: 'Add' })
    public mode: string | any;

    @Prop({ default: '' })
    public id!: number;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public name: string = '';
    public editCategoryDialog: boolean = false;
    public addCategoryDialog: boolean = false;
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        if (this.isEditMode) {
            const response = await this.attachmentCategoryService.getbyId(this.id) as AttachmentCategoryModel;
            this.name = response.name;
        }
    }
    public get isEditMode() {
        return (this.mode === 'Edit');
    }
    public showeditCategoryDialog(id: number) {
        this.editCategoryDialog = true;
    }
    public hideeditCategoryDialog() {
        this.editCategoryDialog = false;
    }
    public showaddCategoryDialog(id: number) {
        this.addCategoryDialog = true;
    }
    public hideddCategoryDialog() {
        this.addCategoryDialog = false;
    }
    public get isValid(): boolean {
        return (this.name !== '' && this.name.length > 4);
    }
    public async addAttachmentCategoryFun() {
        if (this.isEditMode) {
            const response = await this.attachmentCategoryService.put(this.id, this.name);
            if (response.isSuccess && response.result) {
                this.name = '';
                this.$router.push({ name: 'AttachmentCategory' });
            } else {
                Toaster.error(response.errorMessage);
                this.$router.push({ name: 'AttachmentCategory' });
            }
        } else {
            const response = await this.attachmentCategoryService.post(this.name);
            if (response.isSuccess && response.result) {
                Toaster.success(this.$t('shared.addSuccessfully').toString());
                this.name = '';
                this.$router.push({ name: 'AttachmentCategory' });
            } else {
                Toaster.error(response.errorMessage);
                this.$router.push({ name: 'AttachmentCategory' });
            }
        }
    }
}
