<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.Report')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row justify-content-center">
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.senderName')}}</label>
                                <input class="form-control" v-model="model.senderName" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.clientName')}}</label>
                                <input class="form-control" v-model="model.clientName" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.representativeName')}}</label>
                                <input class="form-control" v-model="model.representativeName" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.numberOfActions')}}</label>
                                <input class="form-control" v-model="model.numberOfActions" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.approveDateFrom')}}</label>
                                <v-menu v-model="approveDateFromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                        offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" v-model="model.approveDateFrom"
                                                   readonly v-bind="attrs" ref="approveDatePicker"
                                                   v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="model.approveDateFrom"
                                                   no-title
                                                   scrollable
                                                   @input="approveDateFromMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.approveDateTo')}}</label>
                                <v-menu v-model="approveDateToMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                        offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" v-model="model.approveDateTo"
                                                   readonly v-bind="attrs" ref="approveDateToPicker"
                                                   v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="model.approveDateTo"
                                                   no-title
                                                   scrollable
                                                   @input="approveDateToMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.createdDateFrom')}}</label>
                                <v-menu v-model="createdDateFromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                        offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" v-model="model.createdDateFrom"
                                                   readonly v-bind="attrs" ref="createdDatePicker"
                                                   v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="model.createdDateFrom"
                                                   no-title
                                                   scrollable
                                                   @input="createdDateFromMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.createdDateTo')}}</label>
                                <v-menu v-model="createdDateToMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                        offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" v-model="model.createdDateTo"
                                                   readonly v-bind="attrs" ref="createdDatePicker"
                                                   v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="model.createdDateTo"
                                                   no-title
                                                   scrollable
                                                   @input="createdDateToMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.providers')}}</label>
                                <v-autocomplete v-model="model.providerId"
                                                :items="providerOptions"
                                                item-text="name"
                                                item-value="id"
                                                :label="$t('shared.selectProvider')"
                                                solo>
                                </v-autocomplete>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.customerType')}}</label>
                                <v-select v-model="model.customerType"
                                          :items="customerTypeList"
                                          :label="$t('shared.select')"
                                          item-text="name"
                                          item-value="id"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.senderType')}}</label>
                                <v-select v-model="model.senderType"
                                          :items="senderTypeList"
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('shared.select')"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.phoneNumber')}}</label>
                                <div class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text gray">
                                            +966
                                        </span>
                                    </div>
                                    <input class="form-control border-left-0" @paste="isNumberFieldPaste($event)" maxlength="9" @keypress="isNumberField($event)" v-model="model.representativeMobile" type="text" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.requestStatus')}}</label>
                                <v-select v-model="model.requestStatus"
                                          :items="requestStatusist"
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('shared.select')"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.paymentDate')}}</label>
                                <v-menu v-model="paymentDateFromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                        offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" v-model="model.paymentDate"
                                                   readonly v-bind="attrs" ref="paymentDatePicker"
                                                   v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="model.paymentDate"
                                                   no-title
                                                   scrollable
                                                   @input="paymentDateFromMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.expiryDate')}}</label>
                                <v-menu v-model="expiryDateFromMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                        offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" v-model="model.expiryDate"
                                                   readonly v-bind="attrs" ref="expiryDatePicker"
                                                   v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="model.expiryDate"
                                                   no-title
                                                   scrollable
                                                   @input="expiryDateFromMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2">
                        <div class="form-row justify-content-end align-items-center">
                            <div class="col-auto pull-left">
                                <button class="btn btn-primary btn-action"
                                        @click="reset">
                                    {{$t('shared.reset')}}
                                </button>
                            </div>
                            <div class="col-auto pull-left">
                                <button class="btn btn-primary btn-action"
                                        @click="exportToExcel">
                                    {{$t('shared.exporttoexcel')}}
                                </button>
                            </div>
                            <div class="col-auto pull-left">
                                <button class="btn btn-primary btn-action"
                                        @click="search">
                                    {{$t('shared.apply')}}
                                </button>
                            </div>
                        </div>
                    </div>
                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-12 pr-0">
                            <div class="card card_layout">
                                <div class="card-body">
                                    <template>
                                        <v-data-table :headers="headers"
                                                      :items="reportModel"
                                                      :total-items="Count"
                                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                                      :rows-per-page-items='[10, 25, 50, 100]'
                                                      @update:pagination="updatePaginate"
                                                      class="elevation-1 table-striped">
                                            <template v-slot:items="props">
                                                <tr>
                                                    <td class="text-center">{{ props.item.requestId }}</td>
                                                    <td class="text-center">{{ props.item.senderName }}</td>
                                                    <td class="text-center">{{ props.item.tenantName }}</td>
                                                    <td class="text-center">{{ props.item.clientName }}</td>
                                                    <td class="text-center">{{ props.item.customerType }}</td>
                                                    <td class="text-center">{{ props.item.senderType }}</td>
                                                    <td class="text-center">{{ props.item.requestStatus }}</td>
                                                    <td class="text-center">{{ props.item.crNumber }}</td>
                                                    <td class="text-center">{{ props.item.comment }}</td>
                                                    <td class="text-center">{{ props.item.providerName }}</td>
                                                    <td class="text-center">{{ props.item.representativeName }}</td>
                                                    <td class="text-center">{{ props.item.representativeEmail }}</td>
                                                    <td class="text-center">{{ props.item.representativeMobile }}</td>
                                                    <td class="text-center">{{ props.item.numberOfActions }}</td>
                                                    <td class="text-center">{{ props.item.createdDate }}</td>
                                                    <td class="text-center">{{ props.item.paymentDate }}</td>
                                                    <td class="text-center">{{ props.item.expiryDate }}</td>
                                                    <td class="text-center">{{ props.item.lastModifiedDate }}</td>
                                                    <!--<td class="text-center">{{ props.item.zainActivationDate }}</td>
    <td class="text-center">{{ props.item.stcActivationDate }}</td>
    <td class="text-center">{{ props.item.mobilyActivationDate }}</td>-->
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

