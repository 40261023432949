import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import FileSaver from 'file-saver';
import { Inject, Service } from 'vue-di-container';

@Service()
export default class BalanceInvoiceService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async get(
        invoiceNumber: string,
        providerName: string,
        paymentDateFrom: string,
        paymentDateTo: string,
        isExport: boolean,
        pageIndex: string,
        pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName +
            (isExport ?'api/payment-transaction/exportgetallbalanceinvoices?':'api/payment-transaction/getallbalanceinvoices?')
                +'invoiceNumber=' + invoiceNumber
                + '&providerName=' + providerName
                + '&paymentDateFrom=' + paymentDateFrom
                + '&paymentDateTo=' + paymentDateTo
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            if (result.data.isSuccess) {
                if (isExport) {
                    const array = Buffer.from(result.data.result, 'base64');
                    const blob = new Blob([array], { type: 'application/vnd.ms-excel' });
                    FileSaver.saveAs(blob, 'balance invoice report.xlsx');
                    return result.data;
                } else {
                    return result.data;
                }
            } else {
                return false;
            }         
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async downloadIvnoice(
        transactionReference: string,
        isBalanceInvoice: boolean): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/payment-transaction/downloadinvoice' +
                '?invoiceIdentifier=' + transactionReference
                + '&isBalanceInvoice=' + isBalanceInvoice;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
