<div class="col-md-7">
    <v-form id="payfortForm"
            ref="payfortForm"
            @submit.prevent="PayfortSubmit"
            action=""
            method="post">
        <label class="card_header_orders">{{$t('shared.paymentInformation')}}</label>
        <div class="row">
            <input type="hidden" name="access_code" v-model="payfortModel.accessCode" />
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{$t('shared.name')}}</label>
                    <input class="form-control"
                           name="card_holder_name"
                           ref="card_holder_name"
                           required
                           v-model="cardHolderName"
                           type="text" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{$t('shared.cardNumber')}}</label>
                    <input class="form-control"
                           name="card_number"
                           id="card_numberId"
                           ref="card_number"
                           v-model="cardNumber"
                           :rules="nameRules"
                           required
                           @input="updateValueCardNumber"
                           type="text"
                           @keypress="isNumberField($event)"
                           maxlength="20" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>CCV</label>
                    <input class="form-control"
                           @keypress="isNumberField($event)"
                           maxlength="4"
                           name="card_security_code"
                           v-model="cVV"
                           ref="card_security_code"
                           required
                           type="text" />
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group">
                    <label>{{$t('shared.exDate')}}</label>
                    <input class="form-control text-center"
                           v-model="expiryDate"
                           @input="updateValueExDate"
                           name="expiry_date"
                           ref="expiry_date"
                           placeholder="Mm / Yy"
                           type="text"
                           @keypress="isNumberField($event)"
                           maxlength="7" />
                </div>
            </div>
            <input type="hidden" name="language" v-model="payfortModel.language" />
            <input type="hidden" name="merchant_identifier" v-model="payfortModel.merchantIdentifier" />
            <input type="hidden" name="merchant_reference" v-model="payfortModel.merchantReference" />
            <input type="hidden" name="return_url" v-model="payfortModel.returnURL">
            <input type="hidden" name="service_command" v-model="payfortModel.serviceCommand" />
            <input type="hidden" name="signature" v-model="payfortModel.signature" />
        </div>
        <div class="form-row justify-content-end align-items-center pt-4">
            <div class="col-auto">
                <button class="btn btn-success px-5 py-2">{{$t('shared.payNaw')}}</button>
            </div>
        </div>
    </v-form>
</div>