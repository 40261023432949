import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { PayfortModel } from '../MainPaymentComponents/PayfortModel';
import { HyperPayModel } from '../Payment/HyperPayModel';
import { InvoiceDetailsModel } from './InvoiceDetailsModel';

@Service()
export default class PublicPaymentService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async Get(id: string[]): Promise<any> {
        this.loaderService.ShowLoader();
        const ids = id;
        try {
            const url = window.$config.HostName + 'api/public-payment-transaction';
            const result = await this.axiosService.axiosInstance.post(url, ids);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            return false;
        }
    }
    public async GetPaymentLinkOptions(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/public-payment-transaction/get-payment-link-options';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            return false;
        }
    }
    public async UpdateInvoiceDetails(invoiceDetailsModel: InvoiceDetailsModel): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/public-payment-transaction/updateinvoicedetails';
            const result = await this.axiosService.axiosInstance.put(url, invoiceDetailsModel);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            return false;
        }
    }
    public async Pay(model: string[]): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/senders/pay';
            const result = await this.axiosService.axiosInstance.put(url, model);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetHyperPayModel(amount: string, IsMada: boolean): Promise<any> {
        try {
            const url = '/HyperPay/GetHyperPayModel?Amount=' + amount + '&IsMada=' + IsMada;
            const result = await this.axiosService.axiosInstance.get(url);
            const Data = result.data;
            return Data;
        } catch (exception) {
            return new HyperPayModel();
        }
    }
    public async AddPaymentTransaction(id: string[], checkOutId: string, isMadaValue: boolean,
        invoiceDetailsModel: InvoiceDetailsModel): Promise<any> {
        this.loaderService.ShowLoader();
        const ids = id;
        const model = {
            ids: id,
            checkOurId: checkOutId,
            isMada: isMadaValue,
            OrganizationName: invoiceDetailsModel.buyerOrganizationName,
            Country: invoiceDetailsModel.buyerCountry,
            City: invoiceDetailsModel.buyerCity,
            District: invoiceDetailsModel.buyerDistrict,
            StreetName: invoiceDetailsModel.buyerStreetName,
            BuildingNumber: invoiceDetailsModel.buyerBuildingNumber,
            VATNumber: invoiceDetailsModel.buyerVatNumber,
            PostalCode: invoiceDetailsModel.buyerPostalCode,
            AdditionalNumber: invoiceDetailsModel.buyerAdditionalNumber,
            OtherIdentifier: invoiceDetailsModel.buyerOtherIdentifier,
            BuyerEmail: invoiceDetailsModel.buyerEmail,
        };
        try {
            const url = window.$config.HostName + 'api/public-payment-transaction/add-payment-transaction';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            return false;
        }
    }
}
