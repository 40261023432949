<div class="w-75 h-100 m-auto">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <label class="header_text mb-3">
                {{$t('bulkshortPage.addNewConnectivity')}}
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body card_body_layout">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('bulkshortPage.operatorName')}}</label>
                                <label style="color:red;">{{'*'}}</label>
                                <v-select v-model="shortCodeModel.operatorId"
                                          :items="operatorOptions"
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('bulkshortPage.selectOperator')"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('bulkshortPage.shortcodeType')}}</label>
                                <label style="color:red;">{{'*'}}</label>
                                <v-select v-model="shortCodeModel.shortCodeType"
                                          :items="shortCodeTypes"
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('bulkshortPage.selectType')"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('bulkshortPage.shortcode')}}</label>
                                <label style="color:red;">{{'*'}}</label>
                                <v-select v-model="myShort"
                                          :items="filterdShorts"
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('bulkshortPage.selectShortCode')"
                                          :disabled="!canSelectShortCode"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-12 mt-5">
                            <div class="form-row justify-content-end align-items-center">
                                <div class="col-auto">
                                    <button class="btn btn-success px-5 py-2" @click="showaddShortCodeDialog">{{$t('shared.submit')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="addShortDiDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t(editMode?'shared.editInput':'shared.addInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideddShortDiDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t( editMode?'shared.editShortCodeInputText':'shared.addShortCodeInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="save">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>