<div class="w-100 h-100">
    <div class="row" style="padding-top: 56px;">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body">
                    <template>
                        <v-data-table :headers="headers"
                                      :items="slaList"
                                      hide-actions
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ props.item.number }}</td>
                                    <td class="text-center">{{ props.item.service }}</td>
                                    <td class="text-center">{{ props.item.description }}</td>
                                    <td class="text-center">{{ props.item.responseTime }}</td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                    <h6>&nbsp;</h6>
                    <h6 class="row" style="padding-left: inherit">{{$t('shared.slaNote1')}}</h6>
                    <h6 class="row" style="padding-left: inherit">{{$t('shared.slaNote2')}}</h6>
                </div>
                
            </div>        
        </div>
    </div>
</div>
