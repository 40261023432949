<div class="w-100 h-100">
    <div class="main_page w-100 h-100">
        <div class="container">
            <div class="row">
                <div class="col-md-12 pr-0">
                    <div class="card card_layout">
                        <div class="card-body">
                            <div class="text-xs-center">
                                <h3>{{$t('shared.trackRequest')}}</h3>
                                <label class="w-100">&nbsp;</label>
                            </div>
                            <div class="row">
                                <div class="row col-md-12 mt-2" style="padding: 10px;">
                                    <div class="form-group col-md-4">
                                        <label>{{$t('shared.requestId')}}</label>
                                        <input class="form-control" v-model="id" type="text" />
                                    </div>
                                    <div class="form-group col-md-2">
                                        <label class="w-100">&nbsp;</label>
                                        <button class="btn btn-primary btn-action"
                                                @click="search" :disabled="!isValid">
                                            {{$t('shared.apply')}}
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div class="row card-body">
                                <div class="col-md-12 pr-0">
                                    <div class="row card-body">
                                        <div class="col-sm-3 mb-3">
                                            <label class="user_info_header mb-0">{{$t('shared.requestId')}}</label>
                                            <h4 class="user_info_txt">{{model.requestId}}</h4>
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                            <label class="user_info_header mb-0">{{$t('shared.senderName')}}</label>
                                            <h4 class="user_info_txt">{{model.senderName}}</h4>
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                            <label class="user_info_header mb-0">{{$t('shared.clientName')}}</label>
                                            <h4 class="user_info_txt">{{model.clientName}}</h4>
                                        </div>
                                        <div class="col-sm-3 mb-3">
                                            <label class="user_info_header mb-0">{{$t('shared.providerName')}}</label>
                                            <h4 class="user_info_txt">{{model.providerName}}</h4>
                                        </div>
                                    </div>
                                    <div class="card-body">
                                        <template>
                                            <v-data-table :headers="headers"
                                                          :items="model.senderRequestTrackingList"
                                                          hide-actions
                                                          class="elevation-1 table-striped">
                                                <template v-slot:items="props">
                                                    <tr>
                                                        <td class="text-center">{{ props.item.requestStatus }}</td>
                                                        <td class="text-center">{{ props.item.createdDate }}</td>
                                                        <td class="text-center">{{ props.item.comment }}</td>
                                                        <td class="text-center">
                                                            <button v-if="props.item.requestStatus === 'Paid'"
                                                                    @click="showActivationDialog">
                                                                <i class="fas fa-info-circle Icon_Info">{{$t('shared.activationInformation')}}</i>
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </template>
                                            </v-data-table>
                                        </template>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="text-xs-center">
                <v-dialog v-model="activationDialog" width="554">
                    <v-card>
                        <v-card-title class="headline grey lighten-2" primary-title>
                            {{$t('shared.activation')}}
                            <div class="close__dialog">
                                <i class="fas fa-times" @click="hideActivationDialog"></i>
                            </div>
                        </v-card-title>
                        <v-card-text class="text-center">
                            <template>
                                <v-data-table :headers="activationHeaders"
                                              :items="model.activationInformationList"
                                              hide-actions
                                              class="elevation-1 table-striped">
                                    <template v-slot:items="props">
                                        <tr>
                                            <td class="text-center">{{ props.item.operatorName }}</td>
                                            <td class="text-center">{{ props.item.isActive }}</td>
                                            <td class="text-center">{{ props.item.activationDate }}</td>
                                        </tr>
                                    </template>
                                </v-data-table>
                            </template>
                        </v-card-text>
                    </v-card>
                </v-dialog>
            </div>
            <div class="login_footer form-row justify-content-between align-items-center">
                <div class="col-auto">
                    <a>
                        {{$t('shared.privacy')}}
                    </a>
                </div>
                <div class="col-auto">
                    <a>
                        {{$t('shared.termsOfUse')}}
                    </a>
                </div>
                <div class="col-auto">
                    <a>
                        {{$t('shared.copyRight')}} {{currentYear}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
