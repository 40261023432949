import Progress from 'easy-circular-progress';
import { Inject } from 'vue-di-container';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import LoaderService from '../../../Services/LoaderService';
import { Toaster } from '../../../Services/toast';
import ValidationService from '../../../Services/ValidationService';
import UserModel from '../../../shared/userService/Model/UserModel';
import UserService from '../../../shared/userService/UserService';
import UpdateDataRequestsService from './UpdateDataRequestsService';
import WithRender from './UpdateDataRequests.html';
import { SenderRequestModel } from '../SenderRequestModel';
import { UpdateDataRequestsFilterModel } from './UpdateDataRequestsFilterModel';
import UserRoleService from '../../../shared/userService/UserRoleService';


@WithRender
@Component({
    components: {
        Progress,
    },
    diProvide: [
        UpdateDataRequestsService,
        UserService,
        UserRoleService,
    ],
})
export default class UpdateDataRequests extends Vue {
    @Inject(UserService) public userService!: UserService;
    @Inject(UpdateDataRequestsService) public UpdateDataRequestsService!: UpdateDataRequestsService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public requestStatus: string = '';
    public senderStatus: string = '';
    public approveids: string[] = [];
    public approveDialog: boolean = false;
    public resendids: string[] = [];
    public resendDialog: boolean = false;
    public tenantId: string = '';
    public filterModel: UpdateDataRequestsFilterModel = {
        senderName: '',
        clientName: '',
        representativeName: '',
        representativeMobile: '',
        status: 0,
    };
    public statusOptions: any[] = [
        {
            id: '1',
            name: this.$t('dashboardPage.pendingFor').toString()
                + ' ' + this.$t('dashboardPage.approval').toString(),
        },
        {
            id: '2',
            name: this.$t('dashboardPage.pendingFor').toString()
                + ' ' + this.$t('dashboardPage.payment').toString(),
        },
        {
            id: '3',
            name: this.$t('dashboardPage.rejected').toString()
                + ' ' + this.$t('dashboardPage.requests').toString(),
        },
        {
            id: '4',
            name: this.$t('dashboardPage.inactive').toString()
                + ' ' + this.$t('dashboardPage.requests').toString(),
        },
        {
            id: '5',
            name: this.$t('dashboardPage.active').toString()
                + ' ' + this.$t('dashboardPage.requests').toString(),
        },
        {
            id: '7',
            name: this.$t('dashboardPage.partially').toString()
                + ' ' + this.$t('dashboardPage.activated').toString(),
        },
    ];
    public user: UserModel = {
        userId: '',
        username: '',
        details: {
            Email: '',
            MobilePhone: '',
            TenantId: '',
            Operator: '',
            Provider: '',
            OperatorId: '',
            ProviderId: '',
            Role: '',
            ChangePasswordRequired: '',
        },
    };
    public autoInterval: any;
    public userRole: any;
    public scrollDiv: any;
    public nextPage: string = '';
    public scrollTop: number = 0;
    public selected: SenderRequestModel[] = [];
    public selectedIds: string[] = [];
    public ids: string[] = [];
    public senders: SenderRequestModel[] = [];
    public sendersCount: number = 0;
    public User: any;
    public rejectResone: string = '';
    public rejectdialog: boolean = false;
    public anySelected: boolean = false;
    public pagination: any = {
        sortBy: 'senderName',
    };
    public headers: any = [
        { text: this.$t('dashboardPage.senderName'), sortable: false, value: 'senderName', align: 'left' },
        { text: this.$t('dashboardPage.clientName'), sortable: false, value: 'clientName', align: 'center' },
        {
            text: this.$t('dashboardPage.representativeName'), sortable: false,
            value: 'representativeName', align: 'center',
        },
        {
            text: this.$t('dashboardPage.representativeMobile'), sortable: false,
            value: 'representativeMobile', align: 'center',
        },
        {
            text: this.$t('dashboardPage.status'), sortable: false, value: 'requestStatus',
            align: 'center', width: '190',
        },
        { text: this.$t('shared.actions'), sortable: false, align: 'center', width: '145' },
    ];
    public changeSort(column: any) {
        if (this.pagination.sortBy === column) {
            this.pagination.descending = !this.pagination.descending;
        } else {
            this.pagination.sortBy = column;
            this.pagination.descending = false;
        }
    }
    public checkColor(typeStatus: string) {
        typeStatus = typeStatus.toString();
        switch (typeStatus) {
            case '1': {
                return '#c427ba';
                break;
            }
            case '2': {
                return '#5aaa0b';
                break;
            }
            case '3': {
                return '#C42727';
                break;
            }
            case '4': {
                return '#aaa7a7';
                break;
            }
            case '5': {
                return '#014bb1';
                break;
            }
            case '7': {
                return '#5c9bf2';
                break;
            }
            default: {
                return '#F78500';
                break;
            }
        }
    }
    public GetSenderStatus(typeStatus: string) {
        typeStatus = typeStatus.toString();
        switch (typeStatus) {
            case '1': {
                return 'Pending Approval';
                break;
            }
            case '2': {
                return 'Approved';
                break;
            }
            case '3': {
                return 'Rejected';
                break;
            }
            case '4': {
                return 'Paid';
                break;
            }
            case '5': {
                return 'Expired';
                break;
            }
            case '7': {
                return 'Partially Activated';
                break;
            }
            default: {
                return 'Deactivated';
                break;
            }
        }
    }
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.User = await this.userService.getUser();
        this.userRole = this.GetUserRole();
        this.tenantId = this.User.details.TenantId;
        this.search();
    }
    @Watch('filterModel', { deep: true })
    public async search() {
        const model = {
            senderName: this.filterModel.senderName,
            representativeName: this.filterModel.representativeName,
            clientName: '',
            representativeMobile: this.filterModel.representativeMobile,
            requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
            senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
            pageIndex: '0',
            pagesize: '10',
        };
        const response = await this.UpdateDataRequestsService.Get(model);
        if (response !== false) {
            if (!response.isSuccess) {
                this.$toast.error(response.errorMessage);
            }
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }
    public isEqual(value1: string | null, value2: string) {
        return (value1 === '' || value1 === null || value2.includes(value1));
    }
    public async filter(requestStatus: string, senderStatus: string) {
        if (this.requestStatus === requestStatus &&
            this.senderStatus === senderStatus) {
            this.requestStatus = '0';
            this.senderStatus = '0';
            requestStatus = '0';
            senderStatus = '0';
        } else {
            this.requestStatus = requestStatus;
            this.senderStatus = senderStatus;
        }
        const model = {
            senderName: this.filterModel.senderName,
            representativeName: this.filterModel.representativeName,
            clientName: '',
            representativeMobile: this.filterModel.representativeMobile,
            requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
            senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
            pageIndex: '0',
            pagesize: '10',
        };
        const response = await this.UpdateDataRequestsService.Get(model);
        if (!response.isSuccess) {
            this.$toast.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }
    public selectAll() {
        if (this.selected.length) {
            this.selected = [];
            this.selectedIds = [];
        } else {
            this.selected = this.senders.slice();
            this.selectedIds = this.senders.map((x) => x.id);
        }
    }
    public select(event: any, id: string) {
        if (!event) {
            const index = this.selectedIds.indexOf(id);
            this.selectedIds.splice(index, 1);
        } else {
            this.selectedIds.push(id);
        }
    }
    public getRate(remainingDays: string, totalDays: string) {
        return (parseInt(remainingDays, 10) / parseInt(totalDays, 10)) * 100;
    }
    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.User);
    }
    public showapproveDialog(id: string) {
        if (id !== '') {
            this.selectedIds.push(id);
        }
        this.approveDialog = true;
    }
    public hideapproveDialog() {
        this.approveDialog = false;
    }
    public async approve(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        } else {
            ids = this.selectedIds;
            if (this.senders.filter((s) => ids.includes(s.id) && s.requestStatus.toString() !== '1')
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderPendingApproval').toString());
                return;
            }
        }
        if (this.senders.filter((s) => ids.includes(s.id) && s.tenantId !== this.tenantId)
            .length > 0 && this.userRole === 1) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderRequestRelatedToThisTenant').toString());
            return;
        }
        const response = await this.UpdateDataRequestsService.approve(ids);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        } else {
            Toaster.success(this.$t('dashboardPage.Approvedsuccessfully').toString());
            this.reload();
            this.selectedIds = [];
        }
        this.hideapproveDialog();
    }
    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }
    public showresendDialog(id: string) {
        if (id !== '') {
            this.selectedIds.push(id);
        } else {
            this.resendids = this.selectedIds;
        }
        this.resendDialog = true;
    }
    public hideresendDialog() {
        this.resendDialog = false;
    }
    public async resend(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        } else {
            ids = this.selectedIds;
            if (this.senders.filter((s) => ids.includes(s.id) && s.requestStatus.toString() !== '3')
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderRejected').toString());
                return;
            }
        }
        const response = await this.UpdateDataRequestsService.resend(ids);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        } else {
            this.selectedIds = [];
            Toaster.success(this.$t('dashboardPage.resentSuccessfully').toString());
            this.reload();
        }
        this.hideresendDialog();
    }
    public async showRejectDialog(id: string) {
        this.ids = [];
        if (id !== '') {
            this.ids.push(id);
        } else {
            this.ids = this.selectedIds;
            if (this.senders.filter((s) => this.ids.includes(s.id) && s.requestStatus.toString() !== '1')
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderPendingApproval').toString());
                return;
            }
        }
        this.rejectdialog = true;
    }
    public async hideRejectDialog() {
        this.rejectResone = '';
        this.rejectdialog = false;
    }
    public async reject() {
        if (this.senders.filter((s) => this.ids.includes(s.id) && s.tenantId !== this.tenantId)
            .length > 0 && this.userRole === 1) {
            Toaster.error(this.$t('dashboardPage.NotAllSenderRequestRelatedToThisTenant').toString());
            return;
        }
        if (this.rejectResone !== '') {
            this.rejectdialog = false;
            const response = await this.UpdateDataRequestsService.reject(this.ids, this.rejectResone);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            } else {
                this.rejectResone = '';
                this.selectedIds = [];
                this.ids = [];
                Toaster.success(this.$t('dashboardPage.Rejectedsuccessfully').toString());
                this.reload();
            }
        }
    }
    public PreparePay(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        } else {
            ids = this.selectedIds;
            if (this.senders.filter((s) => ids.includes(s.id) && s.requestStatus.toString() !== '2')
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderPendingPay').toString());
                return;
            }
        }
        this.$router.push({ name: 'Payment', params: { ids: ids.join(',') } });
    }
    public Details(id: string) {
        this.$router.push({ name: 'SenderInfo', params: { id } });
    }
    public async updatePaginate(val: any) {
        const model = {
            senderName: this.filterModel.senderName,
            representativeName: this.filterModel.representativeName,
            clientName: '',
            representativeMobile: this.filterModel.representativeMobile,
            requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
            senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
            pageIndex: (val.page - 1).toString(),
            pagesize: val.rowsPerPage,
        };
        const response = await this.UpdateDataRequestsService.Get(model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }
    public get isAnySelected(): boolean {
        this.anySelected = this.selectedIds.length > 0;
        return this.anySelected;
    }
    public async reload() {
        const model = {
            senderName: this.filterModel.senderName,
            representativeName: this.filterModel.representativeName,
            clientName: '',
            representativeMobile: this.filterModel.representativeMobile,
            requestStatus: this.requestStatus !== '' ? parseInt(this.requestStatus, 0) : 0,
            senderStatus: this.senderStatus !== '' ? parseInt(this.senderStatus, 0) : 0,
            pageIndex: '0',
            pagesize: '10',
        };
        const response = await this.UpdateDataRequestsService.Get(model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
        this.selectedIds = [];
        this.selected = [];
    }
    public get rejectReasonIsValid(): boolean {
        return this.rejectResone !== '';
    }
}
