import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { AddRenewContractRequestModel } from '../RenewContractReques/AddRenewContractRequestModel';
import { ConnectingSenderNameModel } from './ConnectingSenderNameModel';

@Service()
export default class ConnectingSenderNameService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async post(model: ConnectingSenderNameModel) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/contract-requests';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }    
    public async ConnectingSenderName(senderName: string, crNumber: string, customerType: number, enterpriseUnifiedNumber: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/senders/get-sender-for-contract-request?'
                +'senderName=' + senderName
                + '&crNumber=' + crNumber
                + '&customerType=' + customerType
                + '&enterpriseUnifiedNumber=' + enterpriseUnifiedNumber;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }  
    public async GetForRenewContract(senderName: string, crNumber: string, customerType: number, enterpriseUnifiedNumber: string,): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/contract-requests/get-sender-for-contract-renew?'
                + 'senderName=' + senderName
                + '&crNumber=' + crNumber
                + '&customerType=' + customerType
                + '&enterpriseUnifiedNumber=' + enterpriseUnifiedNumber;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }  
    public async addContractRequestEmail(ids: string[]): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/contract-requests/send-add-contract-request-email';
            const result = await this.axiosService.axiosInstance.put(url, ids);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async AddRenewContractRequest(model: AddRenewContractRequestModel) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/contract-requests/renew';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
