import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';

@Service()
export default class ContractRequestInfoService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async GetById(id: string, isFromDashboard: boolean): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            let url;
            if (isFromDashboard) {
                url = window.$config.HostName + 'api/contract-requests/' + id;
            } else {
                url = window.$config.HostName + 'api/contract-requests/' + id;
            }
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetFileContent(id: string): Promise<string> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/contract-requests/attachments/' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return '';
        }
    }
    public async GetRequestApprovalHistory(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/contract-requests/get-request-approval-history/' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
   
}
