import { Component, Vue, Prop, Watch, Ref } from 'vue-property-decorator';
import WithRender from './EditSender.html';
import { Inject } from 'vue-di-container';
import EditSenderService from './EditSenderService';
import ValidationService from '../../../Services/ValidationService';
import { AttachmentModel } from '../../AddRequest/AttachmentModel';
import ShortService from '../../Short/ShortService';
import { SenderModel } from '../SenderModel';
import { Toaster } from '../../../Services/toast';
import { DropdownModel, ProviderModel } from '../../Provider/ProviderModel';
import { SenderType } from '../../../shared/Enums/SenderType';
import CITCKeywordsService from '../../CITCKeywords/CITCKeywordsService';
import { CITCKeywordsModel } from '../../CITCKeywords/CITCKeywordsModel';
import ProviderService from '../../Provider/ProviderService';
import UserService from '../../../shared/userService/UserService';
import UserModel from '../../../shared/userService/Model/UserModel';
import AttachmentCategoryService from '../../AttachmentCategory/AttachmentCategoryService';
import { AttachmentCategoryModel } from '../../AddRequest/AttachmentCategoryModel';
import { v4 as uuidv4 } from 'uuid';


@WithRender
@Component({
    components: {
    },
    diProvide: [
        EditSenderService,
        ShortService,
        CITCKeywordsService,
        ProviderService,
        AttachmentCategoryService
    ],
})
export default class EditSender extends Vue {
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(EditSenderService) public editSenderService!: EditSenderService;
    @Inject(ShortService) public shortService!: ShortService;
    @Inject(CITCKeywordsService) public cITCKeywordsService!: CITCKeywordsService;
    @Inject(ProviderService) public providerService!: ProviderService;
    @Inject(UserService) public userService!: UserService;
    @Inject(AttachmentCategoryService) public attachmentCategoryService!: AttachmentCategoryService;
    @Prop({ type: Boolean, default: false })
    @Ref('fileName') fileNameRef!: HTMLInputElement;
    public layoutSup: boolean | any;

    @Prop({ default: 0 })
    public id!: string;
    public typeFile: number = 0;
    public providerId: string = '';
    public senderNameLength: number = 11;
    public senderType: SenderType[] = [];
    public keywordsList: CITCKeywordsModel[] = [];
    public editSenderDialog: boolean = false;
    public originalHasWorning: boolean = false;
    public originalHasService: boolean = false;
    public originalHasAdvertisement: boolean = false;
    public originalHasAwareness: boolean = false;

    public selectedFileName: string = '';
    public attachmentCategoryList: AttachmentCategoryModel[] = [];
    public deletedAttachmentCategoryList: AttachmentCategoryModel[] = [];
    public teiggerAttachment: AttachmentCategoryModel =
        {
            id: 0,
            name: ""
        };

    public user: UserModel = {
        userId: '',
        username: '',
        details: {
            Email: '',
            MobilePhone: '',
            Operator: '',
            Provider: '',
            OperatorId: '',
            ProviderId: '',
            Role: '',
            TenantId: '',
            ChangePasswordRequired: '',
        },
    };
    public newAttachmentsId: string = uuidv4();
    public provider: ProviderModel = {
        name: '',
        id: '',
        createdDate: null,
        crNumber: '',
        accreditationNumber: '',
        email: '',
        taxNumber: '',
        hasInternational: false,
        country: '',
        city: '',
        district: '',
        streetName: '',
        buildingNumber: '',
        postalCode: '',
        vatNumber: '',
        additionalNumber: '',
        otherIdentifier: '',
        balance: 0,
        creditLimitAmount: 0,
        bankAccountNumber: '',
        expireDate: '',
        state: ''
    };
    public customerTypeList: DropdownModel[] = [
        {
            id: 1,
            name: this.$t('shared.government').toString(),
        },
        {
            id: 2,
            name: this.$t('shared.charity').toString(),
        },
        {
            id: 3,
            name: this.$t('shared.private').toString(),
        },
        {
            id: 4,
            name: this.$t('shared.international').toString(),
        },
    ];
    public senderTypeList: DropdownModel[] = [
        {
            id: SenderType.Advertisement,
            name: this.$t('shared.advertisement').toString(),
        },
        {
            id: SenderType.Service,
            name: this.$t('shared.service').toString(),
        },
        {
            id: SenderType.Awareness,
            name: this.$t('shared.awareness').toString(),
        },
        {
            id: SenderType.Warning,
            name: this.$t('shared.warning').toString(),
        },
    ];
    public expiryDateFromMenu: boolean = false;

    public model: SenderModel = {
        id: '',
        duration: '',
        senderType: 0,
        newSenderType: 0,
        senderStatus: 0,
        startDate: '',
        senderId: '',
        clientName: '',
        crNumber: '',
        enterpriseUnifiedNumber: '',
        providerName: '',
        customerType: 0,
        representativeEmail: '',
        representativeMobile: '',
        representativeName: '',
        senderRequestAttachments: [],
        senderName: '',
        requestStatus: 0,
        lastRejectionReason: '',
        remainingDays: 0,
        isAlreadyExist: false,
        totalDays: 0,
        comment: '',
        isCriticalSender: false,
        tenantId: '',
        isScamSender: false,
        expiryDate: '',
        verificationType: 0,
        crExpiryDate: '',
        companyName: '',
        representativeID: '',
        authorizationExpiryDate: '',
        tenant: '',
    };
    public attachmentType: number = 0;
    public currentAttachment: AttachmentModel =
        {
            id: '',
            fileName: '',
            fileContent: '',
            attachmentType: 0,
            attachmentTypeName: '',
            isDeleted: false,
        };
    public async created() {
        this.$emit('childinit', this.layoutSup);
        const keywordsListdata = await this.cITCKeywordsService.getAll();
        this.keywordsList = keywordsListdata.result;
    }
    public validateAttachmnet(): boolean {
        if (this.model.verificationType == 1 && this.isAdminEdit)
            return true
        else {
            if (this.model.senderRequestAttachments.filter((x) => x.attachmentType === 1 && x.isDeleted !== true).length === 0) {
                Toaster.error(this.$t('shared.canNotAddArequestWithoutADelegationLetter').toString());
                return false;
            } else if (this.model.customerType === 3 &&
                this.model.senderRequestAttachments.filter((x) => x.attachmentType === 2 && x.isDeleted !== true).length === 0) {
                Toaster.error(this.$t('shared.canNotAddArequestWithoutCommercialRegistrationNumber').toString());
                return false;
            }
            else if (this.model.customerType === 2 &&
                this.model.senderRequestAttachments.filter((x) => x.attachmentType === 4 && x.isDeleted !== true).length === 0) {
                Toaster.error(this.$t('shared.canNotAddArequestWithoutHRSDLicenseNumber').toString());
                return false;
            }
            return true;
        }
    }

    public async mounted() {
        const response = await this.editSenderService.get(this.id);
        if (response.isSuccess) {
            this.model = response.result;
        } else {
            Toaster.error(response.errorMessage);
        }
        this.getSenderType();
        if (this.senderType.includes(SenderType.Advertisement)) {
            this.senderNameLength = 8;
        } else if (this.senderType.includes(SenderType.Service) && this.model.customerType === 4) {
            this.senderNameLength = 9;
        } else {
            this.senderNameLength = 11;
        }
        this.user = await this.userService.getUser();
        this.providerId = this.user.details.ProviderId;
        if (this.providerId != null) {
            this.provider = await this.providerService.GetById(this.providerId);
            if (!this.provider.hasInternational) {
                this.customerTypeList.splice(3, 1);
            }
        }
        await this.getAttachmentCategory();
    }
    public async getAttachmentCategory() {
        const attachmentCategoryData = await this.attachmentCategoryService.getAll();
        this.attachmentCategoryList = attachmentCategoryData.result;
        for (let i = 0; i < this.model.senderRequestAttachments.length; i++) {
            const index = this.attachmentCategoryList.findIndex(at => at.id == this.model.senderRequestAttachments[i].attachmentType);
            if (index != -1) {
                var category = this.attachmentCategoryList.find(obj => obj.id === this.model.senderRequestAttachments[i].attachmentType);
                if (category != undefined) {
                    this.deletedAttachmentCategoryList.push(category);
                }
                this.attachmentCategoryList.splice(index, 1);
            }
        }
    }
    public isMobileNumberValid() {
        if (!this.model.representativeMobile.startsWith('5')) {
            Toaster.error(this.$t('shared.InvalidMobileNumber').toString());
        }
    }
    @Watch('model.customerType', { deep: true })
    public onchangeCustomerType(newVal: number, oldVal: number) {
        switch (this.model.customerType) {
            case 1:
                if (this.isAdminEdit) {
                    if (this.senderType.indexOf(SenderType.Service) !== -1 && !this.originalHasService) {
                        this.senderType.splice(this.senderType.indexOf(SenderType.Service), 1);
                    }
                    if (this.originalHasWorning && this.senderType.indexOf(SenderType.Warning) == -1) {
                        this.senderType.push(SenderType.Warning);
                    }
                    if (this.originalHasAwareness && this.senderType.indexOf(SenderType.Awareness) == -1) {
                        this.senderType.push(SenderType.Awareness);
                    }
                    if (this.originalHasAdvertisement && this.senderType.indexOf(SenderType.Advertisement) == -1) {
                        this.senderType.push(SenderType.Advertisement);
                    }
                }
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                    {
                        id: SenderType.Warning,
                        name: this.$t('shared.warning').toString(),
                    },
                ];
                break;
            case 2:
                if (this.isAdminEdit) {
                    if (this.senderType.indexOf(SenderType.Warning) !== -1) {
                        this.senderType.splice(this.senderType.indexOf(SenderType.Warning), 1);
                    }
                    if (this.senderType.indexOf(SenderType.Service) !== -1 && !this.originalHasService) {
                        this.senderType.splice(this.senderType.indexOf(SenderType.Service), 1);
                    }
                    if (this.originalHasAwareness && this.senderType.indexOf(SenderType.Awareness) == -1) {
                        this.senderType.push(SenderType.Awareness);
                    }
                    if (this.originalHasAdvertisement && this.senderType.indexOf(SenderType.Advertisement) == -1) {
                        this.senderType.push(SenderType.Advertisement);
                    }
                    if (this.senderType.length === 0)
                    {
                        Toaster.error(this.$t('shared.thisSenderOriginallyOnlyHasWarningSenderType').toString());
                    }
                }
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                ];
                break;
            case 3:
                if (this.isAdminEdit) {                   
                    if (this.senderType.indexOf(SenderType.Warning) !== -1) {
                        this.senderType.splice(this.senderType.indexOf(SenderType.Warning), 1);
                    }
                    if (this.senderType.indexOf(SenderType.Service) !== -1 && !this.originalHasService) {
                        this.senderType.splice(this.senderType.indexOf(SenderType.Service), 1);
                    }  
                    if (this.originalHasAwareness && this.senderType.indexOf(SenderType.Awareness) == -1) {
                        this.senderType.push(SenderType.Awareness);
                    }
                    if (this.originalHasAdvertisement && this.senderType.indexOf(SenderType.Advertisement) == -1) {
                        this.senderType.push(SenderType.Advertisement);
                    }
                    if (this.senderType.length === 0) {
                        Toaster.error(this.$t('shared.thisSenderOriginallyOnlyHasWarningSenderType').toString());
                    }
                }
                this.senderTypeList = [
                    {
                        id: SenderType.Advertisement,
                        name: this.$t('shared.advertisement').toString(),
                    },
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                    {
                        id: SenderType.Awareness,
                        name: this.$t('shared.awareness').toString(),
                    },
                ];
                break;
            case 4:
                this.senderTypeList = [
                    {
                        id: SenderType.Service,
                        name: this.$t('shared.service').toString(),
                    },
                ];
                this.senderType = [SenderType.Service];
                break;
        }
    }

    @Watch('senderType', { deep: true })
    public onchangeSenderType(newVal: SenderType[], oldVal: SenderType[]) {
        if (newVal.length > 0 && newVal !== oldVal) {
            if (this.senderType.includes(SenderType.Advertisement)) {
                this.senderNameLength = 8;
            } else if (this.senderType.includes(SenderType.Service) && this.model.customerType === 4) {
                this.senderNameLength = 9;
            } else {
                this.senderNameLength = 11;
            }
        }
    }

    public getSenderType() {
        const senderBinary = this.model.senderType;

        if ((senderBinary & 1) === 1) {
            this.senderType.push(1);
            this.originalHasAdvertisement = true;
        }
        if ((senderBinary & 2) === 2) {
            this.senderType.push(2);
            this.originalHasService = true;
        }
        if ((senderBinary & 4) === 4) {
            this.senderType.push(4);
            this.originalHasAwareness = true;
        }
        if ((senderBinary & 8) === 8) {
            this.senderType.push(8);
            this.originalHasWorning = true;
        }
    }

    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }

    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }

    public isSenderNameField(val: KeyboardEvent) {
        const pattern = /^[-a-zA-Z0-9._& ]*$/;
        if (!pattern.test(val.key)) {
            val.preventDefault();
        }
        const oneSpase = / +/g;
        if (this.model.senderName.endsWith(' ')) {
            if (oneSpase.test(val.key)) {
                val.preventDefault();
            }
        }
    }

    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public getNotDeletedAttachmnet() {
        return this.model.senderRequestAttachments.filter(item => item.isDeleted !== true);
    }

    public isSenderNameFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        const pattern = /^[-a-zA-Z0-9._& ]*$/;
        if (!pattern.test(paste)) {
            ev.preventDefault();
        }
        const twoSpaces = /  +/g;
        if (twoSpaces.test(paste)) {
            ev.preventDefault();
        }
    }
    public isValid(): boolean {
        return (
            this.model.clientName.trim() !== '' &&
            this.model.customerType !== 0 &&
            this.model.crNumber.trim() !== '' &&
            (this.model.customerType === 1 || this.model.customerType === 4
                || this.model.enterpriseUnifiedNumber.trim() !== '') &&
            this.model.representativeName.trim() !== '' &&
            this.model.representativeMobile !== '' &&
            (this.model.customerType === 4 ||
                this.model.representativeMobile.length === 9) &&
            (this.model.customerType === 4 ||
                this.model.representativeMobile.startsWith('5')) &&
            this.model.representativeEmail !== '' &&
            this.validationService.isValidEmail(this.model.representativeEmail) &&
            this.model.duration.toString().trim() !== '' &&
            this.model.duration.toString().trim() !== '0' &&
            this.model.senderName.trim() !== '' &&
            this.senderType.length !== 0 &&
            (this.getNotDeletedAttachmnet().length > 0 || (this.model.verificationType == 1 && this.isAdminEdit)));
    }
    public addAttachment() {
        this.currentAttachment.attachmentType = this.attachmentType;
        this.currentAttachment.attachmentTypeName = this.attachmentCategoryList.filter(x => x.id === this.attachmentType)[0].name;
        this.currentAttachment.id = this.newAttachmentsId;
        this.newAttachmentsId = uuidv4();
        this.model.senderRequestAttachments.push(this.currentAttachment);
        this.currentAttachment =
        {
            id: '',
            fileName: '',
            fileContent: '',
            attachmentType: 0,
            attachmentTypeName: '',
            isDeleted: false,
        };
        var category = this.attachmentCategoryList.find(obj => obj.id === this.attachmentType);
        if (category != undefined) {
            this.deletedAttachmentCategoryList.push(category);
        }
        const index = this.attachmentCategoryList.findIndex(obj => obj.id === this.attachmentType);
        if (index !== -1) {
            this.attachmentCategoryList.splice(index, 1);
        }
        this.attachmentType = 0;
        this.selectedFileName = '';
        this.fileNameRef.value = '';
    }
    public get canAddAttachment(): boolean {
        return (this.selectedFileName !== '' && this.attachmentType !== 0);
    }

    public deleteAttachment(id: string) {
        const deletedAtachmentindex = this.model.senderRequestAttachments.findIndex(at => at.id == id);
        this.model.senderRequestAttachments[deletedAtachmentindex].isDeleted = true;
        var requestAttachment = this.model.senderRequestAttachments[deletedAtachmentindex];
        if (requestAttachment != undefined) {
            var element = this.deletedAttachmentCategoryList.find(at => at.id == requestAttachment.attachmentType);
            if (element != undefined) {
                const otherAttacmentSameType = this.model.senderRequestAttachments.findIndex(at => at.attachmentType == requestAttachment.attachmentType && at.isDeleted != true);
                if (otherAttacmentSameType == -1) {
                    this.attachmentCategoryList.push(element);
                    var index = this.deletedAttachmentCategoryList.findIndex(at => at.id == requestAttachment.attachmentType);
                    if (index != -1) {
                        this.deletedAttachmentCategoryList.splice(index, 1);
                    }
                }
            } else {
                this.attachmentCategoryList.push(this.teiggerAttachment);
                this.attachmentCategoryList.pop();
            }
        }
    }
    public onPickFile() {
        (this.$refs.fileName as any).click();
    }

    public onFilePicked(event: any) {
        const files: any[] = event.target.files;
        Array.prototype.forEach.call(files, (file, index) => {
            if (file.size > 3000000) {
                Toaster.error(this.$t('shared.fileSizeExceedsTheLimit').toString());
                this.fileNameRef.value = '';
            } else {
                this.selectedFileName = file.name;
                const reader = new FileReader();
                reader.onloadend = (fileData: any) => {
                    this.currentAttachment.fileContent = fileData.target.result.split(',')[1];
                    this.currentAttachment.fileName = file.name;
                };
                reader.readAsDataURL(file);
            }
        });
    }
    public get isAdminEdit(): boolean {
        return (
            this.user.details.Role === 'Platform Admin' || this.user.details.Role === 'Super Admin'
        );
    }
    public showEditSenderDialog(id: string) {
        this.editSenderDialog = true;
    }
    public hideEditSenderDialog() {
        this.editSenderDialog = false;
    }
    public async save() {
        if (!this.validateAttachmnet()) {
            this.hideEditSenderDialog();
            return;
        }
        if (this.keywordsList.filter((b) => this.model.senderName.trim().toLowerCase()
            === b.name.toLowerCase()).length > 0) {
            Toaster.error(this.$t('shared.senderNameShouldNotBeOneOfTheKeywords').toString());
            return;
        }
        this.model.senderType = this.senderType.reduce((a, b) => a + b, 0);
        if (this.model.senderName.trim().length < 2) {
            Toaster.error(this.$t('shared.senderNameShouldbe2OrmoreChar').toString());
            return;
        }
        if (/^\d+$/.test(this.model.senderName.trim())) {
            Toaster.error(this.$t('shared.senderNameShouldbenumbersandChar').toString());
            return;
        }
        if (this.model.senderName.trim().slice(-3).toLocaleLowerCase() !== '-ad' &&
            this.model.senderName.trim().slice(-2).toLocaleLowerCase() !== '-i') {
            this.model.senderName = this.model.senderName.trim();
            const IsAdmin = (this.user.details.Role === 'Platform Admin' ||
                this.user.details.Role === 'Super Admin');
            let response: any;
            if (IsAdmin) {
                response = await this.editSenderService.editSender(this.model);
            } else {
                response = await this.editSenderService.put(this.model);
            }
            if (response.isSuccess) {
                Toaster.success(this.$t('shared.editSenderSuccessfully').toString());
                this.$router.push({ name: 'SenderInfo', params: { id: this.id.toString() } });
            } else {
                Toaster.error(response.errorMessage);
            }
        } else {
            Toaster.error(this.$t('shared.errorSenderNameEnd').toString());
        }
        this.hideEditSenderDialog();
    }
}