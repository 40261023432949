import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './AlertsDashboard.html';
import { Toaster } from '../../Services/toast';
import AlertsDashboardServiceService from './AlertsDashboardService';
import { Inject } from 'vue-di-container';
import { SenderRequestKpiViewModel } from '../SenderRequestStatusDashboard/SenderRequestKpiViewModel';
import { filterModel } from './FilterModel';
import LoaderService from '../../Services/LoaderService';
import FileSaver from 'file-saver';
import ValidationService from '../../Services/ValidationService';
import { isNumber } from 'util';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        AlertsDashboardServiceService
    ],
})
export default class AlertsDashboard extends Vue {
    @Inject(AlertsDashboardServiceService) public alertsDashboardService!: AlertsDashboardServiceService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    @Prop({ type: Boolean, default: false })

    public headers: any = [
        { text: this.$t('shared.requestId'), value: 'requestId', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.sender'), value: 'sender', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.client'), value: 'client', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.provider'), value: 'provider', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.entityType'), value: 'entityType', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.date'), value: 'date', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('dashboardPage.operatorStatus'), value: 'operatorStatus', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.alerts'), value: 'alerts', align: 'center', class: "white--text", sortable: false },
    ];
    public days: number[] = [];
    public selectedDays: any = [];
    public requests: SenderRequestKpiViewModel[] = [];
    public requestsCount: number = 0;
    public daysSelected: number[] = [];
    public allChecked: boolean = true;
    public customChecked: boolean = false;
    public isAllSelected: boolean = true;
    public firstDurationChecked: boolean = true;
    public secondDurationChecked: boolean = true;
    public thirdDurationChecked: boolean = true;
    public createdDateFromMenu: boolean = false;
    public createdDateToMenu: boolean = false;
    public tenant: string | any = '';
    public requestType: string | any = '';
    public dateFrom: string = '';
    public dateTo: string = '';
    public pagination: any;
    model: filterModel = {
        searchText: "",
        days: [],
        customDay: "",
        pageIndex: 0,
        pageSize: 10,
        type: 0,
        isAllSelected: true,
        tenant: '',
        requestType: 0,
        dateFrom: '',
        dateTo: '',
    };
    customDay: string = "";
    public status: string | any = '';
    allSelected: boolean = false;
    public async mounted() {
        await this.reset();
    }
    public async reset() {
        this.days = [];
        this.selectedDays = [];
        this.daysSelected = [];
        this.allChecked = true;
        this.customChecked = false;
        this.isAllSelected = true;
        this.firstDurationChecked = true;
        this.secondDurationChecked = true;
        this.thirdDurationChecked = true;
        this.createdDateFromMenu = false;
        this.createdDateToMenu = false;
        this.dateFrom = '';
        this.dateTo = '';
        this.model = {
            searchText: "",
            days: [],
            customDay: "0",
            pageIndex: 0,
            pageSize: 10,
            type: 0,
            isAllSelected: true,
            tenant: '',
            requestType: 0,
            dateFrom: '',
            dateTo: '',
        };
        this.customDay = '';
        this.status = '';
        this.allSelected = false;
        this.status = this.$route.query.status;
        this.tenant = this.$route.query.tenant;
        this.requestType = this.$route.query.requestType;
        if (this.status == '1' || this.status == '2' || this.status == '3') {
            const getDaysResponse = await this.alertsDashboardService.getDaysConfiguration(Number(this.status));
            if (!getDaysResponse.isSuccess) {
                Toaster.error(getDaysResponse.errorMessage);
            } else {
                this.days = getDaysResponse.result;
            }
        }
        this.updatePaginate();
    }
    public async selectDateFrom() {
        this.createdDateFromMenu = false;
    }
    public async selectDateTo() {
        this.createdDateToMenu = false;
    }
    public getSelectedDays() {
        this.selectedDays = [];
        if (this.firstDurationChecked) {
            this.selectedDays.push(this.days[0])
        }
        if (this.secondDurationChecked) {
            this.selectedDays.push(this.days[1])
        }
        if (this.thirdDurationChecked) {
            this.selectedDays.push(this.days[2])
        }
    }

    @Watch('customDay', { deep: true })
    public async searchForCustomDay() {
        if (this.customChecked) {
            if ((this.validationService.isNumeric(this.model.customDay))) {
                if (Number(this.customDay) >= this.days[0]) {
                    this.model.customDay = this.customDay;
                } else {
                    Toaster.error(this.$t('shared.customDayMustBeLargerThanTheEnteredValue').toString());
                }
            } else {
                Toaster.error(this.$t('shared.customDaySearchMustBeUsingANumber').toString());
            }
        }
    }
    public async updatePaginate() {
        this.status = this.$route.query.status;
        this.tenant = this.$route.query.tenant;
        this.requestType = this.$route.query.requestType;
        if (!(this.status == '1' || this.status == '2' || this.status == '3')) {
            this.requests = [];
            this.requestsCount = 0;
            Toaster.error(this.$t('shared.sorryUnrecognizedRequestStatus').toString());
        }
        else if (!(this.requestType == '0' || this.requestType == '1' || this.requestType == '2')) {
            this.requests = [];
            this.requestsCount = 0;
            Toaster.error(this.$t('shared.sorryUnrecognizedRequestType').toString());
        }
        else {
            this.getSelectedDays();
            this.model = {
                searchText: this.model.searchText,
                days: this.selectedDays,
                type: Number(this.status),
                customDay: this.customChecked === true ?
                    ((this.customDay !== null && this.customDay !== undefined) ? this.customDay : "0") : "0",
                pageIndex: (this.pagination.page - 1),
                pageSize: this.pagination.rowsPerPage,
                isAllSelected: this.model.isAllSelected,
                tenant: this.tenant,
                requestType: this.requestType,
                dateFrom: this.dateFrom,
                dateTo: this.dateTo,
            };
            const response = await this.alertsDashboardService.getRequests(this.model);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
                this.requests = [];
                this.requestsCount = 0;
            } else {
                this.requests = response.result;
                this.requestsCount = response.totalCount;
            }
        }
    }
    public async exportToExcel() {
        this.getSelectedDays();
        this.model = {
            searchText: this.model.searchText,
            days: this.selectedDays,
            type: Number(this.status),
            customDay: this.customChecked === true ?
                ((this.customDay !== null && this.customDay !== undefined) ? this.customDay : "0") : "0",
            pageIndex: 0,
            pageSize: 2147483647,
            isAllSelected: this.model.isAllSelected,
            tenant: this.tenant,
            requestType: this.requestType,
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
        };
        if (this.status == '1' || this.status == '2' || this.status == '3') {
            const response = await this.alertsDashboardService.exportToExcel
                (this.model);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
                this.loaderService.HideLoader();
            } else {
                const array = Buffer.from(response.result, 'base64');
                const blob = new Blob([array], { type: 'application/vnd.ms-excel' });
                FileSaver.saveAs(blob, 'AlertsReport.xlsx');
                this.loaderService.HideLoader();
            }
        } else {
            this.requests = [];
            this.requestsCount = 0;
            Toaster.error(this.$t('shared.sorryUnrecognizedRequestStatus').toString());
        }
    }
    public getActivation(operatorId: string, requestStatus: string, senderConectivityDetail: any[]) {
        let isActive = false;
        if (this.status == '3') {
            return false
        }
        if (requestStatus == '4') {
            const arrayForthisOperator = senderConectivityDetail
                .filter((a) => a.operator === operatorId
                    && (a.status === 2 || a.status === 1)).length;
            const approvedArrayForthisOperatorApproved = senderConectivityDetail
                .filter((a) => a.operator === operatorId && a.status === 2).length;
            if (approvedArrayForthisOperatorApproved > 0) {
                isActive = true;
            }
        }
        return isActive;
    }

    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }
    public get isValid(): boolean {
        if (this.customChecked && !isNumber(this.customDay) && Number(this.customDay) < this.days[0])
            return false
        else
            return true;
    }
    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }
}