<div class="w-100 h-100">
    <div class="row" style="padding-top: 56px;">
        <div class="col-md-12">
            <label class="header_text mb-3">
                {{$t('shared.updateRequestData')}}
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body card_body_layout">
                    <div class="row">
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.clientName')}}</label>
                                <input class="form-control" :disabled="true" v-model="model.clientName" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.customerType')}}</label>
                                <v-select v-model="model.customerType"
                                          :items="customerTypeList"
                                          :label="$t('shared.select')"
                                          item-text="name"
                                          item-value="id"
                                          :disabled="true"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3" v-if="model.customerType !== 0">
                            <div class="form-group">
                                <label v-if="model.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                                <label v-if="model.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                                <label v-if="model.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                                <label v-if="model.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                                <input class="form-control" :disabled="true" v-model="model.crNumber" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3" v-if="model.customerType === 2 ||model.customerType === 3">
                            <div class="form-group">
                                <label>{{$t('shared.enterpriseUnifiedNumber')}}</label>
                                <input class="form-control" :disabled="enterpriseUnifiedNumberDisabled" v-model="model.enterpriseUnifiedNumber" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.representativeName')}}</label>
                                <input class="form-control" v-model="model.representativeName" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.phoneNumber')}}</label>
                                <div v-if="model.customerType === 0 ||model.customerType === 1 || model.customerType === 2 || model.customerType === 3" class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text gray">
                                            +966
                                        </span>
                                    </div>
                                    <input class="form-control border-left-0" @change="isMobileNumberValid" @paste="isNumberFieldPaste($event)" maxlength="9" @keypress="isNumberField($event)" v-model="model.representativeMobile" type="text" />
                                </div>
                                <div v-if="model.customerType === 4" class="input-group mb-3">
                                    <input class="form-control border-left-0" @paste="isNumberFieldPaste($event)" @keypress="isNumberField($event)" v-model="model.representativeMobile" type="text" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.durationYear')}}</label>
                                <input class="form-control" @paste="isNumberFieldPaste($event)" v-model="model.duration"
                                       @keypress="isNumberField($event)" type="text" :disabled="true" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.representativeEmail')}}</label>
                                <input class="form-control" v-model="model.representativeEmail" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.senderName')}}</label>
                                <input class="form-control" :disabled="true" @paste="isSenderNameFieldPaste($event)" @keypress="isSenderNameField($event)" :maxlength="senderNameLength" v-model="model.senderName" type="text" />
                            </div>
                        </div>

                        <div class="col-md-5 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.senderType')}}</label>
                                <div class="form-control">
                                    <span v-for="(type, index) in senderType" :key="type" :disabled="true">
                                        {{getSenderTypeText(type)}}{{ (index+1 < senderType.length) ? ', ' : '' }}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-12">
                            <div class="row">
                                <div class="col-md-8">
                                    <div class="row">
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label>{{$t('shared.attachDelegationLetter')}}</label>
                                                <div class="input-group mb-3">
                                                    <input class="form-control border-right-0 border-left" readonly v-model="fileNameDelegationLetter" type="text" />
                                                    <input type="file" ref="fileDelegationLetter" hidden accept=".png, .jpg, .jpeg, .pdf, .docx" @change="onFilePicked">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text right" style="padding: 2px 2px 0px 2px !important;">
                                                            <i v-if="fileNameDelegationLetter !== ''" class="far fa-trash-alt Icon_delete" @click="deleteDelegationLetter()"></i>
                                                            <button class="btn btn-primary" @click="onPickFile(1)">{{$t('shared.attach')}}</button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4">
                                            <div class="form-group">
                                                <label class="w-100">&nbsp;</label>
                                                <a class="btn btn-primary" download :href="'../../../../delegationletter/'+ getCustomerTypeText(model.customerType) + '.docx'" v-if="model.customerType !== 0">
                                                    {{$t('shared.delegationletter')}}
                                                    <i class="fas fa-file-download ml-2"></i>
                                                </a>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label v-if="model.customerType === 1">{{$t('shared.attachbudgetNumberDocument')}} {{$t('shared.optional')}}</label>
                                                <label v-if="model.customerType === 2">{{$t('shared.attachlicenseNumberDocument')}}</label>
                                                <label v-if="model.customerType === 3">{{$t('shared.attachcommercialRegistrationNumberDocument')}} </label>
                                                <label v-if="model.customerType === 4">{{$t('shared.attachinternationalCommercialRegistrationNumberDocument')}}</label>
                                                <div class="input-group mb-3">
                                                    <input class="form-control border-right-0 border-left" readonly v-model="fileNameRegistrationDocument" type="text" />
                                                    <input type="file" ref="fileRegistrationDocument" hidden accept=".png, .jpg, .jpeg, .pdf, .docx" @change="onFilePicked">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text right" style="padding: 2px 2px 0px 2px !important;">
                                                            <i v-if="fileNameRegistrationDocument !== ''" class="far fa-trash-alt Icon_delete" @click="deleteRegistrationDocument()"></i>
                                                            <button class="btn btn-primary" @click="onPickFile(2)">{{$t('shared.attach')}}</button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8">
                                            <div class="form-group">
                                                <label>{{$t('shared.additionalAttachments')}} {{$t('shared.optional')}}</label>
                                                <div class="input-group mb-3">
                                                    <input class="form-control border-right-0 border-left" readonly v-model="fileNameAdditionalAttachments" type="text" />
                                                    <input type="file" ref="fileAdditionalAttachments" hidden accept=".png, .jpg, .jpeg, .pdf, .docx" @change="onFilePicked" multiple>
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text right" style="padding: 2px 2px 0px 2px !important;">
                                                            <i v-if="fileNameAdditionalAttachments !== ''" class="far fa-trash-alt Icon_delete" @click="deleteAdditionalAttachments()"></i>
                                                            <button class="btn btn-primary" @click="onPickFile(3)">{{$t('shared.attach')}}</button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-8 col-10">
                                        <div class="form-group">
                                            <label>{{$t('shared.theMaximumSizeForTheAttachmentIs3Megabytes')}}</label>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                        <div class="row pt-4">
                            <div class="col-md-12 mt-5">
                                <div class="form-row justify-content-end align-items-center">
                                    <div class="col-auto mx-3" v-if="isFromDashboard">
                                        <router-link to="/Dashboard" tag="a" class="link_secondary">{{$t('shared.cancel')}}</router-link>
                                    </div>
                                    <div class="col-auto mx-3" v-if="!isFromDashboard">
                                        <router-link :to="'/Dashboard/SenderInfo/' + id" tag="a" class="link_secondary">{{$t('shared.cancel')}}</router-link>
                                    </div>
                                    <div class="col-auto">
                                        <button class="btn btn-success px-5 py-2" :disabled="!isValid" @click="showEditSenderDialog">{{$t('shared.save')}}</button>
                                    </div>
                                </div>
                            </div>
                        </div>

                </div>
            </div>
        </div>
        <div class="text-xs-center">
            <v-dialog v-model="editSenderDialog" width="554">
                <v-card>
                    <v-card-title class="headline grey lighten-2" primary-title>
                        {{$t('shared.updateRequestData')}}
                        <div class="close__dialog">
                            <i class="fas fa-times" @click="hideEditSenderDialog"></i>
                        </div>
                    </v-card-title>

                    <v-card-text class="text-center">
                        <p class="mt-3">
                            {{$t('shared.updateRequestDataInputText')}}
                        </p>
                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <div class="form-row justify-content-end align-items-center">
                            <div class="col-auto mx-3">
                                <a class="link_danger" @click="hideEditSenderDialog">
                                    {{$t('shared.cancel')}}
                                </a>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-danger px-5 py-2" @click="save">{{$t('shared.Ok')}}</button>
                            </div>
                        </div>
                    </v-card-actions>
                </v-card>
            </v-dialog>
        </div>
    </div>
</div>


