import '@babel/polyfill';
import Vue from 'vue';
import './plugins/vuetify';
import i18n from '@/plugins/i18n';
import App from './components/App/App';
import router from './router';
import 'material-design-icons-iconfont/dist/material-design-icons.css';
import { VueDiContainer } from 'vue-di-container';
import AxiosService from './Services/AxiosService';
import LoaderService from './Services/LoaderService';
import initializeApplication from './plugins/application-initialization';
import Vuetify from 'vuetify';
import VuetifyToast from 'vuetify-toast-snackbar';
import 'vuetify/dist/vuetify.css';
import '../public/assat/bootstrap/4.3.1/css/bootstrap-en.css';
import '../public/assat/font-awesome/5.9.0/css/all.css';
import '../public/assat/main/main.css';
import ValidationService from './Services/ValidationService';
import TokenService from './Services/TokenService';
import moment from 'moment';


Vue.config.productionTip = false;
Vue.use(VueDiContainer);
Vue.use(VuetifyToast, {
    y: 'top',
    icon: 'success',
    showClose: true,
});
Vue.use(Vuetify, {
    theme: {
        primary: '#1976D2',
        secondary: '#424242',
        accent: '#82B1FF',
        error: '#FF5252',
        info: '#2196F3',
        success: '#4CAF50',
    },
});

Vue.filter('formatCardNumber', (value: any) => {
    return value ? value.match(/.{1,4}/g).join(' ') : '';
});

Vue.filter('formatExDate', (value: any) => {
    return value ? value.match(/.{1,2}/g).join('/') : '';
});

Vue.filter('formatDate', (value: Date) => {
    if (!value) { return '-'; }
    return moment(value).format('DD/MM/YYYY hh:mm a');
});

Vue.filter('formatDateString', (value: string) => {
    if (!value) { return '-'; }
    return moment(value).format('DD/MM/YYYY');
});

declare module 'vue/types/vue' {
    interface Vue {
        $appInsights: any;
    }
}

declare global {
    interface Window {
        $config: any;
        ShowLoader(): void;
        HideLoader(): void;
    }
}

initializeApplication().then((initialize) => {
    new Vue({
        i18n,
        router,
        diProvide: [
            AxiosService,
            LoaderService,
            ValidationService,
            TokenService,
        ],
        render: (h) => h(App),
    }).$mount('#app');
},
);
