import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './Invoices.html';
import { Toaster } from '../../Services/toast';
import InvoicesService from './InvoicesService';
import { InvoicesModel } from './InvoicesModel';
import VueHtml2pdf from 'vue-html2pdf'
import LoaderService from '../../Services/LoaderService';

@WithRender
@Component({
    components: {
        VueHtml2pdf
    },
    diProvide: [
        InvoicesService,
    ],
})
export default class Invoices extends Vue {
    @Inject(InvoicesService) public invoicesService!: InvoicesService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;

    public $refs!: {
        html2Pdf: any;
        simpleHtml2Pdf: any;
    };

    public invoiceHtml: string = '';
    public isSimpleInvoice: boolean = false;
    public deleteDialog: boolean = false;
    public downloadIssued: boolean = false;
    public downloadIssuedSimple: boolean = false;
    public name: string = '0';
    public User: any;
    public userRole: any;
    public headers: any = [
        { text: this.$t('shared.InvoicesNumber'), value: 'invoiceNumber', align: 'center', sortable: false },
        { text: this.$t('shared.sendersName'), value: 'sendersName', align: 'center', sortable: false },
        { text: this.$t('shared.clientName'), value: 'clientName', align: 'center', sortable: false },
        { text: this.$t('shared.providerName'), value: 'providerName', align: 'center', sortable: false },
        { text: this.$t('shared.paymentdate'), value: 'date', align: 'center', sortable: false },
        { text: this.$t('shared.amount'), value: 'amount', align: 'center', sortable: false },
        { text: this.$t('shared.actions'), sortable: false, value: 'paymentReference', align: 'center', width: '100' },
    ];
    public createdDateToMenu: boolean = false;
    public createdDateFromMenu: boolean = false;
    public invoicesModel: InvoicesModel[] = [];
    public invoicesCount: number = 0;
    public filterinvoices: InvoicesModel[] = [];
    public filterModel: any = {
        invoiceNumber: '',
        sendersName: '',
        clientName:'',
        providerName:'',
        paymentDateFrom:'',
        paymentDateTo:''
    };
    public async created() {
        this.$emit('childinit', this.layoutSup);
    }
    public async mounted() {
        const response = await this.invoicesService.get
            (this.filterModel.invoiceNumber.toString(), 
            this.filterModel.sendersName,
            this.filterModel.clientName,
            this.filterModel.providerName,
            this.filterModel.paymentDateFrom,
            this.filterModel.paymentDateTo,
            false,
             '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.invoicesModel = response.result;
        this.invoicesCount = response.totalCount;
    }

    public async search() {
        const response = await this.invoicesService.get
            (this.filterModel.invoiceNumber.toString(), 
            this.filterModel.sendersName,
            this.filterModel.clientName,
            this.filterModel.providerName,
            this.filterModel.paymentDateFrom,
            this.filterModel.paymentDateTo,
            false,
             '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.invoicesModel = response.result;
        this.invoicesCount = response.totalCount;
    }

    public async reload() {
        const response = await this.invoicesService.get
            (this.filterModel.invoiceNumber.toString(), 
            this.filterModel.sendersName,
            this.filterModel.clientName,
            this.filterModel.providerName,
            this.filterModel.paymentDateFrom,
            this.filterModel.paymentDateTo,
            false,
             '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.invoicesModel = response.result;
        this.invoicesCount = response.totalCount;
    }
    public async updatePaginate(val: any) {
        const response = await this.invoicesService.get
            (this.filterModel.invoiceNumber.toString(), 
            this.filterModel.sendersName,
            this.filterModel.clientName,
            this.filterModel.providerName,
            this.filterModel.paymentDateFrom,
            this.filterModel.paymentDateTo,
            false,
                (val.page - 1).toString(), val.rowsPerPage);
        this.invoicesModel = response.result;
        this.invoicesCount = response.totalCount;
    }
    public async download(paymentReference: string, isBalanceInvoice: boolean) {

        const response = await this.invoicesService.downloadIvnoice(paymentReference, isBalanceInvoice);
        if (response.isSuccess) {
            this.isSimpleInvoice = response.result.isSimpleInvoice;
            this.invoiceHtml = response.result.invoiceHtml;
            if (this.isSimpleInvoice) {
                this.downloadIssuedSimple = true;
                this.generateReportSimple();
            } else {
                this.downloadIssued = true;
                this.generateReport();
            }
        }
    }

    public generateReport() {
        this.$refs.html2Pdf.generatePdf();
    }
    public generateReportSimple() {
        this.$refs.simpleHtml2Pdf.generatePdf();
    }
    public hasDownloaded() {
        this.downloadIssued = false;
    }

    public reset() {
        this.filterModel= {
            invoiceNumber: '',
            sendersName: '',
            clientName:'',
            providerName:'',
            paymentDateFrom:'',
            paymentDateTo:''
        };
        this.search();
    }

    public async exportToExcel() {
        const response = await this.invoicesService.get
            (this.filterModel.invoiceNumber.toString(), 
            this.filterModel.sendersName,
            this.filterModel.clientName,
            this.filterModel.providerName,
            this.filterModel.paymentDateFrom,
            this.filterModel.paymentDateTo,
            true,
             '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
    }
    
}
