<div class="w-100 h-100">
    <div class="main_page w-100 h-100" style="display: inline-block; text-align: center;">
        <div class="container">
            <template>
                <div style="display: inline-block; text-align: center;">
                    <VueHtml2pdf :show-layout="false"
                                 :float-layout="false"
                                 :enable-download="true"
                                 :preview-modal="false"
                                 :paginate-elements-by-height="1300"
                                 filename="Invoice"
                                 :pdf-quality="2"
                                 :manual-pagination="false"
                                 pdf-format="letter"
                                 pdf-orientation="portrait"
                                 pdf-content-width="830px"
                                 ref="simpleHtml2Pdf">
                        <section slot="pdf-content">
                            <div class="align-items-center" style="background-color:white;" v-if="downloadIssuedSimple" v-html="invoiceHtml"></div>
                        </section>
                    </VueHtml2pdf>
                    <VueHtml2pdf :show-layout="false"
                                 :float-layout="false"
                                 :enable-download="true"
                                 :preview-modal="false"
                                 :paginate-elements-by-height="1300"
                                 filename="Invoice"
                                 :pdf-quality="2"
                                 :manual-pagination="false"
                                 pdf-format="legal"
                                 pdf-orientation="portrait"
                                 pdf-content-width="830px"
                                 ref="html2Pdf">
                        <section slot="pdf-content">
                            <div class="align-items-center" style="background-color:white;" v-if="downloadIssued" v-html="invoiceHtml"></div>
                        </section>
                    </VueHtml2pdf>
                </div>
            </template>
            <div class="login_footer form-row justify-content-between align-items-center">
                <div class="col-auto">
                    <a>
                        {{$t('shared.privacy')}}
                    </a>
                </div>
                <div class="col-auto">
                    <a>
                        {{$t('shared.termsOfUse')}}
                    </a>
                </div>
                <div class="col-auto">
                    <a>
                        {{$t('shared.copyRight')}} {{currentYear}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
