import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import ScamListService from '../ScamListService';
import WithRender from './AddScamList.html';

import UserService from '../../../shared/userService/UserService';
import { Toaster } from '../../../Services/toast';
import ValidationService from '../../../Services/ValidationService';
import { ScamModel } from '../ScamModel';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        ScamListService,
        UserService,
    ],
})
export default class AddScamList extends Vue {
    @Inject(ScamListService) public scamService!: ScamListService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(UserService) public userService!: UserService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    @Prop({ type: String, default: '' })
    public User: any;
    public scams: ScamModel[] = [];
    public scamId: string = '0';
    public senderName: string = '';
    public clientName: string = '';
    public deleteDialog: boolean = false;
    public headers: any = [
        {
            text: this.$t('scamPage.clientName'), value: 'Client Name', align: 'center', sortable: false,
        },
        {
            text: this.$t('scamPage.senderName'), value: 'Sender Name', align: 'center', sortable: false,
        },
       
        {
            text: this.$t('shared.actions'), sortable: false, value: 'id', align: 'center', width: '100'
        },
    ];

    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.User = await this.userService.getUser();
    }
    public created() {

    }
    public showDeleteDialog(id: string) {
        this.scamId = id;
        this.deleteDialog = true;
    }
    public hideDeleteDialog() {
        this.deleteDialog = false;
    }
    public async deleteScam() {
        const response = await this.scamService.delete(this.scamId);
        if (response.isSuccess && response.result) {
            const index = this.scams.findIndex(x => x.id == this.scamId);
            if (index > -1) {
                this.scams.splice(index, 1);
            }
            Toaster.success(this.$t('shared.deleteScamSuccessfully').toString());
        } else {
            Toaster.error(response.errorMessage);
        }
        this.deleteDialog = false;
    }
    public async addScamRow() {

        //check if scam already added to the local list 
        const localyExists = this.scams.some((obj) => {
            return obj.senderName === this.senderName && obj.clientName === this.clientName;
        });
        if (localyExists) {
            Toaster.error('a scam with same clientName and senderName already exists');
        }
        else {
            //check if scam already added to the DATABASE
            const dbExists = await this.scamService.isScamExists(this.senderName, this.clientName);
            if (dbExists.isSuccess) {
                const response = await this.scamService.post(this.senderName, this.clientName);
                if (response.isSuccess) {
                    const newRow = {
                        id: response.result,
                        senderName: this.senderName,
                        clientName: this.clientName, // Set an initial age
                        // Add more properties as needed
                    };
                    this.scams.push(newRow);
                    Toaster.success(this.$t('shared.scamAddSuccessfully').toString());
                } else {
                    Toaster.error(response.errorMessage);
                }
            }
            else {
                Toaster.error(dbExists.errorMessage);
            }
        }

    }
    public get isValid(): boolean {
        return (this.senderName.trim() !== '' &&
            this.clientName.trim() !== '')
    }


}
