import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { AddRenewContractRequestModel } from './AddRenewContractRequestModel';

@Service()
export default class RenewContractRequestService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async AddRenewContractRequest
        (model: AddRenewContractRequestModel) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/contract-requests/renew';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    } 
}