<div class="w-100 h-100">
    <div class="d-flex justify-content-center">
        <div class="box pb-3">
            <div class="card-box bg-blue" @click="filter('0', '0')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.all')}}<br /> {{$t('dashboardPage.requests')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.all}}</h2>
                    </div>
                </div>
            </div>
        </div>
        <div class="box pb-3">
            <div class="card-box bg-pink" :class="{'active': requestStatus === '1'}" @click="filter('1', '0')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.pendingFor')}} <br /> {{$t('dashboardPage.approval')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.pendingForApproval}}</h2>
                    </div>
                </div>
                <div class="card-box-footer">{{dashboardBoxes.pendingForApprovalPercentage}}% {{$t('dashboardPage.thisMonth')}}</div>
            </div>
        </div>
        <div class="box pb-3">
            <div class="card-box bg-green" :class="{'active': requestStatus === '2'}" @click="filter('2', '0')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.approved')}} <br /> {{$t('dashboardPage.requests')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.approved}}</h2>
                    </div>
                </div>
                <div class="card-box-footer">{{dashboardBoxes.approvedPercentage}}% {{$t('dashboardPage.thisMonth')}}</div>
            </div>
        </div>
        <div class="box pb-3">
            <div class="card-box bg-red" :class="{'active': requestStatus === '3'}" @click="filter('3', '0')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.rejected')}} <br /> {{$t('dashboardPage.requests')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.rejected}}</h2>
                    </div>
                </div>
                <div class="card-box-footer">{{dashboardBoxes.rejectedPercentage}}% {{$t('dashboardPage.thisMonth')}}</div>
            </div>
        </div>
        <div class="box pb-3">
            <div class="card-box bg-gray" :class="{'active': requestStatus === '4'}" @click="filter('4', '0')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.paid')}} <br /> {{$t('dashboardPage.requests')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.paid}}</h2>
                    </div>
                </div>
                <div class="card-box-footer">{{dashboardBoxes.paidPercentage}}% {{$t('dashboardPage.thisMonth')}}</div>
            </div>
        </div>

        <div class="box pb-3">
            <div class="card-box bg-dark-blue" :class="{'active': senderStatus === '5'}" @click="filter('5', '0')">
                <div class="form-row justify-content-between align-items-center">
                    <div class="col-auto">
                        <label class="header">{{$t('dashboardPage.expired')}} <br /> {{$t('dashboardPage.requests')}}</label>
                    </div>
                    <div class="col-auto pt-0">
                        <h2 class="number">{{dashboardBoxes.expired}}</h2>
                    </div>
                </div>
                <div class="card-box-footer">&nbsp;</div>
            </div>
        </div>
    </div>
    <div class="row row-table">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-header">
                    <div class="form-row justify-content-between align-items-center w-100">
                        <div class="col-auto">
                            <label class="card_header_text">
                                <i class="fas fa-sync-alt reload" @click="reload()"></i>
                                {{$t('dashboardPage.requests')}} ({{sendersCount}})
                            </label>
                        </div>
                        <div class="col-auto">
                            <button v-if="userRole == 2 || userRole == 5" class="btn btn-primary btn-action" @click="exportToExcel()">{{$t('dashboardPage.exportRequestsToExcel')}}</button>
                            <button v-if="((userRole == 2 || userRole == 5) && !providerLocked&& !providerExpired)" :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="showresendDialog('')">{{$t('dashboardPage.resend')}}</button>
                            <button class="btn btn-primary btn-action" @click="checkRenewContract('')">{{$t('dashboardPage.renew')}}</button>
                            <button v-if="((userRole == 2 || userRole == 5) && !providerLocked && contractOptions.isNewContractEnabled && !providerExpired)" @click="addRequest()" tag="button" class="btn btn-primary btn-action">{{$t('dashboardPage.newRequest')}} <i class="fas fa-plus px-2"></i></button>
                            <button v-if="userRole == 1 || userRole == 9" :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="showapproveDialog('')">{{$t('dashboardPage.Approve')}}</button>
                            <button v-if="userRole == 1 || userRole == 9" :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="showRejectDialog('')">{{$t('dashboardPage.reject')}}</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.requestId"
                                       @keypress="search"
                                       :placeholder="$t('dashboardPage.requestId')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.senderName"
                                       @keypress="search"
                                       :placeholder="$t('dashboardPage.senderNameSearch')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       @keypress="search"
                                       v-model="filterModel.clientName"
                                       :placeholder="$t('dashboardPage.clientName')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       @keypress="search"
                                       v-model="filterModel.providerName"
                                       :placeholder="$t('dashboardPage.providerName')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-2 instead-box">
                            <v-select v-model="filterModel.status"
                                      :items="statusOptions"
                                      item-text="name"
                                      item-value="id"
                                      :change="search"
                                      :clearable="true"
                                      :label="$t('shared.selectStatus')"
                                      solo>
                            </v-select>
                        </div>
                    </div>
                    <template>
                        <v-data-table v-model="selected"
                                      :headers="headers"
                                      :items="senders"
                                      :total-items="sendersCount"
                                      select-all
                                      :pagination.sync="pagination"
                                      @update:pagination="updatePaginate"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      item-key="requestId"
                                      class="elevation-1 table-striped">
                            <template v-slot:headers="props">
                                <tr>
                                    <th>
                                        <v-checkbox :input-value="props.all"
                                                    :indeterminate="props.indeterminate"
                                                    primary
                                                    hide-details
                                                    @click.stop="selectAll"></v-checkbox>
                                    </th>
                                    <th v-for="header in props.headers"
                                        :key="header.text"
                                        :class="['column sortable', pagination.descending ? 'desc' : 'asc', header.value === pagination.sortBy ? 'active' : '']"
                                        @click="changeSort(header.value)" :width="header.width !== ''? header.width : ''">
                                        <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                                        {{ header.text }}
                                    </th>
                                </tr>
                            </template>
                            <template v-slot:items="props">
                                <tr>
                                    <td>
                                        <v-checkbox v-model="props.selected" v-if="!((userRole == 1 || userRole == 2 || userRole == 5 || userRole == 9) && (props.item.requestStatus != '4') && props.item.automaticallyInserted)"
                                                    @change="select($event, props.item.id)"
                                                    primary
                                                    hide-details></v-checkbox>
                                    </td>
                                    <td class="text-center" style="text-align: center;">{{props.item.requestId}}</td>
                                    <td class="text-center">{{props.item.senderName}}</td>
                                    <td class="text-center">{{ props.item.clientName }}</td>
                                    <td class="text-center">{{ props.item.providerName }}</td>
                                    <td class="text-center">{{ props.item.createdDate }}</td>
                                    <td class="text-center">{{ props.item.expiryDate }}</td>
                                    <td class="text-center" width="100px">
                                        <div class="d-flex justify-content-center">
                                            <div class="img">
                                                <img :src="require('../img/MobilyActive.svg')" v-if="getActivation(3, props.item.senderId,props.item.providerId)" />
                                                <img :src="require('../img/MobilyDisabeld.svg')" v-if="!getActivation(3, props.item.senderId,props.item.providerId)" />
                                            </div>
                                            <div class="img">
                                                <img :src="require('../img/STCActive.svg')" v-if="getActivation(2, props.item.senderId,props.item.providerId)" />
                                                <img :src="require('../img/STCDisabeld.svg')" v-if="!getActivation(2, props.item.senderId,props.item.providerId)" />
                                            </div>
                                            <div class="img">
                                                <img :src="require('../img/ZainActive.svg')" v-if="getActivation(1, props.item.senderId,props.item.providerId)" />
                                                <img :src="require('../img/ZainDisabeld.svg')" v-if="!getActivation(1, props.item.senderId,props.item.providerId)" />
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="status_txt" :class="'status-' + props.item.requestStatus">{{GetSenderStatus(props.item.requestStatus)}}</div>
                                            <Progress :transitionDuration="1000" :radius="10" :strokeColor="checkColor(props.item.requestStatus)" :strokeWidth="5" :value="getRate(props.item.remainingDays,props.item.totalDays)">
                                                <div class="content"></div>
                                                <template v-slot:footer>
                                                    <b></b>
                                                </template>
                                            </Progress>
                                            <div class="days_left">
                                                {{props.item.remainingDays}} {{$t('dashboardPage.of')}} {{props.item.totalDays}} <br /> {{$t('dashboardPage.daysLeft')}}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center">{{ props.item.senderExpiryDate }}</td>
                                    <td class="text-center btn_menu" v-if="!((userRole == 1 || userRole == 2 || userRole == 5 || userRole == 9) && (props.item.requestStatus != '4' && props.item.requestStatus != '5') && props.item.automaticallyInserted)">
                                        <template>
                                            <v-menu offset-y open-on-hover>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="btn-primary"
                                                           v-on="on">
                                                        <div>{{$t('shared.actions')}}</div>
                                                        <i class="fas fa-chevron-down pl-4" style="vertical-align: sub;"></i>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-tile v-if="(userRole == 1  || userRole == 9) && props.item.requestStatus == '1' && !props.item.automaticallyInserted && ((userRole == 1 && props.item.tenantId == tenantId) || userRole != 1) " @click="showapproveDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.Approve')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="(userRole == 1  || userRole == 9) && ((userRole == 1 && props.item.tenantId == tenantId) || userRole != 1) && props.item.requestStatus == '1' && !props.item.automaticallyInserted" @click="showRejectDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.reject')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="(userRole == 2 || userRole == 5) && !providerLocked && !providerExpired && props.item.requestStatus == '3' && !props.item.automaticallyInserted" @click="showresendDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.resend')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="(userRole == 2 || userRole == 5) && props.item.requestStatus == '3'&& !props.item.automaticallyInserted"
                                                                 :to="'/ConnectingRequestDashboard/UpdateContractRequest/' +  props.item.id">
                                                        <v-list-tile-title class="white">{{$t('shared.edit')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="contractOptions.isRenewContractEnabled &&(userRole == 2 || userRole == 5) && !providerExpired && ((props.item.requestStatus == '5' && props.item.senderStatus != 6) || (props.item.requestStatus == '4' ))"
                                                                 @click="checkRenewContract(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.renew')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="(userRole == 2 || userRole == 5 )&& props.item.requestStatus == '4' && props.item.senderStatus == 7" @click="ConectivityDetail(props.item.senderId)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.ConnectivityDetail')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="((userRole == 2 || userRole == 5 ) && props.item.requestStatus == '4' && (props.item.senderStatus == '2' || (props.item.senderStatus == '7')))" @click="ChangeSenderType(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('shared.changeSenderType')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile @click="Details(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.details')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="resendDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.resendInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideresendDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.comment')}}</label>
                                <input v-model="resendComment" class="form-control " type="text" />
                            </div>
                        </div>
                    </div>
                    <p class="mt-3">
                        {{$t('shared.resendContractInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideresendDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="resend('')">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="approveDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.approveInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideapproveDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.comment')}}</label>
                                <input v-model="approveComment" class="form-control " type="text" />
                            </div>
                        </div>
                    </div>
                    <p class="mt-3">
                        {{$t('shared.approveContractInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideapproveDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="approve('')">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

    <div class="text-xs-center">
        <v-dialog v-model="deleteDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.deleteInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideDeleteDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text">
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.comment')}}</label>
                                <input v-model="deleteComment" class="form-control " type="text" />
                            </div>
                        </div>
                    </div>
                    <p class="mt-3">
                        {{$t('shared.deletesenderInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideDeleteDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="deleteSender">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="rejectdialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('dashboardPage.reject')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideRejectDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>

                    <div class="row mt-3">
                        <div class="col-md-12">
                            <v-autocomplete :label="$t('dashboardPage.rejectreason')"
                                            :items="popUpRejectionReasons"
                                            return-object
                                            item-value="id"
                                            @input="handleItemChange"
                                            v-model="selectedRejectReason"
                                            item-text="rejectionText">

                            </v-autocomplete>

                            <div class="form-group" v-if="isItOtherReason">
                                <v-textarea clearable
                                            clear-icon="mdi-close-circle"
                                            bg-color="#dee2e6  !important"
                                            no-resize
                                            maxlength="80"
                                            rows="3"
                                            :label="$t('dashboardPage.rejectreason')"
                                            required
                                            @display=""
                                            :rules="rules"
                                            variant="filled"
                                            v-model="rejectResone">
                                </v-textarea>
                            </div>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideRejectDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" :disabled="!rejectReasonIsValid" @click="reject">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>

</div>

