import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './ProviderRequestInfo.html';
import { Inject } from 'vue-di-container';
import ProviderRequestsService from '../ProviderRequestsService';
import { ProviderRequestsModel } from '../ProviderRequestsModel';
import FileSaver from 'file-saver';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        ProviderRequestsService,
    ],
})
export default class ProviderRequestInfo extends Vue {
    @Inject(ProviderRequestsService) public providerRequestsService!: ProviderRequestsService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;

    @Prop({ default: '' })
    public id!: string;


    public providerRequest: ProviderRequestsModel = {} as ProviderRequestsModel;


    public created() {
        this.$emit('childinit', this.layoutSup);
    }

    public async mounted() {
        this.providerRequest = await this.providerRequestsService.GeById(this.id);
    }
    public async DownloadFile(file: ProviderRequestsModel) {
        const array = Buffer.from(file.fileContent, 'base64');
        const blob = new Blob([array], { type: 'application/pdf' });
        FileSaver.saveAs(blob, file.fileName);
    }
    public GetProviderRequestStatus(typeStatus: number) {
        typeStatus = typeStatus;
        switch (typeStatus) {
            case 1: {
                return 'Pending Approval';
                break;
            }
            case 2: {
                return 'Approved';
                break;
            }
            case 3: {
                return 'Rejected';
                break;
            }
        }
    }
}
