export enum SenderStatus {
    PendingApproval = 1,
    Approved = 2,
    Rejected = 3,
    Deactivated = 4,
    Expired = 5,
    Deleted = 6,
    Paid = 7,
    PendingForVerify = 9,
    PendingForReview = 10
}