import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './ScamList.html';
import { ScamModel } from './ScamModel';
import ScamListService from './ScamListService';
import { Toaster } from '../../Services/toast';
import UserService from '../../shared/userService/UserService';
import UserRoleService from '../../shared/userService/UserRoleService';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        ScamListService,
        UserService,
        UserRoleService,
    ],
})
export default class ScamList extends Vue {
    @Inject(ScamListService) public scamListService!: ScamListService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public deleteDialog: boolean = false;
    public editDialog: boolean = false;
    public scamId: string = '0';
    public name: string = '0';
    public User: any;
    public userRole: any;
    public headers: any = [
        {
            text: this.$t('scamPage.clientName'), value: 'Client Name', align: 'center', sortable: false,
        },
        {
            text: this.$t('scamPage.senderName'), value: 'Sender Name', align: 'center', sortable: false,
        },
        
        {
            text: this.$t('shared.actions'), sortable: false, value: 'id', align: 'center', width: '100'
        },
    ];

    public scams: ScamModel[] = [];
    public scamsCount: number = 0;
    public filterScams: ScamModel[] = [];
    public filterModel: any = {
        id: '',
        senderName: '',
        clientName: '',
    };
    public editModel: any = {
        id: '',
        senderName: '',
        clientName: '',
    };
    public async created() {
        this.User = await this.userService.getUser();
        this.userRole = this.GetUserRole();
        this.$emit('childinit', this.layoutSup);
    }
    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.User);
    }
    public async mounted() {
        const response = await this.scamListService.get(this.filterModel.senderName,
            this.filterModel.clientName, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.scams = response.result;
        this.scamsCount = response.totalCount;
    }
    @Watch('filterModel', { deep: true })
    public async search() {
        const response = await this.scamListService.get(this.filterModel.senderName,
            this.filterModel.clientName, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.scams = response.result;
        this.scamsCount = response.totalCount;
    }

    public showDeleteDialog(id: string) {
        this.scamId = id;
        this.deleteDialog = true;
    }
    public hideDeleteDialog() {
        this.deleteDialog = false;
    }
    public async deleteScam() {
        const response = await this.scamListService.delete(this.scamId);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.deleteScamSuccessfully').toString());
            this.search();
        } else {
            Toaster.error(response.errorMessage);
        }
        this.deleteDialog = false;
    }

    public showEditDialog(id: string) {
        const scam = this.scams.find(x => x.id == id);
        if (scam === undefined) {
            return;
        }
        this.editModel.id = id;
        this.editModel.senderName = scam.senderName;
        this.editModel.clientName = scam.clientName;
        this.editDialog = true;
    }
    public async editScam() {

        const dbNotExists = await this.scamListService.isScamExists(this.editModel.senderName, this.editModel.clientName);
        if (dbNotExists.isSuccess) { 
            this.editDialog = false;
            const response = await this.scamListService.edit(this.editModel);
            if (response.isSuccess) {
                this.search();
                Toaster.success(this.$t('shared.editScamSuccessfully').toString());
            }

        }
        else {
            Toaster.error('a scam with same clientName and senderName already exists');
        }
    }
    public hideEditDialog() {
        this.editDialog = false;
    }

    public async updatePaginate(val: any) {
        const response = await this.scamListService.get(this.filterModel.senderName.toString(),
            this.filterModel.clientName, (val.page - 1).toString(), val.rowsPerPage);
        this.scams = response.result;
        this.scamsCount = response.totalCount;
    }
    public sleep(ms: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }
}
