import { Service, Inject } from 'vue-di-container';
import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';

@Service()
export default class ConnectivityActivationReportService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async GetConnectivityActivationReport(
        requestId: string, senderName: string,
        pageIndex: string,
        pageSize: string): Promise<any> {
        const baseURL = 'api/report/connectivity-activation-report';
        const url = window.$config.HostName + baseURL + '?requestId='
            + requestId
            + '&senderName=' + senderName
            + '&pageIndex=' + pageIndex
            + '&pageSize=' + pageSize;
        const result = await this.axiosService.axiosInstance.get(url);
        return result.data;
    }
}
