import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
@Service()
export default class UpdateDataRequestService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async Get(model: any): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/update-request-data';
            const result = await this.axiosService.axiosInstance.get(url, {
                params: model,
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async approve(model: string[]): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/update-request-data/approve';
            const result = await this.axiosService.axiosInstance.put(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async deactivate(model: string[]): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/senders/deactivate';
            const result = await this.axiosService.axiosInstance.put(url, model);
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async resend(model: string[]): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/update-request-data/resend';
            const result = await this.axiosService.axiosInstance.put(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async reject(ids: string[], reason: string): Promise<any> {
        this.loaderService.ShowLoader();
        const request = {
            rejectionReason: reason,
            senderIds: ids,
        };
        try {
            const url = window.$config.HostName + 'api/update-request-data/reject';
            const result = await this.axiosService.axiosInstance.put(url, request);
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetById(id: string): Promise<any> {
        try {
            const url = window.$config.HostName + 'api/update-request-data/' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
