import 'izitoast/dist/css/iziToast.min.css';
import iZtoast from 'izitoast';

export class Toaster {
    public static error(msg: string) {
        return iZtoast.error({
            title: '',
            message: msg,
            position: 'topRight',
            timeout: 10000,
        });
    }
    public static success(msg: string) {
        return iZtoast.success({
            title: '',
            message: msg,
            position: 'topRight',
            timeout: 10000,
        });
    }
    public static warning(msg: string, func: () => void) {
        return iZtoast.warning({
            title: 'Warning',
            message: msg,
            position: 'topRight',
            timeout: false,
            buttons: [
                ['<button>Re-login</button>', (instance, toast) => {
                    instance.hide({ transitionOut: 'fadeOut' }, toast, 'button');
                    func();
                }, true],
            ],
        });
    }
}
