<div class="w-100 h-100">
    <div class="row">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="col-md-12 mx-5">
                        <div class="form-row justify-content-between align-items-baseline">
                            <div class="col-auto">
                                <label class="header_text_black mb-4">
                                    {{$t('shared.allActiveSenders')}} <b>{{dashboardBoxes.all}}</b>
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-between mx-5">
                        <div class="card-box-sender shadow p-2 mb-5 bg-white rounded">
                            <div class="row">
                                <div class="col-md-2 mt-25 mx-3 ">
                                    <i class="fs-35 fa fa-university color-red"></i>
                                </div>
                                <div class="col-md-8">
                                    <b>{{$t('shared.governmental')}}</b>
                                    <br />
                                    <b class="color-red fs-25">{{dashboardBoxes.government}}</b>
                                    <p class="fs-11">{{$t('shared.senders')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card-box-sender shadow p-2 mb-5 bg-white rounded">
                            <div class="row">
                                <div class="col-md-2 mt-25 mx-3 ">
                                    <i class="fs-35 fa fa-building color-blue"></i>
                                </div>
                                <div class="col-md-8">
                                    <b>{{$t('shared.private')}}</b>
                                    <br />
                                    <b class="color-blue fs-25">{{dashboardBoxes.private}}</b>
                                    <p class="fs-11">{{$t('shared.senders')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card-box-sender shadow p-2 mb-5 bg-white rounded">
                            <div class="row">
                                <div class="col-md-2 mt-25 mx-3 ">
                                    <i class="fs-35 fa fa-hand-holding-heart color-qreen"></i>
                                </div>
                                <div class="col-md-8">
                                    <b>{{$t('shared.charities')}}</b>
                                    <br />
                                    <b class="color-qreen fs-25">{{dashboardBoxes.charity}}</b>
                                    <p class="fs-11">{{$t('shared.senders')}}</p>
                                </div>
                            </div>
                        </div>
                        <div class="card-box-sender shadow p-2 mb-5 bg-white rounded">
                            <div class="row">
                                <div class="col-md-2 mt-25 mx-3 ">
                                    <i class="fs-35 fa fa-globe color-qreen"></i>
                                </div>
                                <div class="col-md-8">
                                    <b>{{$t('shared.international')}}</b>
                                    <br />
                                    <b class="color-qreen fs-25">{{dashboardBoxes.international}}</b>
                                    <p class="fs-11">{{$t('shared.senders')}}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row row mx-5 ">
                        <div class="col-md-3"></div>
                        <div class="col-md-2 mb-3">
                            <label>{{$t('shared.operator')}}</label>
                            <v-select style="height: 38%; place-items: center; "
                                      class="soled-date-picker rounded-pill shadow p-1 mb-3 bg-white border-blue-dooted bg-white"
                                      v-model="chartFilter.tenant"
                                      :items="tenants"
                                      item-text="name"
                                      item-value="id"
                                      :label="$t('shared.select')"
                                      min-width="200px"
                                      solo>
                            </v-select>
                        </div>
                        <div class="col-md-2 mb-3">
                            <label>{{$t('shared.RequestType')}}</label>
                            <v-select style="height: 38%; place-items: center; "
                                      class="soled-date-picker rounded-pill shadow p-1 mb-3 bg-white border-blue-dooted bg-white"
                                      v-model="chartFilter.requestType"
                                      :items="requestTypes"
                                      item-text="name"
                                      item-value="id"
                                      :label="$t('shared.select')"
                                      min-width="200px"
                                      solo>
                            </v-select>
                        </div>
                        <div class="col-md-2 mb-3">
                            <h5>{{$t('shared.from')}}</h5>
                            <v-menu v-model="createdDateFromMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    lazy
                                    transition="scale-transition"
                                    offset-y
                                    full-width
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="chartFilter.dateFrom"
                                                  label="From"
                                                  prepend-icon="event"
                                                  dense
                                                  solo
                                                  height="10"
                                                  class="soled-date-picker rounded-pill shadow p-1 mb-3 bg-white border-blue-dooted bg-white"
                                                  readonly
                                                  v-on="on">

                                    </v-text-field>
                                </template>
                                <v-date-picker v-model="chartFilter.dateFrom"
                                               @input="createdDateFromMenu = false"
                                               no-title
                                               scrollable></v-date-picker>
                            </v-menu>
                        </div>
                        <div class="col-md-2 mb-7 hi">
                            <h5>{{$t('shared.to')}}</h5>
                            <v-menu v-model="createdDateToMenu"
                                    :close-on-content-click="false"
                                    :nudge-right="40"
                                    lazy
                                    transition="scale-transition"
                                    offset-y
                                    full-width
                                    min-width="290px">
                                <template v-slot:activator="{ on }">
                                    <v-text-field v-model="chartFilter.dateTo"
                                                  label="To"
                                                  prepend-icon="event"
                                                  dense
                                                  solo
                                                  height="10"
                                                  class="soled-date-picker rounded-pill shadow p-1 mb-3 bg-white border-blue-dooted bg-whit"
                                                  readonly
                                                  v-on="on"></v-text-field>
                                </template>
                                <v-date-picker v-model="chartFilter.dateTo"
                                               @input="createdDateToMenu = false"
                                               no-title
                                               scrollable>
                                </v-date-picker>
                            </v-menu>
                        </div>
                        <div class="col-md-1 pt-4">
                            <button class="btn btn-primary btn-action" @click="generateReport">
                                {{$t('Print')}}
                            </button>
                        </div>

                    </div>

                    <div class="row shadow p-4 mb-5 bg-white rounded mx-5">
                        <b>{{$t('shared.allRequests')}}</b>
                        <b class="blue-color fs-17 mx-2">{{TotalRequestsChart}}</b>
                        <div class="col-md-12">
                            <canvas id="line_charts"></canvas>
                        </div>
                    </div>
                    <div class="row justify-content-between mx-5">
                        <div class="card-box-transition width-68 shadow p-2 mb-4 bg-white rounded">
                            <div class="row justify-content-between">
                                <div class="col-md-6 mb-2">
                                    <router-link :to="'/SenderRequestStatusDashboard?status=2&dateFrom=' + chartFilter.dateFrom + '&dateTo=' + chartFilter.dateTo+ '&tenant=' + chartFilter.tenant+'&requestType=' + chartFilter.requestType">
                                        <i><b> {{$t('shared.approvedRequests')}}</b></i>
                                    </router-link>
                                </div>
                                <div class="col-md-4 text-right">
                                    <b class="color-blue-light fs-17" v-if="approved == 1">{{dashboardStatusBoxes.approved.allCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="approved == 2">{{dashboardStatusBoxes.approved.governmentCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="approved == 3">{{dashboardStatusBoxes.approved.privateCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="approved == 4">{{dashboardStatusBoxes.approved.charityCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="approved == 5">{{dashboardStatusBoxes.approved.internationalCount}}</b>
                                </div>
                                <div class="col-md-3 width-20">
                                    <v-select :items="items"
                                              height="10"
                                              v-model="approved"
                                              class="soled-fil"
                                              dense
                                              item-text="name"
                                              item-value="id"
                                              solo></v-select>
                                </div>
                                <div class="col-md-12">
                                    <div class="progress" style="height: 8px;">
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="approved == 1"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.approved.all)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="approved == 2"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.approved.government)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="approved == 3"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.approved.private)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="approved == 4"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.approved.charity)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="approved == 5"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.approved.international)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-box-transition width-30 shadow p-2 mb-4 bg-white rounded">
                            <div class="row justify-content-between">
                                <div class="col-md-6 mb-2 ">
                                    <router-link :to="'/SenderRequestStatusDashboard?status=3&dateFrom=' + chartFilter.dateFrom + '&dateTo=' + chartFilter.dateTo+ '&tenant=' + chartFilter.tenant+'&requestType=' + chartFilter.requestType">
                                        <i> <b> {{$t('shared.rejectedRequests')}}</b></i>
                                    </router-link>
                                </div>
                                <div class="col-md-3 text-right">
                                    <b class="color-blue-light fs-17" v-if="rejected == 1">{{dashboardStatusBoxes.rejected.allCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="rejected == 2">{{dashboardStatusBoxes.rejected.governmentCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="rejected == 3">{{dashboardStatusBoxes.rejected.privateCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="rejected == 4">{{dashboardStatusBoxes.rejected.charityCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="rejected == 5">{{dashboardStatusBoxes.rejected.internationalCount}}</b>
                                </div>
                                <div class="col-md-6">
                                    <v-select :items="items"
                                              height="10"
                                              v-model="rejected"
                                              class="soled-fil"
                                              dense
                                              item-text="name"
                                              item-value="id"
                                              solo></v-select>
                                </div>
                                <div class="col-md-12">
                                    <div class="progress" style="height: 8px;">
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="rejected == 1"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.rejected.all)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="rejected == 2"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.rejected.government)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="rejected == 3"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.rejected.private)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="rejected == 4"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.rejected.charity)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="rejected == 5"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.rejected.international)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-between mx-5">
                        <div class="card-box-transition width-30 shadow p-2 mb-4 bg-white rounded">
                            <div class="row justify-content-between">
                                <div class="col-md-6 mb-2">
                                    <router-link :to="'/SenderRequestStatusDashboard?status=1&dateFrom=' + chartFilter.dateFrom + '&dateTo=' + chartFilter.dateTo+'&tenant=' + chartFilter.tenant+'&requestType=' + chartFilter.requestType">
                                        <i><b> {{$t('shared.pendingApproval')}}</b> </i>
                                    </router-link>
                                </div>
                                <div class="col-md-3 text-right">
                                    <b class="color-blue-light fs-17"
                                       v-if="pendingForApproval == 1">{{dashboardStatusBoxes.pendingForApproval.allCount}}</b>
                                    <b class="color-blue-light fs-17"
                                       v-if="pendingForApproval == 2">{{dashboardStatusBoxes.pendingForApproval.governmentCount}}</b>
                                    <b class="color-blue-light fs-17"
                                       v-if="pendingForApproval == 3">{{dashboardStatusBoxes.pendingForApproval.privateCount}}</b>
                                    <b class="color-blue-light fs-17"
                                       v-if="pendingForApproval == 4">{{dashboardStatusBoxes.pendingForApproval.charityCount}}</b>
                                    <b class="color-blue-light fs-17"
                                       v-if="pendingForApproval == 5">{{dashboardStatusBoxes.pendingForApproval.internationalCount}}</b>
                                </div>
                                <div class="col-md-6">
                                    <v-select :items="items"
                                              height="10"
                                              v-model="pendingForApproval"
                                              class="soled-fil"
                                              dense
                                              item-text="name"
                                              item-value="id"
                                              solo></v-select>
                                </div>
                                <div class="col-md-12">
                                    <div class="progress" style="height: 8px;">
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="pendingForApproval == 1"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.pendingForApproval.all)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="pendingForApproval == 2"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.pendingForApproval.government)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="pendingForApproval == 3"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.pendingForApproval.private)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="pendingForApproval == 4"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.pendingForApproval.charity)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="pendingForApproval == 5"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.pendingForApproval.international)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-box-transition width-30 shadow p-2 mb-4 bg-white rounded">
                            <div class="row justify-content-between">
                                <div class="col-md-6 mb-2">
                                    <router-link :to="'/SenderRequestStatusDashboard?status=0&dateFrom=' + chartFilter.dateFrom + '&dateTo=' + chartFilter.dateTo+'&tenant=' + chartFilter.tenant+'&requestType=' + chartFilter.requestType">
                                        <i><b> {{$t('shared.activatedRequests')}}</b> </i>
                                    </router-link>
                                </div>
                                <div class="col-md-3 text-right">
                                    <b class="color-blue-light fs-17" v-if="activated == 1">{{dashboardStatusBoxes.activated.allCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="activated == 2">{{dashboardStatusBoxes.activated.governmentCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="activated == 3">{{dashboardStatusBoxes.activated.privateCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="activated == 4">{{dashboardStatusBoxes.activated.charityCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="activated == 5">{{dashboardStatusBoxes.activated.internationalCount}}</b>
                                </div>
                                <div class="col-md-6">
                                    <v-select :items="items"
                                              height="10"
                                              v-model="activated"
                                              class="soled-fil"
                                              dense
                                              item-text="name"
                                              item-value="id"
                                              solo></v-select>
                                </div>
                                <div class="col-md-12">
                                    <div class="progress" style="height: 8px;">
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="activated == 1"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.activated.all)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="activated == 2"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.activated.government)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="activated == 3"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.activated.private)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="activated == 4"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.activated.charity)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="activated == 5"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.activated.international)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="card-box-transition width-30 shadow p-2 mb-4 bg-white rounded">
                            <div class="row justify-content-between">
                                <div class="col-md-6 mb-2">
                                    <router-link :to="'/SenderRequestStatusDashboard?status=4&dateFrom=' + chartFilter.dateFrom + '&dateTo=' + chartFilter.dateTo+'&tenant=' + chartFilter.tenant+'&requestType=' + chartFilter.requestType">
                                        <i><b> {{$t('shared.paidRequests')}}</b> </i>
                                    </router-link>
                                </div>

                                <div class="col-md-3 text-right">
                                    <b class="color-blue-light fs-17" v-if="paid == 1">{{dashboardStatusBoxes.paid.allCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="paid == 2">{{dashboardStatusBoxes.paid.governmentCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="paid == 3">{{dashboardStatusBoxes.paid.privateCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="paid == 4">{{dashboardStatusBoxes.paid.charityCount}}</b>
                                    <b class="color-blue-light fs-17" v-if="paid == 5">{{dashboardStatusBoxes.paid.internationalCount}}</b>
                                </div>
                                <div class="col-md-6">
                                    <v-select :items="items"
                                              height="10"
                                              v-model="paid"
                                              class="soled-fil"
                                              dense
                                              item-text="name"
                                              item-value="id"
                                              solo></v-select>
                                </div>
                                <div class="col-md-12">
                                    <div class="progress" style="height: 8px;">
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="paid == 1"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.paid.all)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="paid == 2"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.paid.government)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="paid == 3"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.paid.private)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="paid == 4"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.paid.charity)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                        <div class="progress-bar color-qreen-progress"
                                             v-if="paid == 5"
                                             role="progressbar"
                                             v-bind:style="percentage(dashboardStatusBoxes.paid.international)"
                                             aria-valuenow="60"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <hr class="mx-5" />
                    <div class="col-md-12 mx-5 pt-5">
                        <div class="form-row justify-content-between align-items-baseline">
                            <div class="col-auto">
                                <label class="header_text_black mb-4">
                                    {{$t('shared.requestsAlerts')}}
                                </label>
                            </div>
                        </div>
                    </div>
                    <div class="row justify-content-between mx-5 pt-4">
                        <div class="card-box-Alerts shadow p-2 mb-4 bg-white rounded border-red">
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <i v-if="pendingApprovals[3].percentage > 0" class="color-red fa fa-exclamation-circle"></i>
                                    <router-link :to="'/AlertsDashboard?status=1&tenant=' + chartFilter.tenant+'&requestType=' + chartFilter.requestType">
                                        <i><b> {{$t('shared.PendingApprovalRequests')}}</b> </i>
                                    </router-link>
                                </div>
                                <div class="col-md-3">
                                    <p>{{pendingApprovals[0].dayOrder }}{{$t('shared.days')}}</p>
                                </div>
                                <div class="col-md-7">
                                    <div class="progress" style="height: 5px; margin-top: 12px;">
                                        <div class="progress-bar  color-qreen-progress"
                                             role="progressbar"
                                             v-bind:style="percentage(pendingApprovals[0].percentage)"
                                             aria-valuenow="10"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b class="color-qreen fs-13">{{pendingApprovals[0].count}}</b>
                                </div>
                                <div class="col-md-3">
                                    <p>{{pendingApprovals[1].dayOrder}}{{$t('shared.days')}}</p>
                                </div>
                                <div class="col-md-7">
                                    <div class="progress" style="height: 5px; margin-top: 12px;">
                                        <div class="progress-bar color-blue-light-progress"
                                             role="progressbar"
                                             v-bind:style="percentage(pendingApprovals[1].percentage)"
                                             aria-valuenow="70"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b class="color-blue-light fs-13">{{pendingApprovals[1].count}}</b>
                                </div>
                                <div class="col-md-3">
                                    <p>{{pendingApprovals[2].dayOrder}}{{$t('shared.days')}}</p>
                                </div>
                                <div class="col-md-7">
                                    <div class="progress" style="height: 5px; margin-top: 12px;">
                                        <div class="progress-bar color-pink-progress"
                                             role="progressbar"
                                             v-bind:style="percentage(pendingApprovals[2].percentage)"
                                             aria-valuenow="9"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b class="color-pink fs-13">{{pendingApprovals[2].count}}</b>
                                </div>
                                <div class="col-md-3">
                                    <p>{{pendingApprovals[3].dayOrder}}{{$t('shared.daysAndMore')}}</p>
                                </div>
                                <div class="col-md-7">
                                    <div class="progress" style="height: 5px; margin-top: 12px;">
                                        <div class="progress-bar color-red-progress"
                                             role="progressbar"
                                             v-bind:style="percentage(pendingApprovals[3].percentage)"
                                             aria-valuenow="9"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b class="color-red fs-13">{{pendingApprovals[3].count}}</b>
                                </div>
                            </div>
                        </div>

                        <div class="card-box-Alerts shadow p-2 mb-4 bg-white rounded border-red">
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <i v-if="pendingPaymentAlerts[3].percentage > 0" class="color-red fa fa-exclamation-circle"></i>
                                    <router-link :to="'/AlertsDashboard?status=2&tenant=' + chartFilter.tenant+'&requestType=' + chartFilter.requestType">
                                        <i><b> {{$t('shared.PendingPaymentRequests')}}</b> </i>
                                    </router-link>
                                </div>
                                <div class="col-md-3">
                                    <p>{{pendingPaymentAlerts[0].dayOrder }}{{$t('shared.days')}}</p>
                                </div>
                                <div class="col-md-7">
                                    <div class="progress" style="height: 5px; margin-top: 12px;">
                                        <div class="progress-bar  color-qreen-progress"
                                             role="progressbar"
                                             v-bind:style="percentage(pendingPaymentAlerts[0].percentage)"
                                             aria-valuenow="10"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b class="color-qreen fs-13">{{pendingPaymentAlerts[0].count}}</b>
                                </div>

                                <div class="col-md-3">
                                    <p>{{pendingPaymentAlerts[1].dayOrder}}{{$t('shared.days')}}</p>
                                </div>
                                <div class="col-md-7">
                                    <div class="progress" style="height: 5px; margin-top: 12px;">
                                        <div class="progress-bar color-blue-light-progress"
                                             role="progressbar"
                                             v-bind:style="percentage(pendingPaymentAlerts[1].percentage)"
                                             aria-valuenow="70"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b class="color-blue-light fs-13">{{pendingPaymentAlerts[1].count}}</b>
                                </div>
                                <div class="col-md-3">
                                    <p>{{pendingPaymentAlerts[2].dayOrder}}{{$t('shared.days')}}</p>
                                </div>
                                <div class="col-md-7">
                                    <div class="progress" style="height: 5px; margin-top: 12px;">
                                        <div class="progress-bar color-pink-progress"
                                             role="progressbar"
                                             v-bind:style="percentage(pendingPaymentAlerts[2].percentage )"
                                             aria-valuenow="9"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b class="color-pink fs-13">{{pendingPaymentAlerts[2].count}}</b>
                                </div>
                                <div class="col-md-3">
                                    <p>{{pendingPaymentAlerts[3].dayOrder}}{{$t('shared.days')}}</p>
                                </div>
                                <div class="col-md-7">
                                    <div class="progress" style="height: 5px; margin-top: 12px;">
                                        <div class="progress-bar color-red-progress"
                                             role="progressbar"
                                             v-bind:style="percentage(pendingPaymentAlerts[3].percentage)"
                                             aria-valuenow="9"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b class="color-red fs-13">{{pendingPaymentAlerts[3].count}}</b>
                                </div>
                            </div>
                        </div>
                        <div class="card-box-Alerts shadow p-2 mb-4 bg-white rounded border-red">
                            <div class="row">
                                <div class="col-md-12 mb-3">
                                    <i v-if="pendingActivationAlerts[3].percentage > 0" class="color-red fa fa-exclamation-circle"></i>
                                    <router-link :to="'/AlertsDashboard?status=3&tenant=' + chartFilter.tenant+'&requestType=' + chartFilter.requestType">

                                        <i><b> {{$t('shared.pendingActivationRequests')}}</b> </i>
                                    </router-link>
                                </div>
                                <div class="col-md-3">
                                    <p>{{pendingActivationAlerts[0].dayOrder}}{{$t('shared.days')}}</p>
                                </div>
                                <div class="col-md-7">
                                    <div class="progress" style="height: 5px; margin-top: 12px;">
                                        <div class="progress-bar  color-qreen-progress"
                                             role="progressbar"
                                             v-bind:style="percentage(pendingActivationAlerts[0].percentage)"
                                             aria-valuenow="10"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b class="color-qreen fs-13">{{pendingActivationAlerts[0].count}}</b>
                                </div>

                                <div class="col-md-3">
                                    <p>{{pendingActivationAlerts[1].dayOrder }}{{$t('shared.days')}}</p>
                                </div>
                                <div class="col-md-7">
                                    <div class="progress" style="height: 5px; margin-top: 12px;">
                                        <div class="progress-bar color-blue-light-progress"
                                             role="progressbar"
                                             v-bind:style="percentage(pendingActivationAlerts[1].percentage)"
                                             aria-valuenow="70"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b class="color-blue-light fs-13">{{pendingActivationAlerts[1].count}}</b>
                                </div>
                                <div class="col-md-3">
                                    <p>{{pendingActivationAlerts[2].dayOrder }}{{$t('shared.days')}}</p>
                                </div>
                                <div class="col-md-7">
                                    <div class="progress" style="height: 5px; margin-top: 12px;">
                                        <div class="progress-bar color-pink-progress"
                                             role="progressbar"
                                             v-bind:style="percentage(pendingActivationAlerts[2].percentage)"
                                             aria-valuenow="9"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b class="color-pink fs-13">{{pendingActivationAlerts[2].count}}</b>
                                </div>
                                <div class="col-md-3">
                                    <p>{{pendingActivationAlerts[3].dayOrder }}{{$t('shared.daysAndMore')}}</p>
                                </div>
                                <div class="col-md-7">
                                    <div class="progress" style="height: 5px; margin-top: 12px;">
                                        <div class="progress-bar color-red-progress"
                                             role="progressbar"
                                             v-bind:style="percentage(pendingActivationAlerts[3].percentage)"
                                             aria-valuenow="9"
                                             aria-valuemin="0"
                                             aria-valuemax="100"></div>
                                    </div>
                                </div>
                                <div class="col-md-2">
                                    <b class="color-red fs-13">{{pendingActivationAlerts[3].count}}</b>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <vue-html2pdf :show-layout="false"
                  :float-layout="true"
                  :enable-download="true"
                  :preview-modal="false"
                  filename="Dashboard"
                  :pdf-quality="2"
                  :manual-pagination="true"
                  pdf-format="a3"
                  pdf-orientation="landscape"
                  :paginate-elements-by-height="900"
                  pdf-content-width="100%"
                  ref="html2Pdf">
        <section slot="pdf-content">
            <div class="row">
                <div class="col-md-12 pr-0">
                    <div class="card card_layout">
                        <div class="card-body">
                            <div class="col-md-12 mx-5 pt-5">
                                <div class="form-row justify-content-between align-items-baseline">
                                    <div class="col-auto">
                                        <label class="header_text_black mb-4">
                                            {{$t('shared.allActiveSenders')}} <b>{{dashboardBoxes.all}}</b>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-between mx-5 pt-5">
                                <div class="card-box-sender shadow p-2 mb-5 bg-white rounded">
                                    <div class="row">
                                        <div class="col-md-2 mt-25 mx-3 ">
                                            <i class="fs-35 fa fa-university color-red"></i>
                                        </div>
                                        <div class="col-md-8">
                                            <b>{{$t('shared.governmental')}}</b>
                                            <br />
                                            <b class="color-red fs-25">{{dashboardBoxes.government}}</b>
                                            <p class="fs-11">{{$t('shared.senders')}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-box-sender shadow p-2 mb-5 bg-white rounded">
                                    <div class="row">
                                        <div class="col-md-2 mt-25 mx-3 ">
                                            <i class="fs-35 fa fa-building color-blue"></i>
                                        </div>
                                        <div class="col-md-8">
                                            <b>{{$t('shared.private')}}</b>
                                            <br />
                                            <b class="color-blue fs-25">{{dashboardBoxes.private}}</b>
                                            <p class="fs-11">{{$t('shared.senders')}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-box-sender shadow p-2 mb-5 bg-white rounded">
                                    <div class="row">
                                        <div class="col-md-2 mt-25 mx-3 ">
                                            <i class="fs-35 fa fa-hand-holding-heart color-qreen"></i>
                                        </div>
                                        <div class="col-md-8">
                                            <b>{{$t('shared.charities')}}</b>
                                            <br />
                                            <b class="color-qreen fs-25">{{dashboardBoxes.charity}}</b>
                                            <p class="fs-11">{{$t('shared.senders')}}</p>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-box-sender shadow p-2 mb-5 bg-white rounded">
                                    <div class="row">
                                        <div class="col-md-2 mt-25 mx-3 ">
                                            <i class="fs-35 fa fa-globe color-qreen"></i>
                                        </div>
                                        <div class="col-md-8">
                                            <b>{{$t('shared.international')}}</b>
                                            <br />
                                            <b class="color-qreen fs-25">{{dashboardBoxes.international}}</b>
                                            <p class="fs-11">{{$t('shared.senders')}}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row row mx-5 pt-5">
                                <div class="col-md-4"></div>
                                <div class="col-md-2 mb-3 ">
                                    <label>{{$t('shared.operator')}}</label>
                                    <v-select style="height: 38%; place-items: center; "
                                              class="soled-date-picker rounded-pill shadow p-1 mb-3 bg-white border-blue-dooted bg-white"
                                              v-model="chartFilter.tenant"
                                              :items="tenants"
                                              item-text="name"
                                              item-value="id"
                                              :label="$t('shared.select')"
                                              min-width="200px"
                                              solo>
                                    </v-select>
                                </div>
                                <div class="col-md-2 mb-3">
                                    <label>{{$t('shared.RequestType')}}</label>
                                    <v-select style="height: 38%; place-items: center; "
                                              class="soled-date-picker rounded-pill shadow p-1 mb-3 bg-white border-blue-dooted bg-white"
                                              v-model="chartFilter.requestType"
                                              :items="requestTypes"
                                              item-text="name"
                                              item-value="id"
                                              :label="$t('shared.select')"
                                              min-width="200px"
                                              solo>
                                    </v-select>
                                </div>
                                <div class="col-md-2  pb-3">
                                    <label>{{$t('shared.from')}}</label>
                                    <h5 class="soled-date-picker rounded-pill  text-center  shadow p-2 mb-3 bg-white border-blue-dooted bg-whit">{{chartFilter.dateFrom}}</h5>
                                </div>
                                <div class="col-md-2 pb-3">
                                    <label>{{$t('shared.to')}}</label>
                                    <h5 class="soled-date-picker rounded-pill text-center shadow p-2 mb-3 bg-white border-blue-dooted bg-whit">{{chartFilter.dateTo}}</h5>
                                </div>
                            </div>
                            <div class="row shadow p-4 mb-5 bg-white rounded mx-5">
                                <b>{{$t('shared.allRequests')}}</b>
                                <b class="blue-color fs-17 mx-2">{{TotalRequestsChart}}</b>
                                <div class="col-md-12">
                                    <canvas id="line_charts_report"></canvas>
                                </div>
                            </div>
                            <div class="row justify-content-between mx-5 pt-5">
                                <div class="card-box-transition width-68 shadow p-2 mb-4 bg-white rounded">
                                    <div class="row justify-content-between">
                                        <div class="col-md-6 mb-2">
                                            <router-link :to="'/SenderRequestStatusDashboard?status=2&dateFrom=' + chartFilter.dateFrom + '&dateTo=' + chartFilter.dateTo">
                                                <i><b> {{$t('shared.approvedRequests')}}</b> </i>
                                            </router-link>
                                        </div>
                                        <div class="col-md-4 text-right">
                                            <b class="color-blue-light fs-17" v-if="approved == 1">{{dashboardStatusBoxes.approved.allCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="approved == 2">{{dashboardStatusBoxes.approved.governmentCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="approved == 3">{{dashboardStatusBoxes.approved.privateCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="approved == 4">{{dashboardStatusBoxes.approved.charityCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="approved == 5">{{dashboardStatusBoxes.approved.internationalCount}}</b>
                                        </div>
                                        <div class="col-md-3 width-20">
                                            <v-select :items="items"
                                                      height="10"
                                                      v-model="approved"
                                                      class="soled-fil"
                                                      dense
                                                      item-text="name"
                                                      item-value="id"
                                                      solo></v-select>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="approved == 1"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.approved.all)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="approved == 2"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.approved.government)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="approved == 3"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.approved.private)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="approved == 4"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.approved.charity)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="approved == 5"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.approved.international)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="card-box-transition width-30 shadow p-2 mb-4 bg-white rounded">
                                    <div class="row justify-content-between">
                                        <div class="col-md-6 mb-2 ">
                                            <router-link :to="'/SenderRequestStatusDashboard?status=3&dateFrom=' + chartFilter.dateFrom + '&dateTo=' + chartFilter.dateTo">
                                                <i> <b> {{$t('shared.rejectedRequests')}}</b></i>
                                            </router-link>
                                        </div>
                                        <div class="col-md-3 text-right">
                                            <b class="color-blue-light fs-17" v-if="rejected == 1">{{dashboardStatusBoxes.rejected.allCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="rejected == 2">{{dashboardStatusBoxes.rejected.governmentCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="rejected == 3">{{dashboardStatusBoxes.rejected.privateCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="rejected == 4">{{dashboardStatusBoxes.rejected.charityCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="rejected == 5">{{dashboardStatusBoxes.rejected.internationalCount}}</b>
                                        </div>
                                        <div class="col-md-6">
                                            <v-select :items="items"
                                                      height="10"
                                                      v-model="rejected"
                                                      class="soled-fil"
                                                      dense
                                                      item-text="name"
                                                      item-value="id"
                                                      solo></v-select>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="rejected == 1"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.rejected.all)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="rejected == 2"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.rejected.government)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="rejected == 3"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.rejected.private)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="rejected == 4"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.rejected.charity)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="rejected == 5"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.rejected.international)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-between mx-5">
                                <div class="card-box-transition width-30 shadow p-2 mb-4 bg-white rounded">
                                    <div class="row justify-content-between">
                                        <div class="col-md-6 mb-2">
                                            <router-link :to="'/SenderRequestStatusDashboard?status=1&dateFrom=' + chartFilter.dateFrom + '&dateTo=' + chartFilter.dateTo">
                                                <i><b> {{$t('shared.pendingApproval')}}</b> </i>
                                            </router-link>
                                        </div>
                                        <div class="col-md-3 text-right">
                                            <b class="color-blue-light fs-17"
                                               v-if="pendingForApproval == 1">{{dashboardStatusBoxes.pendingForApproval.allCount}}</b>
                                            <b class="color-blue-light fs-17"
                                               v-if="pendingForApproval == 2">{{dashboardStatusBoxes.pendingForApproval.governmentCount}}</b>
                                            <b class="color-blue-light fs-17"
                                               v-if="pendingForApproval == 3">{{dashboardStatusBoxes.pendingForApproval.privateCount}}</b>
                                            <b class="color-blue-light fs-17"
                                               v-if="pendingForApproval == 4">{{dashboardStatusBoxes.pendingForApproval.charityCount}}</b>
                                            <b class="color-blue-light fs-17"
                                               v-if="pendingForApproval == 5">{{dashboardStatusBoxes.pendingForApproval.internationalCount}}</b>
                                        </div>
                                        <div class="col-md-6">
                                            <v-select :items="items"
                                                      height="10"
                                                      v-model="pendingForApproval"
                                                      class="soled-fil"
                                                      dense
                                                      item-text="name"
                                                      item-value="id"
                                                      solo></v-select>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="pendingForApproval == 1"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.pendingForApproval.all)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="pendingForApproval == 2"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.pendingForApproval.government)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="pendingForApproval == 3"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.pendingForApproval.private)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="pendingForApproval == 4"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.pendingForApproval.charity)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="pendingForApproval == 5"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.pendingForApproval.international)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-box-transition width-30 shadow p-2 mb-4 bg-white rounded">
                                    <div class="row justify-content-between">
                                        <div class="col-md-6 mb-2">
                                            <router-link :to="'/SenderRequestStatusDashboard?status=0&dateFrom=' + chartFilter.dateFrom + '&dateTo=' + chartFilter.dateTo">
                                                <i><b> {{$t('shared.activatedRequests')}}</b> </i>
                                            </router-link>
                                        </div>
                                        <div class="col-md-3 text-right">
                                            <b class="color-blue-light fs-17" v-if="activated == 1">{{dashboardStatusBoxes.activated.allCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="activated == 2">{{dashboardStatusBoxes.activated.governmentCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="activated == 3">{{dashboardStatusBoxes.activated.privateCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="activated == 4">{{dashboardStatusBoxes.activated.charityCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="activated == 5">{{dashboardStatusBoxes.activated.internationalCount}}</b>
                                        </div>
                                        <div class="col-md-6">
                                            <v-select :items="items"
                                                      height="10"
                                                      v-model="activated"
                                                      class="soled-fil"
                                                      dense
                                                      item-text="name"
                                                      item-value="id"
                                                      solo></v-select>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="activated == 1"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.activated.all)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="activated == 2"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.activated.government)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="activated == 3"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.activated.private)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="activated == 4"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.activated.charity)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="activated == 5"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.activated.international)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-box-transition width-30 shadow p-2 mb-4 bg-white rounded">
                                    <div class="row justify-content-between">
                                        <div class="col-md-6 mb-2">
                                            <router-link :to="'/SenderRequestStatusDashboard?status=4&dateFrom=' + chartFilter.dateFrom + '&dateTo=' + chartFilter.dateTo">
                                                <i><b> {{$t('shared.paidRequests')}}</b> </i>
                                            </router-link>
                                        </div>

                                        <div class="col-md-3 text-right">
                                            <b class="color-blue-light fs-17" v-if="paid == 1">{{dashboardStatusBoxes.paid.allCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="paid == 2">{{dashboardStatusBoxes.paid.governmentCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="paid == 3">{{dashboardStatusBoxes.paid.privateCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="paid == 4">{{dashboardStatusBoxes.paid.charityCount}}</b>
                                            <b class="color-blue-light fs-17" v-if="paid == 5">{{dashboardStatusBoxes.paid.internationalCount}}</b>
                                        </div>
                                        <div class="col-md-6">
                                            <v-select :items="items"
                                                      height="10"
                                                      v-model="paid"
                                                      class="soled-fil"
                                                      dense
                                                      item-text="name"
                                                      item-value="id"
                                                      solo></v-select>
                                        </div>
                                        <div class="col-md-12">
                                            <div class="progress" style="height: 8px;">
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="paid == 1"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.paid.all)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="paid == 2"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.paid.government)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="paid == 3"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.paid.private)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="paid == 4"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.paid.charity)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                                <div class="progress-bar color-qreen-progress"
                                                     v-if="paid == 5"
                                                     role="progressbar"
                                                     v-bind:style="percentage(dashboardStatusBoxes.paid.international)"
                                                     aria-valuenow="60"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <hr class="mx-5" />
                            <div class="col-md-12 mx-5 pt-5">
                                <div class="form-row justify-content-between align-items-baseline">
                                    <div class="col-auto">
                                        <label class="header_text_black mb-4">
                                            {{$t('shared.requestsAlerts')}}
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="row justify-content-between mx-5 pt-4">
                                <div class="card-box-Alerts shadow p-2 mb-4 bg-white rounded border-red">
                                    <div class="row">
                                        <div class="col-md-12 mb-3">
                                            <i v-if="pendingApprovals[3].percentage > 0" class="color-red fa fa-exclamation-circle"></i>
                                            <router-link :to="'/AlertsDashboard?status=1&tenant=' + chartFilter.tenant+'&requestType=' + chartFilter.requestType">

                                                <i><b> {{$t('shared.PendingApprovalRequests')}}</b></i>
                                            </router-link>
                                        </div>
                                        <div class="col-md-3">
                                            <p>{{pendingApprovals[0].dayOrder }}{{$t('shared.days')}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="progress" style="height: 5px; margin-top: 12px;">
                                                <div class="progress-bar  color-qreen-progress"
                                                     role="progressbar"
                                                     v-bind:style="percentage(pendingApprovals[0].percentage)"
                                                     aria-valuenow="10"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <b class="color-qreen fs-13">{{pendingApprovals[0].count}}</b>
                                        </div>

                                        <div class="col-md-3">
                                            <p>{{pendingApprovals[1].dayOrder}}{{$t('shared.days')}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="progress" style="height: 5px; margin-top: 12px;">
                                                <div class="progress-bar color-blue-light-progress"
                                                     role="progressbar"
                                                     v-bind:style="percentage(pendingApprovals[1].percentage)"
                                                     aria-valuenow="70"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <b class="color-blue-light fs-13">{{pendingApprovals[1].count}}</b>
                                        </div>

                                        <div class="col-md-3">
                                            <p>{{pendingApprovals[2].dayOrder}}{{$t('shared.days')}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="progress" style="height: 5px; margin-top: 12px;">
                                                <div class="progress-bar color-pink-progress"
                                                     role="progressbar"
                                                     v-bind:style="percentage(pendingApprovals[2].percentage)"
                                                     aria-valuenow="9"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <b class="color-pink fs-13">{{pendingApprovals[2].count}}</b>
                                        </div>

                                        <div class="col-md-3">
                                            <p>{{pendingApprovals[3].dayOrder}}{{$t('shared.daysAndMore')}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="progress" style="height: 5px; margin-top: 12px;">
                                                <div class="progress-bar color-red-progress"
                                                     role="progressbar"
                                                     v-bind:style="percentage(pendingApprovals[3].percentage)"
                                                     aria-valuenow="9"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <b class="color-red fs-13">{{pendingApprovals[3].count}}</b>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-box-Alerts shadow p-2 mb-4 bg-white rounded border-red">
                                    <div class="row">
                                        <div class="col-md-12 mb-3">
                                            <i v-if="pendingPaymentAlerts[3].percentage > 0" class="color-red fa fa-exclamation-circle"></i>
                                            <router-link :to="'/AlertsDashboard?status=2&tenant=' + chartFilter.tenant+'&requestType=' + chartFilter.requestType">

                                                <i><b> {{$t('shared.PendingPaymentRequests')}}</b> </i>
                                            </router-link>
                                        </div>
                                        <div class="col-md-3">
                                            <p>{{pendingPaymentAlerts[0].dayOrder }}{{$t('shared.days')}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="progress" style="height: 5px; margin-top: 12px;">
                                                <div class="progress-bar  color-qreen-progress"
                                                     role="progressbar"
                                                     v-bind:style="percentage(pendingPaymentAlerts[0].percentage)"
                                                     aria-valuenow="10"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <b class="color-qreen fs-13">{{pendingPaymentAlerts[0].count}}</b>
                                        </div>

                                        <div class="col-md-3">
                                            <p>{{pendingPaymentAlerts[1].dayOrder}}{{$t('shared.days')}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="progress" style="height: 5px; margin-top: 12px;">
                                                <div class="progress-bar color-blue-light-progress"
                                                     role="progressbar"
                                                     v-bind:style="percentage(pendingPaymentAlerts[1].percentage)"
                                                     aria-valuenow="70"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <b class="color-blue-light fs-13">{{pendingPaymentAlerts[1].count}}</b>
                                        </div>
                                        <div class="col-md-3">
                                            <p>{{pendingPaymentAlerts[2].dayOrder}}{{$t('shared.days')}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="progress" style="height: 5px; margin-top: 12px;">
                                                <div class="progress-bar color-pink-progress"
                                                     role="progressbar"
                                                     v-bind:style="percentage(pendingPaymentAlerts[2].percentage )"
                                                     aria-valuenow="9"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <b class="color-pink fs-13">{{pendingPaymentAlerts[2].count}}</b>
                                        </div>

                                        <div class="col-md-3">
                                            <p>{{pendingPaymentAlerts[3].dayOrder}}{{$t('shared.days')}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="progress" style="height: 5px; margin-top: 12px;">
                                                <div class="progress-bar color-red-progress"
                                                     role="progressbar"
                                                     v-bind:style="percentage(pendingPaymentAlerts[3].percentage)"
                                                     aria-valuenow="9"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <b class="color-red fs-13">{{pendingPaymentAlerts[3].count}}</b>
                                        </div>
                                    </div>
                                </div>

                                <div class="card-box-Alerts shadow p-2 mb-4 bg-white rounded border-red">
                                    <div class="row">
                                        <div class="col-md-12 mb-3">
                                            <i v-if="pendingActivationAlerts[3].percentage > 0" class="color-red fa fa-exclamation-circle"></i>
                                            <router-link :to="'/AlertsDashboard?status=3&tenant=' + chartFilter.tenant+'&requestType=' + chartFilter.requestType">
                                                <i><b> {{$t('shared.pendingActivationRequests')}}</b> </i>
                                            </router-link>
                                        </div>
                                        <div class="col-md-3">
                                            <p>{{pendingActivationAlerts[0].dayOrder}}{{$t('shared.days')}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="progress" style="height: 5px; margin-top: 12px;">
                                                <div class="progress-bar  color-qreen-progress"
                                                     role="progressbar"
                                                     v-bind:style="percentage(pendingActivationAlerts[0].percentage)"
                                                     aria-valuenow="10"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <b class="color-qreen fs-13">{{pendingActivationAlerts[0].count}}</b>
                                        </div>

                                        <div class="col-md-3">
                                            <p>{{pendingActivationAlerts[1].dayOrder }}{{$t('shared.days')}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="progress" style="height: 5px; margin-top: 12px;">
                                                <div class="progress-bar color-blue-light-progress"
                                                     role="progressbar"
                                                     v-bind:style="percentage(pendingActivationAlerts[1].percentage)"
                                                     aria-valuenow="70"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <b class="color-blue-light fs-13">{{pendingActivationAlerts[1].count}}</b>
                                        </div>

                                        <div class="col-md-3">
                                            <p>{{pendingActivationAlerts[2].dayOrder }}{{$t('shared.days')}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="progress" style="height: 5px; margin-top: 12px;">
                                                <div class="progress-bar color-pink-progress"
                                                     role="progressbar"
                                                     v-bind:style="percentage(pendingActivationAlerts[2].percentage)"
                                                     aria-valuenow="9"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <b class="color-pink fs-13">{{pendingActivationAlerts[2].count}}</b>
                                        </div>
                                        <div class="col-md-3">
                                            <p>{{pendingActivationAlerts[3].dayOrder }}{{$t('shared.daysAndMore')}}</p>
                                        </div>
                                        <div class="col-md-7">
                                            <div class="progress" style="height: 5px; margin-top: 12px;">
                                                <div class="progress-bar color-red-progress"
                                                     role="progressbar"
                                                     v-bind:style="percentage(pendingActivationAlerts[3].percentage)"
                                                     aria-valuenow="9"
                                                     aria-valuemin="0"
                                                     aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                        <div class="col-md-2">
                                            <b class="color-red fs-13">{{pendingActivationAlerts[3].count}}</b>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </vue-html2pdf>
</div>