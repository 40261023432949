<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <label class="header_text mb-3">
                {{$t('shared.renewRequest')}}
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body card_body_layout">
                    <div class="row">
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.clientName')}}</label>
                                <input class="form-control" :disabled="true" v-model="model.clientName" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.customerType')}}</label>
                                <v-select v-model="model.customerType"
                                          :items="customerTypeList"
                                          :label="$t('shared.select')"
                                          item-text="name"
                                          item-value="id"
                                          :disabled="true"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3" v-if="model.customerType !== 0">
                            <div class="form-group">
                                <label v-if="model.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                                <label v-if="model.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                                <label v-if="model.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                                <label v-if="model.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                                <input class="form-control" :disabled="true" v-model="model.crNumber" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3" v-if="model.customerType === 2 ||model.customerType === 3">
                            <div class="form-group">
                                <label>{{$t('shared.enterpriseUnifiedNumber')}}</label>
                                <input class="form-control" :disabled="enterpriseUnifiedNumberDisabled" v-model="model.enterpriseUnifiedNumber" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.representativeName')}}</label>
                                <input class="form-control" v-model="model.representativeName" type="text" :disabled="true" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.phoneNumber')}}</label>
                                <div v-if="model.customerType === 0 || model.customerType === 1 || model.customerType === 2 ||model.customerType === 3" class="input-group mb-3">
                                    <div class="input-group-prepend">
                                        <span class="input-group-text gray">
                                            +966
                                        </span>
                                    </div>
                                    <input class="form-control border-left-0" @paste="isNumberFieldPaste($event)" maxlength="9" @keypress="isNumberField($event)" v-model="model.representativeMobile" type="text" :disabled="true" />
                                </div>
                                <div v-if="model.customerType === 4" class="input-group mb-3">
                                    <input class="form-control border-left-0" @paste="isNumberFieldPaste($event)" @keypress="isNumberField($event)" v-model="model.representativeMobile" type="text" :disabled="true" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.representativeEmail')}}</label>
                                <input class="form-control" v-model="model.representativeEmail" type="text" :disabled="true" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.senderType')}}</label>
                                <v-select v-model="senderType"
                                          :items="senderTypeList"
                                          item-text="name"
                                          item-value="id"
                                          multiple
                                          :disabled="true"
                                          :label="$t('shared.select')"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.senderName')}}</label>
                                <input class="form-control" :disabled="true" @paste="isSenderNameFieldPaste($event)" @keypress="isSenderNameField($event)" :maxlength="senderNameLength" v-model="model.senderName" type="text" />
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.durationYear')}}</label>
                                <v-select v-model="renewRequestModel.duration"
                                          :items="durationList"
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('shared.select')"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                    </div>
                    <div class="row pt-4">
                        <div class="col-md-12 mt-5">
                            <div class="form-row justify-content-end align-items-center">
                                <div class="col-auto mx-3">
                                    <router-link to="/Dashboard" tag="a" class="link_secondary">{{$t('shared.cancel')}}</router-link>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-success px-5 py-2" :disabled="!isValid" @click="showExtendRequestDialog">{{$t('shared.save')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="extendRequestDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.extendRequestTypeInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideExtendRequestDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.extendInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideExtendRequestDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="saveExtendRequest">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>