import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import FileSaver from 'file-saver';

@Service()
export default class CreditLimitTransactionService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async get(
        sendersName: string,
        providerName: string,
        clientName: string,
        paymentDateFrom: string,
        paymentDateTo: string,
        isExport: boolean,
        pageIndex: string,
        pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + (isExport ? 'api/payment-transaction/export-get-credit-limit-payment-transaction?'
                : 'api/payment-transaction/getcreditlimitpaymenttransaction?')
                + 'sendersName=' + sendersName
                + '&clientName=' + clientName
                + '&providerName=' + providerName
                + '&paymentDateFrom=' + paymentDateFrom
                + '&paymentDateTo=' + paymentDateTo
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            if (result.data.isSuccess) {
                if (isExport) {
                    const array = Buffer.from(result.data.result, 'base64');
                    const blob = new Blob([array], { type: 'application/vnd.ms-excel' });
                    FileSaver.saveAs(blob, 'credit limit payment transaction report.xlsx');
                    return result.data;
                } else {
                    return result.data;
                }
            } else {
                return result.data;;
            }
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
