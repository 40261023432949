import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';

@Service()
export default class ShortService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async get(
        operator: string,
        name: string,
        pageIndex: string,
        pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/shortcodes?operatorId=' + operator
                + '&name=' + name
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getAll(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/shortcodes/getall';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    
    public async getById(id:any): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + `api/shortcodes/getById?id=${id}`;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async post(shortCode: string, operatorId: number, ProviderId: string, shortCodeType: number) {
        this.loaderService.ShowLoader();
        const model = {
            name: shortCode,
            operatorId,
            providerId: ProviderId,
            shortCodeType: shortCodeType
        };
        try {
            const url = window.$config.HostName + 'api/shortcodes';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    
    public async edit(id: string, shortCodeType: number) {
        this.loaderService.ShowLoader();
        const model = {
            id:id,
            shortCodeType: shortCodeType
        };
        try {
            const url = window.$config.HostName + 'api/shortcodes';
            const result = await this.axiosService.axiosInstance.put(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async delete(id: string) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/shortcodes';
            const result = await this.axiosService.axiosInstance.delete(url, {
                params: {
                    id,
                },
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
}
