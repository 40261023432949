import { Component, Vue} from 'vue-property-decorator';
import WithRender from './App.html';

@WithRender
@Component({
})
export default class App extends Vue {
    public themeCompany: any = 'Default';
    public created() {
        if (process.env.VUE_APP_TITLE !== undefined) {
            this.themeCompany = process.env.VUE_APP_TITLE.toString();
        } else {
            this.themeCompany = 'Default';
        }
        if (localStorage.dir === undefined) {
            localStorage.dir = 'ltr';
            this.$i18n.locale = 'en';
        } else if (localStorage.dir === 'rtl') {
            this.$i18n.locale = 'ar';
        } else {
            this.$i18n.locale = 'en';
        }
    }

    public get dir() {
        return localStorage.dir;
    }
}
