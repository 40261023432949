import { Service, Inject } from 'vue-di-container';
import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { ReportModel } from './ReportModel';
import FileSaver from 'file-saver';

@Service()
export default class ReportService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async GetSenderNameAndBeneficiariesReport(
        model: ReportModel, pageIndex: string,
        pageSize: string, IsExport: boolean): Promise<any> {
        const baseURL = IsExport ? 'api/report/export-sendername-and-beneficiaries-report' :
            'api/report/sendername-and-beneficiaries-report';
        const url = window.$config.HostName + baseURL + '?SenderName='
            + model.senderName
            + '&CustomerType=' + model.customerType
            + '&SenderType=' + model.senderType
            + '&requestStatus=' + model.requestStatus
            + '&ProviderId=' + model.providerId
            + '&ClientName=' + model.clientName
            + '&RepresentativeName=' + model.representativeName
            + '&RepresentativeMobile=' + model.representativeMobile
            + '&ApproveDateFrom=' + model.approveDateFrom.toString()
            + '&ApproveDateTo=' + model.approveDateTo.toString()
            + '&CreatedDateTo=' + model.createdDateTo.toString()
            + '&CreatedDateFrom=' + model.createdDateFrom.toString()
            + '&NumberOfActions=' + model.numberOfActions
            + '&PaymentDate=' + model.paymentDate.toString()
            + '&ExpiryDate=' + model.expiryDate.toString()
            + '&pageIndex=' + pageIndex
            + '&pageSize=' + pageSize;
        const result = await this.axiosService.axiosInstance.get(url);
        if (result.data.isSuccess) {
            if (IsExport) {
                const array = Buffer.from(result.data.result, 'base64');
                const blob = new Blob([array], { type: 'application/vnd.ms-excel' });
                FileSaver.saveAs(blob, 'SenderNameAndBeneficiariesReport.xlsx');
                return result.data;
            } else {
                return result.data;
            }
        } else {
            return result.data;
        }
    }

    public async GetContractRequestsReport(
        model: ReportModel, pageIndex: string,
        pageSize: string, IsExport: boolean): Promise<any> {
        const baseURL = IsExport ? 'api/report/export-contract-requests-report' :
            'api/report/sendername-and-beneficiaries-report';
        const url = window.$config.HostName + baseURL + '?SenderName='
            + model.senderName
            + '&CustomerType=' + model.customerType
            + '&SenderType=' + model.senderType
            + '&requestStatus=' + model.requestStatus
            + '&ProviderId=' + model.providerId
            + '&ClientName=' + model.clientName
            + '&ApproveDateFrom=' + model.approveDateFrom.toString()
            + '&ApproveDateTo=' + model.approveDateTo.toString()
            + '&CreatedDateTo=' + model.createdDateTo.toString()
            + '&CreatedDateFrom=' + model.createdDateFrom.toString()
            + '&NumberOfActions=' + model.numberOfActions
            + '&PaymentDate=' + model.paymentDate.toString()
            + '&ExpiryDate=' + model.expiryDate.toString()
            + '&pageIndex=' + pageIndex
            + '&pageSize=' + pageSize;
        const result = await this.axiosService.axiosInstance.get(url);
        if (result.data.isSuccess) {
            if (IsExport) {
                const array = Buffer.from(result.data.result, 'base64');
                const blob = new Blob([array], { type: 'application/vnd.ms-excel' });
                FileSaver.saveAs(blob, 'Contract Requests Report.xlsx');
                return result.data;
            } else {
                return result.data;
            }
        } else {
            return result.data;
        }
    }
}
