<div class="w-75 h-100 m-auto">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <label class="header_text mb-3">
                {{$t(editMode?'shortPage.editShortCode':'shortPage.addShortCode')}}
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body card_body_layout">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('shortPage.companyName')}}</label>
                                <v-select v-model="shortCodeModel.operatorId"
                                          :items="operatorOptions"
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('shortPage.selectCompany')"
                                          :disabled="editMode"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="form-group" v-if="shortCodeModel.operatorId !== 0">
                                <label>{{$t('shortPage.shortcodeType')}}</label>
                                <v-select v-model="shortCodeModel.shortCodeType"
                                          :items="shortCodeTypes"
                                          item-text="name"
                                          item-value="id"
                                          :label="$t('shortPage.selectType')"
                                          :disabled="!shortCodeModel.canEditType && editMode"
                                          solo>
                                </v-select>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="shortCodeModel.operatorId === 2">
                            <div class="form-group">
                                <label>{{$t('shortPage.shortCode')}} </label>
                                <div class="input-group mb-3">
                                    <input class="form-control" :disabled="editMode" v-model="shortCodeModel.name" type="text" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="shortCodeModel.operatorId === 1">
                            <div class="form-group">
                                <label>{{$t('shared.customerId')}}</label>
                                <div class="input-group mb-3">
                                    <input class="form-control" :disabled="editMode" v-model="shortCodeModel.name" @paste="isNumberFieldPaste($event)" @keypress="isNumberField($event)" type="text" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4" v-if="shortCodeModel.operatorId === 3">
                            <div class="form-group">
                                <label>{{$t('shared.MSISDN')}}</label>
                                <div class="input-group mb-3">
                                    <input class="form-control" :disabled="editMode" v-model="shortCodeModel.name" type="text" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-group">
                                <label v-if="editMode && !shortCodeModel.canEditType">{{$t('shared.shortCodeEditDisabled')}}</label>
                            </div>
                        </div>
                        <div class="col-md-12 mt-5">
                            <div class="form-row justify-content-end align-items-center">
                                <div class="col-auto mx-3">
                                    <router-link to="/Shorts" tag="a" class="link_secondary">{{$t('shared.cancel')}}</router-link>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-success px-5 py-2" :disabled="!isValid" @click="showaddShortCodeDialog">{{$t('shared.save')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="addShortDiDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t(editMode?'shared.editInput':'shared.addInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideddShortDiDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t( editMode?'shared.editShortCodeInputText':'shared.addShortCodeInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideddShortDiDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="save">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>

