import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './ConnectivityActivationReport.html';
import { ConnectivityActivationReportModel } from './ConnectivityActivationReportModel';
import { Toaster } from '../../Services/toast';
import LoaderService from '../../Services/LoaderService';
import ConnectivityActivationReportService from './ConnectivityActivationReportService';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        ConnectivityActivationReportService,
        LoaderService,
    ],
})
export default class ConnectivityActivationReport extends Vue {
    @Inject(ConnectivityActivationReportService) public connectivityActivationReportService!: ConnectivityActivationReportService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public Count: number = 0;
    public model: ConnectivityActivationReportModel[] = [];
    public requestId: string = '';
    public senderName: string = '';
    public connectivityActivationTable: boolean = false;
    public headers: any = [
        { text: this.$t('shared.requestId'), value: 'requestId', align: 'center', sortable: false },
        { text: this.$t('shared.senderName'), value: 'senderName', align: 'center', sortable: false },
        { text: this.$t('shared.senderType'), value: 'senderType', align: 'center', sortable: false },
        { text: this.$t('shared.clientName'), value: 'clientName', align: 'center', sortable: false },
        { text: this.$t('shared.requestStatus'), value: 'requestStatus', align: 'center', sortable: false },
        { text: this.$t('shared.providerName'), value: 'providerName', align: 'center', sortable: false },
        { text: this.$t('shared.zainActivationDate'), value: 'zainActivationDate', align: 'center', sortable: false },
        { text: this.$t('shared.sTCActivationDate'), value: 'sTCActivationDate', align: 'center', sortable: false },
        { text: this.$t('shared.mobilyActivationDate'), value: 'mobilyActivationDate', align: 'center', sortable: false },
    ];
    public created() {
        this.$emit('childinit', this.layoutSup);
    }
    public async updatePaginate(val: any) {
        const response = await this.connectivityActivationReportService.GetConnectivityActivationReport(this.requestId, this.senderName,
            (val.page - 1).toString(), val.rowsPerPage);
        if (!response.isSuccess) {
            this.loaderService.HideLoader();
            Toaster.error(response.errorMessage);
            this.model = [];
            this.Count = 0;
        } else {
            this.loaderService.HideLoader();
            this.model = response.result;
            this.Count = response.totalCount;
        }
    }
    public async search() {
        this.connectivityActivationTable = true
        const response = await this.connectivityActivationReportService.GetConnectivityActivationReport(this.requestId, this.senderName,
            '0', '10');
        if (!response.isSuccess) {
            this.loaderService.HideLoader();
            Toaster.error(response.errorMessage);
            this.model = [];
            this.Count = 0;
        } else {
            this.loaderService.HideLoader();
            this.model = response.result;
            this.Count = response.totalCount;
        }
    }
    public async reset() {
        this.connectivityActivationTable = false;
        this.senderName = '';
        this.requestId = '';
    }
    public get isValid(): boolean {
        return (this.requestId !== '' ||
            this.senderName !== '');
    }
}
