<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.CITCKeywordsList')}}
                    </label> 
                </div>
                <div class="col-auto">
                    <router-link to="/CITCKeywords/AddCITCKeywords" tag="button" class="btn btn-primary"><span class="text_sm_hidden">{{$t('shared.addNewCITCKeywords')}}</span><i class="fas fa-plus px-2"></i></router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body"> 
                    <div class="row">
                        <div class="col-md-12">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.name"
                                       :placeholder="$t('shared.Search')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template>
                        <v-data-table :headers="headers"
                                      :items="cITCKeywordsModel"
                                      :total-items="cITCKeywordsCount"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      @update:pagination="updatePaginate"
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ props.item.name }}</td>
                                    <td class="text-center">
                                        <router-link :to="'/CITCKeywords/AddCITCKeywords/Edit/' + props.item.id" tag="i" class="fas fa-pencil-alt Icon_Edit"></router-link>
                                        <i class="far fa-trash-alt Icon_delete" @click="showDeleteDialog(props.item.id)"></i>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="deleteDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.deleteInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideDeleteDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <img :src="require('../img/folder.svg')" />
                    <p class="mt-3">                       
                        {{$t('shared.deleteInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideDeleteDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="deleteCITCKeywords">{{$t('shared.delete')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>

