import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import ProvidersFinanceService from '../ProvidersFinanceService';
import WithRender from './EditProvidersFinance.html';
import { ProvidersFinanceModel } from '../ProvidersFinanceModel';
import { Toaster } from '../../../Services/toast';
import ValidationService from '../../../Services/ValidationService';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        ProvidersFinanceService,
    ],
})
export default class EditProvidersFinance extends Vue {
    @Inject(ProvidersFinanceService) public providersFinanceService!: ProvidersFinanceService;
    @Inject(ValidationService) public validationService!: ValidationService;

    @Prop({ default: '' })
    public id!: string;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public provider: ProvidersFinanceModel = {
        name: '',
        id: '',
        balance: 0,
        creditLimitAmount: 0,
    };
    public editProviderDialog: boolean = false;
    public amount: number = 0;
    public providerFinanceHistoryId: string = '';
    public createInvoiceDialog: boolean = false;
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.id = this.$route.params.id;
        this.provider = await this.providersFinanceService.GetById(this.id);
        this.amount = 0;
        this.providerFinanceHistoryId = '';
    }
    public showCreateInvoiceDialog() {
        this.createInvoiceDialog = true;
    }
    public hideCreateInvoiceDialog() {
        this.createInvoiceDialog = false;
    }
    public showeditProviderDialog() {
        this.editProviderDialog = true;
    }
    public hideEditProviderDialog() {
        this.editProviderDialog = false;
    }
    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public async editProviderFinanceInfo() {
        if (this.provider.creditLimitAmount.toString().trim() === '') {
            this.provider.creditLimitAmount = 0;
        }
        if (this.provider.balance.toString().trim() === '') {
            this.provider.balance = 0;
        }
        const response = await this.providersFinanceService.Put(this.provider);
        if (response.isSuccess) {
            Toaster.success(this.$t('shared.editProviderSuccessfully').toString());
            this.amount = response.result.amount;
            this.providerFinanceHistoryId = response.result.providerFinanceHistoryId;
            if (this.amount > 0) {
                this.hideEditProviderDialog();
                this.showCreateInvoiceDialog();
            } else {
                this.$router.push({ name: 'ProvidersFinance' });
            }
        } else {
            Toaster.error(response.errorMessage);
            this.$router.push({ name: 'ProvidersFinance' });
        }
    }
    public async createBalanceInvoice() {
        const response = await this.providersFinanceService.createBalanceInvoice(this.amount, this.providerFinanceHistoryId);
        if (response.isSuccess) {
            Toaster.success(this.$t('shared.invoiceCreatedSuccessfully').toString());
            this.$router.push({ name: 'ProvidersFinance' });
        } else {
            Toaster.error(response.errorMessage);
            this.$router.push({ name: 'ProvidersFinance' });
        }
    }
    public async redirectToProvidersFinance() {
        this.$router.push({ name: 'ProvidersFinance' });
    }
}
