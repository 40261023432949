<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.changeSenderTypeRequests')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row row-table">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-header">
                    <div class="form-row justify-content-between align-items-center w-100">
                        <div class="col-auto">
                            <label class="card_header_text">
                                <i class="fas fa-sync-alt reload" @click="reload()"></i>
                                {{$t('dashboardPage.requests')}} ({{sendersCount}})
                            </label>
                        </div>
                        <div class="col-auto">
                            <button v-if="userRole == 2 || userRole == 5" :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="showresendDialog('')">{{$t('dashboardPage.resend')}}</button>
                            <button v-if="userRole == 2 || userRole == 5" :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="PreparePay('')">{{$t('dashboardPage.Pay')}}</button>
                            <button v-if="userRole == 1" :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="showapproveDialog('')">{{$t('dashboardPage.Approve')}}</button>
                            <button v-if="userRole == 1" :disabled="!isAnySelected" class="btn btn-primary btn-action" @click="showRejectDialog('')">{{$t('dashboardPage.reject')}}</button>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.senderName"
                                       :placeholder="$t('dashboardPage.senderNameSearch')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       type="text"
                                       v-model="filterModel.representativeName"
                                       :placeholder="$t('dashboardPage.representativeName')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text gray">
                                        +966
                                    </span>
                                </div>
                                <input class="form-control border-right-0 border-left-0"
                                       @paste="isNumberFieldPaste($event)"
                                       v-model="filterModel.representativeMobile"
                                       :placeholder="$t('dashboardPage.representativeMobile')"
                                       @keypress="isNumberField($event)"
                                       maxlength="9" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template>
                        <v-data-table v-model="selected"
                                      :headers="headers"
                                      :items="senders"
                                      :total-items="sendersCount"
                                      select-all
                                      :pagination.sync="pagination"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      @update:pagination="updatePaginate"
                                      item-key="id"
                                      class="elevation-1 table-striped">
                            <template v-slot:headers="props">
                                <tr>
                                    <th>
                                        <v-checkbox :input-value="props.all"
                                                    :indeterminate="props.indeterminate"
                                                    primary
                                                    hide-details
                                                    @click.stop="selectAll"></v-checkbox>
                                    </th>
                                    <th v-for="header in props.headers"
                                        :key="header.text"
                                        :class="['column', header.sortable ? 'sortable':'', header.sortable ? pagination.descending ? 'desc' : 'asc': '', header.value === pagination.sortBy ? 'active' : '']"
                                        @click="changeSort(header.value)" :width="header.width !== ''? header.width : '' ">
                                        <v-icon v-if="header.sortable" small>arrow_upward</v-icon>
                                        {{ header.text }}
                                    </th>
                                </tr>
                            </template>
                            <template v-slot:items="props">
                                <tr>
                                    <td>
                                        <v-checkbox v-model="props.selected"
                                                    @change="select($event, props.item.id)"
                                                    primary
                                                    hide-details></v-checkbox>
                                    </td>
                                    <td>{{props.item.senderName}}</td>
                                    <td class="text-center">{{ props.item.clientName }}</td>
                                    <td class="text-center">{{ props.item.representativeName }}</td>
                                    <td class="text-center"><span class="pr-1">+966</span> {{ props.item.representativeMobile }}</td>
                                    <td class="text-center">
                                        <div class="d-flex justify-content-center align-items-center">
                                            <div class="status_txt" :class="'status-' + props.item.requestStatus">{{GetSenderStatus(props.item.requestStatus)}}</div>
                                            <Progress :transitionDuration="1000" :radius="10" :strokeColor="checkColor(props.item.requestStatus)" :strokeWidth="5" :value="getRate(props.item.remainingDays,props.item.totalDays)">
                                                <div class="content"></div>
                                                <template v-slot:footer>
                                                    <b></b>
                                                </template>
                                            </Progress>
                                            <div class="days_left">
                                                {{props.item.remainingDays}} {{$t('dashboardPage.of')}} {{props.item.totalDays}} <br /> {{$t('dashboardPage.daysLeft')}}
                                            </div>
                                        </div>
                                    </td>
                                    <td class="text-center btn_menu">
                                        <template>
                                            <v-menu offset-y open-on-hover>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="btn-primary"
                                                           v-on="on">
                                                        <div>{{$t('shared.actions')}}</div>
                                                        <i class="fas fa-chevron-down pl-4" style="vertical-align: sub;"></i>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-tile v-if="(userRole == 2 || userRole == 5 )&& props.item.requestStatus == '2'" @click="PreparePay(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.Pay')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="userRole == 1  && props.item.requestStatus == '1'" @click="showapproveDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.Approve')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="userRole == 1  && props.item.requestStatus == '1'" @click="showRejectDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.reject')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="(userRole == 2 || userRole == 5) && props.item.requestStatus == '3'" @click="showresendDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.resend')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile @click="Details(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.details')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="resendDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.resendInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideresendDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.resendInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideresendDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="resend('')">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="approveDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.approveInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideapproveDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.approveInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideapproveDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="approve('')">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="rejectdialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('dashboardPage.reject')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideRejectDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text>
                    <div class="row mt-3">
                        <div class="col-md-12">
                            <div class="form-group">
                                <label>{{$t('dashboardPage.rejectreason')}}</label>
                                <input class="form-control" v-model="rejectResone" type="text" />
                            </div>
                        </div>
                    </div>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideRejectDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" :disabled="!rejectReasonIsValid" @click="reject">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>

