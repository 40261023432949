import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './RenewRegisterSender.html';
import { Inject } from 'vue-di-container';
import { GetRenewSenderModel } from './GetRenewSenderModel';
import ValidationService from '../../../../Services/ValidationService';
import { SenderType } from '../../../../shared/Enums/SenderType';
import { DropdownModel } from '../../../Provider/ProviderModel';
import { Toaster } from '../../../../Services/toast';
import RenewRegisterSenderService from './RenewRegisterSenderService';
import { GetRenewSenderRequestModel } from './GetRenewSenderRequestModel';
import { RenewRequestModel } from './RenewRequestModel';
import { SenderViewModel } from '../../../ConnectingSenderName/ConnectingSenderNameModel';
import { AddExtendDurationRequestsModel } from './AddExtendDurationRequestsModel';


@WithRender
@Component({
    components: {
    },
    diProvide: [
        RenewRegisterSenderService,
    ],
})
export default class RenewRegisterSender extends Vue {
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(RenewRegisterSenderService) public renewRegisterSenderService!: RenewRegisterSenderService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;

    @Prop({ default: 0 })
    public id!: string;
    public showSenderInfo: boolean = false;
    public isSelectOption: boolean = true;
    public senderName: string = '';
    public typeFile: number = 0;
    public senderNameLength: number = 11;
    public senderType: SenderType[] = [];
    senderNames: SenderViewModel[] = [];
    public selected: SenderViewModel[] = [];
    public selectedSenderIds: string[] = [];
    public model: RenewRequestModel = {
        senderId: '',
        duration: 0,
        crNumber: '',
        enterpriseUnifiedNumber: '',
        customerType: 0,
        senderName: '',
    };
    public addModel: AddExtendDurationRequestsModel = {
        senderIds: [],
        duration: 0,
    };
    public renewRequestModel: RenewRequestModel = {
        senderId: '',
        duration: 0,
        crNumber: '',
        enterpriseUnifiedNumber: '',
        customerType: 0,
        senderName: '',
    };
    public getRenewSenderModel: GetRenewSenderModel = {
        senderId: '',
        clientName: '',
        crNumber: '',
        enterpriseUnifiedNumber: '',
        customerType: 0,
        representativeEmail: '',
        representativeMobile: '',
        representativeName: '',
    };
    public stepNumber: number = 1;
    public enterpriseUnifiedNumberDisabled: boolean = true;
    public renewRequestDialog: boolean = false;
    public extendRequestDialog: boolean = false;
    public headers: any = [
        { text: this.$t('shared.senderName'), value: 'senderName', align: 'center', sortable: false },
        { text: this.$t('shared.senderType'), value: 'senderType', align: 'center', sortable: false },
    ];
    public customerTypeList: DropdownModel[] = [
        {
            id: 1,
            name: this.$t('shared.government').toString(),
        },
        {
            id: 2,
            name: this.$t('shared.charity').toString(),
        },
        {
            id: 3,
            name: this.$t('shared.private').toString(),
        },
        {
            id: 4,
            name: this.$t('shared.international').toString(),
        },
    ];
    public ids: string | any;
    public idsList: string[] = [];
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        if (this.$route.params.ids != undefined) {
            this.ids = this.$route.params.ids;
            if (this.ids.includes(',')) {
                this.idsList = this.ids.split(',');
            } else {
                this.idsList.push(this.ids);
            }
            this.addModel.senderIds = this.idsList;
            const response = await this.renewRegisterSenderService
                .GetSendersForRenewBySenderIds(this.addModel.senderIds);
            if (response.isSuccess) {
                this.model.crNumber = response.result[0].crNumber;
                this.model.customerType = response.result[0].customerType;
                this.model.enterpriseUnifiedNumber = response.result[0].enterpriseUnifiedNumber;
                this.selected = response.result;
                this.selected.forEach(name => {
                    name.senderTypes = [];
                    this.getSenderType(name.senderType, name);
                });
                this.selected.forEach(s => {
                    this.selectedSenderIds.push(s.senderId)
                });
            } else {
                Toaster.error(response.errorMessage);
            }
        } else {
            this.isSelectOption = false;
        }
    }
    public selectAll() {
        if (this.selected.length) {
            this.selected = [];
            this.selectedSenderIds = [];
        } else {
            this.selected = this.senderNames.slice();
            this.selectedSenderIds = this.senderNames.map((x) => x.senderId);
        }
    }
    public senderTypeList: DropdownModel[] = [
        {
            id: SenderType.Advertisement,
            name: this.$t('shared.advertisement').toString(),
        },
        {
            id: SenderType.Service,
            name: this.$t('shared.service').toString(),
        },
        {
            id: SenderType.Awareness,
            name: this.$t('shared.awareness').toString(),
        },
        {
            id: SenderType.Warning,
            name: this.$t('shared.warning').toString(),
        },
    ];
    public durationList: DropdownModel[] = [
        {
            id: 1,
            name: '1',
        },
        {
            id: 2,
            name: '2',
        },
        {
            id: 3,
            name: '3',
        },
        {
            id: 4,
            name: '4',
        },
        {
            id: 5,
            name: '5',
        },
    ];
    public get isValidApply(): boolean {
        var uinfiedValid = (this.model.customerType == 2 || this.model.customerType == 3) ?
            this.model.enterpriseUnifiedNumber !== '' : this.model.enterpriseUnifiedNumber === '';
        return (this.model.customerType !== 0 &&
            this.model.crNumber !== '' && uinfiedValid);
    }
    public getModel: GetRenewSenderRequestModel = {
        senderName: ''
    };
    public async created() {
        this.$emit('childinit', this.layoutSup);
    }
    public newRequest() {
        this.reset();
        this.stepNumber = 1;
    }
    public get isValidStep(): boolean {
        return (this.selected.length != 0);
    }
    public get isValidExtend(): boolean {
        return (this.addModel.duration != 0);
    }
    public next() {
        if (this.stepNumber < 2) {
            this.stepNumber = this.stepNumber + 1;
        }
    }

    public reset() {
        this.model = {
            senderId: '',
            duration: 0,
            crNumber: '',
            enterpriseUnifiedNumber: '',
            customerType: 0,
            senderName: '',
        };
        this.senderType = [];
    }
    public getSenderTypeText(typeId: number) {
        switch (typeId) {
            case SenderType.Advertisement:
                return this.$t('shared.advertisement').toString();
            case SenderType.Service:
                return this.$t('shared.service').toString();
            case SenderType.Awareness:
                return this.$t('shared.awareness').toString();
            case SenderType.Warning:
                return this.$t('shared.warning').toString();
            default:
                return '-';
        }
    }
    public getSenderType(type: number, sender: SenderViewModel) {
        sender.senderTypes = [];
        const senderBinary = type;
        if ((senderBinary & 1) === 1) {
            sender.senderTypes.push(1);
        }
        if ((senderBinary & 2) === 2) {
            sender.senderTypes.push(2);
        }
        if ((senderBinary & 4) === 4) {
            sender.senderTypes.push(4);
        }
        if ((senderBinary & 8) === 8) {
            sender.senderTypes.push(8);
        }
    }
    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }
    public select(event: any, id: string) {
        if (!event) {
            const index = this.selectedSenderIds.indexOf(id);
            this.selectedSenderIds.splice(index, 1);
        } else {
            let selectedObj = this.selected.find(s => s.id == id);
            if (selectedObj !== undefined) {
                this.selectedSenderIds.push(selectedObj.senderId);
            }
        }
    }
    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }
    public isSenderNameField(val: KeyboardEvent) {
        const pattern = /^[-a-zA-Z0-9._& ]*$/;
        if (!pattern.test(val.key)) {
            val.preventDefault();
        }
    }
    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public isSenderNameFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        const pattern = /^[-a-zA-Z0-9._& ]*$/;
        if (!pattern.test(paste)) {
            ev.preventDefault();
        }
    }
    public get isValid(): boolean {
        return (this.model.duration.toString().trim() !== ''
            && this.model.duration !== 0);
    }
    public showExtendRequestDialog() {
        this.extendRequestDialog = true;
    }
    public cancel() {
        this.$router.push({ name: 'Dashboard' });
    }
    public hideExtendRequestDialog() {
        this.extendRequestDialog = false;
    }
    public async saveExtendRequest() {
        this.addModel.senderIds = this.selectedSenderIds;
        const response = await this.renewRegisterSenderService.AddExtendRequest(this.addModel);
        if (response.isSuccess) {
            this.$router.push({ name: 'Payment', params: { ids: response.result.ids.join(',') } });
        } else {
            Toaster.error(response.errorMessage);
            this.hideExtendRequestDialog();
            this.$router.push({ name: 'Dashboard' });
        }
    }
    public async apply() {
        const response = await this.renewRegisterSenderService
            .GetSendersForRenew(this.model.senderName, this.model.crNumber, this.model.customerType, this.model.enterpriseUnifiedNumber);
        if (response.isSuccess) {
            this.senderNames = response.result;
            this.senderNames.forEach(name => {
                name.senderTypes = [];
                this.getSenderType(name.senderType, name);
            });
            this.showSenderInfo = true;
        } else {
            Toaster.error(response.errorMessage);
            this.showSenderInfo = false;
        }
    }
}