import { Inject } from 'vue-di-container';
import { Component, Prop, Vue } from 'vue-property-decorator';
import GetInvoiceService from './GetInvoiceService';
import WithRender from './GetInvoice.html';
import VueHtml2pdf from 'vue-html2pdf'

@WithRender
@Component({
    components: {
        VueHtml2pdf
    },
    diProvide: [
        GetInvoiceService,
    ],
})
export default class GetInvoice extends Vue {
    @Inject(GetInvoiceService) public getInvoiceService!: GetInvoiceService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public invoiceHtml: string = '';
    public id: string | any = '';
    public senderName: string = '';
    public isSimpleInvoice: boolean = false;
    public downloadIssued: boolean = false;
    public downloadIssuedSimple: boolean = false;
    public $refs!: {
        html2Pdf: any;
        simpleHtml2Pdf: any;
    };
    public async created() {
        this.$emit('childinit', this.layoutSup);
    }
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.id = this.$route.query.id;
        const response = await this.getInvoiceService.get(this.id);
        if (response.isSuccess) {
            this.isSimpleInvoice = response.result.isSimpleInvoice;
            this.invoiceHtml = response.result.invoiceHtml;
            if (this.isSimpleInvoice) {
                this.downloadIssuedSimple = true;
                this.generateReportSimple();
            } else {
                this.downloadIssued = true;
                this.generateReport();
            }
        }
    }
    public generateReport() {
        this.$refs.html2Pdf.generatePdf();
    }
    public generateReportSimple() {
        this.$refs.simpleHtml2Pdf.generatePdf();
    }
}
