import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './ProviderRequests.html';
import ProviderRequestsService from './ProviderRequestsService';
import { Toaster } from '../../Services/toast';
import { ProviderRequestsModel } from './ProviderRequestsModel';
import { FilterModel } from './FilterModel';


@WithRender
@Component({
    components: {
    },
    diProvide: [
        ProviderRequestsService,
    ],
})
export default class ProviderRequests extends Vue {
    @Inject(ProviderRequestsService) public providerRequestsService!: ProviderRequestsService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;

    public headers: any = [
        { text: this.$t('shared.name'), value: 'name', align: 'center', sortable: false },
        { text: this.$t('shared.crNumber'), value: 'crNumber', align: 'center', sortable: false },
        { text: this.$t('shared.accreditationNumber'), value: 'accreditationNumber', align: 'center', sortable: false },
        { text: this.$t('shared.taxNumber'), value: 'taxNumber', align: 'center', sortable: false },
        {
            text: this.$t('dashboardPage.status'),
            sortable: false, value: 'providerRequestStatus', align: 'center',
        },
        { text: this.$t('shared.actions'), sortable: false, align: 'center', width: '145' },
    ];
    public providerRequests: ProviderRequestsModel[] = [];
    public providerRequestsCount: number = 0;
    public rejectResone: string = '';
    public rejectdialog: boolean = false;
    public requestId = '';
    public approveDialog: boolean = false;
    public filterModel: FilterModel = {
        name: '',
        crNumber: '',
        accreditationNumber: '',
        taxNumber: '',
    };
    public created() {
        this.$emit('childinit', this.layoutSup);
    }

    @Watch('filterModel', { deep: true })
    public async search() {
        const response = await this.providerRequestsService.Get
            (this.filterModel.name, this.filterModel.crNumber,
                this.filterModel.accreditationNumber, this.filterModel.taxNumber, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.providerRequests = response.result;
        this.providerRequestsCount = response.totalCount;
    }
    public async updatePaginate(val: any) {
        const response = await this.providerRequestsService.Get
            (this.filterModel.name, this.filterModel.crNumber, this.filterModel.accreditationNumber,
                this.filterModel.taxNumber, (val.page - 1).toString(), val.rowsPerPage);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.providerRequests = response.result;
        this.providerRequestsCount = response.totalCount;
    }
    public GetProviderRequestStatus(typeStatus: number) {
        typeStatus = typeStatus;
        switch (typeStatus) {
            case 1: {
                return 'Pending Approval';
                break;
            }
            case 2: {
                return 'Approved';
                break;
            }
            case 3: {
                return 'Rejected';
                break;
            }
        }
    }
    public async showRejectDialog(id: string) {
        this.requestId = id;
        this.rejectdialog = true;
    }
    public async hideRejectDialog() {
        this.rejectResone = '';
        this.requestId = '';
        this.rejectdialog = false;
    }
    public showapproveDialog(id: string) {
        this.requestId = id;
        this.approveDialog = true;
    }
    public hideapproveDialog() {
        this.approveDialog = false;
    }
    public async reject() {
        if (this.rejectResone !== '') {
            const response = await this.providerRequestsService.reject(this.requestId, this.rejectResone);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            } else {
                this.rejectdialog = false;
                this.rejectResone = '';
                Toaster.success(this.$t('shared.RejectedRequestSuccessfully').toString());
                this.search();
            }
        }
    }
    public async approve() {
        const response = await this.providerRequestsService.approve(this.requestId);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        } else {
            Toaster.success(this.$t('shared.ApprovedRequestSuccessfully').toString());
            this.search();
        }
        this.hideapproveDialog();
    }

    public Details(id: string) {
        this.$router.push({ name: 'ProviderRequestInfo', params: { id } });
    }

    public get rejectReasonIsValid(): boolean {
        return this.rejectResone !== '';
    }

}
