<div class="w-100 h-100">
    <div class="main_page w-100 h-100">
        <div class="container">
            <div class="row" style="padding-top: 50px;">
                <div class="col-12">
                    <div class="card card_layout">
                        <div class="card-header">
                            <label class="card_header_text">
                                {{$t('shared.AddProviderRequest')}}
                            </label>
                        </div>
                        <div class="card-body p-5">
                            <div class="row">
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.name')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control"
                                                    v-model="model.name" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.crNumber')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control"
                                                    v-model="model.crNumber" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.accreditationNumber')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" type="text"
                                                     v-model="model.accreditationNumber" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.taxNumber')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" type="text"
                                                     v-model="model.taxNumber" @keypress="isNumberField($event)"
                                                   @paste="isNumberFieldPaste($event)" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.email')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control" type="text"
                                                     v-model="model.email" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.expireDate')}}</label>
                                        <v-menu v-model="expireDateMenu" :close-on-content-click="false" :nudge-right="40" transition="scale-transition"
                                                offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="input-group mb-3">
                                                    <input class="form-control border-right-0 border-left" v-model="model.expireDate"
                                                           readonly v-bind="attrs" ref="createdDatePicker"
                                                           v-on="on" type="text" />
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text right gray">
                                                            <i class="far fa-calendar-alt"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>
                                            <v-date-picker v-model="model.expireDate"
                                                           no-title
                                                           scrollable
                                                           @input="expireDateMenu = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </div>
                                </div>
                                <!-- sssssss -->
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.country')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control"
                                                    v-model="model.country" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.city')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control"
                                                    v-model="model.city" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.district')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control"
                                                    v-model="model.district" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.streetName')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control"
                                                    v-model="model.streetName" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.buildingNumber')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control"
                                                    v-model="model.buildingNumber" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.postalCode')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control"
                                                    v-model="model.postalCode" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.vatNumber')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control"
                                                    v-model="model.vatNumber" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.additionalNumber')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control"
                                                    v-model="model.additionalNumber" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.otherIdentifier')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control"
                                                    v-model="model.otherIdentifier" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-7">
                                    <div class="row">
                                        <div class="col-md-8 col-10">
                                            <div class="form-group">
                                                <label>{{$t('shared.attachDelegationLetter')}}</label>
                                                <div class="input-group mb-3">
                                                    <input class="form-control border-right-0 border-left" readonly v-model="fileNameDelegationLetter" type="text" />
                                                    <input type="file" ref="fileDelegationLetter" hidden accept=".png, .jpg, .jpeg, .pdf, .docx" @change="onFilePicked">
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text right" style="padding: 2px 2px 0px 2px !important;">
                                                            <i v-if="fileNameDelegationLetter !== ''" class="far fa-trash-alt Icon_delete" @click="deleteDelegationLetter()"></i>
                                                            <button class="btn btn-primary" @click="onPickFile()">{{$t('shared.attach')}}</button>
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-8 col-10">
                                            <div class="form-group">
                                                <label>{{$t('shared.theMaximumSizeForTheAttachmentIs3Megabytes')}}</label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-5">
                                </div>
                                <div class="row col-md-12">
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>{{$t('shared.userNameAdmin')}}</label>
                                            <div class="input-group mb-3">
                                                <input class="form-control"
                                                        v-model="model.username" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>{{$t('shared.userEmailAdmin')}}</label>
                                            <div class="input-group mb-3">
                                                <input class="form-control" type="text"
                                                         v-model="model.useremail" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <div class="form-group">
                                            <label>{{$t('shared.phoneNumberAdmin')}}</label>
                                            <div class="input-group mb-3">
                                                <div class="input-group-prepend">
                                                    <span class="input-group-text gray">
                                                        +966
                                                    </span>
                                                </div>
                                                <input class="form-control border-left-0" @change="isMobileNumberValid" @paste="isNumberFieldPaste($event)" maxlength="9" @keypress="isNumberField($event)" v-model="model.mobile" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12 mt-5">
                                    <div class="form-row justify-content-end align-items-center">
                                        <div class="col-auto mx-3">
                                            <router-link to="/login" tag="a" class="link_secondary">{{$t('shared.cancel')}}</router-link>
                                        </div>
                                        <div class="col-auto">
                                            <button class="btn btn-success px-5 py-2"
                                                    @click="addProviderRequest" :disabled="!isValid">
                                                {{$t('shared.apply')}}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div class="login_footer form-row justify-content-between align-items-center">
                <div class="col-auto">
                    <a>
                        {{$t('shared.privacy')}}
                    </a>
                </div>
                <div class="col-auto">
                    <a>
                        {{$t('shared.termsOfUse')}}
                    </a>
                </div>
                <div class="col-auto">
                    <a>
                        {{$t('shared.copyRight')}} {{currentYear}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

