import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './UserInfo.html';
import { Inject } from 'vue-di-container';

import UserManagementService from '../UserManagementService';
import { UserManagementModel } from '../UserManagementModel';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        UserManagementService,
    ],
})
export default class UserInfo extends Vue {
    @Inject(UserManagementService) public userManagementService!: UserManagementService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;

    @Prop({ default: '' })
    public id!: string;


    public user: UserManagementModel = {} as UserManagementModel;


    public created() {
        this.$emit('childinit', this.layoutSup);
    }

    public async mounted() {
        this.user = await this.userManagementService.GeById(this.id);
    }
}
