import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './LoginUserName.html';
import moment from 'moment';
import LoginUserNameService from './LoginUserNameService';
import TokenService from '@/Services/TokenService';
import { Toaster } from '../../Services/toast';


@WithRender
@Component({
    components: {
    },
    diProvide: [
        LoginUserNameService,
    ],
})
export default class LoginUserName extends Vue {
    @Inject(LoginUserNameService) public logInService!: LoginUserNameService;
    @Inject(TokenService) public tokenService!: TokenService;

    public userName: string = '';
    public password: string = '';
    public themeCompany: any = 'Default';
    public logoSrc: any = 'Logo';
    public logoTextSrc: any = 'LogoText';
    public elementVisible: boolean = true;
    public logInForm: boolean = true;
    public restoreForm: boolean = false;
    public checkRestoreForm: boolean = true;
    public emailForm: boolean = false;
    public verificationForm: boolean = false;
    public setPasswordForm: boolean = false;
    public confermForm: boolean = false;
    public email: string = '';
    public created() {
        if (process.env.VUE_APP_TITLE !== undefined && process.env.VUE_APP_LogoSrc !== undefined &&
            process.env.VUE_APP_LogoTextSrc !== undefined) {
            this.themeCompany = process.env.VUE_APP_TITLE.toString();
            this.logoSrc = process.env.VUE_APP_LogoSrc.toString();
            this.logoTextSrc = process.env.VUE_APP_LogoTextSrc.toString();
        } else {
            this.themeCompany = 'Default';
            this.logoSrc = 'Logo';
            this.logoTextSrc = 'LogoText';
        }
        setTimeout(() => this.elementVisible = false, 2500);
    }

    public showRestoreForm() {
        this.logInForm = false;
        this.restoreForm = true;
    }

    public showEmailForm() {
        this.checkRestoreForm = false;
        this.verificationForm = false;
        this.emailForm = true;
    }

    public showVerificationForm() {
        this.emailForm = false;
        this.verificationForm = true;
    }

    public verify() {
        this.verificationForm = false;
        this.setPasswordForm = true;
    }

    public resetPassword() {
        this.setPasswordForm = false;
        this.confermForm = true;
    }

    public backLogin() {
        this.confermForm = false;
        this.checkRestoreForm = true;
        this.restoreForm = false;
        this.logInForm = true;
    }

    public get currentYear() {
        return moment().year();
    }

    public get isValid(): boolean {
        return (this.userName !== '' && this.password !== '');
    }

    public async login() {
        const result = await this.logInService.login(this.userName, this.password);
        if (result.isSuccess) {
            await this.tokenService.setToken(result.result.token);
            const expiresIn = parseInt(result.result.expiresIn, 0);
            localStorage.timer = expiresIn * 60;
            localStorage.expiresIn = moment().add(expiresIn, 'minutes').format('lll');
            this.$router.push({ name: 'Dashboard' });
        } else {
            Toaster.error(result.errorMessage);
        }
    }
}
