
<div class="w-100 h-100">

    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('rejectionReason.rejectionReasonList')}}
                    </label>
                </div>
                <div class="col-auto">
                    <button @click="showAddDialog()" tag="button" class="btn btn-primary"><span class="text_sm_hidden">{{$t('rejectionReason.addNewReason')}}</span><i class="fas fa-plus px-2"></i></button>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <template>
                        <v-data-table :headers="headers"
                                      :items="rejectionReasons"
                                      :total-items="rejectionReasonsCount"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      @update:pagination="updatePaginate"
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ props.item.rejectionText }}</td>
                                    <td class="text-center">{{ getTextValue(props.item.requestType) }}</td>
                                    <td class="text-center">
                                        <i v-if="userRole === 4||userRole ===3||userRole === 1||userRole ===9" class="far fa-edit Icon_Edit" @click="showEditDialog(props.item.id)"></i>
                                        <i v-if="userRole === 4||userRole ===3||userRole === 1||userRole ===9" class="far fa-trash-alt Icon_delete" @click="showDeleteDialog(props.item.id)"></i>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>

    <div class="text-xs-center">


        <v-dialog v-model="addDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('rejectionReason.addNewReason')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideAddDialog"></i>
                    </div>
                </v-card-title>
                <v-form ref="addRejectionReasonForm"  @submit.prevent>
                    <v-card-text class="text-center">
                        <div class="row">
                            <div class="col-md-12"> 
                                <v-select v-model="addModel.requestType"
                                          :items="requestTypes"
                                          :label="$t('rejectionReason.requestType')"
                                          item-text="name"
                                           required
                                           :rules="[v => !!v || 'Item is required']"
                                          item-value="id"
                                          >
                                </v-select>
                            </div>

                        </div>
                        <div class="row"> 
                            <div class="col-md-12"> 
                                <v-textarea clearable
                                            clear-icon="mdi-close-circle"
                                            bg-color="#dee2e6  !important"
                                            no-resize
                                            maxlength="80"
                                            rows="3"
                                            :label="$t('rejectionReason.rejectionText')"
                                            required
                                            :rules="rules"
                                            variant="filled"
                                            v-model="addModel.rejectionText">
                                </v-textarea>
                            </div>
                        </div>

                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <div class="form-row justify-content-end align-items-center">
                            <div class="col-auto mx-3">
                                <a class="link_danger" @click="hideAddDialog">
                                    {{$t('shared.cancel')}}
                                </a>
                            </div>
                            <div class="col-auto">
                                <v-btn type="submit" class="btn btn-danger px-5 py-2" @click="addRejectionReason">{{$t('shared.Ok')}}</v-btn>
                            </div>
                        </div>
                    </v-card-actions>
                    </v-form>
            </v-card>
        </v-dialog>

        <v-dialog v-model="editDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.updateRequestData')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideEditDialog"></i>
                    </div>
                </v-card-title>
                <v-form ref="editRejectionReasonForm" @submit.prevent>
                    <v-card-text class="text-center">
                        <div class="row">
                            <div class="col-md-12">
                                <v-select v-model="editModel.requestType"
                                          :items="requestTypes"
                                          :label="$t('rejectionReason.requestType')"
                                          item-text="name"
                                          required
                                          :rules="[v => !!v || 'Item is required']"
                                          item-value="id">
                                </v-select>
                            </div>

                        </div>
                        <div class="row">
                            <div class="col-md-12">
                                <v-textarea clearable
                                            clear-icon="mdi-close-circle"
                                            bg-color="#dee2e6  !important"
                                            no-resize
                                            maxlength="80"
                                            rows="3"
                                            :label="$t('rejectionReason.rejectionText')"
                                            required
                                            :rules="rules"
                                            variant="filled"
                                            v-model="editModel.rejectionText">
                                </v-textarea>
                            </div>
                        </div>

                    </v-card-text>

                    <v-card-actions>
                        <v-spacer></v-spacer>
                        <div class="form-row justify-content-end align-items-center">
                            <div class="col-auto mx-3">
                                <a class="link_danger" @click="hideEditDialog">
                                    {{$t('shared.cancel')}}
                                </a>
                            </div>
                            <div class="col-auto">
                                <v-btn type="submit" class="btn btn-danger px-5 py-2" @click="editRejectionReason">{{$t('shared.Ok')}}</v-btn>
                            </div>
                        </div>
                    </v-card-actions>
                </v-form>

            </v-card>
        </v-dialog>
        <v-dialog v-model="deleteDialog" persistent width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.deleteInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideDeleteDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <img :src="require('../img/folder.svg')" />
                    <p class="mt-3">
                        {{$t('shared.deleteInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideDeleteDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="deleteRejectionReason">{{$t('shared.delete')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>



    </div>
</div>

