import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { UpdateExtendDurationRequestsModel } from './UpdateExtendDurationRequestsModel';

@Service()
export default class ExtendDurationRequestsService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async Get(model: any): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests/get-extend-duration-requests';
            const result = await this.axiosService.axiosInstance.get(url, {
                params: model,
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetSenderForRenew(model: any): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/senders/get-sender-for-renew-with-request';
            const result = await this.axiosService.axiosInstance.get(url, {
                params: model,
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async UpdateExtendRequest(model: UpdateExtendDurationRequestsModel) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests/update-extend-duration-request';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
