import { Service, Inject } from 'vue-di-container';
import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { PayfortModel } from './PayfortModel';
@Service()
export default class MainPaymentService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async GetPayfortModel(): Promise<PayfortModel> {
        try {
            const url = '/PayfortPayment/GetPayfortModel';
            const result = await this.axiosService.axiosInstance.get(url);
            const Data = result.data;
            return Data.result;
        } catch (exception) {
            return new PayfortModel();
        }
    }
}
