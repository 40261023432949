<div class="w-100 h-100">
    <div class="auto_animation w-100 h-100" v-show="elementVisible">
        <div class="logo_text d-flex">
            <img :src="require( `../img/${logoSrc}.svg`)" class="px-5" />
        </div>
    </div>
    <div class="main_page w-100 h-100" v-show="!elementVisible">
        <div class="main_box">
            <div class="main_header_box d-flex justify-content-between align-items-center">
                <div class="logo">
                    <img :src="require( `../img/${logoTextSrc}.svg`)" />
                </div>
                <div class="link">
                    <a class="language">
                        {{$t('shared.language')}}
                    </a>
                </div>
            </div>
            <div class="card card_login" v-if="logInForm">
                <div class="card-header card_header_login">
                    <label class="login_header">
                        {{$t('loginPage.loginHeader')}}
                    </label>
                </div>
                <div class="card-body">
                    <label class="w-100 text-center text_body">
                        {{$t('loginPage.loginNote1')}}
                    </label>
                    <div class="row row_input pb-4">
                        <div class="col-md-12">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        <img :src="require('../img/User.svg')" />
                                    </span>
                                </div>
                                <input type="text" class="form-control" v-model="userName" :placeholder="$t('loginPage.userName')" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><img :src="require('../img/Lock.svg')" /></span>
                                </div>
                                <input class="form-control border-right-0" v-model="password" type="password" :placeholder="$t('loginPage.password')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right"><img :src="require('../img/Eye.svg')" /></span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row_input row_button py-0">
                        <div class="form-row justify-content-between align-items-baseline">
                            <div class="col-auto mx-3">
                                <a class="link_login" @click="showRestoreForm">
                                    {{$t('loginPage.canNotLogin')}}
                                </a>
                            </div>
                            <div class="col-auto">
                                <button class="btn btn-primary mb-2" :disabled="!isValid" @click="login">{{$t('loginPage.login')}}</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="card card_login" v-if="restoreForm">
                <div class="card-header card_header_login">
                    <label class="login_header">
                        {{$t('loginPage.restoreMyPassword')}}
                    </label>
                </div>
                <div class="card-body" v-if="checkRestoreForm">
                    <div class="row">
                        <div class="col-md-12 pb-5">
                            <label class="w-100 text_body">
                                {{$t('loginPage.restoreNote1')}}
                                <br />
                                <br />
                                {{$t('loginPage.restoreNote2')}}
                            </label>
                        </div>
                        <div class="col-md-12">
                            <div class="form-row justify-content-end align-items-baseline">
                                <div class="col-auto mx-3">
                                    <a class="link_login" @click="backLogin">
                                        {{$t('loginPage.backToLoginForm')}}
                                    </a>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-primary mb-2" @click="showEmailForm">{{$t('loginPage.yesUnderstand')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-if="emailForm">
                    <div class="row px-4">
                        <div class="col-md-12">
                            <label class="w-100 text_body">
                                {{$t('loginPage.pleaseInsertEmail')}}
                            </label>
                        </div>
                        <div class="col-md-12 pb-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text">
                                        @
                                    </span>
                                </div>
                                <input type="email" class="form-control text-center" v-model="email" :placeholder="$t('loginPage.email')" />
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-row justify-content-end align-items-baseline">
                                <div class="col-auto">
                                    <button class="btn btn-primary mb-2" @click="showVerificationForm">{{$t('loginPage.sendVerificationCode')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-if="verificationForm">
                    <div class="row px-4">
                        <div class="col-md-12">
                            <label class="w-100 text_body">
                                {{$t('loginPage.verificationNote1')}} <a class="link_login px-0" @click="showEmailForm">{{email}}</a> <br />
                                {{$t('loginPage.verificationNote2')}}
                            </label>
                        </div>
                        <div class="col-md-2">
                            <div class="input-group mb-3 number">
                                <input type="number" class="form-control border text-center" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="input-group mb-3 number">
                                <input type="number" class="form-control border text-center" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="input-group mb-3 number">
                                <input type="number" class="form-control border text-center" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="input-group mb-3 number">
                                <input type="number" class="form-control text-center" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="input-group mb-3 number">
                                <input type="number" class="form-control border text-center" />
                            </div>
                        </div>
                        <div class="col-md-2">
                            <div class="input-group mb-3 number">
                                <input type="number" class="form-control border text-center" />
                            </div>
                        </div>
                        <div class="col-md-12 pt-5">
                            <div class="form-row justify-content-end align-items-baseline">
                                <div class="col-auto mx-3">
                                    <a class="link_login">
                                        {{$t('loginPage.resendCodeAgain')}}
                                    </a>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-primary mb-2" @click="verify">{{$t('loginPage.verify')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-if="setPasswordForm">
                    <div class="row px-4">
                        <div class="col-md-12">
                            <label class="w-100 text_body">
                                {{$t('loginPage.setPasswordNote1')}}
                                <br />
                                {{$t('loginPage.setPasswordNote2')}}
                            </label>
                        </div>
                        <div class="col-md-12 pb-5">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text"><img :src="require('../img/Lock.svg')" /></span>
                                </div>
                                <input class="form-control border-right-0" type="password" :placeholder="$t('loginPage.password')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right"><img :src="require('../img/Eye.svg')" /></span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12">
                            <div class="form-row justify-content-end align-items-baseline">
                                <div class="col-auto">
                                    <button class="btn btn-primary mb-2" @click="resetPassword">{{$t('loginPage.setNewPassword')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body" v-if="confermForm">
                    <div class="row px-4 text-center">
                        <div class="col-md-12 py-4">
                            <img :src="require('../img/check.svg')" />
                        </div>
                        <div class="col-md-12 pb-5">
                            <label class="w-100 text_body">
                                {{$t('loginPage.passwordResetedSuccessfully')}}
                                <br />
                                <a class="link_login" @click="backLogin">{{$t('loginPage.backToLogin')}}</a>
                            </label>
                        </div>
                    </div>
                </div>
            </div>
            <div class="login_footer form-row justify-content-between align-items-center">
                <div class="col-auto">
                    <a>
                        {{$t('shared.privacy')}}
                    </a>
                </div>
                <div class="col-auto">
                    <a>
                        {{$t('shared.termsOfUse')}}
                    </a>
                </div>
                <div class="col-auto">
                    <a>
                        {{$t('shared.copyRight')}} {{currentYear}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

