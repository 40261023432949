import { Component, Vue } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './Login.html';
import moment from 'moment';
import TokenService from '@/Services/TokenService';
import LoginService from './LoginService';
import { Toaster } from '../../Services/toast';
import UserModel from '../../shared/userService/Model/UserModel';
import UserService from '../../shared/userService/UserService';
import UserRoleService from '../../shared/userService/UserRoleService';
import ShortService from '../Short/ShortService';
import { ShortModel } from '../Short/ShortModel';
import { VueRecaptcha } from 'vue-recaptcha';


@WithRender
@Component({
    components: {
        VueRecaptcha,
    },
    diProvide: [
        LoginService,
        UserService,
        UserRoleService,
        ShortService,

    ],
})
export default class Login extends Vue {
    @Inject(LoginService) public logInService!: LoginService;
    @Inject(TokenService) public tokenService!: TokenService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Inject(ShortService) public shortService!: ShortService;
    public themeCompany: any = 'Default';
    public logoSrc: any = 'JabberLogo';
    public logoTextSrc: any = 'JabberLogo';
    public userRole: any;
    public userName: string = '';
    public password: string = '';
    public currentPassword: string = '';
    public newPassword: string = '';
    public userNameRe: string = '';
    public confirmPassword: string = '';
    public elementVisible: boolean = true;
    public logInForm: boolean = true;
    public changePasswordForm: boolean = false;
    public isChangePasswordRequired: boolean = false;
    public restoreForm: boolean = false;
    public otpForm: boolean = false;
    public checkRestoreForm: boolean = true;
    public userNameForm: boolean = false;
    public confermForm: boolean = false;
    public email: string = '';
    public data: string = '';
    public otpExpiresAt: string = '';
    public otp: string = '';
    public countDown: number = 0;
    public showPassword: boolean = false;
    public user!: UserModel;
    updateShortCode: boolean | any = false;
    shortCode: ShortModel[] = [];;
    public recapchaResponse: string = '';
    public recapchaResponseFg: string = '';
    public siteKey: string = '';



    public  created() {

        if (process.env.VUE_APP_TITLE !== undefined && process.env.VUE_APP_LogoSrc !== undefined &&
            process.env.VUE_APP_LogoTextSrc !== undefined) {
            this.themeCompany =  process.env.VUE_APP_TITLE.toString();
            this.logoSrc =  process.env.VUE_APP_LogoSrc.toString();
            this.logoTextSrc =  process.env.VUE_APP_LogoTextSrc.toString();
        } else {
            this.themeCompany = 'Default';
            this.logoSrc = 'JabberLogo';
            this.logoTextSrc = 'JabberLogo';
        }
         setTimeout(() => this.elementVisible = false, 2500);
        this.siteKey =  this.logInService.GetRecaptchaSiteKey();
    }

    public showRestoreForm() {
        this.logInForm = false;
        this.restoreForm = true;
    }

    public showEmailForm() {
        this.checkRestoreForm = false;
        this.userNameForm = true;
    }

    public async resetPassword() {
        const response = await this.logInService.ResendPasswordEmail(this.userNameRe, this.recapchaResponseFg);
        if (response.isSuccess) {
            this.userNameForm = false;
            this.confermForm = true;
            this.userNameRe = '';
        } else {
            Toaster.error(response.errorMessage);
        }
    }

    public backLogin() {
        this.confermForm = false;
        this.checkRestoreForm = true;
        this.restoreForm = false;
        this.logInForm = true;
    }

    public switchToOtpForm() {
        this.confermForm = false;
        this.checkRestoreForm = false;
        this.restoreForm = false;
        this.logInForm = false;
        this.otpForm = true;
    }
    public get currentYear() {
        return moment().year();
    }

    public get isValid(): boolean {
        return (this.userName !== '' && this.password !== '' && this.recapchaResponse !== '');
    }


    public get isValidResetPassword(): boolean {
        return this.userNameRe !== '' && this.recapchaResponseFg !== '';
    }

    public get isValidChangePassword(): boolean {
        return (this.currentPassword !== '' &&
            this.newPassword !== '' &&
            this.confirmPassword !== '' &&
            this.newPassword === this.confirmPassword
        );
    }

    public async login() {
        const result = await this.logInService.login(this.userName, this.password, this.recapchaResponse);
        if (result.isSuccess && result.isOTPEnabled) {
            this.data = result.data;
            this.otpExpiresAt = result.expiresAt;
            this.countDown = result.duration;
            this.switchToOtpForm();
            this.countDownTimer();
        } else if (result.isSuccess && !result.isOTPEnabled) {
            await this.tokenService.setToken(result.data);
            const expiresIn = parseInt(result.duration, 0);
            localStorage.timer = expiresIn * 60;
            localStorage.expiresIn = moment().add(expiresIn, 'minutes').format('lll');
            await this.chickChangePasswordRequired();
            if (this.isChangePasswordRequired) {
                this.logInForm = false;
                this.changePasswordForm = true;
            } else {
                this.redirectToMainPage();
            }
        } else {
            const msg = this.$t(result.errorMessage).toString();
            this.recapchaResponse = '';
            window.grecaptcha.reset();
            Toaster.error(msg);
        }
    }

    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.user);
    }

    public async changePassword() {
        const result = await this.logInService.changePassword(
            this.user.userId, this.currentPassword,
            this.newPassword, this.confirmPassword);
        if (result.isSuccess) {
            this.changePasswordForm = false;
            this.currentPassword = '';
            this.newPassword = '';
            this.confirmPassword = '';
            this.redirectToMainPage();
        } else {
            Toaster.error(result.errorMessage);
        }
    }

    public async chickChangePasswordRequired() {
        this.user = await this.userService.getUser();
        this.isChangePasswordRequired =
            this.user.details.ChangePasswordRequired.toLowerCase() === 'true';
    }


    public async verfiyOTP() {
        const result = await this.logInService.verfiyOTP(this.data, this.otpExpiresAt, this.otp);
        if (result.isSuccess) {
            await this.tokenService.setToken(result.token);
            const expiresIn = parseInt(result.expiresIn, 0);
            localStorage.timer = expiresIn * 60;
            localStorage.expiresIn = moment().add(expiresIn, 'minutes').format('lll');
            await this.chickChangePasswordRequired();
            if (this.isChangePasswordRequired) {
                this.otpForm = false;
                this.changePasswordForm = true;
            } else {
                if (this.user.details.Role === 'Provider Admin' || this.user.details.Role === 'Provider User') {
                    this.validateOnShortCodeTypes()
                }
                else {
                    this.redirectToMainPage();
                }
            }
        } else {
            const msg = this.$t(result.errorMessage).toString();
            Toaster.error(msg);
        }
    }
    public countDownTimer() {
        if (this.countDown > 0) {
            setTimeout(() => {
                this.countDown -= 1;
                this.countDownTimer();
            }, 1000);
        }
    }
    public get isResendValid(): boolean {
        return (this.countDown > 0);
    }
    public async resendOtp() {
        const result = await this.logInService.resendOTP(this.data);
        if (result.isSuccess) {
            this.data = result.data;
            this.otpExpiresAt = result.expiresAt;
            this.countDown = result.duration;
            this.countDownTimer();
        } else {
            const msg = this.$t(result.errorMessage).toString();
            Toaster.error(msg);
        }
    }

    public changeLanguage() {
        if (localStorage.dir === 'ltr') {
            localStorage.dir = 'rtl';
        } else {
            localStorage.dir = 'ltr';
        }
        this.$router.go(0);
    }
    redirectToMainPage() {
        this.$router.push({ name: 'Dashboard' });
    }

    showUpdateShortCodeDialog() {
        this.updateShortCode = true;
    }

    hideUpdateShortCodeDialog() {
        this.updateShortCode = false;
        this.redirectToMainPage();
    }

    async validateOnShortCodeTypes() {
        var responseShortCode = await this.shortService.getAll();
        this.shortCode = responseShortCode.result;
        if (this.shortCode.filter(s => s.shortCodeType === null).length > 0) {
            this.showUpdateShortCodeDialog();
            this.otpForm = false;
        }
        else {
            this.redirectToMainPage();
        }
    }

    public verifyRecaptcha(response: any) {
        this.recapchaResponse = response;
    }

    public expiredRecaptcha() {
        this.recapchaResponse = ''
    }

    public verifyRecaptchaFg(response: any) {
        this.recapchaResponseFg = response;
    }

    public expiredRecaptchaFg() {
        this.recapchaResponseFg = '';
    }
}
