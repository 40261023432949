import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import * as $ from 'jquery';

@Service()
export default class LoginService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async login(userName: string, password: string, recapchaResponse: string) {
        try {
            this.loaderService.ShowLoader();
            const url = 'api/login';
            const result = await this.axiosService.axiosInstance.post(url, {
                userName,
                password,
                recapchaResponse,
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async verfiyOTP(data: string, otpExpiresAt: string, otp: string) {
        try {
            const oTPVerificationData = {
                data,
                otpExpiresAt,
                otp,
            };
            this.loaderService.ShowLoader();
            const url = 'api/login/verfiyOTP';
            const result = await this.axiosService.axiosInstance.post(url, oTPVerificationData);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            return error;
        }
    }
    public  GetRecaptchaSiteKey() {
        try {
            const url = 'api/login/getRecaptchaSiteKey';
            const response = $.ajax({
                url: url,
                method: 'GET',
                async: false  
            }).responseText;
            return response;
        } catch (error) {
            return error;
        }
    }
    public async resendOTP(data: string) {
        const resendOtTPData = {
            data,
        };
        try {
            this.loaderService.ShowLoader();
            const url = 'api/login/resendOTP';
            const result = await this.axiosService.axiosInstance.post(url, resendOtTPData);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            return error;
        }
    }

    public async changePassword(id: string, currentPassword: string, newPassword: string, confirmPassword: string) {
        try {
            this.loaderService.ShowLoader();
            const url = window.$config.HostName + 'api/users/changepassword';
            const result = await this.axiosService.axiosInstance.put(url, {
                userId: id,
                oldPassword: currentPassword,
                newPassword,
                confirmNewPassword: confirmPassword,
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }


    public async ResendPasswordEmail(userName: string, recapchaResponse: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/users/resendpasswordemail';
            const result = await this.axiosService.axiosInstance.post(url, {
                userName,
                recapchaResponse,
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }


}
