import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import FileSaver from 'file-saver';

@Service()
export default class SenderConectivitySupportService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async get(
        senderName: string, shorCode: string, providerId: string,
        senderConectivityStatus: number,
        operatorCompany: number,
        createdDateFrom: string,
        createdDateTo: string,
        sortDirection: string,
        sortBy: boolean,
        pageIndex: string, pageSize: string,
    ): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail/sender-conectivity-details-by-info'
                + '?senderName=' + senderName
                + '&shorCode=' + shorCode
                + '&providerId=' + providerId
                + '&senderConectivityStatus=' + senderConectivityStatus
                + '&operatorCompany=' + operatorCompany
                + '&createdDateFrom=' + createdDateFrom
                + '&createdDateTo=' + createdDateTo
                + '&sortDirection=' + sortDirection
                + '&sortBy=' + sortBy
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async getReport(
        senderName: string, shorCode: string, providerId: string,
        senderConectivityStatus: number,
        operatorCompany: number,
        createdDateFrom: string,
        createdDateTo: string,
        sortDirection: string,
        sortBy: boolean)
        : Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail/export-sender-conectivity-support-report'
                + '?senderName=' + senderName
                + '&shorCode=' + shorCode
                + '&providerId=' + providerId
                + '&senderConectivityStatus=' + senderConectivityStatus
                + '&operatorCompany=' + operatorCompany
                + '&createdDateFrom=' + createdDateFrom
                + '&createdDateTo=' + createdDateTo
                + '&sortDirection=' + sortDirection
                + '&sortBy=' + sortBy
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            if (result.data.isSuccess) {
                const array = Buffer.from(result.data.result, 'base64');
                const blob = new Blob([array], { type: 'application/vnd.ms-excel' });
                FileSaver.saveAs(blob, 'SenderConectivitySupportReport.xlsx');
                return result.data;
            }
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async getSenders(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/senders';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getSenderInfo(senderId: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/senders/' + senderId;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async getLogDetails(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail-log?connectivityDetailsId=' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getConnectivityConfigration(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail/connectivity-details-configration';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async markAsPendingAddition(id: string) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail/mark-as-pending-addition?senderConectivityDetailId=' + id;
            const result = await this.axiosService.axiosInstance.put(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async MarkAsAdded(id: string) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail/mark-as-added?senderConectivityDetailId=' + id;
            const result = await this.axiosService.axiosInstance.put(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async MarkAsDeleted(id: string) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-conectivity-detail/mark-as-deleted?senderConectivityDetailId=' + id;
            const result = await this.axiosService.axiosInstance.put(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
}
