export class PayfortModel {
    public returnUrl: string = '';
    public expiryDate: string = '';
    public serviceCommand: string = '';
    public accessCode: string = '';
    public language: string = '';
    public merchantIdentifier: string = '';
    public merchantReference: string = '';
    public signature: string = '';
    public cardNumber: string = '';
    public cardSecurityCode: string = '';
    public GatewayUrl: string = '';
}
