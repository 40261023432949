import { Service, Inject } from 'vue-di-container';
import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { EditProvidersFinanceModel } from './EditProvidersFinance/EditProvidersFinanceModel';

@Service()
export default class ProvidersFinanceService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async Get(
        name: string, pageIndex: string, pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/providers/finance-info?Name=' + name
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return [];
        }
    }
    public async GetById(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/providers/getfinanceinfobyid/' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async Put(model: EditProvidersFinanceModel): Promise<any> {
        this.loaderService.ShowLoader();
        const request = {
            id: model.id,
            balance: model.balance,
            creditLimitAmount: model.creditLimitAmount,
        };
        try {
            const url = window.$config.HostName + 'api/providers/update-finance-info';
            const result = await this.axiosService.axiosInstance.put(url, request);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async createBalanceInvoice(amount: number, providerFinanceHistoryId: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const model = {
                amount: amount,
                providerFinanceHistoryId: providerFinanceHistoryId,
            };
            const url = window.$config.HostName + 'api/payment-transaction/generate-qoyod-balance-invoice';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
