import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './SenderRequestSupport.html';
import { Toaster } from '../../Services/toast';
import { SenderType } from '../../shared/Enums/SenderType';
import UserService from '../../shared/userService/UserService';
import SenderRequestSupportService from './SenderRequestSupportService';
import { SenderSupportDetailsModel } from './SenderSupportDetailsModel';
import { SenderStatus } from '../../shared/Enums/SenderStatus';
import { SenderStatusDropdownModel } from './SenderStatusDropdownModel';
import { RequestStatus } from '../../shared/Enums/RequestStatus';
import { EditSenderRequestModel } from './EditSenderRequestModel';



@WithRender
@Component({
    components: {
    },
    diProvide: [
        SenderRequestSupportService,
        UserService,
    ],
})
export default class SenderRequestSupport extends Vue {
    @Inject(UserService) public userService!: UserService;
    @Inject(SenderRequestSupportService) public senderRequestSupportService!: SenderRequestSupportService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public isExtend: boolean = false;

    public pagination: any = {};
    @Prop({ type: String, default: '' })
    public senderType: SenderType[] = [];
    public headers: any = [
        { text: '', value: 'id', align: 'center', sortable: false },
        { text: this.$t('dashboardPage.requestId'), value: 'id', align: 'center', sortable: false },
        { text: this.$t('shared.providerName'), value: 'providerName', align: 'center', sortable: false },
        { text: this.$t('shared.requestStatus'), value: 'requestStatuss', align: 'center', sortable: false },
        { text: this.$t('shared.requestType'), value: 'requestType', align: 'center', sortable: false },
        { text: this.$t('shared.startDate'), sortable: false, value: 'startDate', align: 'center' },
        { text: this.$t('shared.duration'), sortable: false, value: 'duration', align: 'center' },
        { text: this.$t('shared.createdDate'), sortable: false, value: 'createdDate', align: 'center' },
        { text: this.$t('shared.lastModifiedDate'), sortable: false, value: 'lastModifiedDate', align: 'center' },
        { text: this.$t('shared.changedProperty'), sortable: false, value: 'changedProperty', align: 'center' },
        { text: this.$t('shared.approvalHistory'), sortable: false, value: 'id', align: 'center', width: '100' },
        { text: this.$t('shared.paymentTransactions'), sortable: false, value: 'id', align: 'center', width: '100' },
    ];
    public editSenderDialog: boolean = false;
    public senderName: string = '';
    public editModel: EditSenderRequestModel = {
        senderId: '',
        senderDuration: 0,
        senderStatus: 0,
        requestStatus: 0,
        requestIds: []
    };
    public newSenderRequestStatus: number = 0;
    public historyDate: string = '';
    public selected: SenderSupportDetailsModel[] = [];
    public selectedIds: string[] = [];
    public senderSupportDetailslist: SenderSupportDetailsModel[] = [];
    public async created() {
        this.$emit('childinit', this.layoutSup);
    }
    public listKey: number = 1;

    reloadList() {
        this.listKey += 1;
    }
    public async search() {
        this.selected = [];
        this.selectedIds = [];
        this.senderSupportDetailslist = [];
        let model;
        if (localStorage.storSettings) {
            const storSettings = JSON.parse(localStorage.storSettings);
            this.pagination = storSettings.pagination;
            model = storSettings.filter;
            this.senderName = storSettings.filter.senderName;
        } else {
            model = {
                senderName: this.senderName,
            };
        }
        localStorage.removeItem('storSettings');
        const response = await this.senderRequestSupportService.get(model.senderName);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        } else {
            this.senderSupportDetailslist = response.result;
        }
    }
    public async mounted() {
        this.search();
    }
    public getSenderRequestApprovalHistory(id: string) {
        const storSettings = {
            pagination: this.pagination,
            filter: {
                senderName: this.senderName,
            },
        };
        localStorage.storSettings = JSON.stringify(storSettings);
        this.$router.push({ name: 'SenderRequestApprovalHistory', params: { id } });
    }
    public getSenderRequestPaymentTransactions(id: string) {
        const storSettings = {
            pagination: this.pagination,
            filter: {
                senderName: this.senderName,
            },
        };
        localStorage.storSettings = JSON.stringify(storSettings);
        this.$router.push({ name: 'SenderRequestPaymentTransactions', params: { id } });
    }
    public get isValid(): boolean {
        return (this.senderName.trim() !== '');
    }
    public isValidEdit(newSenderStatus: number, newSenderDuration: number, newRequestStatus: number): boolean {
        newSenderStatus = newSenderStatus == undefined ? 0 : newSenderStatus;
        newSenderDuration = newSenderDuration == undefined ? 0 : newSenderDuration;
        newRequestStatus = newRequestStatus == undefined ? 0 : newRequestStatus;
        if (this.selectedIds.length == 0 && newRequestStatus == 0) {
            return (newSenderStatus !== 0 || newSenderDuration !== 0);
        }
        else if (this.selectedIds.length != 0) {
            return (newRequestStatus != 0)
        }
        else if (newRequestStatus != 0) {
            return (this.selectedIds.length != 0)
        }
        else
            return false;
    }
    public senderStatusOptions: SenderStatusDropdownModel[] = [
        {
            id: SenderStatus.PendingApproval,
            name: this.$t('shared.pendingApproval').toString(),
        },
        {
            id: SenderStatus.Approved,
            name: this.$t('shared.approved').toString(),
        },
        {
            id: SenderStatus.Rejected,
            name: this.$t('shared.rejected').toString(),
        },
        {
            id: SenderStatus.Deactivated,
            name: this.$t('shared.deactivated').toString(),
        },
        {
            id: SenderStatus.Expired,
            name: this.$t('shared.expired').toString(),
        },
        {
            id: SenderStatus.Deleted,
            name: this.$t('shared.deleted').toString(),
        },
        {
            id: SenderStatus.Paid,
            name: this.$t('shared.paid').toString(),
        },
        {
            id: SenderStatus.PendingForReview,
            name: this.$t('shared.pendingForReview').toString(),
        },
        {
            id: SenderStatus.PendingForVerify,
            name: this.$t('shared.pendingForVerify').toString(),
        },
    ];
    public senderRequestStatusOptions: SenderStatusDropdownModel[] = [];

    setExtendRequestoptions() {
        this.senderRequestStatusOptions = [];
        this.senderRequestStatusOptions = [
            {
                id: RequestStatus.Approved,
                name: this.$t('shared.pendingPayment').toString(),
            },
            {
                id: RequestStatus.Paid,
                name: this.$t('shared.paid').toString(),
            }
        ];
    }

    setNotExtendRequestoptions() {
        this.senderRequestStatusOptions = [];
        this.senderRequestStatusOptions = [
            {
                id: RequestStatus.PendingApproval,
                name: this.$t('shared.pendingApproval').toString(),
            },
            {
                id: RequestStatus.Approved,
                name: this.$t('shared.approved').toString(),
            },
            {
                id: RequestStatus.Rejected,
                name: this.$t('shared.rejected').toString(),
            },
            {
                id: RequestStatus.Paid,
                name: this.$t('shared.paid').toString(),
            },
            {
                id: RequestStatus.Expired,
                name: this.$t('shared.expired').toString(),
            },
            {
                id: RequestStatus.Deleted,
                name: this.$t('shared.deleted').toString(),
            },
            {
                id: RequestStatus.PendingForReview,
                name: this.$t('shared.pendingForReview').toString(),
            },
            {
                id: RequestStatus.PendingForVerify,
                name: this.$t('shared.pendingForVerify').toString(),
            },

        ];
    }
    public select(event: Event, id: string) {
        let element = this.senderSupportDetailslist.find(x => x.requestModel.find(y => y.id == id));
        let elementIndex = this.senderSupportDetailslist.findIndex(x => x.requestModel.find(y => y.id == id));
        if (!event) {
            const index = this.selectedIds.indexOf(id);
            this.selectedIds.splice(index, 1);
            if (element !== undefined) {
                let reqif = element.requestModel.find(y => y.id == id);
                let requestIndexif = element.requestModel.findIndex(y => y.id == id);
                if (reqif != undefined) {
                    if (requestIndexif !== undefined) {
                        this.senderSupportDetailslist[elementIndex].requestModel[requestIndexif].isSelected = false;
                    }
                }
            }
        }
        else {
            if (element !== undefined) {
                let req = element.requestModel.find(y => y.id == id);
                let requestIndexelse = element.requestModel.findIndex(y => y.id == id);
                if (req != undefined) {
                    var reqTypeelse = req.requestType;
                    if ((reqTypeelse != 'ExtendDuration' && !this.isExtend) || (this.selectedIds.length == 0 && reqTypeelse != 'ExtendDuration')) {
                        this.selectedIds.push(id);
                        this.senderSupportDetailslist[elementIndex].requestModel[requestIndexelse].isSelected = true;
                        this.isExtend = false;
                        this.setNotExtendRequestoptions();
                    }
                    else if ((reqTypeelse == 'ExtendDuration' && this.isExtend) || (this.selectedIds.length == 0 && reqTypeelse == 'ExtendDuration')) {
                        this.selectedIds.push(id);
                        this.senderSupportDetailslist[elementIndex].requestModel[requestIndexelse].isSelected = true;
                        this.isExtend = true;
                        this.setExtendRequestoptions();
                    }
                    else {
                        if (requestIndexelse !== undefined) {
                            this.senderSupportDetailslist[elementIndex].requestModel[requestIndexelse].isSelected = false;
                            this.reloadList();
                            Toaster.error("Kindly Select Requests with the same type");
                        }
                    }
                }
            }
        }
    }

    public hidedSenderDialog() {
        this.editModel.senderId = '';
        this.editModel.senderStatus = 0;
        this.editSenderDialog = false;
    }
    public get isAnySelected(): boolean {
        return this.selectedIds.length > 0;
    }
    public async save() {
        this.editModel.requestIds = this.selectedIds;
        const response = await this.senderRequestSupportService.editSenderRequestStatus(this.editModel);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
            this.hidedSenderDialog();
        } else {
            Toaster.success(this.$t('shared.senderEditedSuccessfully').toString());
            this.hidedSenderDialog();
            this.search();
            this.selectedIds = [];
            this.selected = [];
            this.editModel.requestIds = [];
        }
    }
    public showEditSenderDialog(id: string, newSenderStatus: number, newSenderDuration: number, newSenderRequestStatus: number) {
        this.editModel.senderId = id;
        this.editModel.senderStatus = newSenderStatus == undefined ? 0 : newSenderStatus;
        this.editModel.senderDuration = newSenderDuration == undefined ? 0 : newSenderDuration;
        this.editModel.requestStatus = newSenderRequestStatus == undefined ? 0 : newSenderRequestStatus;
        this.editSenderDialog = true;
    }
}