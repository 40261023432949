import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';

@Service()
export default class LoginService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async Get(
        name: string, crNumber: string, accreditationNumber: string, taxNumber: string, pageIndex: string,
        pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/providerrequest?name=' + name
                + '&crNumber=' + crNumber
                + '&accreditationNumber=' + accreditationNumber
                + '&taxNumber=' + taxNumber
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async approve(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/providerrequest/approve?id=' + id;
            const result = await this.axiosService.axiosInstance.put(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async reject(id: string, reason: string): Promise<any> {
        this.loaderService.ShowLoader();
        const request = {
            id,
            rejectionReason: reason,
        };
        try {
            const url = window.$config.HostName + 'api/providerrequest/reject';
            const result = await this.axiosService.axiosInstance.put(url, request);
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async GeById(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/providerrequest/' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

}
