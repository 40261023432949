<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('userManagementPage.users')}} ({{users.length}})
                    </label>
                </div>
                <div class="col-auto">
                    <router-link to="/UserManagement/UserRegistration" tag="button" class="btn btn-primary mb-2">{{$t('shared.newUser')}} <i class="fas fa-plus px-2"></i></router-link>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       v-model="filterModel.username"
                                       type="text" :placeholder="$t('shared.userName')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       v-model="filterModel.email"
                                       type="text" :placeholder="$t('shared.userEmail')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4">
                            <div class="input-group mb-3">
                                <div class="input-group-prepend">
                                    <span class="input-group-text gray">
                                        +966
                                    </span>
                                </div>
                                <input class="form-control border-right-0 border-left-0"
                                       @paste="isNumberFieldPaste($event)"
                                       v-model="filterModel.mobile"
                                       :placeholder="$t('shared.phoneNumber')"
                                       @keypress="isNumberField($event)"
                                       maxlength="9"
                                       type="text" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template>
                        <v-data-table :headers="headers"
                                      :items="users"
                                      :total-items="usersCount"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      @update:pagination="updatePaginate"
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ props.item.username }}</td>
                                    <td class="text-center">{{ props.item.email }}</td>
                                    <td class="text-center" v-if="props.item.mobileNumber !== ''"><span class="pr-1">+966</span> {{ props.item.mobileNumber }}</td>
                                    <td class="text-center" v-if="props.item.mobileNumber === ''">-</td>
                                    <td class="text-center">{{ props.item.roleName }}</td>
                                    <td class="text-center">{{ props.item.providerName }}</td>
                                    <td class="text-center">{{ props.item.operatorName }}</td>
                                    <td class="text-center">
                                        <v-switch v-model="props.item.isLocked" @click="showLockDialog($event,props.item.id,props.item.isLocked)"></v-switch>
                                    </td>
                                    <td class="text-center" >
                                        <div class="d-flex">
                                            <v-tooltip bottom v-if="props.item.roleName == 'Platform Admin' && (userRole == 9)">
                                                <template v-slot:activator="{ on }">
                                                    <span v-on="on">
                                                        <span class="material-icons Icon_Edit" @click="showSuperUserDialog($event,props.item.id)">
                                                            supervisor_account
                                                        </span>
                                                    </span>
                                                </template>
                                                <span>Make Super Admin</span>
                                            </v-tooltip>

                                            <router-link :to="'/UserManagement/UserInfo/' + props.item.id" tag="a" class="mx-1">
                                                <i class="fas fa-info-circle Icon_Info"></i>
                                            </router-link>
                                            <a class="mx-1" @click="showResendPasswordDialog(props.item.username)">
                                                <i class="fas fa-envelope Icon_Edit"></i>
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <v-dialog v-model="superUserDialog" persistent width="554">
        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
                {{$t('shared.switchAdminToSuper')}}
                <div class="close__dialog">
                    <i class="fas fa-times" @click="hideSuperUserDialog"></i>
                </div>
            </v-card-title>

            <v-card-text class="text-center">
                <p class="mt-3">
                    {{$t('shared.switchAdminToSuperText')}}
                </p>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <div class="form-row justify-content-end align-items-center">
                    <div class="col-auto mx-3">
                        <a class="link_danger" @click="hideSuperUserDialog">
                            {{$t('shared.no')}}
                        </a>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-danger px-5 py-2" @click="saveSuperUser">{{$t('shared.yes')}}</button>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>

    <v-dialog v-model="lockDialog" persistent width="554">
        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
                {{$t('shared.lockInput')}}
                <div class="close__dialog">
                    <i class="fas fa-times" @click="hideLockDialog"></i>
                </div>
            </v-card-title>

            <v-card-text class="text-center">
                <p class="mt-3" v-if="isLocked">
                    {{$t('shared.lockInputText')}}
                </p>
                <p class="mt-3" v-if="!isLocked">
                    {{$t('shared.unlockInputText')}}
                </p>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <div class="form-row justify-content-end align-items-center">
                    <div class="col-auto mx-3">
                        <a class="link_danger" @click="hideLockDialog">
                            {{$t('shared.no')}}
                        </a>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-danger px-5 py-2" @click="saveShortCode">{{$t('shared.yes')}}</button>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
    <v-dialog v-model="resendPasswordDialog" persistent width="554">
        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
                {{$t('shared.resendPassword')}}
                <div class="close__dialog">
                    <i class="fas fa-times" @click="hideResendPasswordDialog"></i>
                </div>
            </v-card-title>

            <v-card-text class="text-center">
                <p class="mt-3">
                    {{$t('shared.resendPasswordText')}}
                </p>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <div class="form-row justify-content-end align-items-center">
                    <div class="col-auto mx-3">
                        <a class="link_danger" @click="hideResendPasswordDialog">
                            {{$t('shared.no')}}
                        </a>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-danger px-5 py-2" @click="resendPasswordEmail">{{$t('shared.yes')}}</button>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>
</div>

