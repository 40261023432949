<div class="w-75 h-100 m-auto">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <label class="header_text mb-3" v-if="!isEditMode">
                {{$t('shared.addCITCKeywords')}}
            </label>
            <label class="header_text mb-3" v-if="isEditMode">
                {{$t('shared.editKeyword')}}
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body card_body_layout">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{$t('shared.CITCKeywords')}}</label>
                                <div class="input-group mb-3">
                                    <input class="form-control" v-model="name" type="text" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-12 mt-5">
                            <div class="form-row justify-content-end align-items-center">
                                <div class="col-auto mx-3">
                                    <router-link to="/CITCKeywords" tag="a" class="link_secondary">{{$t('shared.cancel')}}</router-link>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-success px-5 py-2" :disabled="!isValid" @click="showaddKeywordDialog" v-if="!isEditMode">{{$t('shared.save')}}</button>
                                    <button class="btn btn-success px-5 py-2" :disabled="!isValid" @click="showeditKeywordDialog" v-if="isEditMode">{{$t('shared.edit')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="editKeywordrDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.editInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideeditKeywordDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <!--<img :src="require('../img/folder.svg')" />-->
                    <p class="mt-3">
                        {{$t('shared.editKeywordInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideeditKeywordDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="addCITCKeywordsFun()">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="addKeywordDiDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.addInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideddKeywordDiDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <!--<img :src="require('../img/folder.svg')" />-->
                    <p class="mt-3">
                        {{$t('shared.addKeywordInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideddKeywordDiDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="addCITCKeywordsFun()">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>

