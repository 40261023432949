import { Component, Vue, Prop } from 'vue-property-decorator';
import WithRender from './PaymentResult.html';
import { Inject } from 'vue-di-container';
import TokenService from '../../../Services/TokenService';


@WithRender
@Component({
    components: {
    },
    diProvide: [
        TokenService,
    ],
})
export default class PaymentResult extends Vue {
    @Inject(TokenService) public tokenService!: TokenService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public Success: boolean | any;
    public isFaild: boolean | any;
    public isPublic: boolean = true;
    public User: any;
    public async created() {
        this.isFaild = this.$route.query.isSuccess.toString().toLowerCase() === 'false' ? true : false;
        if (this.tokenService) {
            const token = await this.tokenService.getToken();
            if (token) {
                this.isPublic = false;
            }
        }
    }
    public GotoDashboard() {
        window.location.href = '/Dashboard';
    }
}
