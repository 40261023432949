import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import ProviderService from '../ProviderService';
import WithRender from './AddProvider.html';
import { ProviderModel } from '../ProviderModel';
import { Toaster } from '../../../Services/toast';
import ValidationService from '../../../Services/ValidationService';
import UserModel from '../../../shared/userService/Model/UserModel';
import UserRoleService from '../../../shared/userService/UserRoleService';
import UserService from '../../../shared/userService/UserService';


@WithRender
@Component({
    components: {
    },
    diProvide: [
        UserService,
        UserRoleService,
        ProviderService,
    ],
})
export default class AddProvider extends Vue {
    @Inject(ProviderService) public providerService!: ProviderService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;

    @Prop({ type: String, default: 'Add' })
    public mode: string | any;

    @Prop({ default: '' })
    public id!: string;
    public expiryDateMenu: boolean = false;
    public provider: ProviderModel = {
        name: '',
        id: '',
        createdDate: null,
        crNumber: '',
        accreditationNumber: '',
        email: '',
        taxNumber: '',
        hasInternational: false,
        country: '',
        city: '',
        district: '',
        streetName: '',
        buildingNumber: '',
        postalCode: '',
        vatNumber: '',
        additionalNumber: '',
        otherIdentifier: '',
        balance: 0,
        creditLimitAmount: 0,
        bankAccountNumber: '',
        expireDate: '',
        state:'',
    };
    public user: UserModel = {
        userId: '',
        username: '',
        details: {
            Email: '',
            MobilePhone: '',
            Operator: '',
            Provider: '',
            OperatorId: '',
            TenantId: '',
            ProviderId: '',
            Role: '',
            ChangePasswordRequired: '',
        },
    };
    public editProviderDialog: boolean = false;
    public isProvider: boolean = false;
    public validVatNumber: boolean = false;
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.user = await this.userService.getUser();
        if (this.user.details.Role === 'Provider Admin' || this.user.details.Role === 'Provider User') {
            this.isProvider = true;
            this.provider = await this.providerService.GetById(this.user.details.ProviderId);


        } else if (this.isEditMode) {
            this.provider = await this.providerService.GetById(this.id);
        }
    }
    public async addProvider() {
        this.provider.name.trim();
        this.provider.crNumber.trim();
        this.provider.accreditationNumber.trim();
        this.provider.taxNumber.trim();
        const response = await this.providerService.Post(this.provider);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.addProviderSuccessfully').toString());
            this.provider = {
                name: '',
                id: '',
                taxNumber: '',
                createdDate: null,
                crNumber: '',
                accreditationNumber: '',
                email: '',
                hasInternational: false,
                country: '',
                city: '',
                district: '',
                streetName: '',
                buildingNumber: '',
                postalCode: '',
                vatNumber: '',
                additionalNumber: '',
                otherIdentifier: '',
                balance: 0,
                creditLimitAmount: 0,
                bankAccountNumber: '',
                expireDate: '',
                state:'',
            };
            this.$router.push({ name: 'Providers' });
        } else {
            Toaster.error(response.errorMessage);
        }
    }
    public showeditProviderDialog(id: string) {
        this.editProviderDialog = true;
    }
    public hideEditProviderDialog() {
        this.editProviderDialog = false;
    }
    public async editProvider() {
        this.provider.name.trim();
        this.provider.crNumber.trim();
        this.provider.accreditationNumber.trim();
        this.provider.taxNumber.trim();
        const response = await this.providerService.Put(this.provider);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.editProviderSuccessfully').toString());
            this.$router.push({ name: 'Providers' });
        } else {
            Toaster.error(response.errorMessage);
        }
    }
    public get isValid(): boolean {
        this.validVatNumber = (this.provider.vatNumber !== null && this.provider.vatNumber !== '') ?
            this.provider.vatNumber.trim() !== '' : false;
        return (this.provider.name.trim() !== '' &&
            this.provider.crNumber.trim() !== '' &&
            this.validVatNumber &&
            this.provider.accreditationNumber.trim() !== '' &&
            this.provider.taxNumber.trim() !== '' &&
            (this.provider.expireDate !== '' && this.provider.expireDate !== null) &&
            this.provider.email.trim() !== '' &&
            this.validationService.isValidEmail(this.provider.email.trim())
        );
    }
    public get isEditMode() {
        return (this.mode === 'Edit');
    }

    //public deleteLicenseCertificate() {
    //    this.provider.licenseCertificateName = '';
    //    this.provider.licenseCertificateContent = '';
    //    this.licenseCertificate = '';
    //    (this.$refs.fileLicenseCertificate as any).value = '';
    //}

    public onPickLicenseCertificate() {
        (this.$refs.fileLicenseCertificate as any).click();
    }

    //public onLicenseCertificatePicked(event: any) {
    //    const files: any[] = event.target.files;
    //    Array.prototype.forEach.call(files, (file, index) => {
    //        this.provider.licenseCertificateName = file.name;
    //        this.licenseCertificate = file.name;
    //        const reader = new FileReader();
    //        reader.onloadend = (fileData: any) => {
    //            this.provider.licenseCertificateContent = fileData.target.result.split(',')[1];
    //        };
    //        reader.readAsDataURL(file);
    //    });
    //}
}
