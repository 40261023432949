import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import ShortcodeConnectingRequestService from '../ShortcodeConnectingRequestService';
import WithRender from './AddNewConnectivity.html';
import UserService from '../../../shared/userService/UserService';
import { Toaster } from '../../../Services/toast';
import ValidationService from '../../../Services/ValidationService';
import { ShortcodeConnectingRequestModel } from '../ShortcodeConnectingRequestModel';
import { ShortModel } from '../../Short/ShortModel';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        ShortcodeConnectingRequestService,
        UserService,
    ],
})
export default class AddNewConnectivity extends Vue {
    @Inject(ShortcodeConnectingRequestService) public shortcodeConnectingRequestService!: ShortcodeConnectingRequestService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(UserService) public userService!: UserService;
    @Prop({ type: Boolean, default: false })
    @Prop({ type: String, default: '' })
    public id: string | any;
    public addShortDiDialog: boolean = false;
    public User: any;
    public operatorOptions: any;
    public shortCodeTypes: any;
    public layoutSup: boolean | any;
    shortCodeModel: ShortcodeConnectingRequestModel | any = {};
    editMode: boolean = false;
    public shorts: ShortModel[] = [];
    public filterdShorts: ShortModel[] = [];
    public myShort: string = '';
    public filterShorts: ShortModel[] = [];
    public filterModel: any = {
        id: '',
        operator: '',
        name: '',
        providerId: '',
    }
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.User = await this.userService.getUser();
        const response = await this.shortcodeConnectingRequestService.getAll();
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.shorts = response.result;
        this.filterdShorts = response.result;
    }
    @Watch('filterModel', { deep: true })
    public async search() {
        const response = await this.shortcodeConnectingRequestService.getAll();
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.shorts = response.result;
    }
    public created() {
        this.operatorOptions = window.$config.OperarorList;
        this.shortCodeTypes =
            [
                { "id": 1, "name": "White List" },
                { "id": 2, "name": "Black List" },
            ];
    }
    @Watch('shortCodeModel.shortCodeType', { deep: true })
    public onchangeShortCodeType() {
        this.filterShortCode();
    }

    @Watch('shortCodeModel.operatorId', { deep: true })
    public onchangeOperator() {
        this.filterShortCode();
    }
    private filterShortCode() {
        this.filterdShorts = this.shorts.filter((x) => x.shortCodeType === this.shortCodeModel.shortCodeType
            && x.operatorId === this.shortCodeModel.operatorId);
        this.myShort = '';
    }
    public get isValid(): boolean {
        return (
            this.shortCodeModel.operatorId !== 0 && this.shortCodeModel.operatorId !== null
            && this.shortCodeModel.shortCodeType !== 0 && this.shortCodeModel.shortCodeType !== null
            && this.shortCodeModel.operatorId !== undefined
            && this.shortCodeModel.shortCodeType !== undefined &&
            this.myShort !== ''
        );
    }
    public get canSelectShortCode(): boolean {
        return (
            this.shortCodeModel.operatorId !== 0 && this.shortCodeModel.operatorId !== null && this.shortCodeModel.operatorId !== undefined
            && this.shortCodeModel.shortCodeType !== 0 && this.shortCodeModel.shortCodeType !== null && this.shortCodeModel.shortCodeType !== undefined
        );
    }

    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }

    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public showaddShortCodeDialog(id: string) {
        this.addShortDiDialog = true;
    }
    public hideddShortDiDialog() {
        this.addShortDiDialog = false;
    }
    async save() {
        if (!(this.shortCodeModel.operatorId !== 0 && this.shortCodeModel.operatorId !== null
            && this.shortCodeModel.shortCodeType !== 0 && this.shortCodeModel.shortCodeType !== null
            && this.shortCodeModel.operatorId !== undefined
            && this.shortCodeModel.shortCodeType !== undefined &&
            this.myShort !== '')) {
            Toaster.error(this.$t('bulkshortPage.allFiledsAreRequierd').toString());
        } else {
            const response = await this.shortcodeConnectingRequestService.post(this.myShort);
            if (response.isSuccess) {
                Toaster.success(this.$t('bulkshortPage.addNewConnectingRequestServiceSuccessfully').toString());
                this.$router.push({ name: 'ShortcodeConnectingRequest' });
            } else {
                Toaster.error(response.errorMessage);
            }
        }
        this.hideddShortDiDialog();
    }
}