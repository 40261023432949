import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { ConnectingSenderNameModel } from './UpdateContractRequestModel';

@Service()
export default class UpdateContractRequestService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async get(id: string) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/contract-requests/' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async post(model: ConnectingSenderNameModel) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/contract-requests';
            const result = await this.axiosService.axiosInstance.put(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    
    public async ConnectingSenderName(senderName: string, crNumber: string, customerType: number): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/senders/get-sender-for-contract-request?'
                +'senderName=' + senderName
                + '&crNumber=' + crNumber
                + '&customerType=' + customerType;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }  
}
