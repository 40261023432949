import Progress from 'easy-circular-progress';
import { Inject } from 'vue-di-container';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import LoaderService from '../../../Services/LoaderService';
import { Toaster } from '../../../Services/toast';
import ValidationService from '../../../Services/ValidationService';
import WithRender from './ExtendDurationRequests.html';
import { ExtendDurationRequestsFilterModel } from './ExtendDurationRequestsFilterModel';
import ExtendDurationRequestsService from './ExtendDurationRequestsService';
import { ExtendDurationRequestsModel } from './ExtendDurationRequestsModel';
import { RequestsOptionsModel } from '../RequestsOptionsModel ';
import DashboardService from '../DashboardService';
import { GetSendersExtendRequestInfoModel } from './GetSendersExtendRequestInfoModel';
import { GetRenewSenderModel } from './RenewRegisterSender/GetRenewSenderModel';
import { DropdownModel } from './../../Provider/ProviderModel';
import { UpdateExtendDurationRequestsModel } from './UpdateExtendDurationRequestsModel';



@WithRender
@Component({
    components: {
        Progress,
    },
    diProvide: [
        ExtendDurationRequestsService,
        DashboardService,
    ],
})
export default class ExtendDurationRequests extends Vue {
    @Inject(DashboardService) public dashboardService!: DashboardService;
    @Inject(ExtendDurationRequestsService) public extendDurationRequestsService!: ExtendDurationRequestsService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public requestStatus: string = '';
    public senderStatus: string = '';
    public requestsOptions: RequestsOptionsModel = {} as RequestsOptionsModel;
    public resendDialog: boolean = false;
    public filterModel: ExtendDurationRequestsFilterModel = {
        senderName: '',
    };
    public userRole: any;
    public scrollDiv: any;
    public nextPage: string = '';
    public scrollTop: number = 0;
    public selected: ExtendDurationRequestsModel[] = [];
    public selectedIds: string[] = [];
    public selectedSenderIds: string[] = [];
    public ids: string[] = [];
    public senders: ExtendDurationRequestsModel[] = [];
    public sendersCount: number = 0;
    public User: any;
    public anySelected: boolean = false;
    public pagination: any = {
        sortBy: 'senderName',
    };
    public getModel: GetSendersExtendRequestInfoModel = {
        requestId: ''
    };
    public enterpriseUnifiedNumberDisabled: boolean = true;

    public updateExtendDurationRequestsModel: UpdateExtendDurationRequestsModel = {
        id: '',
        duration: 0,
    };

    public customerTypeList: DropdownModel[] = [
        {
            id: 1,
            name: this.$t('shared.government').toString(),
        },
        {
            id: 2,
            name: this.$t('shared.charity').toString(),
        },
        {
            id: 3,
            name: this.$t('shared.private').toString(),
        },
        {
            id: 4,
            name: this.$t('shared.international').toString(),
        },
    ];

    public durationList: DropdownModel[] = [
        {
            id: 1,
            name: '1',
        },
        {
            id: 2,
            name: '2',
        },
        {
            id: 3,
            name: '3',
        },
        {
            id: 4,
            name: '4',
        },
        {
            id: 5,
            name: '5',
        },
    ];

    public getRenewSenderModel: GetRenewSenderModel = {
        senderId: '',
        clientName: '',
        crNumber: '',
        enterpriseUnifiedNumber: '',
        customerType: 0,
        representativeEmail: '',
        representativeMobile: '',
        representativeName: '',
    };

    public headers: any = [
        { text: this.$t('extendDurationRequestsPage.extendRequestId'), sortable: false, value: 'id', align: 'center' },
        { text: this.$t('extendDurationRequestsPage.senderName'), sortable: false, value: 'senderName', align: 'center' },
        { text: this.$t('extendDurationRequestsPage.extendDuration'), sortable: false, value: 'duration', align: 'left' },
        { text: this.$t('extendDurationRequestsPage.requestDate'), sortable: false, value: 'createdDate', align: 'center' },
        { text: this.$t('extendDurationRequestsPage.status'), sortable: false, value: 'requestStatus', align: 'center' },
        { text: this.$t('extendDurationRequestsPage.actions'), sortable: false, value: 'id', align: 'center', width: '145' },
    ];
    public changeSort(column: any) {
        if (this.pagination.sortBy === column) {
            this.pagination.descending = !this.pagination.descending;
        } else {
            this.pagination.sortBy = column;
            this.pagination.descending = false;
        }
    }
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.search();
        this.getRequestsOptions();
    }
    @Watch('filterModel', { deep: true })
    public async search() {
        const model = {
            senderName: this.filterModel.senderName,
            pageIndex: '0',
            pagesize: '10',
        };
        const response = await this.extendDurationRequestsService.Get(model);
        if (response !== false) {
            if (!response.isSuccess) {
                this.$toast.error(response.errorMessage);
            }
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }
    public async getRequestsOptions() {
        const response = await this.dashboardService.getRequestsOptions();
        this.requestsOptions.isChangeSenderTypeEnabled = response.result.isChangeSenderTypeEnabled;
        this.requestsOptions.isNewRequestEnabled = response.result.isNewRequestEnabled;
        this.requestsOptions.isUpdateRequstDataEnabled = response.result.isUpdateRequstDataEnabled;
        this.requestsOptions.isRenewRequstEnabled = response.result.isRenewRequstEnabled;

    }
    public isEqual(value1: string | null, value2: string) {
        return (value1 === '' || value1 === null || value2.includes(value1));
    }
    public selectAll() {
        if (this.selected.length) {
            this.selected = [];
            this.selectedIds = [];
            this.selectedSenderIds = [];
        } else {
            this.selected = this.senders.slice();
            this.selectedIds = this.senders.map((x) => x.id);
            this.selectedSenderIds = this.senders.map((x) => x.senderId);
        }
    }
    public select(event: any, id: string) {
        if (!event) {
            const index = this.selectedIds.indexOf(id);
            this.selectedIds.splice(index, 1);
            this.selectedSenderIds.splice(index, 1);
        } else {
            this.selectedIds.push(id);
            let selectedObj = this.selected.find(s => s.id == id);
            if (selectedObj !== undefined) {
                this.selectedSenderIds.push(selectedObj.senderId);
            }
        }
    }
    public PreparePay(id: string) {
        let ids: string[] = [];
        if (id !== '') {
            ids.push(id);
        } else {
            ids = this.selectedIds;
            if (this.senders.filter((s) => ids.includes(s.id) && s.requestStatus !== 'Pending Payment')
                .length > 0) {
                Toaster.error(this.$t('dashboardPage.NotAllSenderPendingPay').toString());
                return;
            }
        }
        this.$router.push({ name: 'Payment', params: { ids: ids.join(',') } });
    }
    public async updatePaginate(val: any) {
        const model = {
            senderName: this.filterModel.senderName,
            pageIndex: (val.page - 1).toString(),
            pagesize: val.rowsPerPage,
        };
        const response = await this.extendDurationRequestsService.Get(model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }
    public get isAnySelected(): boolean {
        this.anySelected = this.selectedIds.length > 0;
        return this.anySelected;
    }
    public async reload() {
        const model = {
            senderName: this.filterModel.senderName,
            pageIndex: '0',
            pagesize: '10',
        };
        const response = await this.extendDurationRequestsService.Get(model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
        this.selectedIds = [];
        this.selectedSenderIds = [];
        this.selected = [];
    }
    public async renewRegisterSender() {
        this.$router.push({ name: 'RenewRegisterSender' });
    }

    public async checkRenewSender(senderId: string) {
        if (this.isAnySelected) {
            let ids: string[] = [];
            if (senderId !== '') {
                ids.push(senderId);
            } else {
                ids = this.selectedSenderIds;
            }
            const response = await this.dashboardService.checkRenewSender(ids);
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
                await this.reload();
                this.selectedIds = [];
                this.selectedSenderIds = [];
            } else {
                this.$router.push({ name: 'RenewRegisterSender', params: { ids: ids.join(',') } });
            }
        } else {
            this.$router.push({ name: 'RenewRegisterSender' });
        }
    }


    public async saveExtendRequest() {
        this.updateExtendDurationRequestsModel.id = this.getModel.requestId;
        const response = await this.extendDurationRequestsService.UpdateExtendRequest(this.updateExtendDurationRequestsModel);
        if (response.isSuccess) {
            Toaster.success(this.$t('shared.extendRequestedUpdatedSuccessfully').toString());
            this.hideresendDialog();
            this.reload();
        } else {
            Toaster.error(response.errorMessage);
            this.hideresendDialog();
        }
    }

    public async showresendDialog(requestId: string) {
        this.getModel.requestId = requestId;
        const response = await this.extendDurationRequestsService
            .GetSenderForRenew(this.getModel);
        if (response.isSuccess) {
            this.getRenewSenderModel = response.result;
            this.updateExtendDurationRequestsModel.duration = response.result.duration;
        } else {
            Toaster.error(response.errorMessage);
        }
        this.resendDialog = true;
    }

    public hideresendDialog() {
        this.resendDialog = false;
    }
}