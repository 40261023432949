import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { RejectionReasonModel } from './RejectionReasonModel';

@Service()
export default class RejectionReasonListService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    controllerPath = window.$config.HostName + 'api/RejectionReason';


    public async get(
        rejectionText: string,
        requestType: number,
        pageIndex: string,
        pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = this.controllerPath + '?rejectionText=' + rejectionText
                + '&requestType=' + requestType
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getAll(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = this.controllerPath + '/getall';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async getById(id: any): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = this.controllerPath + '/getById?id=${id}';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async isRejectionReasonExists(reasonText: string, requestType: number) {
        this.loaderService.ShowLoader();
        try {
            const url = this.controllerPath + '/isRejectionReasonExists?reasonText=' + reasonText + '&&requestType=' + requestType;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async post(rejectionText: string, requestType: number) {
        this.loaderService.ShowLoader();
        try {
            const rejectionRessonModel = {
                rejectionText: rejectionText,
                requestType: requestType
            }
            const url = this.controllerPath;
            const result = await this.axiosService.axiosInstance.post(url, rejectionRessonModel);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }

    public async edit(rejectionRessonModel: RejectionReasonModel) {
        this.loaderService.ShowLoader();
        try {
            const url = this.controllerPath;
            const result = await this.axiosService.axiosInstance.put(url, rejectionRessonModel);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async delete(id: string) {
        this.loaderService.ShowLoader();
        try {
            const url = this.controllerPath;
            const result = await this.axiosService.axiosInstance.delete(url, {
                params: {
                    id,
                },
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
}
