import { Service } from 'vue-di-container';

@Service()
export default class TokenService {
    public async getToken(): Promise<string> {
        return localStorage.token;
    }
    public async setToken(token: string) {
        localStorage.token = token;
    }

}
