import { Inject } from 'vue-di-container';
import { Component, Prop, Vue } from 'vue-property-decorator';
import { Toaster } from '../../../Services/toast';
import ValidationService from '../../../Services/ValidationService';
import { CITCKeywordsModel } from '../CITCKeywordsModel';
import CITCKeywordsService from '../CITCKeywordsService';
import WithRender from './AddCITCKeywords.html';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        CITCKeywordsService,
        ],
})
export default class AddCITCKeywords extends Vue {
    @Inject(CITCKeywordsService) public cITCKeywordsService!: CITCKeywordsService;
    @Inject(ValidationService) public validationService!: ValidationService;

    @Prop({ type: String, default: 'Add' })
    public mode: string | any;

    @Prop({ default: '' })
    public id!: string;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public name: string = '';
    public editKeywordrDialog: boolean = false;
    public addKeywordDiDialog: boolean = false;
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        if (this.isEditMode) {
            const response = await this.cITCKeywordsService.getbyId(this.id) as CITCKeywordsModel;
            this.name = response.name;
        }
    }
    public get isEditMode() {
        return (this.mode === 'Edit');
    }
    public showeditKeywordDialog(id: string) {
        this.editKeywordrDialog = true;
    }
    public hideeditKeywordDialog() {
        this.editKeywordrDialog = false;
    }
    public showaddKeywordDialog(id: string) {
        this.addKeywordDiDialog = true;
    }
    public hideddKeywordDiDialog() {
        this.addKeywordDiDialog = false;
    }
    public get isValid(): boolean {
        return (this.name !== '');
    }
    public async addCITCKeywordsFun() {
        if (this.isEditMode) {
            const response = await this.cITCKeywordsService.put(this.id , this.name);
            if (response.isSuccess && response.result) {
                this.name = '';
                this.$router.push({ name: 'CITCKeywords' });
            } else {
                Toaster.error(response.errorMessage);
                this.$router.push({ name: 'CITCKeywords' });
            }
        } else {
            const response = await this.cITCKeywordsService.post(this.name);
            if (response.isSuccess && response.result) {
                Toaster.success(this.$t('shared.addSuccessfully').toString());
                this.name = '';
                this.$router.push({ name: 'CITCKeywords' });
            } else {
                Toaster.error(response.errorMessage);
                this.$router.push({ name: 'CITCKeywords' });
            }
        }
    }
}
