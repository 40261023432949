import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { SenderModel } from '../SenderModel';

@Service()
export default class EditSenderService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async get(id: string) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests/' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async put(model: SenderModel) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests';
            const result = await this.axiosService.axiosInstance.put(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async editSender(model: SenderModel) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/senders';
            const result = await this.axiosService.axiosInstance.put(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
