import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './Short.html';
import { ShortModel } from './ShortModel';
import ShortService from './ShortService';
import { Toaster } from '../../Services/toast';
import UserService from '../../shared/userService/UserService';
import UserRoleService from '../../shared/userService/UserRoleService';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        ShortService,
        UserService,
        UserRoleService,
    ],
})
export default class Short extends Vue {
    @Inject(ShortService) public shortService!: ShortService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public deleteDialog: boolean = false;
    public shortCodeId: string = '0';
    public operatorId: string = '0';
    public name: string = '0';
    public User: any;
    public userRole: any;
    public headers: any = [
        { text: this.$t('shortPage.company'), value: 'operatorId', align: 'center', sortable: false },
        {
            text: this.$t('shortPage.shortCode')
                + ' / ' +
                this.$t('shared.customerId'), value: 'name', align: 'center', sortable: false,
        },{
            text: this.$t('shortPage.shortcodeType'), value: 'name', align: 'center', sortable: false,
        },
        { text: this.$t('shared.actions'), sortable: false, value: 'id', align: 'center', width: '100' },
    ];

    public shorts: ShortModel[] = [];
    public shortsCount: number = 0;
    public filterShorts: ShortModel[] = [];
    public filterModel: any = {
        id: '',
        operator: '',
        name: '',
        providerId: '',
    };
    public async created() {
        this.User = await this.userService.getUser();
        this.userRole = this.GetUserRole();
        this.$emit('childinit', this.layoutSup);
    }
    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.User);
    }
    public async mounted() {
        const response = await this.shortService.get(this.filterModel.operator,
            this.filterModel.name, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.shorts = response.result;
        this.shortsCount = response.totalCount;
    }
    @Watch('filterModel', { deep: true })
    public async search() {
        const response = await this.shortService.get(this.filterModel.operator.toString(),
            this.filterModel.name, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.shorts = response.result;
        this.shortsCount = response.totalCount;
    }

    public getOperatorText(operatorId: number) {
        switch (operatorId) {
            case 1:
                return this.$t('operator_Zain').toString();
            case 2:
                return this.$t('operator_STC').toString();
            case 3:
                return this.$t('operator_Mobily').toString();
            default:
                return '-';
        }
    }
    
    public getShortCodeType(type: number) {
        switch (type) {
            case 1:
                return this.$t('White List').toString();
            case 2:
                return this.$t('Black List').toString();
            default:
                return '-';
        }
    }

    public isEqual(value1: string | null, value2: string) {
        return (value1 === '' || value1 === null || value1 === undefined || value2.includes(value1));
    }

    public showDeleteDialog(id: string) {
        this.shortCodeId = id;
        this.deleteDialog = true;
    }
    
    public editShortCode(id: string) {
        this.$router.push({ name: 'AddShortCode' , params: { id } });
    }

    public hideDeleteDialog() {
        this.deleteDialog = false;
    }

    public async deleteShortCode() {
        const response = await this.shortService.delete(this.shortCodeId);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.deleteShortSuccessfully').toString());
            this.search();
        } else {
            Toaster.error(response.errorMessage);
        }
        this.deleteDialog = false;
    }
    public async updatePaginate(val: any) {
        const response = await this.shortService.get(this.filterModel.operator.toString(),
            this.filterModel.name, (val.page - 1).toString(), val.rowsPerPage);
        this.shorts = response.result;
        this.shortsCount = response.totalCount;
    }
    public sleep(ms: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }
}
