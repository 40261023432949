import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { CITCKeywordsModel } from './CITCKeywordsModel';

@Service()
export default class CITCKeywordsService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    public async get(
        name: string,
        pageIndex: string,
        pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/citckeywords?name=' + name
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getbyId(id: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/citckeywords/' + id;
            const response = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return response.data.result;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getAll(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/citckeywords/getall';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async post(nameKeywords: string) {
        this.loaderService.ShowLoader();
        const model = {
            name: nameKeywords,
        };
        try {
            const url = window.$config.HostName + 'api/citckeywords';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async put(idKeywords: string, nameKeywords: string) {
        this.loaderService.ShowLoader();
        const model = {
            id: idKeywords,
            name: nameKeywords,
        };
        try {
            const url = window.$config.HostName + 'api/citckeywords';
            const result = await this.axiosService.axiosInstance.put(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async delete(id: string) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/citckeywords';
            const result = await this.axiosService.axiosInstance.delete(url, {
                params: {
                    id,
                },
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
}
