import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './BalanceInvoice.html';
import { Toaster } from '../../Services/toast';
import BalanceInvoiceService from './BalanceInvoiceService';
import { BalanceInvoiceModel } from './BalanceInvoiceModel';
import VueHtml2pdf from 'vue-html2pdf'

@WithRender
@Component({
    components: {
        VueHtml2pdf
    },
    diProvide: [
        BalanceInvoiceService,
    ],
})
export default class BalanceInvoice extends Vue {
    @Inject(BalanceInvoiceService) public invoicesService!: BalanceInvoiceService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public $refs!: {
        html2Pdf: any;
    };
    public invoiceHtml: string = '';
    public deleteDialog: boolean = false;
    public cITCKeywordsId: string = '0';
    public name: string = '0';
    public User: any;
    public userRole: any;
    public headers: any = [
        { text: this.$t('shared.InvoicesNumber'), value: 'invoiceNumber', align: 'center', sortable: false },
        { text: this.$t('shared.providerName'), value: 'providerName', align: 'center', sortable: false },
        { text: this.$t('shared.paymentdate'), value: 'date', align: 'center', sortable: false },
        { text: this.$t('shared.amount'), value: 'amount', align: 'center', sortable: false },
        { text: this.$t('shared.actions'), sortable: false, value: 'paymentReference', align: 'center', width: '100' },
    ];
    public createdDateToMenu: boolean = false;
    public createdDateFromMenu: boolean = false;
    public selectedPaymentReference: string = '';
    public downloadIssued: boolean = false;
    public invoicesModel: BalanceInvoiceModel[] = [];
    public invoicesCount: number = 0;
    public filterinvoices: BalanceInvoiceModel[] = [];
    public filterModel: any = {
        invoiceNumber: '',
        providerName:'',
        paymentDateFrom:'',
        paymentDateTo:''
    };
    public async created() {
        this.$emit('childinit', this.layoutSup);
    }
    public async mounted() {
        const response = await this.invoicesService.get
            (this.filterModel.invoiceNumber.toString(),
            this.filterModel.providerName,
            this.filterModel.paymentDateFrom,
            
            this.filterModel.paymentDateTo,false, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.invoicesModel = response.result;
        this.invoicesCount = response.totalCount;
    }
    //@Watch('filterModel', { deep: true })
    public async search() {
        const response = await this.invoicesService.get
            (this.filterModel.invoiceNumber.toString(),
            this.filterModel.providerName,
            this.filterModel.paymentDateFrom,
            this.filterModel.paymentDateTo,false, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.invoicesModel = response.result;
        this.invoicesCount = response.totalCount;
    }

    public async reload() {
        const response = await this.invoicesService.get
            (this.filterModel.invoiceNumber.toString(),
            this.filterModel.providerName,
            this.filterModel.paymentDateFrom,
            this.filterModel.paymentDateTo, false,'0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.invoicesModel = response.result;
        this.invoicesCount = response.totalCount;
    }

    public async updatePaginate(val: any) {
        const response = await this.invoicesService.get
            (this.filterModel.invoiceNumber.toString(),
            this.filterModel.providerName,
            this.filterModel.paymentDateFrom,
            this.filterModel.paymentDateTo,false,
            
            (val.page - 1).toString(), val.rowsPerPage);
        this.invoicesModel = response.result;
        this.invoicesCount = response.totalCount;
    }

    public async download(paymentReference: string, isBalanceInvoice: boolean) {
        this.downloadIssued = true;
        const response = await this.invoicesService.downloadIvnoice(paymentReference, isBalanceInvoice);
        this.selectedPaymentReference = paymentReference;
        if (response.isSuccess) {
            this.invoiceHtml = response.result.invoiceHtml;
            this.generateReport();
        }
    }

    public generateReport() {
        this.$refs.html2Pdf.generatePdf()
    }

    public hasDownloaded() {
        this.downloadIssued = false;
    }

    public reset() {
        this.filterModel = {
            invoiceNumber: '',
            providerName:'',
            paymentDateFrom:'',
            paymentDateTo:''
        };
        this.search();
    }

    public async exportToExcel() {
        const response = await this.invoicesService.get
        (this.filterModel.invoiceNumber.toString(),
        this.filterModel.providerName,
        this.filterModel.paymentDateFrom,
        this.filterModel.paymentDateTo, true,'0', '10');
    if (!response.isSuccess) {
        Toaster.error(response.errorMessage);
    }

    }
}
