import { stringify } from 'querystring';
import { Service } from 'vue-di-container';

@Service()
export default class ValidationService {
    public readonly phoneCodePhoneNumber: string = '05';
    public readonly phoneCodeFixedPhone: string = '01';
    public readonly emailPattern: RegExp = /\b^[\w-\.]+@([\w-]+\.)+[\w-]{2,15}$\b/;

    public isValidEmail(email: string) {
        return this.emailPattern.test(email);
    }
    public validateLength(max: number, text: any, evt?: KeyboardEvent): boolean {
        if (evt !== undefined) {
            const pattern = /^[0-9]+$/;
            if ((text && text.length >= max) || !pattern.test(evt.key)) {
                evt.preventDefault();
                return false;
            } else {
                return true;
            }
        } else {
            return text && text.length <= max ? true : false;
        }
    }
    public validateLengthString(max: number, text: any, evt?: KeyboardEvent): boolean {
        if (evt !== undefined) {
            if (text && text.length >= max) {
                evt.preventDefault();
                return false;
            } else {
                return true;
            }
        } else {
            return text && text.length <= max ? true : false;
        }
    }
    public isValidNumber(phoneNumber: string, prefixNumber: string, length: number): boolean {
        return (phoneNumber.length === length && phoneNumber.startsWith(prefixNumber));
    }
    public isArabic(text: string) {
        const pattern = /^[\u0621-\u064A\u0660-\u0669 ]+$/;
        return pattern.test(text);
    }
    public calculateRemainingCharactersCount(count: number, value: string) {
        return count - value.length;
    }
    public maxLength(max: number, that: any, evt: Event) {
        if (that.length >= max) {
            evt.preventDefault();
            return false;
        }
    }
    public isNumber(num: string) {
        return /^[0-9]$/.test(num);
    }
    public isDate(num: string) {
        return /^[0-9-]$/.test(num);
    }
    public isNumeric(text: any): boolean {
        const pattern = /^[0-9]+$/;
        return pattern.test(text);
    }
    public calcMaxDate() {
        const date = new Date();
        date.setTime(date.getTime());
        return date.getFullYear() + '-' + (date.getMonth() + 1) + '-' + date.getDate();
    }
}
