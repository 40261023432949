<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.addRequest')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-sm-center">
        <div class="col-sm-8">
            <v-stepper alt-labels :value="stepNumber">
                <v-stepper-header>
                    <v-stepper-step step="1" :class="{'complete': stepNumber > 1}">Client info</v-stepper-step>
                    <v-divider></v-divider>
                    <v-stepper-step step="2" :class="{'complete': stepNumber > 2}">Review</v-stepper-step>
                </v-stepper-header>
            </v-stepper>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="box_conferm" v-if="stepNumber == 3">
                    <img class="pb-4" :src="require('../img/BigCheck.svg')" />
                    <label class="w-100 pb-4">{{$t('shared.requestSubmittedSuccessfully')}}</label>
                    <div class="form-row justify-content-center align-items-center">
                        <div class="col-auto mx-3">
                            <router-link to="/Dashboard" tag="button" class="btn btn-outline-primary px-5 py-2 mx-2">{{$t('shared.requestsListPage')}}</router-link>
                        </div>
                        <div class="col-auto mx-3">
                            <button class="btn btn-primary px-5 py-2" @click="newRequest()">{{$t('shared.newRequest')}}</button>
                        </div>
                    </div>
                </div>
                <div class="card-body card_body_layout" v-show="stepNumber == 1">
                    <div class="row">
                        <div class="col-md-7">
                            <div class="row">
                                <div class="col-md-4 mb-3">
                                    <div class="form-group">
                                        <label>{{$t('shared.clientName')}}</label>
                                        <input class="form-control" v-model="model.clientName" type="text" />
                                    </div>
                                </div>
                                <div class="col-md-4">
                                    <div class="form-group">
                                        <label>{{$t('shared.customerType')}}</label>
                                        <v-select v-model="model.customerType"
                                                  :items="customerTypeList"
                                                  :label="$t('shared.select')"
                                                  item-text="name"
                                                  item-value="id"
                                                  solo>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3" v-if="model.customerType !== 0">
                                    <div class="form-group">
                                        <label v-if="model.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                                        <label v-if="model.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                                        <label v-if="model.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                                        <label v-if="model.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                                        <input class="form-control" v-model="model.crNumber" type="text" />
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3" v-if="model.customerType === 2 ||model.customerType === 3 ">
                                    <div class="form-group">
                                        <label>{{$t('shared.enterpriseUnifiedNumber')}}</label>
                                        <input class="form-control" v-model="model.enterpriseUnifiedNumber" type="text" />
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="form-group">
                                        <label>{{$t('shared.representativeName')}}</label>
                                        <input class="form-control" v-model="model.representativeName" type="text" />
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="form-group">
                                        <label>{{$t('shared.phoneNumber')}}</label>
                                        <div v-if="model.customerType ===0 ||model.customerType === 1 || model.customerType === 2 ||model.customerType === 3" class="input-group mb-3">
                                            <div class="input-group-prepend">
                                                <span class="input-group-text gray">
                                                    +966
                                                </span>
                                            </div>
                                            <input class="form-control border-left-0" @change="isMobileNumberValid" @paste="isNumberFieldPaste($event)" maxlength="9" @keypress="isNumberField($event)" v-model="model.representativeMobile" type="text" />
                                        </div>
                                        <div v-if="model.customerType === 4" class="input-group mb-3">
                                            <input class="form-control border-left-0" @paste="isNumberFieldPaste($event)" @keypress="isNumberField($event)" v-model="model.representativeMobile" type="text" />
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="form-group">
                                        <label>{{$t('shared.durationYear')}}</label>
                                        <input class="form-control" @paste="isNumberFieldPaste($event)" v-model="model.duration"
                                               @keypress="isNumberField($event)" :disabled="true" type="text" />
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="form-group">
                                        <label>{{$t('shared.representativeEmail')}}</label>
                                        <input class="form-control" v-model="model.representativeEmail" type="text" />
                                    </div>
                                </div>
                                <div class="col-md-4 mb-3">
                                    <div class="input-group mb-3">
                                        <label>{{$t('shared.expiryDate')}}</label>
                                        <v-menu v-model="expiryDateFromMenu" :close-on-content-click="true" :nudge-right="40"
                                                transition="scale-transition" offset-y>
                                            <template v-slot:activator="{ on, attrs }">
                                                <div class="input-group mb-3">
                                                    <input class="form-control border-right-0 border-left" v-model="model.expiryDate" readonly
                                                           v-bind="attrs" ref="createdDatePicker" v-on="on" type="text" />
                                                    <div class="input-group-prepend">
                                                        <span class="input-group-text right gray">
                                                            <i class="far fa-calendar-alt"></i>
                                                        </span>
                                                    </div>
                                                </div>
                                            </template>
                                            <v-date-picker v-model="model.expiryDate" no-title scrollable @input="expiryDateFromMenu = false">
                                            </v-date-picker>
                                        </v-menu>
                                    </div>
                                </div>
                                <div class="col-md-4 pr-md-0">
                                    <v-tooltip bottom>
                                        <template v-slot:activator="{ on }">
                                            <span v-on="on">
                                                <label>{{$t('shared.connectivityAdding')}}</label>
                                            </span>
                                        </template>
                                        <span>{{$t('shared.autoActivationNote')}}</span>
                                    </v-tooltip>
                                    <div class="form-group">
                                        <input type="radio" id="true" value="true" v-model="model.isAutoActivation" @click="show()" />
                                        <label for="true" style="padding: 10px;">Auto</label>
                                        <input type="radio" id="false" value="false" v-model="model.isAutoActivation" @click="hide()" />
                                        <label for="false" style="padding: 10px;">Manual</label>
                                    </div>
                                </div>
                                <div class="col-md-4 pr-md-0" v-if="showOperators">
                                    <div class="form-group">
                                        <label>{{$t('shared.Operator')}}</label>
                                        <v-select v-model="model.operators"
                                                  :items="operatorList"
                                                  multiple
                                                  :label="$t('shared.select')"
                                                  solo>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-8 col-10">
                                            <div class="form-group">
                                                <label>{{$t('shared.comment')}}</label>
                                                <input class="form-control" v-model="model.comment" type="text" />
                                            </div>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-6 col-5 pt-3">
                                            <div class="form-group">
                                                <div class="input-group mb-3 ">
                                                    <input type="file" ref="fileName" hidden accept=".png, .jpg, .jpeg, .pdf, .docx" @change="onFilePicked">
                                                    <input placeholder="Add Attachment" @click="onPickFile" class="form-control border-right-0 border-left" readonly v-model="selectedFileName" type="text" />
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-md-4 pt-3 ">
                                            <div class="form-group">
                                                <v-select v-model="attachmentType"
                                                          :items="attachmentCategoryList"
                                                          :label="$t('shared.addCategory')"
                                                          item-text="name"
                                                          item-value="id"
                                                          solo>
                                                </v-select>
                                            </div>
                                        </div>
                                        <div class="input-group-prepend pt-3">
                                            <span style="padding: 4px 4px 4px 4px !important;">
                                                <button :disabled="!canAddAttachment" @click="addAttachment" class="btn btn-primary">{{$t('shared.add')}}</button>
                                            </span>
                                        </div>
                                        <div class="col-md-12">
                                            <label>{{$t('shared.attachment')}}</label>
                                            <ul class="list-group list_Sender scrollbar">
                                                <li class="list-group-item" v-if="model.senderRequestAttachments.length > 0" v-for="(item, index) in model.senderRequestAttachments" :key="item">
                                                    <lable class="box-name">{{item.fileName}}</lable>
                                                    <lable class="box-name">{{item.attachmentTypeName}}</lable>
                                                    <i class="far fa-trash-alt Icon_delete" @click="deleteAttachment(index)"></i>
                                                </li>
                                                <li class="list-group-item" v-if="model.senderRequestAttachments.length == 0">
                                                    {{$t('shared.noAttachments')}}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div class="col-md-8 col-10">
                                        <div class="form-group">
                                            <label v-if="model.customerType === 1">{{$t('shared.pleaseAttachAtLeast1DocumentWithCategoryDelegationLetter')}}</label>
                                            <label v-if="model.customerType === 2">{{$t('shared.pleaseAttachaAtLeast2DocumentsWithCategoryDelegationLetterAndHRSDLicense')}}</label>
                                            <label v-if="model.customerType === 3">{{$t('shared.pleaseattachAtLeast2DocumentsWithCategoryDelegationLetterAndCommercialDocument')}}</label>
                                            <label>{{$t('shared.forAdditionalDocumentSelectTheAppropriateCategory')}}</label>
                                            <label>{{$t('shared.theMaximumSizeForTheAttachmentIs3Megabytes')}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-5">
                            <div class="row">
                                <div class="col-md-3 pt-2 pr-md-0">
                                    <div class="form-group">
                                        <v-checkbox v-model="isCriticalSender"
                                                    :label="$t('shared.criticalSender')"></v-checkbox>
                                    </div>
                                </div>
                                <div class="col-md-4 pr-md-0">
                                    <div class="form-group">
                                        <label>{{$t('shared.sendersList')}}</label>
                                        <v-select v-model="senderType"
                                                  :items="senderTypeList"
                                                  item-text="name"
                                                  item-value="id"
                                                  multiple
                                                  :label="$t('shared.select')"
                                                  solo>
                                        </v-select>
                                    </div>
                                </div>
                                <div class="col-md-5 pl-md-0">
                                    <div class="form-group">
                                        <label>{{$t('shared.sendersName')}}</label>
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" @paste="isSenderNameFieldPaste($event)" @keypress="isSenderNameField($event)" :maxlength="senderNameLength" v-model="senderName" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right" style="padding: 2px 2px 0px 2px !important;">
                                                    <button class="btn btn-primary" @click="AddSender" :disabled="!isValidSenderName"><i class="fas fa-plus"></i></button>
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-md-12">
                                    <ul class="list-group list_Sender scrollbar">
                                        <li class="list-group-item" v-if="model.senderNames.length > 0" v-for="(item, index) in model.senderNames" :key="item">
                                            <lable class="box-name">{{item.senderName}}</lable>
                                            <lable class="box-name" v-if="item.isCriticalSender">{{$t('shared.critical')}}</lable>
                                            <lable class="box-name" v-if="!item.isCriticalSender">{{$t('shared.notCritical')}}</lable>
                                            <i class="far fa-trash-alt Icon_delete" @click="deleteSender(index)"></i>
                                        </li>
                                        <li class="list-group-item" v-if="model.senderNames.length == 0">
                                            {{$t('shared.noSenderNames')}}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="card-body card_body_layout" v-if="stepNumber >= 2">
                    <div class="row">
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.clientName')}}</label>
                            <h4 class="user_info_txt">{{model.clientName}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.customerType')}}</label>
                            <h4 class="user_info_txt">{{getCustomerTypeText(model.customerType)}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0" v-if="model.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                            <label class="user_info_header mb-0" v-if="model.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                            <label class="user_info_header mb-0" v-if="model.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                            <label class="user_info_header mb-0" v-if="model.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                            <h4 class="user_info_txt">{{model.crNumber}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.representativeName')}}</label>
                            <h4 class="user_info_txt">{{model.representativeName}}</h4>
                        </div>
                        <div class="col-md-3 mb-3" v-if="model.customerType === 2 || model.customerType === 3">
                            <label class="user_info_header mb-0">{{$t('shared.enterpriseUnifiedNumber')}}</label>
                            <h4 class="user_info_txt">{{model.enterpriseUnifiedNumber}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.phoneNumber')}}</label>
                            <h4 class="user_info_txt"><span class="pr-3">+966</span> {{model.representativeMobile}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.apEmail')}}</label>
                            <h4 class="user_info_txt">{{model.representativeEmail}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.duration')}}</label>
                            <h4 class="user_info_txt">{{model.duration}} {{$t('shared.year')}}</h4>
                        </div>
                        <div class="col-md-3 mb-3">
                            <label class="user_info_header mb-0">{{$t('shared.comment')}}</label>
                            <h4 class="user_info_txt">{{model.comment}}</h4>
                        </div>
                        <div class="col-md-12 mb-3 pl-1">
                            <ul class="user_info_header row">
                                <li style="list-style:none" v-if="model.senderRequestAttachments.length > 0" v-for="(item, index) in model.senderRequestAttachments" :key="item">
                                    <div class="col-md-12 mb-3">
                                        <label class="user_info_header">{{item.attachmentTypeName}}</label>
                                        <h4 class="user_info_txt">
                                            <span class="badge badge-primary"><i class="fas fa-paperclip"></i>{{item.fileName}}</span>
                                        </h4>
                                    </div>
                                </li>
                                <li class="list-group-item" v-if="model.senderRequestAttachments.length == 0">
                                    {{$t('shared.noAttachments')}}
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="card-footer">
                    <div class="form-row justify-content-between align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_light" @click="reset" v-if="stepNumber == 1">{{$t('shared.reset')}}</a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-outline-primary px-5 py-2 mx-2" @click="back" v-if="stepNumber > 1">{{$t('shared.back')}}</button>
                            <button class="btn btn-primary px-5 py-2" @click="next" :disabled="!isValidStep" v-if="stepNumber < 2">{{$t('shared.next')}}</button>
                            <button class="btn btn-primary px-5 py-2" v-if="stepNumber >= 2" @click="showsubmitRequestDialog">{{$t('shared.submit')}}</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="submitRequestdialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.addInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hidesubmitRequestDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.addsubmitRequestInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hidesubmitRequestDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="submitRequest">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>