import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { SenderModel } from '../../../AddRequest/SenderModel';
import { AddExtendDurationRequestsModel } from './AddExtendDurationRequestsModel';

@Service()
export default class RenewRegisterSenderService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async Post(model: SenderModel) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests/renew';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetSendersForRenew(senderName: string, crNumber: string, customerType: number, enterpriseUnifiedNumber: string,): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests/get-senders-for-renew?'
                + 'senderName=' + senderName
                + '&crNumber=' + crNumber
                + '&customerType=' + customerType
                + '&enterpriseUnifiedNumber=' + enterpriseUnifiedNumber;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetSendersForRenewBySenderIds(ids: string[]): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const model = {
                Ids: ids,
            };
            const url = window.$config.HostName + 'api/sender-requests/get-senders-for-renew-by-senderIds';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async GetForRenewContract(senderName: string, crNumber: string, customerType: number, enterpriseUnifiedNumber: string,): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/contract-requests/get-sender-for-contract-renew?'
                + 'senderName=' + senderName
                + '&crNumber=' + crNumber
                + '&customerType=' + customerType
                + '&enterpriseUnifiedNumber=' + enterpriseUnifiedNumber;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async AddExtendRequest(model: AddExtendDurationRequestsModel) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/sender-requests/add-extend-duration-requests';
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}