import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { ScamModel } from './ScamModel';

@Service()
export default class ScamListService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    controllerPath = window.$config.HostName + 'api/scammanagement';


    public async get(
        senderName: string,
        clientName: string,
        pageIndex: string,
        pageSize: string): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = this.controllerPath + '?senderName=' + senderName
                + '&clientName=' + clientName
                + '&pageIndex=' + pageIndex
                + '&pageSize=' + pageSize;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async getAll(): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url =  this.controllerPath +'/getall';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    
    public async getById(id:any): Promise<any> {
        this.loaderService.ShowLoader();
        try {
            const url = this.controllerPath +'/getById?id=${id}';
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (exception) {
            this.loaderService.HideLoader();
            return false;
        }
    }
    public async isScamExists(senderName: string, clientName: string) {
        this.loaderService.ShowLoader(); 
        try {
            const url = this.controllerPath + '/isScamExists?senderName=' + senderName + '&&clientName=' + clientName;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async post(senderName: string, clientName: string) {
        this.loaderService.ShowLoader();
        const model = {
            senderName: senderName,
            clientName: clientName 
        };
        try {
            const url = this.controllerPath;
            const result = await this.axiosService.axiosInstance.post(url, model);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    
    public async edit(sacmModel: ScamModel) {
        this.loaderService.ShowLoader(); 
        try {
            const url = this.controllerPath;
            const result = await this.axiosService.axiosInstance.put(url, sacmModel);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
    public async delete(id: string) {
        this.loaderService.ShowLoader();
        try {
            const url = this.controllerPath;
            const result = await this.axiosService.axiosInstance.delete(url, {
                params: {
                    id,
                },
            });
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return error;
        }
    }
}
