<div class="w-100 h-100">
    <div class="row" style="padding-top: 56px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.connectivityActivationReport')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{$t('shared.requestId')}}</label>
                                <input class="form-control" v-model="requestId" type="text" />
                            </div>
                        </div>
                        <div class="col-md-6">
                            <div class="form-group">
                                <label>{{$t('shared.senderName')}}</label>
                                <input class="form-control" v-model="senderName" type="text" />
                            </div>
                        </div>
                    </div>
                    <div class="col-md-12 mt-2" style="padding: 10px;">
                        <div class="form-row justify-content-end align-items-center">
                            <div class="col-auto pull-left">
                                <button class="btn btn-primary btn-action"
                                        @click="search" :disabled="!isValid">
                                    {{$t('shared.apply')}}
                                </button>
                                <button class="btn btn-primary btn-action"
                                        @click="reset">
                                    {{$t('shared.reset')}}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="row" v-if="connectivityActivationTable">
        <div class="col-md-12 pr-0">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row" style="margin-top: 10px;">
                        <div class="col-md-12 pr-0">
                            <div class="card card_layout">
                                <div class="card-body">
                                    <template>
                                        <v-data-table :headers="headers"
                                                      :items="model"
                                                      :total-items="Count"
                                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                                      :rows-per-page-items='[10, 25, 50, 100]'
                                                      @update:pagination="updatePaginate"
                                                      class="elevation-1 table-striped">
                                            <template v-slot:items="props" >
                                                <tr>
                                                    <td class="text-center">{{ props.item.requestId }}</td>
                                                    <td class="text-center">{{ props.item.senderName }}</td>
                                                    <td class="text-center">{{ props.item.senderType }}</td>
                                                    <td class="text-center">{{ props.item.clientName }}</td>
                                                    <td class="text-center">{{ props.item.requestStatus }}</td>
                                                    <td class="text-center">{{ props.item.providerName }}</td>
                                                    <td class="text-center">{{ props.item.zainActivationDate }}</td>
                                                    <td class="text-center">{{ props.item.stcActivationDate }}</td>
                                                    <td class="text-center">{{ props.item.mobilyActivationDate }}</td>
                                                </tr>
                                            </template>
                                        </v-data-table>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

