import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './MainPaymentComponents.html';
import MainPaymentService from '../MainPaymentComponents/MainPaymentService';
import { Inject } from 'vue-di-container';
import { PayfortModel } from './PayfortModel';
import ValidationService from '../../Services/ValidationService';

@WithRender
@Component({
    components: {},
    diProvide: [MainPaymentService],
})
export default class MainPaymentComponents extends Vue {
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(MainPaymentService) public mainPaymentService!: MainPaymentService;
    public payfortModel: PayfortModel = new PayfortModel();
    public expiryDate = new Date().toISOString().substr(0, 7);
    public cardNumber: string = '';
    public cVV: string = '';
    public cardHolderName: string = '';
    public exDate: string = '';
    public $refs!: {

        payfortForm: HTMLFormElement,

        paymentMethod: HTMLFormElement,
    };
    public nameRules = [
        (v: any) => !!v || this.$t('sheared.itemisRequired'),
    ];
    public rulesExpiryDate = [
        (v: any) => v!! || this.$t('sheared.itemisRequired'),
    ];
    public paymentMethoditems = [
        { paymentMethodValue: 'VISA', paymentMethodName: 'VISA' },
        { paymentMethodValue: 'MASTERCARD', paymentMethodName: 'MASTERCARD' },
    ];
    public async PayfortSubmit(event: any) {
        if (this.$refs.payfortForm.validate()) {
            const key = 'expiry_date';
            event.target.elements[key].value = this.GetExpireDate();
            const keyHolderName = 'card_holder_name';
            event.target.elements[keyHolderName].value = this.cardHolderName;
            const keyNumber = 'card_number';
            event.target.elements[keyNumber].value = this.cardNumber;
            const keycVV = 'card_security_code';
            event.target.elements[keycVV].value = this.cVV;
            event.target.action = this.payfortModel.GatewayUrl;
            event.target.submit();
        }
    }
    public async mounted() {
        this.payfortModel = await this.mainPaymentService.GetPayfortModel();
    }
    public GetExpireDate() {
        const date = this.expiryDate.split('-');
        const year = date[0].substr(2);
        const month = date[1];
        return year + month;
    }
    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }
    public updateValueCardNumber(e: any) {
        this.cardNumber = e.target.value.replace(/ /g, '');
    }
    public updateValueExDate(e: any) {
        this.exDate = e.target.value.replace(/\//g, '', '');
    }
}
