import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './AttachmentCategory.html';
import { AttachmentCategoryModel } from './AttachmentCategoryModel';
import AttachmentCategoryService from './AttachmentCategoryService';
import { Toaster } from '../../Services/toast';
import UserService from '../../shared/userService/UserService';
import UserRoleService from '../../shared/userService/UserRoleService';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        AttachmentCategoryService,
        UserService,
        UserRoleService,
    ],
})
export default class AttachmentCategory extends Vue {
    @Inject(AttachmentCategoryService) public attachmentCategoryService!: AttachmentCategoryService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public deleteDialog: boolean = false;
    public attachmentCategoryId: number = 0;
    public name: string = '0';
    public User: any;
    public userRole: any;
    public headers: any = [
        { text: this.$t('shared.AttachmentCategory'), value: 'name', align: 'center', sortable: false },
        { text: this.$t('shared.actions'), sortable: false, value: 'id', align: 'center', width: '100' },
    ];

    public attachmentCategoryModel: AttachmentCategoryModel[] = [];
    public attachmentCategoryCount: number = 0;
    public filterAttachmentCategory: AttachmentCategoryModel[] = [];
    public filterModel: any = {
        id: '',
        name: '',
    };
    public async created() {
        this.User = await this.userService.getUser();
        this.userRole = this.GetUserRole();
        this.$emit('childinit', this.layoutSup);
    }
    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.User);
    }
    public async mounted() {
        const response = await this.attachmentCategoryService.get(this.filterModel.name, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.attachmentCategoryModel = response.result;
        this.attachmentCategoryCount = response.totalCount;
    }
    @Watch('filterModel', { deep: true })
    public async search() {
        const response = await this.attachmentCategoryService.get(this.filterModel.name, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.attachmentCategoryModel = response.result;
        this.attachmentCategoryCount = response.totalCount;
    }

    public showDeleteDialog(id: number) {
        this.attachmentCategoryId = id;
        this.deleteDialog = true;
    }

    public hideDeleteDialog() {
        this.deleteDialog = false;
    }

    public async deleteAttachmentCategory() {
        const response = await this.attachmentCategoryService.delete(this.attachmentCategoryId);
        if (response.isSuccess) {
            Toaster.success(this.$t('shared.deleteSuccessfully').toString());
            this.search();
        } else {
            Toaster.error(response.errorMessage);
        }
        this.deleteDialog = false;
    }
    public async reload() {
        const response = await this.attachmentCategoryService.get(this.filterModel.name, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.attachmentCategoryModel = response.result;
        this.attachmentCategoryCount = response.totalCount;
    }
    public async updatePaginate(val: any) {
        const response = await this.attachmentCategoryService.get(this.filterModel.name,
            (val.page - 1).toString(), val.rowsPerPage);
        this.attachmentCategoryModel = response.result;
        this.attachmentCategoryCount = response.totalCount;
    }

    public sleep(ms: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }
}
