import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import WithRender from './SLA.html';
import { SLAModel } from './SLAModel';

@WithRender
    @Component({
        components: {
        }
    })

export default class SLA extends Vue {
    public headers: any = [
        { text: this.$t('#'), value: 'number', align: 'center', sortable: false },
        { text: this.$t('shared.service'), value: 'service', align: 'center', sortable: false },
        { text: this.$t('shared.description'), value: 'description', align: 'center', sortable: false },
        { text: this.$t('shared.responseTime'), value: 'responseTime', align: 'center', sortable: false },
    ];
    public slaList: SLAModel[] = [];
    public sla1: SLAModel = {
        number: this.$t('shared.one'), 
        service: this.$t('shared.inquiries'),
        description: this.$t('shared.inquiriesAboutTheSystemOrAboutAdmissionCriteria'),
        responseTime: this.$t('shared.workingDay1'),
    };
    public sla2: SLAModel = {
        number: this.$t('shared.two'), 
        service: this.$t('shared.sIDRequest'),
        description:
            this.$t('shared.processingARequestWithAcceptanceOrRejectionBasedOnTheRegistrationCriteria'),
        responseTime: this.$t('shared.workingDay2'),
    };
    public sla3: SLAModel = {
        number: this.$t('shared.three'), 
        service: this.$t('shared.technicalSupport'),
        description: this.$t('shared.problemsWithASpecificSIDOrLinkWithOperators'),
        responseTime: this.$t('shared.workingDays2'),
    };
    public async mounted() {
        this.slaList.push(this.sla1);
        this.slaList.push(this.sla2);
        this.slaList.push(this.sla3);
    }

}