import { Component, Vue, Prop } from 'vue-property-decorator';
import ValidationService from '../../Services/ValidationService';
import { Inject } from 'vue-di-container';
import WithRender from './PublicPayment.html';
import PaymentService from './PublicPaymentService';
import { PublicPaymentModel } from './PublicPaymentModel';
import MainPaymentComponents from '../MainPaymentComponents/MainPaymentComponents';
import { Toaster } from '../../Services/toast';
import MainPaymentService from '../MainPaymentComponents/MainPaymentService';
import { PayfortModel } from '../MainPaymentComponents/PayfortModel';
import { v4 as uuid } from 'uuid';
import { InvoiceDetailsModel } from './InvoiceDetailsModel';
import { HyperPayModel } from '../Payment/HyperPayModel';

@WithRender
@Component({
    components: {
        MainPaymentComponents,
    },
    diProvide: [
        PaymentService, MainPaymentService,
    ],
})
export default class PublicPayment extends Vue {
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(PaymentService) public paymentService!: PaymentService;
    @Inject(MainPaymentService) public mainPaymentService!: MainPaymentService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    @Prop({ type: Boolean, default: false })
    public isFromDashboard: boolean | any;
    public ids: string | any;
    public idsList: string[] = [];
    public sendersPaymentSummery: PublicPaymentModel = {} as PublicPaymentModel;
    public payfortModel: PayfortModel = new PayfortModel();
    public expiryDate = new Date().toISOString().substr(0, 7);
    public cardNumber: string = '';
    public cVV: string = '';
    public cardHolderName: string = '';
    public exDate: string = '';
    public isAnyPaid: boolean = false;
    public paymentForm: boolean = false;
    public canEditBuyerInfo: boolean = true;
    public checkoutId: string = '';
    public hyperPayModel: HyperPayModel = {} as HyperPayModel;
    public shopperResultUrl: string = '';
    public isMada: boolean = false;
    public canPay: boolean = false;
    public disclaimerChecked: boolean = false;
    public invoiceDetailsModel: InvoiceDetailsModel = {
        paymentReference: '',
        buyerOrganizationName: '',
        buyerCountry: '',
        buyerCity: '',
        buyerDistrict: '',
        buyerStreetName: '',
        buyerBuildingNumber: '',
        buyerVatNumber: '',
        buyerPostalCode: '',
        buyerAdditionalNumber: '',
        buyerOtherIdentifier: '',
        buyerEmail: '',
    };
    public $refs!: {

        payfortForm: HTMLFormElement,

        paymentMethod: HTMLFormElement,
    };
    public nameRules = [
        (v: any) => !!v || this.$t('sheared.itemisRequired'),
    ];
    public rulesExpiryDate = [
        (v: any) => v!! || this.$t('sheared.itemisRequired'),
    ];
    public paymentMethoditems = [
        { paymentMethodValue: 'VISA', paymentMethodName: 'VISA' },
        { paymentMethodValue: 'MASTERCARD', paymentMethodName: 'MASTERCARD' },
    ];
    public async mounted() {
        this.GetPaymentLinkOptions();
        this.$emit('childinit', this.layoutSup);
        this.ids = this.$route.query.ids;
        this.isMada = (this.$route.query.IsMada === 'true') ? true : false;
        if (this.ids.includes(',')) {
            this.idsList = this.ids.split(',');
        } else {
            this.idsList.push(this.ids);
        }
        const response = await this.paymentService.Get(this.idsList);
        if (response.isSuccess) {
            this.sendersPaymentSummery = response.result;
        } else {
            this.isAnyPaid = true;
        }
        this.canPay = this.sendersPaymentSummery.isCreditCardEnabled && !this.isAnyPaid;
        this.invoiceDetailsModel.buyerOrganizationName = this.sendersPaymentSummery.clientNames;
    }
    public async addPaymentTransaction() {
        this.paymentForm = true;
        const getHyperPayModelResponse = await this.paymentService.GetHyperPayModel
            (this.sendersPaymentSummery.total, this.isMada);
        if (getHyperPayModelResponse.isSuccess) {
            this.hyperPayModel = getHyperPayModelResponse.result;
            this.checkoutId = this.hyperPayModel.id;
            this.shopperResultUrl = this.hyperPayModel.shopperResultUrl;
            await this.paymentService.AddPaymentTransaction
                (this.idsList, this.checkoutId, this.isMada, this.invoiceDetailsModel);
            const script = document.createElement('script');
            script.setAttribute('src', this.sendersPaymentSummery.hyperPayUrl + this.checkoutId);
            const container = document.getElementById('hyperPaycontainer');
            container!.appendChild(script);
            this.canEditBuyerInfo = false;
        }
        this.invoiceDetailsModel.paymentReference = this.sendersPaymentSummery.paymentReference;
    }
    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public async Pay() {
        let idsList: string[] = [];
        if (this.ids.includes(',')) {
            idsList = this.ids.split(',');
        } else {
            idsList.push(this.ids);
        }
        await this.paymentService.Pay(idsList);
        Toaster.success(this.$t('dashboardPage.Paysuccessfully').toString());
        if (this.isFromDashboard) {
            this.$router.push({ name: 'Dashboard' });
        } else {
            this.$router.push({ name: 'ChangeSenderTypeRequests' });
        }
    }
    public async GetPaymentLinkOptions() {
        const response = await this.paymentService.GetPaymentLinkOptions();
        if (response.isSuccess) {
            this.sendersPaymentSummery = response.result.isPublicPaymentLinkEnabled;
            if (!response.result.isPublicPaymentLinkEnabled) {
                this.$router.push({ name: 'Login' });
            }
        }    
    }
    public async PayfortSubmit(event: any) {
        this.UpdateInvoiceDetails();
        if (this.$refs.payfortForm.validate()) {
            const key = 'expiry_date';
            event.target.elements[key].value = this.GetExpireDate();
            const keyHolderName = 'card_holder_name';
            event.target.elements[keyHolderName].value = this.cardHolderName;
            const keyNumber = 'card_number';
            event.target.elements[keyNumber].value = this.cardNumber;
            const keycVV = 'card_security_code';
            event.target.elements[keycVV].value = this.cVV;
            event.target.action = this.payfortModel.GatewayUrl;
            event.target.submit();
        }
    }
    public UpdateInvoiceDetails() {
        const response = this.paymentService.UpdateInvoiceDetails(this.invoiceDetailsModel);
    }
    public GetExpireDate() {
        const date = this.expiryDate.split('-');
        const year = date[0].substr(2);
        const month = date[1];
        return year + month;
    }
    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }
    public isDateField(val: KeyboardEvent) {
        if (!this.validationService.isDate(val.key)) {
            val.preventDefault();
        }
    }
    public updateValueCardNumber(e: any) {
        this.cardNumber = e.target.value.replace(/ /g, '');
    }
    public updateValueExDate(e: any) {
        this.exDate = e.target.value.replace(/\//g, '', '');
    }
    public get isValid(): boolean {
        return (
            this.invoiceDetailsModel.buyerPostalCode.trim() !== '' &&
            this.invoiceDetailsModel.buyerBuildingNumber.trim() !== '' &&
            this.invoiceDetailsModel.buyerStreetName.trim() !== '' &&
            this.invoiceDetailsModel.buyerDistrict.trim() !== '' &&
            this.invoiceDetailsModel.buyerCity.trim() !== '' &&
            this.invoiceDetailsModel.buyerAdditionalNumber.trim() !== '' &&
            this.invoiceDetailsModel.buyerVatNumber.trim() !== '' &&
            this.invoiceDetailsModel.buyerVatNumber.trim().length == 15
            && this.invoiceDetailsModel.buyerOrganizationName.trim() !== ''
            && this.invoiceDetailsModel.buyerEmail.trim() !== ''
            && this.disclaimerChecked
            && this.validationService.isValidEmail(this.invoiceDetailsModel.buyerEmail.trim()));
    }
}
