import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './Provider.html';
import ProviderService from './ProviderService';
import { ProviderModel } from './ProviderModel';
import { Toaster } from '../../Services/toast';
import UserService from '../../shared/userService/UserService';
import UserRoleService from '../../shared/userService/UserRoleService';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        UserService,
        UserRoleService,
        ProviderService,
    ],
})
export default class Provider extends Vue {
    @Inject(ProviderService) public providerService!: ProviderService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public deleteDialog: boolean = false;
    public providerId: string = '';
    public userRole: any;
    public User: any;
    public headers: any = [
        { text: this.$t('shared.providerName'), value: 'name', align: 'center', sortable: false },
        { text: this.$t('shared.crNumber'), value: 'crNumber', align: 'center', sortable: false },
        { text: this.$t('shared.accreditationNumber'), value: 'accreditationNumber', align: 'center', sortable: false },
        { text: this.$t('shared.taxNumber'), value: 'taxNumber', align: 'center', sortable: false },
        { text: this.$t('shared.providerStatus'), value: 'state', align: 'center', sortable: false },
        {
            text: this.$t('shared.status'),
            sortable: false, value: 'isLocked', align: 'center',
        },
        { text: this.$t('shared.actions'), sortable: false, value: 'id', align: 'center', width: '100' },
    ];
    public isLocked: boolean = false;
    public lockDialog: boolean = false;
    public providers: ProviderModel[] = [];
    public providersCount: number = 0;
    public providersFiltered: ProviderModel[] = [];
    public filterModel: ProviderModel = {
        id: '',
        name: '',
        crNumber: '',
        taxNumber: '',
        createdDate: null,
        accreditationNumber: '',
        email: '',
        hasInternational: false,
        country: '',
        city: '',
        district: '',
        streetName: '',
        buildingNumber: '',
        postalCode: '',
        vatNumber: '',
        additionalNumber: '',
        otherIdentifier: '',
        balance: 0,
        creditLimitAmount: 0,
        bankAccountNumber: '',
        expireDate: '',
        state:'',
    };

    public created() {
        this.$emit('childinit', this.layoutSup);
    }

    public async mounted() {
        this.User = await this.userService.getUser();
        this.userRole = this.GetUserRole();
        const response = await this.providerService.Get(this.filterModel.name,
            this.filterModel.crNumber, this.filterModel.taxNumber, this.filterModel.accreditationNumber, '0', '10');
        if (response.isSuccess) {
            this.providers = response.result;
            this.providersCount = response.totalCount;
        } else {
            Toaster.error(response.errorMessage);
        }
    }
    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.User);
    }

    @Watch('filterModel', { deep: true })
    public async search() {
        const response = await this.providerService.Get(this.filterModel.name,
            this.filterModel.crNumber, this.filterModel.accreditationNumber, this.filterModel.taxNumber, '0', '10');
        if (response.isSuccess) {
            this.providers = response.result;
            this.providersCount = response.totalCount;
        } else {
            Toaster.error(response.errorMessage);
        }
    }
    public showLockDialog(evt: any, id: string, isLocked: boolean) {
        evt.preventDefault();
        this.providerId = id;
        this.isLocked = !isLocked;
        this.lockDialog = true;
    }

    public hideLockDialog() {
        this.providerId = '';
        this.isLocked = false;
        this.lockDialog = false;
    }

    public isEqual(value1: string | null, value2: string) {
        return (value1 === '' || value1 === null || value1 === undefined || value2.includes(value1));
    }
    public showDeleteDialog(id: string) {
        this.providerId = id;
        this.deleteDialog = true;
    }
    public hideDeleteDialog() {
        this.deleteDialog = false;
    }
    public async deleteProvider() {
        this.deleteDialog = false;
        const response = await this.providerService.Delete(this.providerId);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.deleteShortSuccessfully').toString());
            this.$router.go(0);
        } else {
            Toaster.error(response.errorMessage);
        }
    }
    public async updatePaginate(val: any) {
        const response = await this.providerService.Get(this.filterModel.name,
            this.filterModel.crNumber, this.filterModel.accreditationNumber,
            this.filterModel.taxNumber, (val.page - 1).toString(), val.rowsPerPage);
        this.providers = response.result;
        this.providersCount = response.totalCount;
    }
    public async switchLock() {
        const response = await this.providerService.SwitchLock(this.providerId, this.isLocked);
        if (response.isSuccess) {
            this.hideLockDialog();
            Toaster.success(this.$t('shared.editProviderStatusSuccessfully').toString());
            this.search();
        } else {
            Toaster.error(response.errorMessage);
        }
    }
}
