import { Component, Vue } from 'vue-property-decorator';
import WithRender from './SenderRequestStatusDashboard.html';
import SenderRequestStatusDashboardService from './SenderRequestStatusDashboardService';
import { Inject } from 'vue-di-container';
import { Toaster } from '../../Services/toast';
import { filterModel } from './FilterModel';
import { SenderRequestKpiViewModel } from './SenderRequestKpiViewModel';
import LoaderService from '../../Services/LoaderService';
import FileSaver from 'file-saver';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        SenderRequestStatusDashboardService
    ],
})
export default class SenderRequestStatusDashboard extends Vue {
    @Inject(SenderRequestStatusDashboardService) public requestStatusDashboardService!: SenderRequestStatusDashboardService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public headers: any = [
        { text: this.$t('shared.requestId'), value: 'requestId', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.sender'), value: 'sender', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.client'), value: 'client', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.provider'), value: 'provider', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.entityType'), value: 'entityType', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.date'), value: 'date', align: 'center', class: "white--text", sortable: false },
        { text: this.$t('shared.operatorStatus'), value: 'operatorStatus', align: 'center', class: "white--text", sortable: false },
    ];
    public customerTypes: number[] = [];
    public allChecked: boolean = true;
    public hasMounted: boolean = false;
    public governmentChecked: boolean = true;
    public charityChecked: boolean = true;
    public privateChecked: boolean = true;
    public internationalChecked: boolean = true;
    public requests: SenderRequestKpiViewModel[] = [];
    public requestsCount: number = 0;
    public createdDateFromMenu: boolean = false;
    public createdDateToMenu: boolean = false;
    public dateFrom: string | any = '';
    public dateTo: string | any = '';
    public status: string | any = '';
    public tenant: string | any = '';
    public requestType: string | any = '';
    public pagination: any;
    model: filterModel = {
        searchText: '',
        customerTypes: [],
        dateTo: '',
        dateFrom: '',
        tenant: '',
        requestType: 0,
        pageIndex: 0,
        pageSize: 10,
        type: 0
    };
    public layoutSup: boolean | any;
    searchText: string = "";
    public async mounted() {
        this.hasMounted = true;
        this.assignParams();
        this.updatePaginate();
    }
    public async assignParams() {
        this.$emit('childinit', this.layoutSup);
        this.status = this.$route.query.status;
        this.dateTo = this.$route.query.dateTo;
        this.dateFrom = this.$route.query.dateFrom;
        this.tenant = this.$route.query.tenant;
        this.requestType = this.$route.query.requestType;
    }
    public async reset() {
        this.allChecked = true;
        this.governmentChecked = true;
        this.charityChecked = true;
        this.privateChecked = true;
        this.internationalChecked = true;
        this.searchText = '';
        this.status = this.$route.query.status;
        this.dateTo = this.$route.query.dateTo;
        this.dateFrom = this.$route.query.dateFrom;
        this.tenant = this.$route.query.tenant;
        this.requestType = this.$route.query.requestType;
        this.updatePaginate();
    }

    public async updatePaginate() {
        if (this.hasMounted === true) {
            if (!(this.status == '0' || this.status == '1' || this.status == '2' || this.status == '3' || this.status == '4')) {
                this.requests = [];
                this.requestsCount = 0;
                Toaster.error(this.$t('shared.sorryUnrecognizedRequestStatus').toString());
            }
            else if (!(this.requestType == '0' || this.requestType == '1' || this.requestType == '2')) {
                this.requests = [];
                this.requestsCount = 0;
                Toaster.error(this.$t('shared.sorryUnrecognizedRequestRequestType').toString());
            } else {
                this.customerTypes = [];
                if (this.allChecked) {
                    this.customerTypes.push(1);
                    this.customerTypes.push(2);
                    this.customerTypes.push(3);
                    this.customerTypes.push(4);
                } else {
                    if (this.governmentChecked == true) {
                        this.customerTypes.push(1);
                    }
                    if (this.charityChecked == true) {
                        this.customerTypes.push(2);
                    }
                    if (this.privateChecked == true) {
                        this.customerTypes.push(3);
                    }
                    if (this.internationalChecked == true) {
                        this.customerTypes.push(4);
                    }
                }
                this.model = {
                    searchText: this.searchText,
                    customerTypes: this.customerTypes,
                    type: Number(this.status),
                    dateFrom: this.dateFrom,
                    dateTo: this.dateTo,
                    pageIndex: (this.pagination.page - 1),
                    pageSize: this.pagination.rowsPerPage,
                    tenant: this.tenant,
                    requestType: Number(this.requestType),
                };
                if (this.model.type == 3 && this.headers.length !== 8) {
                    this.headers.push({ text: this.$t('shared.rejectionReason'), value: 'rejectionReason', align: 'center', class: "white--text", sortable: false });
                }
                const response = await this.requestStatusDashboardService.getRequests(this.model);
                if (!response.isSuccess) {
                    Toaster.error(response.errorMessage);
                }
                else {
                    this.requests = response.result;
                    this.requestsCount = response.totalCount;
                }
            }
        }
    }

    public getActivation(operatorId: string, SenderId: string, ProviderId: string) {
        let isActive = false;
        const senderElem = this.requests.filter((s) => s.senderId === SenderId && s.providerId === ProviderId)[0];
        if (senderElem.requestStatus.toString() === '4') {
            if (senderElem.senderConectivityDetail.filter((a) => a.operator === operatorId)) {
                const arrayForthisOperator = senderElem.senderConectivityDetail
                    .filter((a) => a.operator === operatorId
                        && (a.status === 2 || a.status === 1)).length;
                const approvedArrayForthisOperatorApproved = senderElem.senderConectivityDetail
                    .filter((a) => a.operator === operatorId && a.status === 2).length;
                if (approvedArrayForthisOperatorApproved > 0) {
                    isActive = true;
                }
            }
        }
        return isActive;
    }

    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }
    public async selectDateFrom() {
        this.createdDateFromMenu = false;
        this.updatePaginate();
    }
    public async selectDateTo() {
        this.createdDateToMenu = false;
        this.updatePaginate();
    }
    public async exportToExcel() {
        this.model = {
            searchText: this.searchText,
            customerTypes: this.customerTypes,
            type: Number(this.status),
            dateFrom: this.dateFrom,
            dateTo: this.dateTo,
            pageIndex: 0,
            pageSize: 2147483647,
            tenant: this.tenant,
            requestType: Number(this.requestType),
        };
        const response = await this.requestStatusDashboardService.exportToExcel
            (this.model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        } else {
            const array = Buffer.from(response.result, 'base64');
            const blob = new Blob([array], { type: 'application/vnd.ms-excel' });
            FileSaver.saveAs(blob, 'SenderRequestReport.xlsx');
        }
    }
}