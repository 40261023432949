import Vue from 'vue';
import VueI18n from 'vue-i18n';

Vue.use(VueI18n);

const messages = {
    en: {
        operator_Zain: 'Zain',
        operator_STC: 'STC',
        operator_Mobily: 'Mobily',
        shared: {
            tenant: 'Tenant',
            favorite: 'Favorite',
            unfavorite: 'Unfavorite',
            favoriteInputText:'Are you sure you want to  favorite this client?',
            unfavoriteInputText: 'Are you sure you want to  unfavorite this client?',
            clientFavoriteSuccessfully: 'Client Favorite Successfully',
            clientUnfavoriteSuccessfully:'Client Unfavorite Successfully',
            pendingForReview: 'Pending For Review',
            pendingForVerify:'Pending For Verify',
            thisSenderOriginallyOnlyHasWarningSenderType:'This Sender Originally Only Has Warning Sender Type.',
            renewalInfo: 'Renewal Info',
            sendersRenewal:'Senders Renewal',
            renewContractRequest:'Renew Contract Request',
            finalApprovedsuccessfully:'Final Approved Successfully',
            finalApproveInputText: 'Are you sure you want to Final Approve the selected Senders?',
            finalApproveInput: 'Final Approve',
            review:'review',
            action: 'Action',
            deleteHistory: 'Are you sure you want to delete this history?',
            deleteHistorySuccessfully:'Delete History Successfully',
            pendingPayment: 'Pending Payment',
            autoVerificationCompletedSuccessfully: 'Auto Verification Completed Successfully',
            representativeID: 'Representative ID',
            authorizationExpiryDate: 'Authorization ExpiryDate',
            crExpiryDate:'CR Expiry Date',
            addHistoryInputText: 'Are you sure you want to add this history?',
            addHistory:'Add History',
            requestHistorySuccessfully:'Request History Added Successfully.',
            historyDate: 'History Date',
            youMustSelectAtLeastOneRequestToChangeStatus: 'You must select at least one request to change status',
            manual: 'Manual',
            auto: 'Auto',
            addingType: 'Adding Type',
            autoActivationNote: 'When you choose auto that will be connect all short code based on( sender type and operator)',
            connectivityAdding: 'Connectivity Adding',
            reactivateInput: 'Reactivate',
            reactivateInputText: 'Are you sure you want to reactivate the selected Senders?',
            complaintID: 'Complaint ID',
            senderComplaintsList: 'Sender Complaints List',
            aPIUser330: '330API User',
            skipped: 'Skipped',
            contractExpiryDate: 'Contract Expiry Date',
            tenantName: 'Platform',
            attachmentList: 'Attachments List',
            pleaseAttachAtLeast1DocumentWithCategoryDelegationLetter: 'Please attach at least 1 document with category Delegation Letter',
            pleaseattachAtLeast2DocumentsWithCategoryDelegationLetterAndCommercialDocument: 'Please attach at least 2 documents with category Delegation Letter and Commercial document',
            pleaseAttachaAtLeast2DocumentsWithCategoryDelegationLetterAndHRSDLicense: 'Please attach at least 2 documents with category Delegation Letter and HRSD License',
            forAdditionalDocumentSelectTheAppropriateCategory: 'For additional document select the appropriate category',
            canNotAddArequestWithoutADelegationLetter: 'Can Not Add A Request Without A Delegation Letter',
            canNotAddArequestWithoutCommercialRegistrationNumber: 'Can Not Add A Request Without Commercial Registration Number',
            canNotAddArequestWithoutHRSDLicenseNumber: 'Can Not Add A Request Without HRSD License Number',
            noAttachments: 'No Attachments',
            attachment: 'Attachment',
            addCategory: 'Add Category',
            other: 'Other',
            providerStatus: 'Provider Status',
            expireDate: 'Expiry Date',
            switchAdminToSuper: 'Swtich Admin User To Super Admin User',
            switchAdminToSuperText: 'Are you Sure to Switch Admin User To Super Admin User ?',
            sorryUnrecognizedRequestType: 'Sorry Unrecognized Request Type',
            RequestType: 'Request Type :',
            operator: 'Operator :',
            Operator: 'Operator',
            senderEditedSuccessfully: 'Sender Edited Successfully',
            edit: 'Edit',
            editSenderStatusInputText: 'Are you sure you want edit sender status?',
            senderId: 'Sender Id',
            approved: 'Approved',
            rejected: 'Rejected',
            deactivated: 'Deactivated',
            expired: 'Expired',
            paid: 'Paid',
            RejectionReason: 'Rejection Reason',
            canceled: 'Canceled',
            senderExpiryDate: 'Sender Expiry Date',
            customDayMustBeLargerThanTheEnteredValue: 'Custom Day Must Be Larger Than The Entered Value',
            customDaySearchMustBeUsingANumber: 'Custom Day Search Must Be Using a Number.',
            scamAddSuccessfully: 'Scam Added Successfuly',
            deleteScamSuccessfully: 'Scam Deleted Successfuly',
            editScamSuccessfully: 'Scam Edited Successfuly',
            ScamManagement: "Scam Monitoring List",
            theMaximumSizeForTheAttachmentIs3Megabytes: 'The maximum size for the attachment is 3 megabytes',
            fileSizeExceedsTheLimit: 'File Size Exceeds The Limit.',
            days: ' Days',
            daysAndMore: ' Days and more',
            from: 'From : ',
            to: 'To :  ',
            pendingActivationRequests: 'Pending Activation Requests',
            operatorStatus: 'Operator Status',
            sorryUnrecognizedRequestStatus: 'Sorry! Unrecognized Request Status',
            requestsAlerts: 'Requests Alerts',
            allRequests: 'All Requests',
            allsenders: 'All senders',
            allActiveSenders: 'All Active Senders',
            PendingActivation: 'Pending Activation',
            PendingPaymentRequests: 'Pending Payment Requests',
            requestsAlertsPendingActivation: 'Requests Alerts - Pending Activation',
            requestsAlertsPendingPaymentRequests: 'Requests Alerts - Pending Payment Requests',
            requestsAlertsPendingApprovalRequests: 'Requests Alerts - Pending Approval Requests',
            custom: 'Custom',
            more: 'More',
            twenyfourDays: '24 days',
            tenDays: '10 days',
            twoDays: '2 days',
            PendingApprovalRequests: 'Pending Approval Requests',
            alerts: 'Alerts',
            paidRequests: 'Paid Requests',
            activatedRequests: 'Activated Requests',
            pendingApproval: 'Pending Approval',
            rejectedRequests: 'Rejected Requests',
            charities: 'Charities',
            governmental: 'Governmental',
            all: 'All',
            approvedRequests: 'Approved Requests',
            date: 'Date',
            entityType: 'Entity Type',
            client: 'Client',
            sender: 'Sender',
            senders: 'Senders',
            shortCodeEditDisabled: 'Short Code Type can not be edited because it is already connected to a sender',
            renewSender: 'Renew Sender',
            sadadPaymentDisclaimer: 'The invoice will be issued for ',
            sadadPaymentDisclaimerArabic: ' ستصدر الفاتورة لمزود الخدمة ',
            senderCriticality: 'Sender Criticality',
            notCritical: 'Not Critical',
            critical: 'Critical',
            criticalSender: 'Critical Sender',
            publicPaymentDisclaimer: 'لا تتحمل شركة حوسبة التقنية لتقنية المعلومات أي مسؤولية ناتجة عن بيانات العميل في النظام، بما في ذلك، على سبيل المثال لا الحصر، ما يتعلق بدقة أو صحة أي بيانات العميل. وفي جميع الأحوال، لا تتحمل شركة حوسبة التقنية لتقنية المعلومات أي مسؤولية، تجاه أي زوار أو أطراف ثالثة فيما يتعلق بالبيانات المدخلة من قبلهم.',
            sadadNumberCreatedSuccessfully: 'Sadad Number Created Successfully',
            sadadNumber: 'Sadad Number',
            getSadadNumber: 'Get Sadad Number',
            status: 'Status',
            lockProviderInput: 'Provider status change',
            lockProviderInputText: 'Are you sure you want to lock the selected provider?',
            unlockProviderInputText: 'Are you sure you want to unlock the selected provider?',
            editProviderStatusSuccessfully: 'Provider status edited successfully',
            extend: 'Extend',
            extendRequestTypeInput: 'Extend Request',
            extendInputText: 'Are you sure you want to extend duration for this senders?',
            showExtendRequest: 'Show Extend Request',
            extendRequest: 'Extend Request',
            extendDurationRequests: 'Extend Duration Requests',
            extendRequestedSuccessfully: 'Extend Requested Successfully',
            extendRequestedUpdatedSuccessfully: 'Extend Requested Updated Successfully',
            senderConnectivity: 'Sender Connectivity',
            company: 'Company',
            zain: 'Zain',
            mobily: 'Mobily',
            sTC: 'STC',
            exportToExcel: 'Export To Excel',
            selectCompany: 'Select Company',
            balanceInvoice: 'Balance Invoice',
            invoiceCreatedSuccessfully: 'Invoice Created Successfully',
            createInvoiceInputText: 'Do you want to issue a balance invoice for this transaction?',
            activation: 'Activation',
            isActive: 'Is Active',
            activationDate: 'Activation Date',
            activationInformation: 'Activation Information',
            trackRequest: 'Track Request',
            rejectionReasonOrComment: 'Rejection Reason/Comment',
            comment: 'Comment',
            mobilyActivationDate: 'Mobily Activation Date',
            sTCActivationDate: 'STC Activation Date',
            zainActivationDate: 'Zain Activation Date',
            requestId: 'Request Id',
            connectivityActivationReport: 'Connectivity Activation Report',
            connectivityStatus: 'Connectivity Status',
            SenderRequestActionsInput: 'Request Actions',
            showSenderRequestActions: 'Show Sender Request Actions',
            numberOfActions: 'Number Of Actions',
            senderRequestPaymentTransactions: 'Sender Request Payment Transactions',
            paymentStatus: 'Payment Status',
            senderRequestApprovalHistory: 'Sender Request Approval History',
            transactionReference: 'Transaction Reference',
            checkOutId: 'Checkout Id',
            creditLimitPayment: 'CreditLimit Payment',
            isMada: 'Is Mada',
            userId: 'User Id',
            senderRequestSupport: 'Sender Request Support',
            requestType: 'Request Type',
            changedProperty: 'Changed Property',
            approvalHistory: 'Approval History',
            paymentTransactions: 'Payment Transactions',
            slaNote1: '* Requests of three characters or less require more time, up to 5 working days',
            slaNote2:
                '** If the operator or system developer requires more time, then Hawsaba will notify the client - within 2 working days- with updated response time.',
            problemsWithASpecificSIDOrLinkWithOperators: 'problems with a specific SID or link with operators',
            workingDays2: '2 working days**',
            technicalSupport: 'Technical support',
            workingDay2: '2 working days*',
            processingARequestWithAcceptanceOrRejectionBasedOnTheRegistrationCriteria:
                'Processing a request with acceptance or rejection based on the registration criteria',
            sIDRequest: 'SID Request',
            three: '3',
            two: '2',
            one: '1',
            workingDay1: '1 working day',
            inquiriesAboutTheSystemOrAboutAdmissionCriteria: 'Inquiries about the system or about admission criteria',
            inquiries: 'Inquiries',
            description: 'Description',
            responseTime: 'Response Time',
            sla: 'SLA',
            paymentDate: 'Payment Date',
            expiryDate: 'Expiry Date',
            creditCardVisaMastercard: 'Credit Card (Visa,Mastercard)',
            debitCardMada: 'Debit Card(Mada)',
            createPaymentLinkVisaMastercard: 'Create Payment Link (Visa,Mastercard)',
            createPaymentLinkMada: 'Create Payment Link (Mada)',
            details: 'Details',
            provider: 'Provider',
            convertToAdded: 'Convert To Added',
            markAsAddedInputText: 'Are you sure you want to convert status of the selected Connectivity to added?',
            convertToDeleted: 'Convert To Deleted',
            markAsDeletedInputText: 'Are you sure you want to convert status of the selected Connectivity to Deleted?',
            resendToOperator: 'Resend To Operator',
            resendToOperatorInputText: 'Are you sure you want to Resend the selected Connectivity to Operator?',
            convert: 'Convert',
            resend: 'Resend',
            updateRequestDataInputText: 'Are you sure you want to Update Sender Request Data?',
            updateDataRequests: 'Update Data Requests',
            updateRequestData: 'Update Request Data',
            updateContractRequest: 'Edit Contract Request',
            Paid: 'Paid',
            myProfileInfo: 'My Profile Information',
            myProfile: 'My Profile',
            createddate: 'Created Date',
            InvalidMobileNumber: 'Invalid Mobile Number',
            InvalidIdNumber: 'Invalid Id Number',
            invoiceUnderProcessing: 'Invoice Under Processing',
            invoiceNumber: 'Invoice Number',
            FaildToEnterPaymentPage: 'Not all senders names are ready to pay',
            organizationName: 'Organization Name',
            clientInformation: 'Client Information',
            ToAddTypeAdvertisementSendernameShouldBeLessThanOrEqual8: 'To Add Type Advertisement Sender name Should Be Less Than Or Equal 8',
            ToAddTypeServiceWithCustomerTypeInternationalSendernameShouldBeLessThanOrEqual8: 'To Add Type Service With Customer Type International Sender name Should Be Less Than Or Equal 9',
            copyLink: 'Copy Link',
            createPaymentLink: 'Create Payment Link',
            buyerName: 'Buyer Name',
            buyerCountry: 'Buyer Country',
            buyerCity: 'Buyer City',
            buyerDistrict: 'Buyer District',
            buyerStreetName: 'Buyer Street Name',
            buyerBuildingNumber: 'Buyer Building Number',
            buyerVatNumber: 'Buyer VatNumber',
            buyerPostalCode: 'Buyer PostalCode',
            buyerAdditionalNumber: 'Buyer Additional Number',
            buyerOtherIdentifier: 'Buyer Other Identifier',
            editProviderFinanceInfo: 'Edit Provider Finance Information',
            providersFinance: 'Providers Finance Info',
            BalanceInvoice: 'Balance Invoices',
            balance: 'Balance',
            Go: 'Go',
            CreditLimitTransaction: 'Credit Limit Transaction',
            sendersName: 'Sender Name',
            paymentdate: 'Payment Date',
            creditLimitAmount: 'Credit Limit Amount',
            fellInfo: 'can you fill the following information about the provider',
            reLogin: 'Re-Login',
            amount: 'Amount',
            Invoices: 'Invoices',
            InvoicesNumber: 'Invoices Number',
            invoicesList: 'Invoices List',
            ConverttoAdded: 'Convert to Added',
            ConverttoDeleted: 'Convert to Deleted',
            ConvertStatus: 'Convert Status',
            lastModifiedDate: 'Last Modified Date',
            addSuccessfully: 'Added Successfully',
            deleteSuccessfully: 'Deleted Successfully',
            changeSenderTypeRequests: 'Change Sender Type Requests',
            renewRequest: 'Renew Request',
            renewRequestTypeInput: 'Renew Request',
            renewInputText: 'Are you sure you want to Renew this Sender Request?',
            renewRequestSuccessfully: 'Renewed Successfully',
            Search: 'Search',
            CITCKeywordsList: 'Keywords List',
            addCITCKeywords: 'Add  Keyword',
            editKeyword: 'Edit Keyword',
            addNewCITCKeywords: 'Add New Keyword',
            CITCKeywords: ' Keyword',
            AttachmentCategoryList: 'Attachments Categories List',
            AttachCategoryCharValidation: 'Category Name must be between 5-128 character',
            addAttachmentCategory: 'Add  Category',
            editCategory: 'Edit Category',
            addNewAttachmentCategory: 'Add New Category',
            AttachmentCategory: ' Attachment Category',
            AttachmentCategoryName: ' Category Name',
            GotoDashboard: 'Go to Dashboard',
            ConvertedSuccessfully: 'Converted Successfully',
            SenderConnectivitySupport: 'Sender Connectivity Support',
            SupportPaymentTransaction: 'Payment Transaction',
            paymentReference: 'Payment Reference',
            shortcode: 'Short Code',
            senderName: 'Sender Name',
            senderNameShouldNotBeOneOfTheKeywords: 'Sender Name Could Not Be One Of The Keywords',
            senderConectivityDetail: 'Sender Connectivity Detail',
            licenseNumber: 'HRSD License Number',
            internationalCommercialRegistrationNumber: 'International Commercial Registration Number',
            commercialRegistrationNumber: 'Commercial Registration Number',
            AddProviderRequest: 'Add Provider Request',
            unLock: 'Unlock',
            users: 'Users',
            lock: 'Lock',
            providerRequests: 'Provider Requests',
            providerRequestInfo: 'Provider Request Info',
            apply: 'Apply',
            exporttoexcel: 'Export To Excel',
            rejectionResson: 'Rejection Reason',
            ApprovedRequestSuccessfully: 'Provider Request Has been Approved successfully',
            RejectedRequestSuccessfully: 'Provider Request Has been Rejected successfully',
            email: 'Email',
            addConectivitySuccessfully: 'Add Connectivity is successfully',
            resentSuccessfully: 'Resent Successfully',
            resendtooperator: 'Resend To Operator',
            AddUsersuccessfully: 'Add User Successfully',
            lockInput: 'User status change',
            lockInputText: 'Are you sure you want to lock the selected user?',
            unlockInputText: 'Are you sure you want to unlock the selected user?',
            addNewConectivity: 'Add New Connectivity',
            addRequest: 'Add Request',
            addResponse: 'Add Response',
            deleteRequest: 'Delete Request',
            deleteResponse: 'Delete Response',
            Conectivitylog: 'Connectivity log',
            selectAll: 'Select All',
            conectivityStatus: 'Connectivity Status',
            deleted: 'Deleted',
            pendingDeletion: 'Pending Deletion',
            confirm: 'Confirm',
            added: 'Added',
            pendingAddition: 'Pending Addition',
            deleteConectivitySuccessfully: 'Delete Connectivity is successfully',
            senderType: 'Sender Type',
            Expired: 'Expired',
            Deleted: 'Deleted',
            Deactivated: 'Deactivated',
            Approved: 'Approved',
            Rejected: 'Rejected',
            PendingApproval: 'Pending Approval',
            PendingForVerify: 'Pending Verify',
            senderStatus: 'Sender Status',
            Report: 'Report',
            customerType: 'Customer Type',
            approveDateFrom: 'Approve Date From',
            createdDate: 'Created Date',
            approveDateTo: 'Approve Date To',
            createdDateTo: 'Created Date To',
            createdDateFrom: 'Created Date From',
            customerId: 'Customer Id',
            MSISDN: 'MSISDN (SAN)',
            unifiedNumber: 'Unified Number',
            government: 'Government',
            charity: 'Charity',
            errorSenderNameDuplicate: 'Sender name already exists',
            private: 'Private',
            international: 'International',
            advertisement: 'Advertisement',
            service: 'Service',
            awareness: 'Awareness',
            warning: 'Warning',
            errorSystem: 'An error occurred in the system',
            errorSenderNameEnd: 'The Sender name must not end in -AD or -I',
            PaymentSuccess: 'Payment Success !',
            editSenderSuccessfully: 'Edit Sender Is Successfully',
            senderNameShouldbenumbersandChar: 'Sender Name Should Contains  Numbers & Characters',
            senderNameShouldbe2OrmoreChar: 'Minimum Length of the sender is 2 characters',
            PaymentFaild: 'Payment Faild !',
            PaymentFaildMsg: 'If the amount was deducted, please contact the admin',
            errorUserCertificate: 'Not same user, Please Re-Login the current user',
            userCertificate: 'User Certificate',
            language: 'العربية',
            dashboard: 'Dashboard',
            providers: 'Providers',
            operators: 'Operators',
            selectStatus: 'Select Status',
            shorts: 'Short Codes',
            userManagement: 'User Management',
            DashboardApprovedRequests: 'Chart Dashboard',
            rowsPerPage: 'Rows Per Page',
            newUser: 'New user',
            editUser: 'Edit user',
            name: 'Name',
            userName: 'User Name',
            userEmail: 'User Email',
            phoneNumber: 'Phone Number',
            phoneNumberAdmin: 'Provider Admin Phone Number',
            userNameAdmin: 'Provider Admin User Name',
            country: 'Country',
            city: 'City',
            district: 'District',
            streetName: 'Street Name',
            buildingNumber: 'Building Number',
            postalCode: 'Postal Code',
            vatNumber: 'VAT Number',
            additionalNumber: 'Additional Number',
            otherIdentifier: 'Other ID',
            userEmailAdmin: 'Provider Admin User Email',
            type: 'Type',
            selectOperator: 'Select Operator',
            selectProvider: 'Select Provider',
            requestStatus: 'Request Status',
            selectType: 'Select Type',
            save: 'Save',
            userInfo: 'User information',
            providerName: 'Provider Name',
            operatorName: 'Operator Name',
            editInfo: 'Edit Info',
            actions: 'Actions',
            deleteInput: 'Delete',
            deleteInputText: 'Are you sure you want to delete the selected item?',
            resendPassword: 'Resend Password',
            resendPasswordText: 'Are you sure you want to resend the password of the selected user?',
            deactivateInput: 'Deactivate',
            approveInput: 'Approve',
            resendInput: 'Resend',
            cancel: 'Cancel',
            delete: 'Delete',
            addProvider: 'Add Provider',
            editProvider: 'Edit Provider',
            add: 'Add',
            clientName: 'Client Name',
            crNumber: 'CR Number',
            budgetNumber: 'Budget Number',
            accreditationNumber: 'CITC License Number',
            taxNumber: 'Tax Registration Number',
            hasInternational: 'Request International Sender',
            representativeName: 'Representative Name',
            enterpriseUnifiedNumber: 'Enterprise Unified Number',
            startDate: 'Start date',
            durationYear: 'Duration ( Year)',
            representativeEmail: 'Representative Email',
            attachDelegationLetter: 'Attach Delegation Letter',
            attachContract: 'Attach Contract',
            delegationletter: 'Delegation Letter',
            attacDocument: 'Attach Document',
            attachRegistrationDocument: 'Attach Registration Document',
            attachUnifiedNumberDocument: 'Attach Unified Number Document',
            attachbudgetNumberDocument: 'Attach Budget Number Document',
            attachlicenseNumberDocument: 'Attach HRSD License Number Document',
            attachinternationalCommercialRegistrationNumberDocument: 'Attach International Commercial Registration Number Document',
            attachcommercialRegistrationNumberDocument: 'Attach Commercial Registration Number Document',
            additionalAttachments: 'Additional Attachments',
            AlreadyActivated: 'Are the sender name and the shortcodes already activated in the operators database before launching this platform?',
            AlreadyActivatedAR: 'هل اسم المرسل و الرموز القصيرة مفعلة في أنظمة قواعد بيانات المشغلين قبل إطلاق هذه المنصة الإلكترونية؟',
            isAlreadyExist: 'I attest that the sender has been already activated before launching the system. As a result the sender will not be sent to operators for activation',
            optional: '(Optional)',
            attach: 'Attach',
            sendersList: 'Senders list',
            NotAllSenderApprovedOrPaid: 'Not All Selected Senders Approved Or Paid',
            sureAddConnectivity: 'Are you sure to add this Connectivity?',
            deactivateInputText: 'Are you sure you want to deactivate the selected Senders?',
            deletesenderInputText: 'Are you sure you want to Delete the selected Senders?',
            resendInputText: 'Are you sure you want to Resend the selected Senders?',
            resendContractInputText: 'Are you sure you want to Resend the selected Contract Requests?',
            approveInputText: 'Are you sure you want to Approve the selected Senders?',
            approveContractInputText: 'Are you sure you want to approve the selected contract?',
            approveProviderRequestInputText: 'Are you sure you want to Approve this Request?',
            editProviderInputText: 'Are you sure you want to Edit Provider?',
            editKeywordInputText: 'Are you sure you want to Edit Keyword?',
            addKeywordInputText: 'Are you sure you want to Add Keyword?',
            editCategoryInputText: 'Are you sure you want to Edit Category?',
            addCategoryInputText: 'Are you sure you want to Add Category?',
            editUserInputText: 'Are you sure you want to Edit User?',
            addUserInputText: 'Are you sure you want to Add User?',
            addInput: 'Add',
            addShortCodeInputText: 'Are you sure you want to Add Short Code?',
            editShortCodeInputText: 'Are you sure you want to Edit Short Code?',
            addsubmitRequestInputText: 'Are you sure you want to Add Request?',
            addsubmitContractRequestInputText: 'Are you sure you want to Add Contract Request?',
            updateContractRequestInputText: 'Are you sure you want to edit Contract Request?',
            addsubmitRenewContractRequestInputText: 'Are you sure you want to renew Contract Request?',
            editSenderInputText: 'Are you sure you want to Edit Sender ?',
            editInput: 'Edit',
            reset: 'Reset',
            next: 'Next',
            back: 'Back',
            stcShortCodes: 'STC ShortCodes',
            mobileAccounts: 'Mobile Accounts',
            zainShortCodes: 'Zain ShortCodes',
            addToList: 'Add to list',
            zainSC: 'Zain SC',
            mobilySC: 'Mobily SC',
            stcSC: 'STC SC',
            apEmail: 'AP Email',
            sendersShortCodesList: 'Senders/ShortCodes List',
            duration: 'Duration',
            year: 'Year',
            submit: 'Submit',
            requestSubmittedSuccessfully: 'Request submitted successfully',
            newRequest: 'New request',
            requestsListPage: 'Requests list page',
            editRequest: 'Edit Request',
            changeSenderTypeInput: 'Change Sender Type',
            changeSenderTypeInputText: 'Are you sure you want to Change Sender Type?',
            changeSenderType: 'Change Sender Type',
            request: 'Request',
            actionHere: 'Action here',
            payment: 'Payment',
            cardNumber: 'Card number',
            exDate: 'Ex. Date',
            nameCard: 'Name on the card',
            orderSummary: 'Order Summary',
            CreditLimit: 'Credit Limit',
            CreditCard: 'Credit Card',
            PaymentOptions: 'Payment Options',
            paidSuccessfully: 'Paid Successfully',
            subtotal: 'Subtotal',
            total: 'Total',
            paymentInformation: 'Payment information',
            payNaw: 'Pay Now',
            privacy: 'Privacy',
            termsOfUse: 'Terms of use',
            copyRight: 'All rights is reserved for T2 © MSG Stack',
            deleteShortSuccessfully: 'Delete Short Code is successfully',
            addShortSuccessfully: 'Add Short Code is successfully',
            updateShortSuccessfully: 'Update Short Code is successfully',
            addProviderSuccessfully: 'Add Provider is successfully',
            editProviderSuccessfully: 'Edit Provider is successfully',
            Ok: 'Ok',
            noSenderNames: 'No Sender Names',
            select: 'Select',
            noData: 'No Data',
            logOut: 'Logout',
            logOutText: 'Are you sure you want to Logout?',
            yes: 'Yes',
            no: 'No',
            rejectionReason: 'Rejection Reason',
            paymentDateFrom: 'Payment Date From',
            paymentDateTo: 'Payment Date To',
            clientInfo: 'Client Info',
            contractInfo: 'Contract Info',
            reviewRequest: 'Review Request',
            connectingSenderName: 'Connecting Sender Name',
            connectingRequestDashboard: 'Connecting Request Dashboard',
            check: 'check',
            representativeIDNumber: 'Representative Id Number',
            verificationType: 'Verification Type',
            automatic: 'Automatic Verification',
        },
        rejectionReason: {
            addNewReason: 'Add New Reason',
            requestType: 'Request Type',
            rejectionText: 'Rejection Reason',
            rejectionReasonList: 'Rejection Reason List',
            editRejectionReasonSuccessfully: 'Edit Rejection Reason Successfully',
            addRejectionReasonSuccessfully: 'Add Rejection Reason Successfully',
            deleteRejectionReasonSuccessfully: 'Delete Rejection Reason Successfully'
        },
        senderComplaints: {
            notAllSenderAreDeactivated: 'Not All Sender Are Deactivated',
            complaintId: 'Complaint Id',
            reactivate: 'Reactivate',
            providerName: 'Provider Name',
            provider: 'Provider',
            createdDate: 'Created Date',
            requestDate: 'Request Date',
            reactivatedsuccessfully: 'Reactivated Successfully',
            deactivate: 'Deactivate',
            deactivated: 'Deactivated',
            requests: 'Requests',
            senders: 'Senders',
            activated: 'Activated',
            senderNameSearch: 'Sender name',
            status: 'Status',
            of: 'of',
            daysLeft: 'days left',
            senderName: 'Sender Name',
            newRequest: 'New request',
            clientName: 'Client Name',
            details: 'Details',
        },
        loginPage: {
            aPIUser330: 'Users With 330API User Type Can Not Login',
            logoTextMain: 'MSG Stack',
            serviceuser: 'Users With Service Type Can Not Login',
            cstTaguser: 'Users With CST Tag Name Type Can Not Login',
            logoTextSub: 'SMS Management system',
            loginHeader: 'Login to my account',
            loginNote1: 'Please, use your account credential to login to the platform',
            verfiyOTP: 'Verfiy OTP',
            resendOtp: 'Resend OTP',
            invalidOTP: 'Invalid OTP',
            enterOtp: 'Please, please Enter Otp to login to the platform',
            OtpReminingTime: 'OTP Will be expired after',
            invalidResponseTime: 'Invalid response time',
            unableToLogin: 'Unable To Login',
            userName: 'User Name',
            password: 'Password',
            currentPassword: 'Current Password',
            newPassword: 'New Password',
            confirmPassword: 'Confirm Password',
            canNotLogin: 'I can’t login to my account',
            login: 'Login',
            restoreMyPassword: 'Restore my password',
            changePassword: 'Change Password',
            restoreNote1: 'We will reset the password for your account by sending a new password to your email through this process.',
            restoreNote2: 'Do want to proceed?',
            backToLoginForm: 'Back to login form',
            yesUnderstand: 'Yes, I understand',
            pleaseInsertEmail: 'Please, insert your User Name',
            email: 'Email',
            sendVerificationCode: 'Send verification code',
            sendPassword: 'Send Password',
            verificationNote1: 'Verification code has been send to your email',
            verificationNote2: 'Please, insert the verification code',
            resendCodeAgain: 'Resend Code again',
            verify: 'Verify',
            setPasswordNote1: 'Verification code verified',
            setPasswordNote2: 'Please, insert your new password',
            setNewPassword: 'Set new password',
            passwordResetedSuccessfully: 'Password reseted successfully',
            backToLogin: 'Back to login',
            updateShortCodeType: 'The short code has been updated and a short code type has been added to it. Please view and modify it through its own page',
        },
        dashboardPage: {
            finalApprove:'Final Approve',
            review: 'review',
            someContractsAreMergeInserted: 'Some Contracts Are Merge Inserted',
            senderExpiryDate: 'Sender Expiry',
            expiryDate: 'Expiry Date',
            comment: 'Comment',
            active: 'Active',
            verify: 'Verify',
            requestId: 'Request Id',
            providerName: 'Provider Name',
            provider: 'Provider',
            createdDate: 'Created Date',
            requestDate: 'Request Date',
            all: 'All',
            approved: 'Approved',
            paid: 'Paid',
            pendingForVerify: 'Pending For Verify',
            deactivate: 'Deactivate',
            deactivated: 'Deactivated',
            expired: 'Expired',
            requests: 'Requests',
            senders: 'Senders',
            thisMonth: 'This month',
            partially: 'Partially',
            edit: 'Edit',
            ConnectivityDetail: 'Connectivity Detail',
            activated: 'Activated',
            inactive: 'Inactive',
            pendingFor: 'Pending for',
            payment: 'Payment',
            approval: 'Approval',
            rejected: 'Rejected',
            senderNameSearch: 'Sender name',
            NotAllSenderActivateOrPartiallyActivated: 'Not All selected Senders are Activate Or Partially Activated',
            representativeName: 'Representative Name',
            representativeMobile: 'Representative Mobile',
            deactivatedsuccessfully: 'Deactivated Successfully',
            status: 'Status',
            of: 'of',
            daysLeft: 'days left',
            renew: 'Renew',
            pause: 'Pause',
            sendRemainder: 'Send remainder',
            terminate: 'Terminate',
            senderName: 'Sender Name',
            newRequest: 'New request',
            clientName: 'Client Name',
            operatorStatus: 'Operator Status',
            details: 'Details',
            Approve: 'Approve',
            reject: 'Reject',
            Pay: 'Pay',
            NotAllSenderCanBeRejected:'Not All Selected Senders Can Be Rejected',
            NotAllSenderPendingApproval: 'Not All Selected Senders Are Pending for Approval',
            Approvedsuccessfully: 'Senders Has been Approved successfully',
            Rejectedsuccessfully: 'Senders Has been Rejected successfully',
            NotAllSenderRequestRelatedToThisTenant: 'Not All Sender Requests Related To This Tenant',
            NotAllSenderPendingPay: 'Not All Selected Senders Are Pending for Pay',
            Paysuccessfully: 'Paid successfully',
            rejectreason: 'Reject Reason',
            resend: 'Resend',
            exportToExcel: 'Export All Senders To Excel',
            exportRequestsToExcel: 'Export All Requests To Excel',
            NotAllSenderRejected: 'Not All Selected Sender status Rejected',
            resentSuccessfully: 'Resent Successfully',
            checkSender: 'Check',
        },
        userManagementPage: {
            users: 'Users',
            providerName: 'Provider Name',
            OperatorName: 'Operator Name',
            UserPassword: 'User Password',
            AddUserSuccessfully: 'User Added Successfully',
            EditUserSuccessfully: 'User edited  successfully',
            requestSubmittedSuccessfully: 'The request has been submitted successfully',
            EditUserStatusSuccessfully: 'User status edited successfully',
            resendPasswordEmailSuccessfully: 'Sent password by email successfully',
        },
        extendDurationRequestsPage: {
            renew: 'Renew',
            extendRequestId: 'Extend Request Id',
            extendDuration: 'Extend Duration',
            pay: 'Pay',
            senderName: 'Sender Name',
            requestDate: 'Request Date',
            status: 'Status',
            actions: 'Actions',
            extendDurationRequests: 'Extend Duration Requests',
            requests: 'Requests',
        },
        providerPage: {
            providersList: 'Providers list',
            addNewProvider: 'Add new provider',
            providerSearch: 'Provider name, ID search',
            addingDate: 'Adding Date',
        },
        shortPage: {
            shortCodeList: 'Short code list',
            addNewShortCode: 'Add new short code',
            company: 'Company',
            shortCode: 'Short code',
            addShortCode: 'Add short code',
            editShortCode: 'Edit short code',
            companyName: 'Company Name',
            selectCompany: 'Select company',
            selectShortCode: 'Select short code',
            operatorSearch: 'Operator name, ID search',
            shortcodeSearch: 'short Code name, ID search',
            shortcodeType: 'Short Code Type',
            selectType: 'Select short code type',
            whiteList: 'White List',
            blackList: 'Black List',
        },
        senderInfo: {
            comment: 'Comment',
            requestStatus: 'Request Status',
            createdDate: 'Date',
            rejectionReason: 'Rejection Reason',
            extendRequestId: 'Extend Request Id',
            duration: 'Duration'
        },
        scamPage: {
            scamList: "Scam List",
            newScamList: "New Scam List",
            senderName: 'Sender Name',
            clientName: 'Client Name',
            scamId: 'ScamId'
        },
        bulkshortPage: {
            shortCodeConnectivityRequest: "Shortcode Connectivity Request",
            selectConnectivityStatus: "Select Connectivity Status",
            selectRequestStatus: 'Select Request Status',
            addNewConnectivity: 'Add New Connectivity',
            added: 'Added',
            pendingAddition: 'Pending Addition',
            completed: 'Completed',
            inProgress: 'In Progress',
            operatorName: 'Operator Name',
            shortCode: 'ShortCode',
            senderTotal: 'Sender Total',
            senderSkipped: 'Sender Skipped',
            of: 'Of',
            senderLeft: 'Sender Left',
            ok: 'Ok',
            shortcodeType: 'Shortcode Type',
            shortcode: 'Shortcode',
            selectOperator: 'Select Operator',
            selectType: 'Select Type',
            selectShortCode: 'Select Shortcode',
            requestStatus: 'Request Status',
            connectivityStatus: 'Connectivity Status',
            addNewConnectingRequestServiceSuccessfully: 'Add New Connecting Request Successfully',
            shortcodeConnectingRequest: 'Shortcode Connecting Request',
            linkInfo: 'Link Info',
        }
    },
    ar: {
        operator_1: 'Zain',
        operator_2: 'STC',
        operator_3: 'Mobily',
        shared: {
            tenant:'Tenant',
            favorite: 'Favorite',
            unfavorite: 'Unfavorite',
            favoriteInputText: 'Are you sure you want to  favorite this client?',
            unfavoriteInputText: 'Are you sure you want to  unfavorite this client?',
            clientFavoriteSuccessfully: 'Client Favorite Successfully',
            clientUnfavoriteSuccessfully: 'Client Unfavorite Successfully',
            pendingForReview: 'Pending For Review',
            pendingForVerify: 'Pending For Verify',
            thisSenderOriginallyOnlyHasWarningSenderType: 'This Sender Originally Only Has Warning Sender Type.',
            finalApprovedsuccessfully: 'Final Approved Successfully',
            finalApproveInputText: 'Are you sure you want to Final Approve the selected Senders?',
            finalApproveInput: 'Final Approve',
            review: 'review',
            renewContractRequest: 'Renew Contract Request',
            renewalInfo: 'Renewal Info',
            sendersRenewal: 'Senders Renewal',
            action: 'Action',
            deleteHistory: 'Are you sure you want to delete this history?',
            deleteHistorySuccessfully: 'Delete History Successfully',
            pendingPayment:'Pending Payment',
            autoVerificationCompletedSuccessfully: 'Auto Verification Completed Successfully',
            representativeID: 'Representative ID',
            authorizationExpiryDate: 'Authorization ExpiryDate',
            addHistoryInputText: 'Are you sure you want to add this history?',
            addHistory: 'Add History',
            requestHistorySuccessfully: 'Request History Added Successfully.',
            historyDate: 'History Date',
            manual: 'Manual',
            auto: 'Auto',
            addingType: 'Adding Type',
            Operator: 'Operator',
            autoActivationNote: 'When you choose auto that will be connect all short code based on( sender type and operator)',
            connectivityAdding: 'Connectivity Adding',
            reactivateInput: 'Reactivate',
            reactivateInputText: 'Are you sure you want to reactivate the selected Senders?',
            complaintID: 'Complaint ID',
            senderComplaintsList: 'Sender Complaints List',
            aPIUser330: '330API User',
            skipped: 'Skipped',
            contractExpiryDate: 'Contract Expiry Date',
            attachmentList: 'Attachments List',
            canNotAddArequestWithoutADelegationLetter: 'Can Not Add A Request Without A Delegation Letter',
            canNotAddArequestWithoutCommercialRegistrationNumber: 'Can Not Add A Request Without Commercial Registration Number',
            canNotAddArequestWithoutHRSDLicenseNumber: 'Can Not Add A Request Without HRSD License Number',
            attachment: 'Attachment',
            noAttachments: 'No Attachments',
            addCategory: 'Add Category',
            other: 'Other',
            providerStatus: 'Provider Status',
            expireDate: 'Expiry Date',
            RejectionReason: 'Rejection Reason',
            canceled: 'Canceled',
            senderExpiryDate: 'Sender Expiry Date',
            customDayMustBeLargerThanTheEnteredValue: 'Custom Day Must Be Larger Than The Entered Value',
            customDaySearchMustBeUsingANumber: 'Custom Day Search Must Be Using a Number.',
            scamAddSuccessfully: 'Scam Added Successfuly',
            deleteScamSuccessfully: 'Scam Deleted Successfuly',
            editScamSuccessfully: 'Scam Edited Successfuly',
            theMaximumSizeForTheAttachmentIs3Megabytes: 'The maximum size for the attachment is 3 megabytes',
            fileSizeExceedsTheLimit: 'File Size Exceeds The Limit',
            days: ' Days',
            daysAndMore: ' Days and more',
            from: 'From : ',
            to: 'To :  ',
            allActiveSenders: 'All Active Senders',
            pendingActivationRequests: 'Pending Activation Requests',
            renewSender: 'Renew Sender',
            operatorStatus: 'Operator Status',
            sorryUnrecognizedRequestStatus: 'Sorry! Unrecognized Request Status',
            shortCodeEditDisabled: 'Short Code Type can not be edited because it is already connected to a sender',
            senderCriticality: 'Sender Criticality',
            notCritical: 'Not Critical',
            critical: 'Critical',
            criticalSender: 'Critical Sender',
            publicPaymentDisclaimer: 'لا تتحمل شركة حوسبة التقنية لتقنية المعلومات أي مسؤولية ناتجة عن بيانات العميل في النظام، بما في ذلك، على سبيل المثال لا الحصر، ما يتعلق بدقة أو صحة أي بيانات العميل. وفي جميع الأحوال، لا تتحمل شركة حوسبة التقنية لتقنية المعلومات أي مسؤولية، تجاه أي زوار أو أطراف ثالثة فيما يتعلق بالبيانات المدخلة من قبلهم.',
            sadadNumberCreatedSuccessfully: 'Sadad Number Created Successfully',
            sadadNumber: 'Sadad Number',
            getSadadNumber: 'Get Sadad Number',
            status: 'Status',
            lockProviderInput: 'Provider status change',
            lockProviderInputText: 'Are you sure you want to lock the selected provider?',
            unlockProviderInputText: 'Are you sure you want to unlock the selected provider?',
            editProviderStatusSuccessfully: 'Provider status edited successfully',
            extend: 'Extend',
            extendRequestTypeInput: 'Extend Request',
            extendInputText: 'Are you sure you want to extend duration for this senders ?',
            showExtendRequest: 'Show Extend Request',
            extendRequest: 'Extend Request',
            extendDurationRequests: 'Extend Duration Requests',
            extendRequestedSuccessfully: 'Extend Requested Successfully',
            senderConnectivity: 'Sender Connectivity',
            company: 'Company',
            zain: 'Zain',
            mobily: 'Mobily',
            sTC: 'STC',
            exportToExcel: 'Export To Excel',
            selectCompany: 'Select Company',
            balanceInvoice: 'Balance Invoice',
            invoiceCreatedSuccessfully: 'Invoice Created Successfully',
            createInvoiceInputText: 'Do you want to issue a balance invoice for this transaction?',
            activation: 'Activation',
            isActive: 'Is Active',
            activationDate: 'Activation Date',
            activationInformation: 'Activation Information',
            trackRequest: 'Track Request',
            rejectionReasonOrComment: 'Rejection Reason/Comment',
            comment: 'Comment',
            mobilyActivationDate: 'Mobily Activation Date',
            sTCActivationDate: 'STC Activation Date',
            zainActivationDate: 'Zain Activation Date',
            requestId: 'Request Id',
            connectivityActivationReport: 'Connectivity Activation Report',
            connectivityStatus: 'Connectivity Status',
            numberOfActions: 'Number Of Actions',
            SenderRequestActionsInput: 'Request Actions',
            showSenderRequestActions: 'Show Sender Request Actions',
            senderRequestPaymentTransactions: 'Sender Request Payment Transactions',
            paymentStatus: 'Payment Status',
            senderRequestApprovalHistory: 'Sender Request Approval History',
            transactionReference: 'Transaction Reference',
            checkOutId: 'Checkout Id',
            creditLimitPayment: 'CreditLimit Payment',
            isMada: 'Is Mada',
            userId: 'User Id',
            senderRequestSupport: 'Sender Request Support',
            requestType: 'Request Type',
            changedProperty: 'Changed Property',
            approvalHistory: 'Approval History',
            paymentTransactions: 'Payment Transactions',
            creditCardVisaMastercard: 'Credit Card (Visa,Mastercard)',
            debitCardMada: 'Debit Card(Mada)',
            createPaymentLinkVisaMastercard: 'Create Payment Link (Visa,Mastercard)',
            slaNote1: '* Requests of three characters or less require more time, up to 5 working days',
            slaNote2:
                '** If the operator or system developer requires more time, then Hawsaba will notify the client - within 2 working days- with updated response time.',
            problemsWithASpecificSIDOrLinkWithOperators: 'problems with a specific SID or link with operators',
            workingDays2: '2 working days**',
            technicalSupport: 'Technical support',
            workingDay2: '2 working days*',
            processingARequestWithAcceptanceOrRejectionBasedOnTheRegistrationCriteria:
                'Processing a request with acceptance or rejection based on the registration criteria',
            sIDRequest: 'SID Request',
            three: '3',
            two: '2',
            one: '1',
            workingDay1: '1 working day',
            inquiriesAboutTheSystemOrAboutAdmissionCriteria: 'Inquiries about the system or about admission criteria',
            inquiries: 'Inquiries',
            description: 'Description',
            responseTime: 'Response Time',
            sla: 'SLA',
            paymentDate: 'Payment Date',
            expiryDate: 'Expiry Date',
            details: 'Details',
            provider: 'Provider',
            updateRequestDataInputText: 'Are you sure you want to Update Sender Request Data?',
            updateDataRequests: 'Update Data Requests',
            updateRequestData: 'Update Request Data',
            updateContractRequest: 'Edit contract request',
            convertToAdded: 'Convert To Added',
            markAsAddedInputText: 'Are you sure you want to convert status of the selected Connectivity to added?',
            convertToDeleted: 'Convert To Deleted',
            markAsDeletedInputText: 'Are you sure you want to convert status of the selected Connectivity to Deleted?',
            resendToOperator: 'Resend To Operator',
            resendToOperatorInputText: 'Are you sure you want to Resend the selected Connectivity to Operator?',
            convert: 'Convert',
            resend: 'Resend',
            myProfileInfo: 'My Profile Information',
            myProfile: 'My Profile',
            Paid: 'Paid',
            requestStatus: 'Request Status',
            createddate: 'Created Date',
            InvalidMobileNumber: 'Invalid Mobile Number',
            InvalidIdNumber: 'Invalid Id Number',
            invoiceUnderProcessing: 'Invoice Under Processing',
            ToAddTypeAdvertisementSendernameShouldBeLessThanOrEqual8: 'To Add Type Advertisement Sender name Should Be Less Than Or Equal 8',
            ToAddTypeServiceWithCustomerTypeInternationalSendernameShouldBeLessThanOrEqual8: 'To Add Type Service With Customer Type International Sender name Should Be Less Than Or Equal 9',
            invoiceNumber: 'Invoice Number',
            FaildToEnterPaymentPage: 'Not all senders names are ready to pay',
            organizationName: 'Organization Name',
            clientInformation: 'Client Information',
            copyLink: 'Copy Link',
            createPaymentLink: 'Create Payment Link',
            invoicesList: 'Invoices List',
            paymentdate: 'Payment Date',
            sendersName: 'Senders Names',
            amount: 'Amount',
            Invoices: 'Invoices',
            InvoicesNumber: 'Invoices Number',
            editProviderFinanceInfo: 'Edit Provider Finance Information',
            providersFinance: 'Providers Finance Info',
            BalanceInvoice: 'Balance Invoices',
            balance: 'Balance',
            CreditLimitTransaction: 'Credit Limit Transaction',
            creditLimitAmount: 'Credit Limit Amount',
            fellInfo: 'can you fill the following information about the provider',
            Conectivitylog: 'Connectivity log',
            shortcode: 'Short Code',
            ConvertStatus: 'Convert Status',
            addRequest: 'Add Request',
            ConvertedSuccessfully: 'Converted Successfully',
            addResponse: 'Add Response',
            deleteRequest: 'Delete Request',
            deleteResponse: 'Delete Response',
            lastModifiedDate: 'Last Modified Date',
            deleteSuccessfully: 'Deleted Successfully',
            addSuccessfully: 'Added Successfully',
            reLogin: 'Re-Login',
            senderName: 'Sender Name',
            Search: 'Search',
            exporttoexcel: 'Export To Excel',
            rejectionResson: 'Rejection Reason',
            addNewCITCKeywords: 'Add New Keyword',
            addCITCKeywords: 'Add  Keyword',
            editKeyword: 'Edit Keyword',
            CITCKeywordsList: 'Keywords List',
            CITCKeywords: ' Keyword',
            senderNameShouldNotBeOneOfTheKeywords: 'Sender Name Could Not Be One Of The Keywords',
            PaymentSuccess: 'تم الدفع بنجاج',
            PaymentFaild: 'حدث خطأ في عملية الدفع',
            PaymentFaildMsg: 'اذا تم اقتطاع المبلغ يرجى مراجعة الآدمن',
            GotoDashboard: 'الرجوع إلى الصفحة الرئيسية',
            senderType: 'Sender Type',
            Rejected: 'Rejected',
            Expired: 'Expired',
            Deleted: 'Deleted',
            Active: 'Active',
            Deactivated: 'Deactivated',
            PendingApproval: 'Pending Approval',
            PendingForVerify: 'Pending Verify',
            AddUsersuccessfully: 'Add User Successfully',
            commercialRegistrationNumber: 'Commercial Registration Number',
            internationalCommercialRegistrationNumber: 'International Commercial Registration Number',
            Approved: 'Approved',
            senderStatus: 'Sender Status',
            customerType: 'Customer Type',
            senders: 'Senders',
            senderNameShouldbenumbersandChar: 'Sender Name Should Contains  Numbers & Characters',
            senderNameShouldbe2OrmoreChar: 'Minimum Length of the sender is 2 characters',
            Report: 'Report',
            approveDateFrom: 'Approve Date From',
            approveDateTo: 'Approve Date To',
            createdDate: 'Created Date',
            createdDateTo: 'Created Date To',
            createdDateFrom: 'Created Date From',
            customerId: 'Customer Id',
            MSISDN: 'MSISDN (SAN)',
            taxNumber: 'Tax Registration Number',
            hasInternational: 'Request International Sender',
            unifiedNumber: 'Unified Number',
            errorUserCertificate: 'Not same user, Please Re-Login the current user',
            deactivateInputText: 'Are you sure you want to deactivate the selected Senders?',
            NotAllSenderApprovedOrPaid: 'Not All Selected Senders Approved Or Paid',
            approveInputText: 'Are you sure you want to Approve the selected Senders?',
            approveContractInputText: 'Are you sure you want to approve the selected contract?',
            approveProviderRequestInputText: 'Are you sure you want to Approve this Request?',
            resendInputText: 'Are you sure you want to Resend the selected Senders?',
            editProviderInputText: 'Are you sure you want to Edit Provider?',
            editKeywordInputText: 'Are you sure you want to Edit Keyword?',
            addKeywordInputText: 'Are you sure you want to Add Keyword?',
            editUserInputText: 'Are you sure you want to Edit User?',
            addUserInputText: 'Are you sure you want to Add User?',
            addInput: 'Add',
            addShortCodeInputText: 'Are you sure you want to Add Short Code?',
            editShortCodeInputText: 'Are you sure you want to Edit Short Code?',
            addsubmitRequestInputText: 'Are you sure you want to Add Request?',
            editSenderInputText: 'Are you sure you want to Edit Sender ?',
            editInput: 'Edit',
            userCertificate: 'User Certificate',
            advertisement: 'Advertisement',
            service: 'Service',
            awareness: 'Awareness',
            warning: 'Warning',
            language: 'English',
            dashboard: 'Dashboard',
            providers: 'Providers',
            operators: 'Operators',
            errorSenderNameDuplicate: 'Sender name already exists',
            selectStatus: 'Select Status',
            shorts: 'Shorts',
            userManagement: 'User Management',
            DashboardApprovedRequests: 'Chart Dashboard',
            rowsPerPage: 'Rows Per Page',
            newUser: 'New user',
            editUser: 'Edit user',
            name: 'Name',
            userName: 'User Name',
            userEmail: 'User Email',
            phoneNumber: 'Phone Number',
            phoneNumberAdmin: 'Provider Admin Phone Number',
            userNameAdmin: 'Provider Admin User Name',
            userEmailAdmin: 'Provider Admin User Email',
            country: 'Country ',
            city: 'City',
            district: 'District',
            streetName: 'Street Name',
            buildingNumber: 'Building Number',
            postalCode: 'Postal Code',
            vATNumber: 'VAT Number',
            additionalNumber: 'Additional Number',
            otherIdentifier: 'Other ID',
            type: 'Type',
            selectOperator: 'Select Operator',
            selectProvider: 'Select Provider',
            selectType: 'Select Type',
            save: 'Save',
            userInfo: 'User information',
            providerName: 'Provider Name',
            operatorName: 'Operator Name',
            editInfo: 'Edit Info',
            actions: 'Actions',
            deleteInput: 'Delete',
            deactivateInput: 'Deactivate',
            resendInput: 'Resend',
            approveInput: 'Approve',
            deleteInputText: 'Are you sure you want to delete the selected items?',
            cancel: 'Cancel',
            delete: 'Delete',
            addProvider: 'Add Provider',
            editProvider: 'Edit Provider',
            add: 'Add',
            clientName: 'Client Name',
            crNumber: 'CR Number',
            budgetNumber: 'Budget Number',
            representativeName: 'Representative Name',
            enterpriseUnifiedNumber: 'Enterprise Unified Number',
            startDate: 'Start date',
            durationYear: 'Duration ( Year)',
            representativeEmail: 'Representative Email',
            attachDelegationLetter: 'Attach Delegation Letter',
            attacDocument: 'Attach Document',
            attachRegistrationDocument: 'Attach Registration Document',
            attachUnifiedNumberDocument: 'Attach Unified Number Document',
            attachbudgetNumberDocument: 'Attach Budget Number Document',
            attachlicenseNumberDocument: 'Attach HRSD License Number Document',
            attachcommercialRegistrationNumberDocument: 'Attach Commercial Registration Number Document',
            attachinternationalCommercialRegistrationNumberDocument: 'Attach International Commercial Registration Number Document',
            additionalAttachments: 'Additional Attachments',
            isAlreadyExist: 'I attest that the sender has been already activated before launching the system. As a result the sender will not be sent to operators for activation',
            optional: '(Optional)',
            AlreadyActivated: 'هل اسم المرسل و الرموز القصيرة مفعلة في أنظمة قواعد بيانات المشغلين قبل إطلاق هذه المنصة الإلكترونية؟',
            attach: 'Attach',
            SenderConnectivitySupport: 'Sender Connectivity Support',
            SupportPaymentTransaction: 'Payment Transaction',
            paymentReference: 'Payment Reference',
            sendersList: 'Senders list',
            reset: 'Reset',
            next: 'Next',
            back: 'Back',
            stcShortCodes: 'STC ShortCodes',
            mobileAccounts: 'Mobile Accounts',
            zainShortCodes: 'Zain ShortCodes',
            addToList: 'Add to list',
            zainSC: 'Zain SC',
            mobilySC: 'Mobily SC',
            stcSC: 'STC SC',
            apEmail: 'AP Email',
            sendersShortCodesList: 'Senders/ShortCodes List',
            duration: 'Duration',
            year: 'Year',
            submit: 'Submit',
            requestSubmittedSuccessfully: 'Request submitted successfully',
            newRequest: 'New request',
            requestsListPage: 'Requests list page',
            editRequest: 'Edit Request',
            changeSenderType: 'Change Sender Type',
            changeSenderTypeInput: 'Change Sender Type',
            changeSenderTypeInputText: 'Are you sure you want to Change Sender Type?',
            request: 'Request',
            edit: 'Edit',
            actionHere: 'Action here',
            payment: 'Payment',
            cardNumber: 'Card number',
            exDate: 'Ex. Date',
            nameCard: 'Name on the card',
            orderSummary: 'Order Summary',
            CreditLimit: 'Credit Limit',
            CreditCard: 'Credit Card',
            paidSuccessfully: 'Paid Successfully',
            PaymentOptions: 'Payment Options',
            subtotal: 'Subtotal',
            total: 'Total',
            paymentInformation: 'Payment information',
            payNaw: 'Pay Now',
            privacy: 'Privacy',
            termsOfUse: 'Terms of use',
            copyRight: 'All rights is reserved for T2 © MSG Stack',
            deleteShortSuccessfully: 'Delete Short Code is successfully',
            addShortSuccessfully: 'Add Short Code is successfully',
            updateShortSuccessfully: 'Update Short Code is successfully',
            addConectivitySuccessfully: 'Add Connectivity is successfully',
            resendtooperator: 'Resend To Operator',
            resentSuccessfully: 'Resent To Operator Successfully',
            addProviderSuccessfully: 'Add Provider is successfully',
            editProviderSuccessfully: 'Edit Provider is successfully',
            Ok: 'Ok',
            noSenderNames: 'No Sender Names',
            select: 'Select',
            noData: 'No Data',
            logOut: 'Logout',
            logOutText: 'Are you sure you want to Logout?',
            yes: 'Yes',
            no: 'No',
            Go: 'Go',
            rejectionReason: 'Rejection Reason',
            paymentDateFrom: 'Payment Date From',
            paymentDateTo: 'Payment Date To',
            clientInfo: 'Client Info',
            contractInfo: 'Contract Info',
            reviewRequest: 'Review Request',
            connectingSenderName: 'Connecting Sender Name',
            connectingRequestDashboard: 'Connecting Request Dashboard',
            check: 'check',
            representativeIDNumber: 'Representative Id Number',
            verificationType: 'Verification Type',
            automatic: 'Automatic Verification',
        },
        rejectionReason: {
            addNewReason: 'Add New Reason',
            requestType: 'Request Type',
            rejectionText: 'Rejection Reason',
            rejectionReasonList: 'Rejection Reason List',
            editRejectionReasonSuccessfully: 'Edit Rejection Reason Successfully',
            addRejectionReasonSuccessfully: 'Add Rejection Reason Successfully',
            deleteRejectionReasonSuccessfully: 'Delete Rejection Reason Successfully'
        },
        senderComplaints: {
            notAllSenderAreDeactivated: 'Not All Sender Are Deactivated',
            complaintId: 'Complaint Id',
            reactivate: 'Reactivate',
            providerName: 'Provider Name',
            provider: 'Provider',
            createdDate: 'Created Date',
            requestDate: 'Request Date',
            reactivatedsuccessfully: 'Reactivated Successfully',
            deactivate: 'Deactivate',
            deactivated: 'Deactivated',
            requests: 'Requests',
            senders: 'Senders',
            activated: 'Activated',
            senderNameSearch: 'Sender name',
            status: 'Status',
            of: 'of',
            daysLeft: 'days left',
            senderName: 'Sender Name',
            newRequest: 'New request',
            clientName: 'Client Name',
            details: 'Details',
        },
        loginPage: {
            requestsAlertsPendingActivation: 'Requests Alerts - Pending Activation',
            requestsAlertsPendingPaymentRequests: 'Requests Alerts - Pending Payment Requests',
            requestsAlertsPendingApprovalRequests: 'Requests Alerts - Pending Approval Requests',
            cstTaguser: 'Users With CST Tag Name Type Can Not Login',
            serviceuser: 'Users With Service Type Can Not Login',
            aPIUser330: 'Users With 330API User Type Can Not Login',
            logoTextMain: 'MSG Stack',
            logoTextSub: 'SMS Management system',
            loginHeader: 'Login to my account',
            loginNote1: 'Please, use your account credential to login to the platform',
            enterOtp: 'Please, please Enter Otp to login to the platform',
            OtpReminingTime: 'OTP Will be expired after',
            resendOtp: 'Resend OTP',
            verfiyOTP: 'Verfiy OTP',
            userName: 'User Name',
            password: 'Password',
            canNotLogin: 'I can’t login to my account',
            login: 'Login',
            restoreMyPassword: 'Restore my password',
            restoreNote1: 'We will reset the password for your account by sending a verification code to your email through this process.',
            restoreNote2: 'Do want to proceed?',
            backToLoginForm: 'Back to login form',
            yesUnderstand: 'Yes, I understand',
            pleaseInsertEmail: 'Please, insert your email',
            email: 'Email',
            sendVerificationCode: 'Send verification code',
            verificationNote1: 'Verification code has been send to your email',
            verificationNote2: 'Please, insert the verification code',
            resendCodeAgain: 'Resend Code again',
            verify: 'Verify',
            setPasswordNote1: 'Verification code verified',
            setPasswordNote2: 'Please, insert your new password',
            setNewPassword: 'Set new password',
            passwordResetedSuccessfully: 'Password reseted successfully',
            backToLogin: 'Back to login',
            updateShortCodeType: 'تم تحديث الشورت كود واضافة نوع له، يرجى الاطلاع والتعديل عليه من خلال الصفحة الخاصة به',


        },
        dashboardPage: {
            finalApprove: 'Final Approve',
            review: 'review',
            NotAllSenderCanBeRejected: 'Not All Selected Senders Can Be Rejected',
            someContractsAreMergeInserted: 'Some Contracts Are Merge Inserted',
            senderExpiryDate: 'Sender Expiry',
            expiryDate: 'Expiry Date',
            comment: 'Comment',
            active: 'Active',
            requestId: 'Request Id',
            all: 'All',
            edit: 'Edit',
            providerName: 'Provider Name',
            provider: 'Provider',
            createdDate: 'Created Date',
            requestDate: 'Request Date',
            approved: 'Approved',
            paid: 'Paid',
            pendingForVerify: 'Pending For Verify',
            deactivate: 'Deactivate',
            requests: 'Requests',
            senders: 'Senders',
            thisMonth: 'This month',
            partially: 'Partially',
            activated: 'Activated',
            inactive: 'Inactive',
            pendingFor: 'Pending for',
            payment: 'Payment',
            approval: 'Approval',
            rejected: 'Rejected',
            senderNameSearch: 'Sender name, ID search',
            NotAllSenderActivateOrPartiallyActivated: 'Not All selected Senders are Activate Or Partially Activated',
            representativeName: 'Representative Name',
            representativeMobile: 'Representative Mobile',
            deactivatedsuccessfully: 'Deactivated Successfully',
            status: 'Status',
            of: 'of',
            daysLeft: 'days left',
            renew: 'Renew',
            pause: 'Pause',
            sendRemainder: 'Send remainder',
            terminate: 'Terminate',
            senderName: 'Sender Name',
            newRequest: 'New request',
            clientName: 'Client Name',
            operatorStatus: 'Operator Status',
            details: 'Details',
            Approve: 'Approve',
            reject: 'Reject',
            Pay: 'Pay',
            NotAllSenderPendingApproval: 'Not All Selected Senders Are Pending for Approval',
            Approvedsuccessfully: 'Senders Has been Approved successfully',
            Rejectedsuccessfully: 'Senders Has been Rejected successfully',
            NotAllSenderRequestRelatedToThisTenant: 'Not All Sender Requests Related To This Tenant',
            NotAllSenderPendingPay: 'Not All Selected Senders Are Pending for Pay',
            Paysuccessfully: 'Paid successfully',
            rejectreason: 'Reject Reason',
            resend: 'Resend',
            exportToExcel: 'Export All Senders To Excel',
            exportRequestsToExcel: 'Export All Requests To Excel',
            NotAllSenderRejected: 'Not All Selected Sender status Rejected',
            resentSuccessfully: 'Resent Successfully',
            checkSender: 'Check',
        },
        userManagementPage: {
            users: 'Users',
            providerName: 'Provider Name',
            OperatorName: 'Operator Name',
            UserPassword: 'User Password',
            AddUserSuccessfully: 'User Added successfully',
            EditUserSuccessfully: 'User Edited  successfully',
        },
        extendDurationRequestsPage: {
            renew: 'Renew',
            extendRequestId: 'Extend Request Id',
            extendDuration: 'Extend Duration',
            pay: 'Pay',
            senderName: 'Sender Name',
            requestDate: 'Request Date',
            status: 'Status',
            actions: 'Actions',
            extendDurationRequests: 'Extend Duration Requests',
            requests: 'Requests',
        },
        providerPage: {
            providersList: 'Providers list',
            addNewProvider: 'Add new provider',
            providerSearch: 'Provider name, ID search',
            addingDate: 'Adding Date',
        },
        senderInfo: {
            comment: 'Comment',
            requestStatus: 'Request Status',
            createdDate: 'Date',
            rejectionReason: 'Rejection Reason',
            extendRequestId: 'Extend Request Id',
            duration: 'Duration'
        },
        scamPage: {
            scamList: "Scam List",
            newScamList: "New Scam List",
            senderName: 'Sender Name',
            clientName: 'Client Name',
            scamId: 'ScamId'
        },
        shortPage: {
            shortCodeList: 'Short code list',
            addNewShortCode: 'Add new short code',
            company: 'Company',
            shortCode: 'Short code',
            addShortCode: 'Add short code',
            editShortCode: 'Edit short code',
            companyName: 'Company Name',
            selectCompany: 'Select company',
            operatorSearch: 'Operator name, ID search',
            shortcodeSearch: 'short Code name, ID search',
            whiteList: 'القائمة البيضاء',
            blackList: 'القائمة السوداء',
        },
    },
};

const i18n = new VueI18n({
    locale: 'en',
    messages,
});

export default i18n;
