import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './TrackRequest.html';
import { Toaster } from '../../Services/toast';
import TrackRequestService from './TrackRequestService';
import { TrackRequestModel } from './TrackRequestModel';
import { SenderRequestTrackingInfoModel } from './SenderRequestTrackingInfoModel';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        TrackRequestService,
    ],
})
export default class TrackRequest extends Vue {
    @Inject(TrackRequestService) public trackRequestService!: TrackRequestService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public model: TrackRequestModel = {
        requestId: '',
        senderName: '',
        clientName: '',
        providerName: '',
        senderRequestTrackingList: [],
        activationInformationList: []
    };
    public id: string | any = '';
    public activationDialog: boolean = false;
    public senderRequestTrackingInfoModel: SenderRequestTrackingInfoModel[] = [];
    public headers: any = [
        { text: this.$t('senderInfo.requestStatus'), value: 'requestStatus', align: 'center', sortable: false },
        { text: this.$t('senderInfo.createdDate'), value: 'createdDate', align: 'center', sortable: false },
        { text: this.$t('senderInfo.comment'), value: 'comment', align: 'center', sortable: false },
        { text: '', value: 'requestStatus', align: 'center', sortable: false },
    ];
    public activationHeaders: any = [
        { text: this.$t('shared.operatorName'), value: 'requestStatus', align: 'center', sortable: false },
        { text: this.$t('shared.isActive'), value: 'createdDate', align: 'center', sortable: false },
        { text: this.$t('shared.activationDate'), value: 'comment', align: 'center', sortable: false },
    ];
    public senderName: string = '';
    public async created() {
        this.$emit('childinit', this.layoutSup);
    }
    public async search() {
        const response = await this.trackRequestService.get(this.id);
        if (response.isSuccess) {
            this.model.clientName = response.result.clientName;
            this.model.senderName = response.result.senderName;
            this.model.providerName = response.result.providerName;
            this.model.requestId = response.result.requestId;
            this.model.senderRequestTrackingList = response.result.senderRequestTrackingList;
            this.model.activationInformationList = response.result.activationInformationList;
        } else {
            Toaster.error(response.errorMessage);
        }
    }
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.id = this.$route.query.id;
        this.search();
    }
    public async showActivationDialog() {
        this.activationDialog = true;
    }
    public async hideActivationDialog() {
        this.activationDialog = false;
    }
    public get isValid(): boolean {
        return (this.id.trim() !== '');
    }
}
