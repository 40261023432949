<div class="w-100 h-100 m-auto">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <label class="header_text mb-3" v-if="!isEditMode && !isProvider">
                {{$t('shared.addProvider')}}
            </label>
            <label class="header_text mb-3" v-if="isEditMode && !isProvider">
                {{$t('shared.editProvider')}}
            </label>
            <label class="header_text mb-3" v-if="isProvider">
                {{$t('shared.myProfileInfo')}}
            </label>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body card_body_layout">
                    <div class="row">
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.providerName')}}</label>
                                <div class="input-group mb-3">
                                    <input class="form-control"
                                           type="text"
                                            v-model="provider.name" :disabled="isEditMode || isProvider" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.crNumber')}}</label>
                                <input class="form-control" v-model="provider.crNumber" type="text" :disabled="isEditMode || isProvider" />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.accreditationNumber')}}</label>
                                <div class="input-group mb-3">
                                    <input class="form-control" type="text"
                                             v-model="provider.accreditationNumber"
                                           :disabled="isEditMode || isProvider" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.taxNumber')}}</label>
                                <div class="input-group mb-3">
                                    <input class="form-control" type="text"
                                             v-model="provider.taxNumber" @keypress="isNumberField($event)"
                                           :disabled="isEditMode || isProvider" />
                                </div>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.email')}}</label>
                                <input class="form-control" v-model="provider.email" :disabled="isProvider" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.country')}}</label>
                                <input class="form-control" v-model="provider.country" :disabled="isProvider" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.city')}}</label>
                                <input class="form-control" v-model="provider.city" :disabled="isProvider" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.district')}}</label>
                                <input class="form-control" v-model="provider.district" :disabled="isProvider" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.streetName')}}</label>
                                <input class="form-control" v-model="provider.streetName" :disabled="isProvider" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.buildingNumber')}}</label>
                                <input class="form-control" v-model="provider.buildingNumber" :disabled="isProvider" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.postalCode')}}</label>
                                <input class="form-control" v-model="provider.postalCode" :disabled="isProvider" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.vatNumber')}}</label>
                                <input class="form-control" v-model="provider.vatNumber" :disabled="isProvider" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.additionalNumber')}}</label>
                                <input class="form-control" v-model="provider.additionalNumber" :disabled="isProvider" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3">
                            <div class="form-group">
                                <label>{{$t('shared.otherIdentifier')}}</label>
                                <input class="form-control" v-model="provider.otherIdentifier" :disabled="isProvider" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3" v-if="isProvider">
                            <div class="form-group">
                                <label>{{$t('shared.creditLimitAmount')}}</label>
                                <input class="form-control" v-model="provider.creditLimitAmount" v-if="isProvider" :disabled="isProvider" type="text" />
                            </div>
                        </div>
                        <div class="col-md-4 mb-3" v-if="isProvider">
                            <div class="form-group">
                                <label>{{$t('shared.balance')}}</label>
                                <input class="form-control" v-model="provider.balance" :disabled="isProvider" type="text" />
                            </div>
                        </div>
                        <div class="col-md-8 mb-3" v-if="isProvider">
                            <div class="form-group">
                                <h5>You can charge your balance by transferring or depositing money (including VAT) to bank account {{provider.bankAccountNumber}}. After transfer, please share the receipt with Hawsabah support team.</h5>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3" v-if="!isProvider">
                            <div class="form-group">
                                <label>{{$t('shared.expireDate')}}</label>
                                <v-menu v-model="expiryDateMenu" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" v-model="provider.expireDate" readonly
                                                   v-bind="attrs" ref="createdDatePicker" v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="provider.expireDate" no-title scrollable @input="expiryDateMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                        <div class="col-md-4 mb-3" v-if="!isProvider">
                            <div class="form-group">
                                <v-checkbox v-model="provider.hasInternational"
                                            :label="$t('shared.hasInternational')"></v-checkbox>
                            </div>
                        </div>
                        <div class="col-md-12 mt-5" v-if="!isProvider">
                            <div class="form-row justify-content-end align-items-center">
                                <div class="col-auto mx-3">
                                    <router-link to="/Providers" tag="a" class="link_secondary">{{$t('shared.cancel')}}</router-link>
                                </div>
                                <div class="col-auto">
                                    <button class="btn btn-success px-5 py-2" :disabled="!isValid" @click="addProvider" v-if="!isEditMode">{{$t('shared.add')}}</button>
                                    <button class="btn btn-success px-5 py-2" :disabled="!isValid" @click="showeditProviderDialog" v-if="isEditMode">{{$t('shared.save')}}</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="editProviderDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.editInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideEditProviderDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.editProviderInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideEditProviderDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="editProvider">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>

