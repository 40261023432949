import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './CreditLimitTransaction.html';
import { Toaster } from '../../Services/toast';
import CreditLimitTransactionService from './CreditLimitTransactionService';
import { CreditLimitTransactionModel } from './CreditLimitTransactionModel';
import UserModel from '../../shared/userService/Model/UserModel';
import UserRoleService from '../../shared/userService/UserRoleService';
import UserService from '../../shared/userService/UserService';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        UserService,
        UserRoleService,
        CreditLimitTransactionService,
    ],
})
export default class CreditLimitTransaction extends Vue {
    @Inject(CreditLimitTransactionService) public creditLimitTransactionService!: CreditLimitTransactionService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public deleteDialog: boolean = false;
    public cITCKeywordsId: string = '0';
    public name: string = '0';
    public User: any;
    public userRole: any;
    public headers: any = [
        { text: this.$t('shared.sendersName'), value: 'sendersName', align: 'center', sortable: false },
        { text: this.$t('shared.clientName'), value: 'clientName', align: 'center', sortable: false },
        { text: this.$t('shared.providerName'), value: 'providerName', align: 'center', sortable: false },
        { text: this.$t('shared.paymentdate'), value: 'date', align: 'center', sortable: false },
        { text: this.$t('shared.amount'), value: 'amount', align: 'center', sortable: false },
        { text: this.$t('shared.balance'), value: 'balance', align: 'center', sortable: false },
    ];
    public createdDateToMenu: boolean = false;
    public createdDateFromMenu: boolean = false;
    public creditLimitTransactionModel: CreditLimitTransactionModel[] = [];
    public creditLimitTransactionCount: number = 0;
    public filterModel: any = {
        invoiceNumber: '',
        date: '',
        amount: 0,
        sendersName: '',
        providerName: '',
        clientName: '',
        paymentDateFrom: '',
        paymentDateTo: ''
    };
    public user: UserModel = {
        userId: '',
        username: '',
        details: {
            Email: '',
            MobilePhone: '',
            Operator: '',
            Provider: '',
            OperatorId: '',
            TenantId: '',
            ProviderId: '',
            Role: '',
            ChangePasswordRequired: '',
        },
    };
    public async created() {
        this.$emit('childinit', this.layoutSup);
    }
    public async mounted() {
        this.User = await this.userService.getUser();
        this.userRole = this.userRoleService.GetUserRole(this.User);
        const response = await this.creditLimitTransactionService.get
            (this.filterModel.sendersName, this.filterModel.providerName, this.filterModel.clientName, this.filterModel.paymentDateFrom,
                this.filterModel.paymentDateTo, false, '0', '10');
        if (!response.isSuccess)
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            }
        this.creditLimitTransactionModel = response.result;
        this.creditLimitTransactionCount = response.totalCount;
    }

    public async search() {
        const response = await this.creditLimitTransactionService.get
            (this.filterModel.sendersName, this.filterModel.providerName, this.filterModel.clientName, this.filterModel.paymentDateFrom,
                this.filterModel.paymentDateTo, false, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.creditLimitTransactionModel = response.result;
        this.creditLimitTransactionCount = response.totalCount;
    }

    public async reload() {
        const response = await this.creditLimitTransactionService.get
            (this.filterModel.sendersName, this.filterModel.providerName, this.filterModel.clientName, this.filterModel.paymentDateFrom,
                this.filterModel.paymentDateTo, false, '0', '10');
        if (!response.isSuccess)
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            }
        this.creditLimitTransactionModel = response.result;
        this.creditLimitTransactionCount = response.totalCount;
    }
    public async updatePaginate(val: any) {
        const response = await this.creditLimitTransactionService.get
            (this.filterModel.sendersName, this.filterModel.providerName, this.filterModel.clientName, this.filterModel.paymentDateFrom,
                this.filterModel.paymentDateTo, false, (val.page - 1).toString(), val.rowsPerPage);
        this.creditLimitTransactionModel = response.result;
        this.creditLimitTransactionCount = response.totalCount;
    }

    public reset() {
        this.filterModel = {
            invoiceNumber: '',
            sendersName: '',
            clientName: '',
            providerName: '',
            paymentDateFrom: '',
            paymentDateTo: ''
        };
        this.search();
    }

    public async exportToExcel() {
        const response = await this.creditLimitTransactionService.get
            (this.filterModel.sendersName, this.filterModel.providerName, this.filterModel.clientName,
                this.filterModel.paymentDateFrom,
                this.filterModel.paymentDateTo, true, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }

    }


}
