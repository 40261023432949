import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './SenderRequestApprovalHistory.html';
import SenderRequestSupportService from '../SenderRequestSupportService';
import { SenderRequestApprovalHistoryModel } from './SenderRequestApprovalHistoryModel';
import { Toaster } from '../../../Services/toast';
import { RequestStatus } from '../../../shared/Enums/RequestStatus';
import { SenderStatusDropdownModel } from '../SenderStatusDropdownModel';
import { AddHistoryRequestModel } from './AddHistoryRequestModel';
import { ApprovalHistoryGetResponseModel } from './ApprovalHistoryGetResponseModel';



@WithRender
@Component({
    components: {
    },
    diProvide: [
        SenderRequestSupportService,
    ],
})
export default class SenderRequestApprovalHistory extends Vue {
    @Inject(SenderRequestSupportService) public senderRequestSupportService!: SenderRequestSupportService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public senderRequestApprovalHistory: SenderRequestApprovalHistoryModel[] = [];
    public approvalHistoryGetResponseModel: ApprovalHistoryGetResponseModel =
        {
            isExtend: false,
            senderRequestApprovalHistories: [],
        };

    public senderRequestApprovalHistoryCount: number = 0;
    public headers: any = [
        { text: this.$t('shared.createdDate'), value: 'createdDate', align: 'center', sortable: false },
        { text: this.$t('shared.requestStatus'), value: 'requestStatus', align: 'center', sortable: false },
        { text: this.$t('shared.rejectionReason'), value: 'rejectionReason', align: 'center', sortable: false },
        { text: this.$t('shared.action'), value: 'id', align: 'center', sortable: false },
    ];
    public senderRequestStatusOptions: SenderStatusDropdownModel[] = [];

    public newHistoryStatus: number = 0;
    public historyId: string = '0';
    public editSenderDialog: boolean = false;
    public id: string | any;
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        if (this.$route.params.id !== undefined) {
            this.id = this.$route.params.id;
            await this.getHistory();
        }
    }

    public async getHistory() {
        const response = await this.senderRequestSupportService.getSenderRequestApprovalHistory(this.id, '0', '1000');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        } else {
            this.approvalHistoryGetResponseModel = response.result;
            this.senderRequestApprovalHistory = this.approvalHistoryGetResponseModel.senderRequestApprovalHistories;
            this.senderRequestApprovalHistoryCount = response.totalCount;
            if (!this.approvalHistoryGetResponseModel.isExtend) {
                this.senderRequestStatusOptions = [
                    {
                        id: RequestStatus.PendingApproval,
                        name: this.$t('shared.pendingApproval').toString(),
                    },
                    {
                        id: RequestStatus.Approved,
                        name: this.$t('shared.approved').toString(),
                    },
                    {
                        id: RequestStatus.Rejected,
                        name: this.$t('shared.rejected').toString(),
                    },
                    {
                        id: RequestStatus.Paid,
                        name: this.$t('shared.paid').toString(),
                    },
                    {
                        id: RequestStatus.Expired,
                        name: this.$t('shared.expired').toString(),
                    },
                    {
                        id: RequestStatus.Deleted,
                        name: this.$t('shared.deleted').toString(),
                    },
                ];
            }
            else {

                this.senderRequestStatusOptions = [
                    {
                        id: RequestStatus.Approved,
                        name: this.$t('shared.pendingPayment').toString(),
                    },
                    {
                        id: RequestStatus.Paid,
                        name: this.$t('shared.paid').toString(),
                    }
                ];
            }
        }
    }
    public historyDateFromMenu: boolean = false;
    public historyDate: string = '';
    public deleteDialog: boolean = false;
    public model: AddHistoryRequestModel = {
        requestId: '',
        requestStatus: 0,
        historyDate: ''
    };

    public async updatePaginate(val: any) {
        if (this.id !== undefined) {
            const response = await this.senderRequestSupportService.getSenderRequestApprovalHistory(this.id,
                (val.page - 1).toString(), val.rowsPerPage);
            this.senderRequestApprovalHistory = response.result;
            this.senderRequestApprovalHistoryCount = response.totalCount;
        }
    }
    public showEditSenderDialog() {
        this.editSenderDialog = true;
    }
    public isValidEdit(): boolean {
        return this.model.requestStatus !== 0 && this.model.historyDate != '';
    }
    public hidedSenderDialog() {
        this.editSenderDialog = false;
    }
    public hideDeleteDialog() {
        this.deleteDialog = false;
    }
    public showDeleteDialog(id: string) {
        this.historyId = id;
        this.deleteDialog = true;
    }
    public async deleteHistory() {
        const response = await this.senderRequestSupportService.deleteRequestHistory(this.historyId);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.deleteHistorySuccessfully').toString());
            await this.getHistory();
        } else {
            Toaster.error(response.errorMessage);
        }
        this.deleteDialog = false;
    }
    public async save() {
        this.model.requestId = this.id;
        const response = await this.senderRequestSupportService.addRequestHistory(this.model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
            this.hidedSenderDialog();
        } else {
            Toaster.success(this.$t('shared.requestHistorySuccessfully').toString());
            this.hidedSenderDialog();
            await this.getHistory();
        }
        this.model.historyDate = '';
        this.model.requestStatus = 0;
    }
}