import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './RejectionReasonList.html';
import { RejectionReasonModel } from './RejectionReasonModel';
import RejectionReasonListService from './RejectionReasonListService';
import { Toaster } from '../../Services/toast';
import UserService from '../../shared/userService/UserService';
import UserRoleService from '../../shared/userService/UserRoleService';
import { ref } from 'vue';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        RejectionReasonListService,
        UserService,
        UserRoleService,
    ],
})
export default class RejectionReasonList extends Vue {
    @Inject(RejectionReasonListService) public rejectionReasonListService!: RejectionReasonListService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public addDialog: boolean = false;
    public deleteDialog: boolean = false;
    public editDialog: boolean = false;
    public RejectionReasonId: string = '0';
    public name: string = '0';
    public User: any;
    public userRole: any;
    public rules: any[] = [];
    $refs!: {
        addRejectionReasonForm: HTMLFormElement,
        editRejectionReasonForm: HTMLFormElement
    }
    public headers: any = [
        {
            text: this.$t('rejectionReason.rejectionText'), value: 'Rejection Reason', align: 'center', sortable: false,
        },
        {
            text: this.$t('rejectionReason.requestType'), value: 'Request Type', align: 'center', sortable: false,
        },

        {
            text: this.$t('shared.actions'), sortable: false, value: 'id', align: 'center', width: '100'
        },
    ];

    public rejectionReasons: RejectionReasonModel[] = [];
    public rejectionReasonsCount: number = 0;
    public filterRejectionReasons: RejectionReasonModel[] = [];
    public filterModel: any = {
        id: '',
        rejectionText: '',
        requestType: 0,
    };
    public addModel: RejectionReasonModel = {
        id: '',
        rejectionText: '',
        requestType: 0,
    };
    public editModel: RejectionReasonModel = {
        id: '',
        rejectionText: '',
        requestType: 0,
    };

    public requestTypes: any[] = [
        {
            id: 1,
            name: 'Sender Name Request',
        },
        {
            id: 2,
            name: 'Contract Request',
        },
    ];
    public async created() {
        this.User = await this.userService.getUser();
        this.userRole = this.GetUserRole();
        this.$emit('childinit', this.layoutSup);
    }
    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.User);
    }
    public async mounted() {
        const response = await this.rejectionReasonListService.get(this.filterModel.rejectionText,
            this.filterModel.requestType, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.rejectionReasons = response.result;
        this.rejectionReasonsCount = response.totalCount;
        this.validation();
    }
    @Watch('filterModel', { deep: true })
    public async search() {
        const response = await this.rejectionReasonListService.get(this.filterModel.rejectionText,
            this.filterModel.requestType, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.rejectionReasons = response.result;
        this.rejectionReasonsCount = response.totalCount;
    }


    // #region  Add Rejection Reason Code Area 
    public showAddDialog() { 
        this.addDialog = true;
    }
    public hideAddDialog() {
        this.addDialog = false;
        this.addModel.id = '';
        this.addModel.rejectionText = '';
        this.addModel.requestType = 0;
    }

    public async addRejectionReason() {
        if (this.$refs.addRejectionReasonForm.validate()) {
            const response = await this.rejectionReasonListService.post(this.addModel.rejectionText, this.addModel.requestType);
            if (response.isSuccess) {
                this.search();
                Toaster.success(this.$t('rejectionReason.addRejectionReasonSuccessfully').toString());
                this.hideAddDialog();
            }
            else {
                Toaster.error('a error was happened');
            }
        }
    }

    // #endregion

    // #region Edit Rejection Reason Code Area
    public showEditDialog(id: string) {
        const RejectionReason = this.rejectionReasons.find(x => x.id == id);
        if (RejectionReason === undefined) {
            return;
        }
        this.editModel.id = id;
        this.editModel.rejectionText = RejectionReason.rejectionText;
        this.editModel.requestType = RejectionReason.requestType;
        this.editDialog = true;
    }
    public async editRejectionReason() {
        if (this.$refs.editRejectionReasonForm.validate()) {
            const dbNotExists = await this.rejectionReasonListService.isRejectionReasonExists(this.editModel.rejectionText, this.editModel.requestType);
            if (dbNotExists.isSuccess) {
                this.editDialog = false;
                const response = await this.rejectionReasonListService.edit(this.editModel);
                if (response.isSuccess) {
                    this.search();
                    Toaster.success(this.$t('rejectionReason.editRejectionReasonSuccessfully').toString());
                    this.hideEditDialog();
                }
            }
            else {
                Toaster.error('a RejectionReason with same requestType and rejectionText already exists');
            }
        }
    }
    public hideEditDialog() {
        this.editDialog = false;
    }
    // #endregion

    // #region Delete Rejection Reason Area Code
    public showDeleteDialog(id: string) {
        this.RejectionReasonId = id;
        this.deleteDialog = true;
    }
    public hideDeleteDialog() {
        this.deleteDialog = false;
    }
    public async deleteRejectionReason() {
        const response = await this.rejectionReasonListService.delete(this.RejectionReasonId);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('rejectionReason.deleteRejectionReasonSuccessfully').toString());
            this.search();
        } else {
            Toaster.error(response.errorMessage);
        }
        this.deleteDialog = false;
    }
    // #endregion

    //#region Validation
    validation() {
        // Define Vuetify validation rules
        this.rules = [
            (v: string) => v.length <= 80 || 'Max 80 characters',
            (v: string) => !!v.trim() || 'Field Is requierd'
        ];
    } 
    // #endregion

    public getTextValue(id: number): string | undefined {
        const requestedType = this.requestTypes.find((item) => item.id === id);
        return requestedType ? requestedType.name : undefined;
    }

    public async updatePaginate(val: any) {
        const response = await this.rejectionReasonListService.get(this.filterModel.rejectionText.toString(),
            this.filterModel.requestType, (val.page - 1).toString(), val.rowsPerPage);
        this.rejectionReasons = response.result;
        this.rejectionReasonsCount = response.totalCount;
    }
    public sleep(ms: number) {
        return new Promise((resolve) => {
            setTimeout(resolve, ms);
        });
    }
}
