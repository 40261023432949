<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.providerRequests')}} ({{providerRequestsCount}})
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       v-model="filterModel.name"
                                       type="text" :placeholder="$t('shared.name')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       v-model="filterModel.crNumber"
                                       type="text" :placeholder="$t('shared.crNumber')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       v-model="filterModel.accreditationNumber"
                                       type="text" :placeholder="$t('shared.accreditationNumber')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3">
                            <div class="input-group mb-3">
                                <input class="form-control border-right-0 border-left"
                                       v-model="filterModel.taxNumber"
                                       type="text" :placeholder="$t('shared.taxNumber')" />
                                <div class="input-group-append">
                                    <span class="input-group-text right gray">
                                        <i class="fas fa-search"></i>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <template>
                        <v-data-table :headers="headers"
                                      :items="providerRequests"
                                      :total-items="providerRequestsCount"
                                      :rows-per-page-text="$t('shared.rowsPerPage')"
                                      :rows-per-page-items='[10, 25, 50, 100]'
                                      @update:pagination="updatePaginate"
                                      class="elevation-1 table-striped">
                            <template v-slot:items="props">
                                <tr>
                                    <td class="text-center">{{ props.item.name }}</td>
                                    <td class="text-center">{{ props.item.crNumber }}</td>
                                    <td class="text-center">{{ props.item.accreditationNumber }}</td>
                                    <td class="text-center">{{ props.item.taxNumber }}</td>
                                    <td class="text-center">
                                        <div :class="'status-' + props.item.providerRequestStatus">{{ GetProviderRequestStatus(props.item.providerRequestStatus)}}</div>
                                    </td>
                                    <td class="text-center btn_menu">
                                        <template>
                                            <v-menu offset-y open-on-hover>
                                                <template v-slot:activator="{ on }">
                                                    <v-btn color="btn-primary"
                                                           v-on="on">
                                                        <div>{{$t('shared.actions')}}</div>
                                                        <i class="fas fa-chevron-down pl-4" style="vertical-align: sub;"></i>
                                                    </v-btn>
                                                </template>
                                                <v-list>
                                                    <v-list-tile v-if="props.item.providerRequestStatus == 1" @click="showapproveDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.Approve')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile v-if="props.item.providerRequestStatus == 1" @click="showRejectDialog(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.reject')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                    <v-list-tile @click="Details(props.item.id)">
                                                        <v-list-tile-title class="white">{{$t('dashboardPage.details')}}</v-list-tile-title>
                                                    </v-list-tile>
                                                </v-list>
                                            </v-menu>
                                        </template>
                                    </td>
                                </tr>
                            </template>
                        </v-data-table>
                    </template>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="approveDialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.approveInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hideapproveDialog"></i>
                    </div>
                </v-card-title>

                <v-card-text class="text-center">
                    <img :src="require('../img/folder.svg')" />
                    <p class="mt-3">
                        {{$t('shared.approveProviderRequestInputText')}}
                    </p>
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hideapproveDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="approve">{{$t('shared.Ok')}}</button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
    <v-dialog v-model="rejectdialog" persistent width="554">
        <v-card>
            <v-card-title class="headline grey lighten-2" primary-title>
                {{$t('dashboardPage.reject')}}
                <div class="close__dialog">
                    <i class="fas fa-times" @click="hideRejectDialog"></i>
                </div>
            </v-card-title>
            <v-card-text>
                <div class="row mt-3">
                    <div class="col-md-12">
                        <div class="form-group">
                            <label>{{$t('dashboardPage.rejectreason')}}</label>
                            <input class="form-control" v-model="rejectResone" type="text" />
                        </div>
                    </div>
                </div>
            </v-card-text>

            <v-card-actions>
                <v-spacer></v-spacer>
                <div class="form-row justify-content-end align-items-center">
                    <div class="col-auto mx-3">
                        <a class="link_danger" @click="hideRejectDialog">
                            {{$t('shared.cancel')}}
                        </a>
                    </div>
                    <div class="col-auto">
                        <button class="btn btn-danger px-5 py-2" :disabled="!rejectReasonIsValid" @click="reject">{{$t('shared.Ok')}}</button>
                    </div>
                </div>
            </v-card-actions>
        </v-card>
    </v-dialog>

</div>


