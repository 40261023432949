<div class="w-100 h-100">
    <div class="row d-flex justify-content-center">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.renewRequest')}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row justify-content-sm-center">
        <div class="col-sm-8">
        </div>
    </div>
    <div class="row">
        <div class="col-md-12 ">
            <div class="card card_layout">
                <div class="box_conferm" v-if="stepNumber == 4">
                    <img class="pb-4" :src="require('../img/BigCheck.svg')" />
                    <label class="w-100 pb-4">{{$t('shared.requestSubmittedSuccessfully')}}</label>
                    <div class="form-row justify-content-center align-items-center">
                        <div class="col-auto mx-3">
                            <router-link to="/ConnectingRequestDashboard" tag="button"
                                         class="btn btn-outline-primary px-5 py-2 mx-2">{{$t('shared.requestsListPage')}}</router-link>
                        </div>
                    </div>
                </div>
                <div class="card-body">
                    <div class="row">
                        <div class="col-md-4">
                            <div class="form-group">
                                <label>{{$t('shared.attachContract')}}</label>
                                <div class="input-group mb-3" v-if="stepNumber != 4">
                                    <input class="form-control border-right-0 border-left" readonly v-model="contractAttachmentName"
                                           type="text" />
                                    <input type="file" ref="contractAttachment" hidden accept=".png, .jpg, .jpeg, .pdf, .docx"
                                           @change="onFilePicked" />
                                    <div class="input-group-prepend">
                                        <span class="input-group-text right" style="padding: 2px 2px 0px 2px !important;">
                                            <i v-if="contractAttachmentName !== ''" class="far fa-trash-alt Icon_delete"
                                               @click="deleteContract()"></i>
                                            <button class="btn btn-primary" @click="onPickFile(1)">
                                                {{$t('shared.attach')}}
                                            </button>
                                        </span>
                                    </div>
                                </div>
                                <div class="col-md-8 col-10">
                                    <div class="form-group">
                                        <label>{{$t('shared.theMaximumSizeForTheAttachmentIs3Megabytes')}}</label>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-md-3 mb-3">
                            <div class="input-group mb-3">
                                <label>{{$t('shared.expiryDate')}}</label>
                                <v-menu v-model="expiryDateFromMenu" :close-on-content-click="false" :nudge-right="40"
                                        transition="scale-transition" offset-y>
                                    <template v-slot:activator="{ on, attrs }">
                                        <div class="input-group mb-3">
                                            <input class="form-control border-right-0 border-left" v-model="renewRequestModel.expiryDate" readonly
                                                   v-bind="attrs" ref="createdDatePicker" v-on="on" type="text" />
                                            <div class="input-group-prepend">
                                                <span class="input-group-text right gray">
                                                    <i class="far fa-calendar-alt"></i>
                                                </span>
                                            </div>
                                        </div>
                                    </template>
                                    <v-date-picker v-model="renewRequestModel.expiryDate" no-title scrollable @input="expiryDateFromMenu = false">
                                    </v-date-picker>
                                </v-menu>
                            </div>
                        </div>
                    </div>
                </div>
                <br>
                <br><br>
                <br>
                <div class="card-footer">
                    <div class="form-row justify-content-between align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_light" @click="reset" v-if="stepNumber == 1">{{$t('shared.reset')}}</a>
                        </div>
                        <button class="btn btn-primary px-5 py-2" :disabled="!isValid" @click="showsubmitRequestDialog">
                            {{$t('shared.submit')}}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    </div>
    <div class="text-xs-center">
        <v-dialog v-model="submitRequestdialog" width="554">
            <v-card>
                <v-card-title class="headline grey lighten-2" primary-title>
                    {{$t('shared.addInput')}}
                    <div class="close__dialog">
                        <i class="fas fa-times" @click="hidesubmitRequestDialog"></i>
                    </div>
                </v-card-title>
                <v-card-text class="text-center">
                    <p class="mt-3">
                        {{$t('shared.addsubmitRenewContractRequestInputText')}}
                    </p>
                </v-card-text>
                <v-card-actions>
                    <v-spacer></v-spacer>
                    <div class="form-row justify-content-end align-items-center">
                        <div class="col-auto mx-3">
                            <a class="link_danger" @click="hidesubmitRequestDialog">
                                {{$t('shared.cancel')}}
                            </a>
                        </div>
                        <div class="col-auto">
                            <button class="btn btn-danger px-5 py-2" @click="submitRequest">
                                {{$t('shared.Ok')}}
                            </button>
                        </div>
                    </div>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</div>