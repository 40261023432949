<div class="w-100 h-100">
    <div class="main_page w-100 h-100">
        <div class="container">
            <div class="row" style="padding-top: 56px;">
                <div class="col-12">
                    <div class="card card_layout">
                        <div class="card-header">
                            <label class="card_header_text">
                                {{$t('shared.payment')}}
                            </label>
                        </div>
                        <div class="card-body p-5">
                            <div class="row" v-show="!canPay">
                                <div class="col-md-6 mx-auto mt-5">
                                    <h2>{{$t('shared.FaildToEnterPaymentPage')}}</h2>
                                </div>
                            </div>
                            <div class="row" v-show="canPay">
                                <div class="col-md-12">
                                    <div class="row">
                                        <div class="col-md-3 border-right">
                                            <label class="card_header_orders">{{$t('shared.orderSummary')}}</label>
                                            <ul class="list-group list_orders">
                                                <li v-for="sender in sendersPaymentSummery.details" class="list-group-item d-flex justify-content-between align-items-end">
                                                    <div class="order_text">
                                                        <label class="order_title mb-0"></label>
                                                        <h5 class="order_sub_title">{{sender.senderName}}</h5>
                                                    </div>
                                                    <label class="order_cost">{{sender.amount}} SAR</label>
                                                </li>
                                            </ul>
                                            <div class="d-flex justify-content-between align-items-center">
                                                <label class="order_total">{{$t('shared.total')}}</label>
                                                <label class="order_total text-right"> {{sendersPaymentSummery.total}} SAR</label>
                                            </div>
                                        </div>
                                        <div class="col-md-5 border-right">
                                            <fieldset :disabled="!canEditBuyerInfo">
                                                <label class="card_header_orders">{{$t('shared.clientInformation')}}</label>
                                                <div class="row">
                                                    <div class="col-md-12">
                                                        <div class="form-group">
                                                            <label>{{$t('shared.organizationName')}}</label>
                                                            <input class="form-control border-right border-left"
                                                                   name="organizationName"
                                                                   type="text"
                                                                   v-model="invoiceDetailsModel.buyerOrganizationName"
                                                                   :disabled="true" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{$t('shared.vatNumber')}}</label>
                                                            <input class="form-control border-right border-left"
                                                                   name="vatNumber"
                                                                   v-model="invoiceDetailsModel.buyerVatNumber"
                                                                   @paste="isNumberFieldPaste($event)"
                                                                   maxlength="15"
                                                                   @keypress="isNumberField($event)"
                                                                   type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{$t('shared.email')}}</label>
                                                            <input class="form-control border-right border-left"
                                                                   name="email"
                                                                   v-model="invoiceDetailsModel.buyerEmail"
                                                                   type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{$t('shared.country')}}</label>
                                                            <input class="form-control border-right border-left"
                                                                   name="country"
                                                                   v-model="invoiceDetailsModel.buyerCountry"
                                                                   type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{$t('shared.city')}}</label>
                                                            <input class="form-control border-right border-left"
                                                                   name="city"
                                                                   v-model="invoiceDetailsModel.buyerCity"
                                                                   type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{$t('shared.district')}}</label>
                                                            <input class="form-control border-right border-left"
                                                                   name="district"
                                                                   v-model="invoiceDetailsModel.buyerDistrict"
                                                                   type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{$t('shared.streetName')}}</label>
                                                            <input class="form-control border-right border-left"
                                                                   name="streetName"
                                                                   v-model="invoiceDetailsModel.buyerStreetName"
                                                                   type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{$t('shared.buildingNumber')}}</label>
                                                            <input class="form-control border-right border-left"
                                                                   name="buildingNumber"
                                                                   v-model="invoiceDetailsModel.buyerBuildingNumber"
                                                                   type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{$t('shared.postalCode')}}</label>
                                                            <input class="form-control border-right border-left"
                                                                   name="postalCode"
                                                                   v-model="invoiceDetailsModel.buyerPostalCode"
                                                                   type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{$t('shared.additionalNumber')}}</label>
                                                            <input class="form-control border-right border-left"
                                                                   name="additionalNumber"
                                                                   v-model="invoiceDetailsModel.buyerAdditionalNumber"
                                                                   type="text" />
                                                        </div>
                                                    </div>
                                                    <div class="col-md-6">
                                                        <div class="form-group">
                                                            <label>{{$t('shared.otherIdentifier')}}</label>
                                                            <input class="form-control border-right border-left"
                                                                   name="otherIdentifier"
                                                                   v-model="invoiceDetailsModel.buyerOtherIdentifier"
                                                                   type="text" />
                                                        </div>
                                                    </div>
                                                    <div dir="rtl">
                                                        <div class="col-md-12 pt-4">
                                                                <v-checkbox v-model="disclaimerChecked"
                                                                            :label="$t('shared.publicPaymentDisclaimer')"></v-checkbox>
                                                        </div>
                                                        <div class="col-md-6 pt-4">
                                                            <button :disabled="!isValid" class="btn btn-success px-5 py-2" @click="addPaymentTransaction">{{$t('shared.next')}}</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </fieldset>
                                        </div>
                                        <div id="hyperPaycontainer" v-show="paymentForm && !isMada" class="col-md-4">
                                            <v-form :action="shopperResultUrl"
                                                    name="hyperPayForm"
                                                    class="paymentWidgets"
                                                    data-brands="VISA MASTER"></v-form>
                                        </div>
                                        <div id="hyperPaycontainer" v-show="paymentForm && isMada" class="col-md-4">
                                            <v-form :action="shopperResultUrl"
                                                    name="hyperPayForm"
                                                    class="paymentWidgets"
                                                    data-brands="MADA"></v-form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div class="login_footer form-row justify-content-between align-items-center">
                <div class="col-auto">
                    <a>
                        {{$t('shared.privacy')}}
                    </a>
                </div>
                <div class="col-auto">
                    <a>
                        {{$t('shared.termsOfUse')}}
                    </a>
                </div>
                <div class="col-auto">
                    <a>
                        {{$t('shared.copyRight')}} {{currentYear}}
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>

