import Progress from 'easy-circular-progress';
import { Inject } from 'vue-di-container';
import { Component, Prop, Vue, Watch } from 'vue-property-decorator';
import LoaderService from '../../../Services/LoaderService';
import { Toaster } from '../../../Services/toast';
import { SenderType } from '../../../shared/Enums/SenderType';
import UserRoleService from '../../../shared/userService/UserRoleService';
import UserService from '../../../shared/userService/UserService';
import SenderComplaintsService from '../SenderComplaintsService';
import WithRender from './ComplaintDetails.html';
import { ComplaintDetailsModel } from './ComplaintDetailsModel';


@WithRender
@Component({
    components: {
        Progress,
    },
    diProvide: [
        SenderComplaintsService,
        UserService,
        UserRoleService,
    ],
})
export default class ComplaintDetails extends Vue {
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    @Prop({ type: Boolean, default: false })
    @Prop({ type: String, default: '' })
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    @Inject(SenderComplaintsService) public dashboardService!: SenderComplaintsService;

    public complaint: ComplaintDetailsModel = {
        complaintId: '',
        senderType: 0,
        clientName: '',
        referenceNumber: '',
        providerName: '',
        customerType: 0,
        representativeEmail: '',
        representativePhone: '',
        representativeName: '',
        senderName: '',
        isCritical: false,
        senderExpiryDate: '',
    };
    public customerType: string = '';
    public id: string | any;
    public senderType: SenderType[] = [];
    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.id = this.$route.params.id;
        const response = await this.dashboardService.GetDetails(this.id);
        if (response.isSuccess === false) {
            Toaster.error(response.errorMessage);
        }
        else {
            this.complaint = response.result;
            this.getSenderType();
        }
    }

    public getCustomerTypeText(typeId: number) {
        switch (typeId) {
            case 1:
                return this.$t('shared.government').toString();
            case 2:
                return this.$t('shared.charity').toString();
            case 3:
                return this.$t('shared.private').toString();
            case 4:
                return this.$t('shared.international').toString();
            default:
                return '-';
        }
    }

    public getSenderType() {
        const senderBinary = this.complaint.senderType;
        if ((senderBinary & 1) === 1) {
            this.senderType.push(1);
        }
        if ((senderBinary & 2) === 2) {
            this.senderType.push(2);
        }
        if ((senderBinary & 4) === 4) {
            this.senderType.push(4);
        }
        if ((senderBinary & 8) === 8) {
            this.senderType.push(8);
        }
    }

    public getSenderTypeText(typeId: number) {
        switch (typeId) {
            case SenderType.Advertisement:
                return this.$t('shared.advertisement').toString();
            case SenderType.Service:
                return this.$t('shared.service').toString();
            case SenderType.Awareness:
                return this.$t('shared.awareness').toString();
            case SenderType.Warning:
                return this.$t('shared.warning').toString();
            default:
                return '-';
        }
    }
}