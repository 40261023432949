import Progress from 'easy-circular-progress';
import { Inject } from 'vue-di-container';
import { Component, Prop, Watch, Vue } from 'vue-property-decorator';
import LoaderService from '../../Services/LoaderService';
import { Toaster } from '../../Services/toast';
import UserModel from '../../shared/userService/Model/UserModel';
import UserRoleService from '../../shared/userService/UserRoleService';
import UserService from '../../shared/userService/UserService';
import { FilterModel } from './FilterModel';
import WithRender from './SenderComplaints.html';
import { SenderComplaintsModel } from './SenderComplaintsModel';
import SenderComplaintsService from './SenderComplaintsService';


@WithRender
@Component({
    components: {
        Progress,
    },
    diProvide: [
        SenderComplaintsService,
        UserService,
        UserRoleService,
    ],
})
export default class SenderComplaints extends Vue {
    @Inject(UserService) public userService!: UserService;
    @Inject(SenderComplaintsService) public senderComplaintsService!: SenderComplaintsService;
    @Inject(LoaderService) public loaderService!: LoaderService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public requestStatus: string = '';
    public senderStatus: string = '';
    public reactivateids: string[] = [];
    public senders: SenderComplaintsModel[] = [];
    public selected: SenderComplaintsModel[] = [];
    public senderStatusModel: any = {
        senderName: '',
        clientName: '',
        crNumber: '',
        enterpriseUnifiedNumber: '',
        customerType: '',
        senderType: '',
        senderStatus: '',
        hrsdLicense: '',
        isAlreadyExist: '',
    };
    public reactivateDialog: boolean = false;
    public filterModel: FilterModel = {
        complaintId: '',
        senderName: '',
        clientName: '',
        providerName: '',
    };

    public statusOptions: any[] = [
        {
            id: '1',
            name: this.$t('senderComplaints.pendingFor').toString()
                + ' ' + this.$t('senderComplaints.approval').toString(),
        },
        {
            id: '2',
            name: this.$t('senderComplaints.pendingFor').toString()
                + ' ' + this.$t('senderComplaints.payment').toString(),
        },
        {
            id: '3',
            name: this.$t('senderComplaints.rejected').toString()
                + ' ' + this.$t('senderComplaints.requests').toString(),
        },
        {
            id: '4',
            name: this.$t('senderComplaints.inactive').toString()
                + ' ' + this.$t('senderComplaints.requests').toString(),
        },
        {
            id: '5',
            name: this.$t('senderComplaints.active').toString()
                + ' ' + this.$t('senderComplaints.requests').toString(),
        },
        {
            id: '7',
            name: this.$t('senderComplaints.partially').toString()
                + ' ' + this.$t('senderComplaints.activated').toString(),
        },
    ];
    public user: UserModel = {
        userId: '',
        username: '',
        details: {
            Email: '',
            MobilePhone: '',
            Operator: '',
            Provider: '',
            OperatorId: '',
            ProviderId: '',
            TenantId: '',
            Role: '',
            ChangePasswordRequired: '',
        },
    };
    public senderName: string = '';
    public autoInterval: any;
    public userRole: number = 0;
    public scrollDiv: any;
    public nextPage: string = '';
    public scrollTop: number = 0;
    public selectedIds: string[] = [];
    public ids: string[] = [];
    public sendersCount: number = 0;
    public User: any;
    public anySelected: boolean = false;
    public pagination: any = {};
    public tenantId: string = '';
    public senderType: number[] = [];
    public headers: any = [
        { text: this.$t('senderComplaints.complaintId'), sortable: false, value: 'complaintId', align: 'center' },
        { text: this.$t('senderComplaints.senderName'), sortable: false, value: 'senderName', align: 'center' },
        { text: this.$t('senderComplaints.clientName'), sortable: false, value: 'clientName', align: 'center' },
        { text: this.$t('senderComplaints.providerName'), sortable: false, value: 'providerName', align: 'center' },
        {
            text: this.$t('senderComplaints.requestDate'), sortable: false,
            value: 'createdDate', align: 'center',
        },
        {
            text: this.$t('senderComplaints.status'), sortable: false, value: 'requestStatus',
            align: 'center', width: '190',
        },
        { text: this.$t('shared.actions'), sortable: false, align: 'center', width: '145' },
    ];
    public checkColor(typeStatus: string) {
        typeStatus = typeStatus.toString();
        switch (typeStatus) {
            case '1': {
                return '#c427ba';
                break;
            }
            case '2': {
                return '#5aaa0b';
                break;
            }
            case '3': {
                return '#C42727';
                break;
            }
            case '4': {
                return '#aaa7a7';
                break;
            }
            case '5': {
                return '#014bb1';
                break;
            }
            case '7': {
                return '#5c9bf2';
                break;
            }
            default: {
                return '#F78500';
                break;
            }
        }
    }
    public getSenderStatusById(typeStatus: number) {
        switch (typeStatus) {
            case 2: {
                return this.$t('shared.approved').toString();
            }
            case 4: {
                return this.$t('shared.deactivated').toString();
            }
            case 5: {
                return this.$t('shared.expired').toString();
            }
            case 7: {
                return this.$t('shared.paid').toString();
            }
        }
    }
    public GetSenderStatus(typeStatus: string) {
        typeStatus = typeStatus.toString();
        switch (typeStatus) {
            case '1': {
                return 'Pending Approval';
            }
            case '2': {
                return 'Approved';
            }
            case '3': {
                return 'Rejected';
            }
            case '4': {
                return 'Deactivated';
            }
            case '5': {
                return 'Expired';
            }
            case '6': {
                return 'Deleted';
            }
            case '7': {
                return 'Paid';
            }
            default: {
                return '';
            }
        }
    }


    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.User = await this.userService.getUser();
        this.userRole = this.userRoleService.GetUserRole(this.User);
        this.tenantId = this.User.details.TenantId;
        this.search();
    }

    public async search() {
        let model;
        model = {
            senderName: this.filterModel.senderName,
            complaintId: this.filterModel.complaintId,
            clientName: this.filterModel.clientName,
            providerName: this.filterModel.providerName,
            pageIndex: '0',
            pagesize: '10',
        };
        const response = await this.senderComplaintsService.Get(model);
        if (response !== false) {
            if (!response.isSuccess) {
                Toaster.error(response.errorMessage);
            }
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }

    public isEqual(value1: string | null, value2: string) {
        return (value1 === '' || value1 === null || value2.includes(value1));
    }
    public Details(id: string) {
        this.$router.push({ name: 'ComplaintDetails', params: { id } });
    }
    public async filter(requestStatus: string, senderStatus: string) {
        if (this.requestStatus === requestStatus &&
            this.senderStatus === senderStatus) {
            this.requestStatus = '0';
            this.senderStatus = '0';
            requestStatus = '0';
            senderStatus = '0';
        } else {
            this.requestStatus = requestStatus;
            this.senderStatus = senderStatus;
        }
        const model = {
            senderName: this.filterModel.senderName,
            complaintId: this.filterModel.complaintId,
            clientName: this.filterModel.clientName,
            providerName: this.filterModel.providerName,
            pageIndex: '0',
            pagesize: '10',
        };
        const response = await this.senderComplaintsService.Get(model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }

    public selectAll() {
        if (this.selected.length) {
            this.selected = [];
            this.selectedIds = [];
        } else {
            this.selected = this.senders.slice();
            this.selectedIds = this.senders.map((x) => x.senderRequestId);
        }
    }

    public select(event: any, id: string) {
        if (!event) {
            const index = this.selectedIds.indexOf(id);
            this.selectedIds.splice(index, 1);
        } else {
            this.selectedIds.push(id);
        }
    }
    public showReactivateDialog(id: string) {
        this.reactivateids = [];
        if (id !== '') {
            this.reactivateids.push(id);
        } else {
            this.reactivateids = this.selectedIds;
        }
        this.reactivateDialog = true;
    }
    public hideReactivateDialog() {
        this.reactivateDialog = false;
    }
    public async Reactivate(id: string) {
        let ids: string[] = [];
        ids = this.reactivateids;
        if (this.senders.filter((s) => ids.includes(s.senderRequestId) && s.senderStatus.toString() !== '4').length > 0) {
            Toaster.error(this.$t('senderComplaints.notAllSenderAreDeactivated').toString());
            this.reactivateDialog = false;
            return;
        }
        const response = await this.senderComplaintsService.Reactivate(ids);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
            this.reactivateDialog = false;
            await this.reload();
        } else {
            this.reactivateDialog = false;
            this.selectedIds = [];
            Toaster.success(this.$t('senderComplaints.reactivatedsuccessfully').toString());
            await this.reload();
        }
    }
    public async updatePaginate(val: any) {
        const model = {
            senderName: this.filterModel.senderName,
            complaintId: this.filterModel.complaintId,
            clientName: this.filterModel.clientName,
            providerName: this.filterModel.providerName,
            pageIndex: (val.page - 1).toString(),
            pagesize: val.rowsPerPage,
        };
        const response = await this.senderComplaintsService.Get(model);
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.senders = response.result;
        this.sendersCount = response.totalCount;
    }
    public get isAnySelected(): boolean {
        this.anySelected = this.selectedIds.length > 0;
        return this.anySelected;
    }
    public async reload() {
        const model = {
            senderName: this.filterModel.senderName,
            complaintId: this.filterModel.complaintId,
            clientName: this.filterModel.clientName,
            providerName: this.filterModel.providerName,
            pageIndex: '0',
            pagesize: '10',
        };
        const response = await this.senderComplaintsService.Get(model);
        this.senders = response.result;
        this.sendersCount = response.totalCount;
        this.selectedIds = [];
        this.selected = [];
    }
}