import AxiosService from '@/Services/AxiosService';
import LoaderService from '@/Services/LoaderService';
import { Inject, Service } from 'vue-di-container';
import { SenderModel } from '../Senders/SenderModel';

@Service()
export default class UpdateRequestDataService {
    @Inject(AxiosService) public axiosService!: AxiosService;
    @Inject(LoaderService) public loaderService!: LoaderService;

    public async get(id: string) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/update-request-data/' + id;
            const result = await this.axiosService.axiosInstance.get(url);
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }

    public async put(model: SenderModel, isFromDashboard: boolean) {
        this.loaderService.ShowLoader();
        try {
            const url = window.$config.HostName + 'api/update-request-data/';
            let result;
            if (isFromDashboard) {
                result = await this.axiosService.axiosInstance.post(url, model);
            } else {
                result = await this.axiosService.axiosInstance.put(url, model);
            }
            this.loaderService.HideLoader();
            return result.data;
        } catch (error) {
            this.loaderService.HideLoader();
            return false;
        }
    }
}
