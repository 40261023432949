import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './SupportPaymentTransaction.html';
import { Toaster } from '../../Services/toast';
import SupportPaymentTransactionService from './SupportPaymentTransactionService';
import { SupportPaymentTransactionModel } from './SupportPaymentTransactionModel';
import FileSaver from 'file-saver';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        SupportPaymentTransactionService,
    ],
})
export default class SupportPaymentTransaction extends Vue {
    @Inject(SupportPaymentTransactionService) public supportPaymentTransactionService!
        : SupportPaymentTransactionService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public headers: any = [
        { text: this.$t('dashboardPage.requestId'), value: 'requestId', align: 'center', sortable: false },
        { text: this.$t('shared.sendersName'), value: 'senderName', align: 'center', sortable: false },
        { text: this.$t('shared.InvoicesNumber'), value: 'invoiceNumber', align: 'center', sortable: false },
        { text: this.$t('shared.providerName'), value: 'provider', align: 'center', sortable: false },
        { text: this.$t('shared.paymentReference'), value: 'paymentReference', align: 'center', sortable: false },
        { text: this.$t('dashboardPage.status'), value: 'status', align: 'center', sortable: false },
        { text: this.$t('shared.createdDate'), value: 'createdDate', align: 'center', sortable: false },
    ];

    public invoicesModel: SupportPaymentTransactionModel[] = [];
    public invoicesCount: number = 0;
    public filterinvoices: SupportPaymentTransactionModel[] = [];
    public filterModel: any = {
        invoiceNumber: '',
        senderName: '',
        requestId: '',
        provider: '',
        paymentReference: '',
    };
    public async created() {
        this.$emit('childinit', this.layoutSup);
    }
    public async mounted() {
        const response = await this.supportPaymentTransactionService.get
            (this.filterModel.invoiceNumber.toString(), this.filterModel.senderName,
                this.filterModel.requestId, this.filterModel.provider, this.filterModel.paymentReference, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.invoicesModel = response.result;
        this.invoicesCount = response.totalCount;
    }
    @Watch('filterModel', { deep: true })
    public async search() {
        const response = await this.supportPaymentTransactionService.get
            (this.filterModel.invoiceNumber.toString(), this.filterModel.senderName,
                this.filterModel.requestId, this.filterModel.provider, this.filterModel.paymentReference, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.invoicesModel = response.result;
        this.invoicesCount = response.totalCount;
    }

    public async reload() {
        const response = await this.supportPaymentTransactionService.get
            (this.filterModel.invoiceNumber.toString(), this.filterModel.senderName,
                this.filterModel.requestId, this.filterModel.provider, this.filterModel.paymentReference, '0', '10');
        if (!response.isSuccess) {
            Toaster.error(response.errorMessage);
        }
        this.invoicesModel = response.result;
        this.invoicesCount = response.totalCount;
    }
    public async updatePaginate(val: any) {
        const response = await this.supportPaymentTransactionService.get
            (this.filterModel.invoiceNumber.toString(), this.filterModel.senderName,
                this.filterModel.requestId, this.filterModel.provider, this.filterModel.paymentReference,
                (val.page - 1).toString(), val.rowsPerPage);
        this.invoicesModel = response.result;
        this.invoicesCount = response.totalCount;
    }
}
