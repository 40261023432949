import { ShortCodeType } from './../../../shared/Enums/ShortCodeType';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import ShortService from '../ShortService';
import WithRender from './AddShortCode.html';
import { OperatorDropdownModel } from './operatorDropdownModel';
import UserService from '../../../shared/userService/UserService';
import { Toaster } from '../../../Services/toast';
import ValidationService from '../../../Services/ValidationService';
import { ShortModel } from '../ShortModel';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        ShortService,
        UserService,
        ],
})
export default class AddShortCode extends Vue {
    @Inject(ShortService) public shortService!: ShortService;
    @Inject(ValidationService) public validationService!: ValidationService;
    @Inject(UserService) public userService!: UserService;

    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    @Prop({ type: String, default: '' })
    public id: string | any;
    public addShortDiDialog: boolean = false;
    public User: any;
    public operatorOptions: any;
    public shortCodeTypes : any;
    shortCodeModel: ShortModel| any ={};
    editMode: boolean =false;
    


    public async mounted() {
        this.$emit('childinit', this.layoutSup);
        this.User = await this.userService.getUser();
        this.id = this.$route.params.id;
        if (this.id && this.id !==null) {
            const responseShortCode =await this.shortService.getById(this.id);
            this.shortCodeModel = responseShortCode.result;
            this.editMode= true;
        }
    }
    public created() {
        this.operatorOptions = window.$config.OperarorList;
        this.shortCodeTypes =
        [
            {"id": 1,"name": "White List"},
            {"id": 2,"name": "Black List"},
        ];

    }
    public get isValid(): boolean {
        return (
            this.shortCodeModel.operatorId !== 0 && this.shortCodeModel.operatorId !== null
            && this.shortCodeModel.shortCodeType !== 0 && this.shortCodeModel.shortCodeType !== null
            && this.shortCodeModel.operatorId !== undefined && this.shortCodeModel.name !== ''
            );
    }

    public isNumberField(val: KeyboardEvent) {
        if (!this.validationService.isNumber(val.key)) {
            val.preventDefault();
        }
    }

    public isNumberFieldPaste(ev: any) {
        const paste = (ev.clipboardData).getData('text');
        if (isNaN(paste)) {
            ev.preventDefault();
        }
    }
    public showaddShortCodeDialog(id: string) {
        this.addShortDiDialog = true;
    }
    public hideddShortDiDialog() {
        this.addShortDiDialog = false;
    }
    public async addShortCode() {
        const response = await this.shortService.post(this.shortCodeModel.name, this.shortCodeModel.operatorId,
            this.User.details.ProviderId, this.shortCodeModel.shortCodeType);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.addShortSuccessfully').toString());
            this.shortCodeModel.operatorId = 0;
            this.shortCodeModel.name = '';
            this.$router.push({ name: 'Shorts' });
        } else {
            Toaster.error(response.errorMessage);
        }
        this.hideddShortDiDialog();
    }
    
    public async editShortCode() {
        const response = await this.shortService.edit(this.id,this.shortCodeModel.shortCodeType);
        if (response.isSuccess && response.result) {
            Toaster.success(this.$t('shared.updateShortSuccessfully').toString());
            this.$router.push({ name: 'Shorts' });
        } else {
            Toaster.error(response.errorMessage);
        }
        this.hideddShortDiDialog();
    }

    save(){
        this.editMode?this.editShortCode():this.addShortCode();
    }
}
