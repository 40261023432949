<div class="w-100 h-100">
    <div class="row" style="padding-top: 25px;padding-bottom: 25px;">
        <div class="col-md-12">
            <div class="form-row justify-content-between align-items-baseline">
                <div class="col-auto">
                    <label class="header_text mb-3">
                        {{$t('shared.complaintID')}} #{{complaint.complaintId}}
                    </label>
                </div>
            </div>
        </div>
    </div>
    <div class="row">
        <div class="col-md-12">
            <div class="card card_layout">
                <div class="card-body card_body_layout">
                    <div class="row">
                        <div class="col-md-9">
                            <div class="row">
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.providerName')}}</label>
                                    <h4 class="user_info_txt">{{complaint.providerName}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.customerType')}}</label>
                                    <h4 class="user_info_txt">{{getCustomerTypeText(complaint.customerType)}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0" v-if="complaint.customerType === 1">{{$t('shared.budgetNumber')}}</label>
                                    <label class="user_info_header mb-0" v-if="complaint.customerType === 2">{{$t('shared.licenseNumber')}}</label>
                                    <label class="user_info_header mb-0" v-if="complaint.customerType === 3">{{$t('shared.commercialRegistrationNumber')}}</label>
                                    <label class="user_info_header mb-0" v-if="complaint.customerType === 4">{{$t('shared.internationalCommercialRegistrationNumber')}}</label>
                                    <h4 class="user_info_txt no-collapse">{{complaint.referenceNumber}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('dashboardPage.senderName')}}</label>
                                    <h4 class="user_info_txt">{{complaint.senderName}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.senderType')}}</label>
                                    <h4 class="user_info_txt">
                                        <span v-for="(type, index) in senderType" :key="type">
                                            {{getSenderTypeText(type)}}
                                        </span>
                                    </h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.clientName')}}</label>
                                    <h4 class="user_info_txt">{{complaint.clientName}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.representativeName')}}</label>
                                    <h4 class="user_info_txt">{{complaint.representativeName}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.apEmail')}}</label>
                                    <h4 class="user_info_txt">{{complaint.representativeEmail}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.phoneNumber')}}</label>
                                    <h4 v-if="complaint.customerType === 1 ||complaint.customerType === 2 ||complaint.customerType === 3" class="user_info_txt"><span class="pr-3">+966</span> {{complaint.representativePhone}}</h4>
                                    <h4 v-if="complaint.customerType === 4" class="user_info_txt">{{complaint.representativePhone}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.senderCriticality')}}</label>
                                    <h4 v-if="complaint.isCritical" class="user_info_txt">{{$t('shared.critical')}}</h4>
                                    <h4 v-if="!complaint.isCritical" class="user_info_txt">{{$t('shared.notCritical')}}</h4>
                                </div>
                                <div class="col-sm-4 mb-3">
                                    <label class="user_info_header mb-0">{{$t('shared.senderExpiryDate')}}</label>
                                    <h4 class="user_info_txt">{{complaint.senderExpiryDate}}</h4>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>