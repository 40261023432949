import { ErrorHandler } from './errorHandler';
import axios, { AxiosInstance } from 'axios';
import { Service, Inject } from 'vue-di-container';
import TokenService from './TokenService';

@Service()
export default class AxiosService {
    @Inject(TokenService) public tokenService!: TokenService;

    public axiosInstance: AxiosInstance;
    constructor() {
        const config = {
            baseURL: process.env.BASE_URL,
            // timeout: 60 * 1000, // Timeout
            // withCredentials: true, // Check cross-site Access-Control
        };
        this.axiosInstance = axios.create(config);
        this.axiosInstance.interceptors.request.use(
            async (axiosRequestConfig) => {
                if (this.tokenService) {
                    const token = await this.tokenService.getToken();
                    if (token) {
                        const authStr = 'Bearer '.concat(token).toString();
                        axiosRequestConfig.headers.Authorization = authStr;
                    }
                }
                return axiosRequestConfig;
            },
            (error) => ErrorHandler.errorResponseHandler(error),
        );

        // Add a response interceptor
        this.axiosInstance.interceptors.response.use(
            (axiosResponse) => axiosResponse,
            (error) => {
                ErrorHandler.errorResponseHandler(error);
            },
        );
    }
}
