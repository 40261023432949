import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { Inject } from 'vue-di-container';
import WithRender from './ProvidersFinance.html';
import ProvidersFinanceService from './ProvidersFinanceService';
import { ProvidersFinanceModel } from './ProvidersFinanceModel';
import { Toaster } from '../../Services/toast';
import UserService from '../../shared/userService/UserService';
import UserRoleService from '../../shared/userService/UserRoleService';

@WithRender
@Component({
    components: {
    },
    diProvide: [
        UserService,
        UserRoleService,
        ProvidersFinanceService,
    ],
})
export default class ProvidersFinance extends Vue {
    @Inject(ProvidersFinanceService) public providersFinanceService!: ProvidersFinanceService;
    @Inject(UserService) public userService!: UserService;
    @Inject(UserRoleService) public userRoleService!: UserRoleService;
    @Prop({ type: Boolean, default: false })
    public layoutSup: boolean | any;
    public providerId: string = '';
    public userRole: any;
    public User: any;
    public headers: any = [
        { text: this.$t('shared.providerName'), value: 'name', align: 'center', sortable: false },
        { text: this.$t('shared.balance'), value: 'balance', align: 'center', sortable: false },
        { text: this.$t('shared.creditLimitAmount'), value: 'creditLimitAmount', align: 'center', sortable: false },
        { text: this.$t('shared.actions'), sortable: false, value: 'id', align: 'center', width: '100' },
    ];
    public providersFinance: ProvidersFinanceModel[] = [];
    public providersCount: number = 0;
    public providersFiltered: ProvidersFinanceModel[] = [];
    public filterModel: ProvidersFinanceModel = {
        id: '',
        name: '',
        balance: 0,
        creditLimitAmount: 0,
    };

    public created() {
        this.$emit('childinit', this.layoutSup);
    }

    public async mounted() {
        const response = await this.providersFinanceService.Get(this.filterModel.name, '0', '10');
        if (response.isSuccess) {
            this.providersFinance = response.result;
            this.providersCount = response.totalCount;
        } else {
            Toaster.error(response.errorMessage);
        }
        this.User = await this.userService.getUser();
        this.userRole = this.GetUserRole();
    }
    public GetUserRole() {
        return this.userRoleService.GetUserRole(this.User);
    }

    @Watch('filterModel', { deep: true })
    public async search() {
        const response = await this.providersFinanceService.Get(this.filterModel.name, '0', '10');
        if (response.isSuccess) {
            this.providersFinance = response.result;
            this.providersCount = response.totalCount;
        } else {
            Toaster.error(response.errorMessage);
        }
    }
    public async updatePaginate(val: any) {
        const response = await this.providersFinanceService.Get
            (this.filterModel.name, (val.page - 1).toString(), val.rowsPerPage);
        this.providersFinance = response.result;
        this.providersCount = response.totalCount;
    }
}
